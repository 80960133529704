import React, { useState, Fragment, useEffect } from 'react'
import {
	BoardContainer,
	ContainerListCards,
	GoalContainer,
	Header,
	SectionLabel,
	SeparationDrawerBoardWrapper,
} from './styles'
import { ISeparationDrawerBoardProps } from './types'
import ArrowDown from '../../assets/images/arrow-to-down.svg'
import ListSeparationTickets from 'modules/ListSeparationTickets'
import { getSeparationBoardColumns } from 'shared/util/separation'
import { dateFormat } from 'shared/util/translate'
import { ITicket } from 'shared/interfaces/ticket'

function SeparationDrawerBoard(props: ISeparationDrawerBoardProps): JSX.Element {
	const {
		activateModalTicket,
		fetchParams,
		scheduleDate,
		selectedRetailsInThePageFilter
	} = props

	const [formattedDate, setFormattedDate] = useState('')
	const [ticketsColumns, setTicketsColumns] = useState<{ [key: string]: ITicket[] }>({
		pendingColumn: [],
		separationColumn: [],
		checkingColumn: []
	})
	const [canOpenList, setCanOpenList] = useState(false)

	const [currentStatus, setCurrentStatus] = useState(false)

	const currentHeaderClass = currentStatus ? true : false

	function mergeTickets() {
		const mergedTickets = scheduleDate.reduce((acc: ITicket[], dateScheduleItem) => {
			acc.push(...dateScheduleItem.tickets)
			return acc
		}, [])

		return mergedTickets
	}

	function formatDate() {
		const { date, period } = scheduleDate[0]

		setFormattedDate(dateFormat(new Date(date), period))
	}

	function loadData() {
		formatDate()

		const tickets = mergeTickets()

		setCanOpenList(tickets.length > 0)
		setTicketsColumns(getSeparationBoardColumns(tickets))
	}

	function toggleVisibility() {
		if (!canOpenList) return

		setCurrentStatus((state) => !state)
	}

	function renderRetailsTicketLimit() {
		return scheduleDate.map(({ totalOrders, maxItems, retail }) => {
			const retailName = selectedRetailsInThePageFilter.find(
				retailOption => retailOption.value === retail
			)

			if (!retailName) return <></>
			
			const formattedString = `${retailName.label} - ${totalOrders}/${maxItems}`

			const currentGoalStyle = totalOrders >= maxItems
				? 'goal-inactive'
				: 'goal-active'

			return (
				<GoalContainer className={currentGoalStyle}>
					{formattedString}
				</GoalContainer>
			)
		})
	}

	useEffect(loadData, [])

	return (
		<>
			<SeparationDrawerBoardWrapper
				isActiveToClick={canOpenList}
				onClick={toggleVisibility}
			>
				<Header currentHeaderClass={currentHeaderClass}>
					<SectionLabel>{formattedDate}</SectionLabel>
					<div>
						<>
							{renderRetailsTicketLimit()}
							{canOpenList && (
								<img src={ArrowDown} alt='arrow-down' />
							)}
						</>
					</div>
				</Header>
			</SeparationDrawerBoardWrapper>
			<BoardContainer>
				{currentStatus ? (
					<ContainerListCards>
						<ListSeparationTickets
							title='Pendente'
							cards={ticketsColumns.pendingColumn}
							activateModalTicket={activateModalTicket}
							fetchParams={fetchParams}
						/>
						<ListSeparationTickets
							title='Em separação'
							cards={ticketsColumns.separationColumn}
							activateModalTicket={activateModalTicket}
							fetchParams={fetchParams}
						/>
						<ListSeparationTickets
							title='Em Conferência'
							cards={ticketsColumns.checkingColumn}
							activateModalTicket={activateModalTicket}
							fetchParams={fetchParams}
						/>
					</ContainerListCards>
				) : (
					<Fragment />
				)}
			</BoardContainer>
		</>
	)
}

export default SeparationDrawerBoard
