import React from 'react'
import { FloatLabelInput, Select } from '@buildbox/components'
import {
	primaryDisableInputTicketStyles,
	primaryDisableSelectTicketStyles,
	primaryInputStyles
} from 'shared/styles/theme'
import { labelChannel, CustomerProfile } from 'shared/util/Consts'
import OrderDisable from 'modules/OrderDisable'
import StatusActionComponent from './StatusActionComponent'
import { IViewProps } from './types'
import {
	CustomModal as Modal,
	ModalContent,
	SectionLabel,
	OrderInformation,
	OrderLabel,
	OrderValueWrapper,
	OrderValue,
	OrderRequests,
	Content,
	OrderContainer,
	OrderWithdrawalWrapper
} from './styles'
import { getRetailName } from './util'

const ModalTicket: React.FC<IViewProps> = (props: IViewProps) => {
	const {
		active,
		className,
		ticket,
		withdrawalAssistantsOptions,
		handleCloseModal,
		handleSetAction,
		handleSetWithdrawalAssistants,
		handleConfirmAction,
		user
	} = props

	const {
		withdrawalLocale,
		ticketNumber,
		createdBy,
		codeOrName,
		channel,
		customerProfile,
		orders,
		totalValue,
		retail
	} = ticket.dataToShow

	return (
		<Modal
			title='Detalhe do Ticket'
			isActive={active}
			onClose={handleCloseModal}
			className={className}
			size={600}
		>
			<ModalContent>
				<>
					<Content>
						<SectionLabel>Retirada</SectionLabel>
						<StatusActionComponent
        					ticket={ticket}
        					withdrawalAssistantsOptions={withdrawalAssistantsOptions}
        					handleSetAction={handleSetAction}
        					handleConfirmAction={handleConfirmAction}
        					handleSetWithdrawalAssistants={handleSetWithdrawalAssistants}
						/>
					</Content>

					<Content>
						<SectionLabel>Separação</SectionLabel>
						<OrderWithdrawalWrapper>
							<FloatLabelInput
								{...primaryInputStyles}
								label='LOCAL PARA RETIRADA'
								round
								id='withdrawal-locale'
								value={withdrawalLocale}
								disabled
								dashedWhenDisabled={false}
							/>
						</OrderWithdrawalWrapper>
					</Content>

					<Content>
						<SectionLabel>Informações sobre o Ticket</SectionLabel>
						<div className='text-ticket'>
							<span className='ticket'>Ticket: {ticketNumber}</span>
						</div>
					</Content>
				</>

				<OrderInformation>
					<Content>
						<div className='input-wrapper'>
							<div>
								<FloatLabelInput
									label='CRIADO POR'
									id='created-by'
									round
									disabled
									value={createdBy}
									{...primaryDisableInputTicketStyles}
									dashedWhenDisabled={false}
								/>
								<FloatLabelInput
									label='CÓDIGO OU NOME DO CLIENTE'
									id='code-or-name'
									round
									value={codeOrName}
									{...primaryDisableInputTicketStyles}
									disabled
									dashedWhenDisabled={false}
								/>
								<FloatLabelInput
									label='LOJA'
									id='retail'
									round
									value={getRetailName({
										ticketRetailId: retail,
										userRetails: user.retails
									})}
									{...primaryDisableInputTicketStyles}
									disabled={true}
									dashedWhenDisabled={false}
								/>
							</div>
							<div>
								<Select
									round
									placeholder='CANAL'
									{...primaryDisableSelectTicketStyles}
									id='select'
									value={labelChannel.find(
										i => i.value === channel
									)}
									isDisabled
									dashedWhenDisabled={false}
								/>
								<Select
									round
									placeholder='PERFIL CLIENTE'
									{...primaryDisableSelectTicketStyles}
									id='select2'
									value={CustomerProfile.find(
										i => i.value === customerProfile
									)}
									isDisabled
									dashedWhenDisabled={false}
								/>
							</div>
						</div>
					</Content>

					<OrderValueWrapper>
						<OrderRequests>
							<OrderLabel>N de pedidos</OrderLabel>
							<OrderValue>{orders.length}</OrderValue>
						</OrderRequests>
						<OrderRequests>
							<OrderLabel>Valor total</OrderLabel>
							<OrderValue>{totalValue}</OrderValue>
						</OrderRequests>
					</OrderValueWrapper>

					<OrderContainer>
						{orders.length > 0 &&
							orders.map((order, key) => (
								<OrderDisable
									key={key}
									order={order}
									index={key}
								/>
							))}
					</OrderContainer>
				</OrderInformation>
			</ModalContent>
		</Modal>
	)
}

export default ModalTicket
