import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const PendingForApprovalButton = styled.button`
	background-color: ${({ theme }) => theme.colors.lightRed};
	padding: 20px;
	display: flex;
	align-items: center;
	border-radius: 29px;
	color: ${({ theme }) => theme.colors.red};
	justify-content: space-between;
	border: none;
	cursor: pointer;
	height: 58px;
`

export const Label = styled.span`
	${fontType(0)}
	font-size: 14px;
	margin-right: 20px;
`

export const PendingForApprovalContainer = styled.div`
	& * {
		${fontType(0)};
	}
`

export const ModalWrapper = styled.div`
	p + p {
		margin-top: 16px;
	}
`

export const PendingForApprovalWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 32px;
	margin-top: 32px; 
`
