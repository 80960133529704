import React from 'react'
import { IRetail } from 'shared/interfaces/retail'

import TrashIcon from '../../assets/images/trash.svg'
import SettingsIcon from '../../assets/images/settings.svg'

function renderTrashIcon(onClick: () => void): JSX.Element {
	return (
		<div className='icon-wrapper'>
			<button onClick={onClick} className='trash-icon'>
				<img src={TrashIcon} alt='trash' />
			</button>
		</div>
	)
}

function renderSettingsIcon(onClick: () => void): JSX.Element {
	return (
		<div className='icon-wrapper'>
			<button onClick={onClick} className='settings-icon'>
				<img src={SettingsIcon} alt='settings' />
			</button>
		</div>
	)
}

function getRetailName(retails: IRetail[], retailId: string) {
	const findRetail = retails.find((retail) => retail._id === retailId)

	return findRetail?.name || ''
}

export const render = {
	trashIcon: renderTrashIcon,
	settingsIcon: renderSettingsIcon,
	retailName: getRetailName,
}
