import styled from 'styled-components'

import { fontType, toRem, transition } from '../../styles/functions'

export const ButtonPrimaryWrapper = styled.div`
	button {
		background: ${({ theme }) => theme.colors.blue};
		color: ${({ theme }) => theme.colors.white};
		border: none;
		border-radius: 29px;
		padding: 20px 64px;
		font-size: ${toRem(14)};

		cursor: pointer;
		${fontType(1)}
		${transition()}

		&:hover {
			opacity: 0.8;
		}

		&:active {
			transform: translateY(2px);
			${transition(0)}
		}

		&[disabled] {
			background: ${({ theme }) => theme.colors.grey};
			cursor: not-allowed;
			opacity: 1;
		}
	}
`
