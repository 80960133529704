import React, { ChangeEvent } from 'react'
import UploadIcon from '../../../assets/images/upload.svg'
import TrashIcon from '../../../assets/images/white-trash-icon.svg'
import { IImageUploadProps } from './types'
import { ImageUploadWrapper } from './styles'

export default function ImageUpload({
	returnImage,
	imageUrl,
	width = '327',
	id = 'image',
	height = '211',
	secondLabel
}: IImageUploadProps) {
	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		returnImage({
			file: selectedImage,
			url: URL.createObjectURL(selectedImage)
		})

		event.target.value = ''
	}

	function handleRemoveImage() {
		returnImage({ file: null, url: '' })
	}

	return (
		<ImageUploadWrapper containerWidth={width} containerHeight={height}>
			<label className='label' htmlFor={id}>
				{imageUrl ? (
					<div className='preview-image'>
						<img
							src={imageUrl}
							alt='Imagem do Banner'
						/>
						<div />
					</div>
				) : (
					<div className='placeholder'>
						<input
							id={id}
							type='file'
							accept='.png, .jpg, .jpeg'
							onChange={handleInputChange}
						/>
						<img src={UploadIcon} alt='upload-icon' />
						<span>Arraste ou selecione a imagem</span>
						{secondLabel ? <span className='second-label'>{secondLabel}</span> : null}
						<span>PESO MÁXIMO: 500KB</span>
					</div>
				)}
			</label>

			{imageUrl && (
				<button className='trash-icon' onClick={handleRemoveImage}>
					<img src={TrashIcon} alt='trash-icon' />
				</button>
			)}
		</ImageUploadWrapper>
	)
}
