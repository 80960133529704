import React from 'react'
import { Container } from './styles'
import { IProps } from './types'
import { ReactComponent as Arrow } from '../../assets/images/arrow-left.svg'
import { generalDashboardTitle } from 'shared/components/GeneralTime/util'
import { TicketStatusValueOptions } from 'shared/interfaces/ticket'

function GeneralTimeDetail({ setView, data }: IProps): JSX.Element {
	return (
		<Container>
			<div>
				<div onClick={() => setView(false)}>
					<span>
						<Arrow />
					</span>
					<p>Ver todos</p>
				</div>
				<h4>
					{generalDashboardTitle(data.sectionTitle)} (
					{data.averageTotalAttendanceTime.toFixed(1)} min)
				</h4>
				{data.sectionTitle === TicketStatusValueOptions.ATTENDANCE ? (
					<table>
						<thead>
							<tr>
								<th>Em Atendimento</th>
								<th>Cotação Enviada</th>
								<th>Em Negociação</th>
								<th>Negociação Enviada</th>
								<th>Ticket Aprovado</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								{data.attendanceTimesPerStatus.map((e) => (
									<td key={e.averageTime}>{e.averageTime.toFixed(1)} min</td>
								))}
							</tr>
						</tbody>
					</table>
				) : (
					<table>
						<thead>
							<tr>
								<th>Pendente</th>
								<th>Em Separação</th>
								<th>Separado</th>
								<th>Em Conferência</th>
								<th>Conferido</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								{data.attendanceTimesPerStatus.map((e) => (
									<td key={e.averageTime}>{e.averageTime ? e.averageTime.toFixed(1) : '-'} min</td>
								))}
							</tr>
						</tbody>
					</table>
				)}
			</div>
		</Container>
	)
}

export default GeneralTimeDetail
