import { createElement, FormEvent, useEffect, useState } from 'react'
import {
	IModalTicket,
	IProps,
	IViewProps,
	ITicketWithdrawalMessage,
} from './types'
import ModalTicket from './view'
import {
	ChannelTypeEnum,
	CustomerProfileEnum,
	TicketSeparationStatusEnum,
	TicketStatusEnum,
	ISeparationDateOption,
	ITicket,
	ticketSeparationStatus,
} from 'shared/interfaces/ticket'
import {
	createNewOrder,
	fetchDeleteTicket,
	fetchNewTicket,
	fetchUpdateTicket,
} from 'shared/services/ticket.service'
import { SelectOption } from '@buildbox/components'
import { IOrder } from 'shared/interfaces/order'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { CustomerProfile, labelChannel, WITHDRAWAL_MESSAGE } from 'shared/util/Consts'
import {
	getSeparationScheduleSuggestion,
	getOrderWithdrawalDate,
	fetchRetailSeparationDateOptions,
	tryRescheduleTicket,
	sendPluginRequest,
} from 'shared/services/separation.service'
import { ISelectOption } from '@buildbox/components/lib/components/Select/types'
import { formatSchedulingDateOption } from 'shared/util/schedulingDateOptions'
import {
	IPermission,
	permissionNameValues,
} from 'shared/interfaces/permissions'

function ModalCards(props: IProps): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const { active, className, handleModal, modalAction, currentTicket } = props

	const [filledData, setFilledData] = useState(false)

	const [profileOptions, setProfileOptions] = useState<SelectOption[]>([])
	const [newStatusSelection, setNewStatusSelection] = useState<
		TicketStatusEnum[]
	>([])

	const [newActionOptionSelected, setNewActionOptionSelected] =
		useState<SelectOption | null>(null)

	const [currentOrderWithdrawalOption, setCurrentOrderWithdrawalOption] =
		useState<SelectOption>()
	const [orderWithdrawalOptionSelected, setOrderWithdrawalOptionSelected] =
		useState<SelectOption>()

	const [oldInputValues, setOldInputValues] = useState<any[]>([])
	const [oldOrderValue, setOldOrderValue] = useState<IOrder[]>([])
	const [scheduleDateOptions, setScheduleDateOptions] = useState<
		ISelectOption[]
	>([])

	//#region [ Channel ]

	const [channelOptions, setChannelOptions] = useState<SelectOption[]>([])
	const [selectedChannel, setSelectedChannel] = useState<SelectOption | null>(
		null,
	)
	const [channel, setChannel] = useState<ChannelTypeEnum>('STORE')

	const [retailOptions, setRetailOptions] = useState<SelectOption[]>([])
	const [selectedRetail, setSelectedRetail] = useState<SelectOption | null>(
		null,
	)

	//#endregion

	const [selectedProfile, setSelectedProfile] = useState<SelectOption | null>(
		null,
	)
	const [profile, setProfile] = useState<CustomerProfileEnum>('B2B_CONSUMPTION')

	const [selectedAction, setSelectedAction] = useState('')

	const [selectedOrderWithdrawal, setSelectedOrderWithdrawal] = useState('')

	const [ticketNumber, setTicketNumber] = useState(0)

	const [codeOrName, setCodeOrName] = useState('')
	const [currentStatus, setCurrentStatus] =
		useState<TicketStatusEnum>('ATTENDANCE')

	const [selectOthers, setSelectOthers] = useState(false)

	const [orders, setOrders] = useState<IOrder[]>([])

	const [inputOthers, setInputOthers] = useState<string>('')

	const [confirmPluginRequest, setConfirmPluginRequest] = useState(false)
	const [canPluginRequest, setCanPluginRequest] = useState(false)

	const [withdrawalMessage, setWithdrawalMessage] =
		useState<ITicketWithdrawalMessage>()

	const [selectedTicket, setSelectedTicket] = useState<boolean>(false)

	const [total, setTotal] = useState<number>(0)

	const [isLoading, setIsLoading] = useState(false)

	const onChangeOrder = (index: number, updatedOrder: IOrder) => {
		orders[index] = updatedOrder
		setOrders([...orders])
	}

	function populateRetailOptions() {
		const userRetails = user.retails

		if (modalAction === 'EDIT') {
			const selectedUserRetail = userRetails.find(
				userRetail => userRetail._id === currentTicket.retail
			)

			if (selectedUserRetail) {
				setSelectedRetail({
					value: String(selectedUserRetail._id),
					label: selectedUserRetail.name
				})
			}

			return
		}

		setRetailOptions(userRetails.map(retail => ({
			value: String(retail._id),
			label: retail.name
		})))
	}

	function initializeTicketFields() {
		setTicketNumber(currentTicket.ticket)
		setChannel(
			currentTicket._id ? (currentTicket.channel as ChannelTypeEnum) : 'STORE',
		)
		setCurrentStatus(currentTicket.currentStatus)
		setCodeOrName(currentTicket.codeOrName)
		actionsStatusEdit(currentTicket.currentStatus)
		setProfile(currentTicket.customerProfile as CustomerProfileEnum)

		const orders =
			currentTicket.orders.length > 0
				? currentTicket.orders
				: [createNewOrder()]

		setOldInputValues([
			currentTicket.channel,
			currentTicket.customerProfile,
			currentTicket.codeOrName,
			'',
		])
		setOldOrderValue(currentTicket.orders)

		setOrders(orders)
	}

	function handleValidateForm() {
		let isValid = true

		if (checkEmpty(channel)) isValid = false
		if (checkEmpty(codeOrName)) isValid = false

		const isOrderValid = isValidateOrders()

		if (!isOrderValid) isValid = false

		if (!selectedRetail) isValid = false

		setFilledData(isValid)
	}

	function checkEmpty(value: string) {
		return value === '' ? true : false
	}

	function validateEmptyOrders() {
		return !(
			!hasOrders() &&
			(currentTicket.currentStatus === 'NEGOTIATION_SENT' ||
				currentTicket.currentStatus === 'APPROVED')
		)
	}

	function wasThereAnUpdateInInputs() {
		// hack for get enum value
		let hackChannel: any = channel
		hackChannel = typeof hackChannel == 'object' ? hackChannel[0] : hackChannel

		const realProfile = profile === 'OTHERS' ? inputOthers : profile

		const newInputValues = [
			hackChannel,
			realProfile,
			codeOrName,
			selectedAction,
		]

		const hasNewData = oldInputValues
			// checks whether it contains the same data
			.map((x) => newInputValues.includes(x))
			// make sure you have any different items
			.some((x) => x === false)

		const anyOrderChanged = someOrderHasBeenChanged()

		const updated = hasNewData || anyOrderChanged

		return updated
	}

	function someOrderHasBeenChanged() {
		if (orders.length !== oldOrderValue.length) return true

		const hasNewValue = oldOrderValue
			.map((order, index) => {
				const currentOrder = orders[index]
				if (currentOrder === null) return true

				// if (order._id !== currentOrder._id) return true
				if (order.code !== currentOrder.code) return true
				if (order.quantity !== currentOrder.quantity) return true
				if (order.totalSkus !== currentOrder.totalSkus) return true
				if (order.totalValue !== currentOrder.totalValue) return true

				return false
			})
			.some((item) => item === true)

		return hasNewValue
	}

	function calculate() {
		const total = orders.reduce((total, value) => total + value.totalValue, 0)
		setTotal(total)
	}

	function handleEffectProfile(selectedOption: SelectOption | null) {
		if (!selectedOption) return

		setProfileOptions(profileOptions)
		setSelectedProfile(selectedOption)
		setProfile(selectedOption.value as CustomerProfileEnum)
	}

	function handleEffectChannel(selectedOption: SelectOption | null) {
		if (!selectedOption) return

		setChannelOptions(channelOptions)
		setSelectedChannel(selectedOption)
		setChannel(selectedOption.value as ChannelTypeEnum)
	}

	function handleEffectRetail(selectedOption: SelectOption | null) {
		if (!selectedOption) return

		setSelectedRetail(selectedOption)
	}

	function handlerSelectNewAction(selectedOption: SelectOption | null): void {
		if (!selectedOption) return

		setNewActionOptionSelected(selectedOption)
		setSelectedAction(selectedOption.value as TicketStatusEnum)
	}

	async function handlerSelectOrderWithdrawal(selectedOption: SelectOption) {
		if (
			!selectedOption ||
			selectedOption.value === orderWithdrawalOptionSelected?.value
		)
			return

		const { value, label } = selectedOption

		const selectedLabel = { ...JSON.parse(label), selected: true }

		setOrderWithdrawalOptionSelected({
			value,
			label: JSON.stringify(selectedLabel),
		})
		setSelectedOrderWithdrawal(selectedOption.value)

		if (currentTicket._id) {
			const { value, period } = JSON.parse(selectedOption.value)

			const { limitAlreadyExceeded } = await tryRescheduleTicket({
				retailId: currentTicket.retail,
				ticketId: currentTicket._id,
				date: value,
				period: period,
			})

			if (limitAlreadyExceeded) {
				setConfirmPluginRequest(true)
			} else {
				currentTicket.currentSeparationStatus =
					ticketSeparationStatus.SEPARATION_PENDING
				currentTicket.statusSeparationHistory.push({
					status: 'SEPARATION_PENDING',
					assignedBy: user._id,
				})

				setWithdrawalMessage({
					message: WITHDRAWAL_MESSAGE.DATE_HAS_BEEN_CHANGED_SUCCESSFULLY,
					type: 'info',
				})

				getListScheduleDateOptions()
			}
		}
	}

	function isValidateOrders(): boolean {
		const validators = orders.map((order) => {
			if (order.code === '') {
				return false
			}
			if (order.quantity === 0) {
				return false
			}

			if (order.totalSkus === 0) {
				return false
			}

			if (order.totalValue === 0) {
				return false
			}
			return true
		})

		return !validators.some((e) => e === false)
	}

	function populateOrders() {
		setOrders(currentTicket.orders)
	}

	function addOrder() {
		if (isValidateOrders()) {
			const order = createNewOrder()
			setOrders([...orders, order])
		}
	}

	function actionsStatusEdit(
		actionStatus: TicketStatusEnum | TicketSeparationStatusEnum,
	) {
		let enabledStatusSelection: TicketStatusEnum[] = []

		if (actionStatus === 'ATTENDANCE') {
			enabledStatusSelection = hasOrders()
				? [
					'QUOTE_SENT',
					'NEGOTIATION',
					'NEGOTIATION_SENT',
					'APPROVED',
					'CANCELED',
				]
				: ['QUOTE_SENT', 'NEGOTIATION', 'CANCELED']
		}

		else if (actionStatus === 'QUOTE_SENT') {
			enabledStatusSelection = hasOrders()
				? ['NEGOTIATION', 'NEGOTIATION_SENT', 'APPROVED', 'CANCELED']
				: ['NEGOTIATION', 'CANCELED']
		}

		else if (actionStatus === 'NEGOTIATION') {
			enabledStatusSelection = hasOrders()
				? ['NEGOTIATION_SENT', 'APPROVED', 'CANCELED']
				: ['CANCELED']
		}

		else if (actionStatus === 'NEGOTIATION_SENT') {
			enabledStatusSelection = hasOrders() ? ['APPROVED', 'CANCELED'] : ['CANCELED']
		}

		if (withdrawalMessage?.message === WITHDRAWAL_MESSAGE.PARAMS_NOT_FOUND) {
			enabledStatusSelection = enabledStatusSelection.filter(
				status => status !== 'APPROVED'
			)
		}

		if (actionStatus === 'APPROVED') {
			enabledStatusSelection = hasOrders() ? ['INVOICED', 'CANCELED'] : ['CANCELED']
		}

		setNewStatusSelection(enabledStatusSelection)
	}

	function hasOrders(): boolean {
		return orders.length > 0
	}

	function removeOrder(index: number, e?: FormEvent<HTMLButtonElement>) {
		if (e) e.preventDefault()

		const listUpdated = [...orders]
		listUpdated.splice(index, 1)
		setOrders(listUpdated)
	}

	function findProfileSelect(): SelectOption | null {
		const findedProfile = CustomerProfile.find((x) => {
			return x.value === String(profile)
		})

		if (findedProfile === undefined) return null

		return {
			label: findedProfile.label,
			value: findedProfile.value,
		}
	}

	async function remove() {
		try {
			currentTicket._id && (await fetchDeleteTicket(currentTicket._id))

			cogoToast.success('Ticket removido com sucesso!', cogoDefaultOptions)
		} catch (error) {
			cogoToast.success('Erro ao remover Ticket!', cogoDefaultOptions)
		} finally {
			handleModal()
		}
	}

	function changeDeleteModalState(e?: FormEvent<HTMLButtonElement>) {
		if (e) e.preventDefault()
		setSelectedTicket(!selectedTicket)
	}

	function findChannelSelect(): SelectOption | null {
		const findedChannel = labelChannel.find((x) => {
			return x.value === String(channel)
		})

		if (findedChannel === undefined) return null

		return {
			label: findedChannel.label,
			value: findedChannel.value,
		}
	}

	async function handleSubmitTicket(e: FormEvent<HTMLButtonElement>) {
		e.preventDefault()

		const isOrderValid = isValidateOrders()
		if (!isOrderValid) return

		const ticketEdit: IModalTicket = {
			ticket: ticketNumber,
			channel,
			codeOrName,
			actionStatus: (selectedAction as TicketStatusEnum) || undefined,
			currentStatus,
			currentSeparationStatus: currentTicket.currentSeparationStatus,
			customerProfile: selectOthers ? inputOthers : profile,
			orders: orders,
			retail: selectedRetail?.value || ''
		}

		try {
			setIsLoading(state => !state)

			if (currentTicket._id) {
				await fetchUpdateTicket(currentTicket._id, ticketEdit, user._id)
				cogoToast.success('Atualizado com sucesso', cogoDefaultOptions)
			} else {
				await fetchNewTicket(ticketEdit, user._id)

				cogoToast.success('Criado com sucesso', cogoDefaultOptions)

				// setTicket(ticketDoc)
			}

			handleModal()
		} finally {
			setIsLoading(state => !state)
		}
	}

	async function handleConfirmPluginRequest() {
		setCurrentOrderWithdrawalOption(orderWithdrawalOptionSelected)

		if (currentTicket._id) {
			const { value, period } = JSON.parse(selectedOrderWithdrawal)

			const ticket: ITicket = await sendPluginRequest(
				currentTicket._id,
				value,
				period,
			)
			currentTicket.statusSeparationHistory = ticket.statusSeparationHistory
			currentTicket.currentSeparationStatus = ticket.currentSeparationStatus
			checkTicketStatusSeparationHistory()
			getListScheduleDateOptions()
		}

		setConfirmPluginRequest(false)
	}

	function handleCancelPluginRequest() {
		if (currentOrderWithdrawalOption) {
			handlerSelectOrderWithdrawal(currentOrderWithdrawalOption)
		}
		setConfirmPluginRequest(false)
	}

	function getListScheduleDateOptions() {
		; (async () => {
			let orderWithdrawal: ISeparationDateOption | undefined = undefined

			let retailParamsNotFound = false

			if (currentTicket.currentStatus === 'APPROVED' && currentTicket._id) {
				orderWithdrawal = await getOrderWithdrawalDate(currentTicket)

				const separationDayOptions: ISeparationDateOption[] =
					await fetchRetailSeparationDateOptions(currentTicket.retail)
				const formattedOptions: ISelectOption[] = separationDayOptions.map(
					(option) => {
						return formatSchedulingDateOption(option, false)
					},
				)
				setScheduleDateOptions(formattedOptions)
			} else {
				const orderWithdrawalResponse = await getSeparationScheduleSuggestion(
					currentTicket
				)

				if (orderWithdrawalResponse?.retailParamsNotFound) {
					retailParamsNotFound = true
				} else {
					orderWithdrawal = orderWithdrawalResponse
				}
			}

			if (retailParamsNotFound) {
				setWithdrawalMessage({
					type: 'error',
					message: WITHDRAWAL_MESSAGE.PARAMS_NOT_FOUND
				})
			}

			if (orderWithdrawal) {
				const formattedCurrentOption = formatSchedulingDateOption(
					orderWithdrawal,
					true,
				)
				setCurrentOrderWithdrawalOption(formattedCurrentOption)
				setOrderWithdrawalOptionSelected(formattedCurrentOption)
				setSelectedOrderWithdrawal(formattedCurrentOption.value)
			}
		})()
	}

	function checkIfUserCanRequestPlugin() {
		const userPermissions = user.profile.permissions as IPermission[]
		const canRequestPlugin =
			currentTicket.currentStatus === 'APPROVED' &&
			userPermissions.some(
				(permission) => permission.name === permissionNameValues.PLUGIN_REQUEST,
			)
		setCanPluginRequest(canRequestPlugin)
	}

	function checkTicketStatusSeparationHistory() {
		let withdrawalMessageToShow: ITicketWithdrawalMessage | undefined =
			undefined

		const statusSeparation = currentTicket.currentSeparationStatus
		const statusSeparationHistory = currentTicket.statusSeparationHistory

		if (
			statusSeparation &&
			statusSeparation ===
			ticketSeparationStatus.SEPARATION_PLUGIN_REQUEST
		) {
			withdrawalMessageToShow = {
				message: WITHDRAWAL_MESSAGE.PLUGIN_PENDING_APPROVAL,
				type: 'info',
			}
		} else if (
			statusSeparationHistory &&
			statusSeparationHistory[statusSeparationHistory.length - 2]
		) {
			const penultimateSeparationStatus =
				statusSeparationHistory[statusSeparationHistory.length - 2]

			if (
				penultimateSeparationStatus.status ===
				ticketSeparationStatus.SEPARATION_PLUGIN_REQUEST_REFUSED
			) {
				withdrawalMessageToShow = {
					message: WITHDRAWAL_MESSAGE.PLUGIN_WAS_NOT_POSSIBLE,
					type: 'error',
				}
			} else if (
				penultimateSeparationStatus.status ===
				ticketSeparationStatus.SEPARATION_PLUGIN_REQUEST_APPROVED
			) {
				withdrawalMessageToShow = {
					message: WITHDRAWAL_MESSAGE.PLUGIN_WAS_APPROVED,
					type: 'info',
				}
			}
		}

		withdrawalMessageToShow && setWithdrawalMessage(withdrawalMessageToShow)
	}

	useEffect(() => {
		setSelectedProfile(findProfileSelect())
		setSelectedChannel(findChannelSelect())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channel, profile])

	useEffect(() => {
		initializeTicketFields()
		setChannelOptions(labelChannel)
		setProfileOptions(CustomerProfile)
		populateOrders()
		if (modalAction === 'EDIT') {
			getListScheduleDateOptions()
			checkIfUserCanRequestPlugin()
			checkTicketStatusSeparationHistory()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		selectedProfile?.value === 'OTHERS'
			? setSelectOthers(true)
			: setSelectOthers(false)
	}, [selectedProfile])

	useEffect(() => {
		calculate()
		actionsStatusEdit(currentTicket.currentStatus)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orders])

	useEffect(() => {
		actionsStatusEdit(currentTicket.currentStatus)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [withdrawalMessage])

	useEffect(() => {
		if (
			currentTicket.customerProfile !== 'B2B_CONSUMPTION' &&
			currentTicket.customerProfile !== 'B2B_RETAIL'
		) {
			setProfile('OTHERS')
			setInputOthers(currentTicket.customerProfile)
		}
	}, [currentTicket])

	useEffect(() => {
		if (modalAction === 'CREATE') {
			handleValidateForm()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channel, codeOrName, orders, modalAction, selectedRetail])

	useEffect(() => {
		if (modalAction === 'EDIT') {
			const hasUpdate = wasThereAnUpdateInInputs()
			setFilledData(hasUpdate && isValidateOrders() && validateEmptyOrders())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		channel,
		profile,
		codeOrName,
		orders,
		selectedAction,
		inputOthers,
		selectedOrderWithdrawal,
	])

	useEffect(populateRetailOptions, [user])

	const viewProps: IViewProps = {
		active,
		className,
		handleSubmitTicket,
		handleModal,
		modalAction,
		currentTicket,
		profileOptions,
		handleEffectChannel,
		handleEffectProfile,
		channelOptions,
		selectedChannel,
		selectedProfile,
		orders,
		addOrder,
		setCodeOrName,
		codeOrName,
		removeOrder,
		newStatusSelection,
		others: selectOthers,
		filledData,
		handlerSelectNewAction,
		newActionOptionSelected,
		onChangeOrder,
		calculate,
		total,
		setInputOthers,
		inputOthers,
		changeDeleteModalState,
		remove,
		selectedTicket,
		orderWithdrawalOptionSelected,
		handlerSelectOrderWithdrawal,
		scheduleDateOptions,
		canPluginRequest,
		confirmPluginRequest,
		handleCancelPluginRequest,
		handleConfirmPluginRequest,
		withdrawalMessage,
		isLoading,
		retailOptions,
		handleEffectRetail,
		selectedRetail
	}

	return createElement(ModalTicket, viewProps)
}

export default ModalCards
