import { fontType, toRem } from 'shared/styles/functions'
import styled, { css } from 'styled-components'
import { ILabelProps } from './types'

export const CardWrapperContainer = styled.div`
	* {
		${fontType(1)}
	}
	cursor: pointer;

	/* width: ${toRem(250)}; */
	height: auto;
	padding: ${toRem(10)};

	&:active {
		position: relative;
		top: 2px;
	}
`

export const Content = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
`
export const TotalSkus = styled.h4`
	color: ${({ theme }) => theme.colors.darkGrey};
	font-weight: 400;
	font-size: 11.5px;
	${fontType(1)}
`
export const TotalItems = styled.h4`
	margin-left: 40px;
	color: ${({ theme }) => theme.colors.darkGrey};
	font-weight: 400;
	font-size: 11.5px;
	${fontType(1)};
`

export const TotalValue = styled.h4`
	color: ${({ theme }) => theme.colors.darkGrey};
	margin-left: 40px;
	font-weight: 400;
	font-size: 11.5px;
	${fontType(1)}
`
interface ICardWrapperProps {
	showLocated: boolean
}
export const CardWrapper = styled.div<ICardWrapperProps>`
	width: 100%;
	height: 100%;
	border-radius: ${toRem(7)};
	padding: ${toRem(15)};

	${(props) =>
		props.showLocated
			? css`
					background: #f9f9f9;
			  `
			: css`
					background: ${({ theme }) => theme.colors.white};
			  `}
`

export const BadgeTicket = styled.span<ILabelProps>`
	font-size: ${toRem(11)};
	padding: ${toRem(6)};
	border-radius: ${toRem(50)};
	height: auto;
	min-width: 52px;
	text-align: center;
	font-weight: bold;

	${(props) =>
		props.isLast
			? css`
					background: ${({ theme }) => theme.colors.lightRed};
					color: ${({ theme }) => theme.colors.red};
			  `
			: css`
					background: ${({ theme }) => theme.colors.lightBlue};
					color: ${({ theme }) => theme.colors.blue};
			  `}
`
export const OrderCounter = styled.span`
	span {
		color: ${({ theme }) => theme.colors.grey};
		margin-left: ${toRem(4)};
		letter-spacing: ${toRem(0.48)};
		font-size: ${toRem(12)};
	}
`
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const CreatedByWrapper = styled.div<ILabelProps>`
	margin-left: 14px;

	${(props) =>
		props.isLast
			? css`
					img {
						width: 15px;
						height: 15px;
					}
			  `
			: css`
					img {
						width: 15px;
						height: 15px;
						color: ${({ theme }) => theme.colors.red};
					}
			  `}
`

export const SeparatorLabel = styled.span<ILabelProps>`
	color: ${({ theme }) => theme.colors.blue};
	font-size: 12px;
	margin-left: 4px;
	font-weight: bold;

	${(props) =>
		props.isLast
			? css`
					color: ${({ theme }) => theme.colors.red};
			  `
			: css`
					color: ${({ theme }) => theme.colors.blue};
			  `}
`

export const Footer = styled.div`
	color: ${({ theme }) => theme.colors.grey};
	font-size: ${toRem(13)};
	display: flex;
	justify-content: flex-end;
	padding: ${toRem(10)};
`

export const TicketAndSeparationNameWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const ButtonSection = styled.div`
	background-color: #e2e8fd;
	padding: 9px 16px 9px 16px;
	border-radius: 4px 4px 0px 0px;
	justify-content: space-between;
	display: flex;
`

export const ButtonSectionLabel = styled.span`
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: 14px;
	font-weight: 400;
`
export const ButtonWrapper = styled.div`
	display: flex;
`

export const ButtonApprove = styled.button`
	width: 22px;
	height: 22px;
	border: none;
	background: transparent;

	img {
		width: 22px;
		height: 22px;
		cursor: pointer;
	}
`

export const ButtonReject = styled.button`
	margin-right: 8px;
	width: 22px;
	height: 22px;
	border: none;
	background: transparent;

	img {
		width: 22px;
		height: 22px;
		cursor: pointer;
	}
`

export const LocatedWrapper = styled.div`
	display: flex;
	height: 39px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	background: #dde4fd;
	border-radius: 4px;
	padding: ${toRem(15)};
`

export const LocatedLabel = styled.span`
	color: #354d9e;
	${fontType(0)};
	font-size: 12px;
`

export const LocatedLocale = styled.span`
	color: #354d9e;
	font-size: 12px;
	${fontType(2)};
`
export const BadgeRetail = styled(BadgeTicket)`
	display: block;
	margin: 0 0 15px;
`
