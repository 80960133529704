import styled from 'styled-components'

import { fontType, toRem, transition } from '../../styles/functions'

const navWidth = 300
export const SideNavWrapper = styled.div`
	.background {
		position: fixed;
		z-index: 10;

		${transition()}
	}

	.menu-wrapper {
		position: fixed;
		display: flex;
		flex-direction: column;
		z-index: 10;
		top: 0;
		bottom: 0;
		left: 0;
		visibility: hidden;

		.spacer {
			height: 80px;
		}

		.nav-wrapper {
			display: flex;
			flex-direction: column;
			flex: 1;
			width: ${navWidth}px;
			padding: 8px;
			transform: translateX(-${navWidth}px);
			overflow-y: auto;
			${transition()}

			nav {
				flex: 1;
				background: ${({ theme }) => theme.colors.blue};
				border-radius: 4px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.nav-top {
					margin-top: 48px;
					margin-bottom: 64px;
					.profile {
						display: flex;
						flex-direction: column;
						align-items: center;
						.photo {
							width: 64px;
							height: 64px;
							overflow: hidden;
							border-radius: 100%;
							background: ${({ theme }) => theme.colors.lightGrey};
							display: flex;
							justify-content: center;
							align-items: center;
							margin-bottom: 24px;
						}

						.info {
							display: flex;
							flex-direction: column;
							align-items: center;

							.name {
								font-size: ${toRem(16)};
								color: ${({ theme }) => theme.colors.white};
								${fontType(0)}
								margin-bottom: 8px;
							}

							.profile-link {
								font-size: ${toRem(10)};
								color: ${({ theme }) => theme.colors.white};
								opacity: 0.7;
								border: none;
								background: none;
								cursor: pointer;
								${fontType(0)}
							}
						}
					}
				}
				.nav-mid { }
				.nav-bottom {
					li {
						padding: 20px 24px;
						display: flex;
						align-items: center;
						border: none;
						background: none;
						color: ${({ theme }) => theme.colors.white};
						font-size: ${toRem(12)};
						cursor: pointer;
						${transition()}
						${fontType(1)};
						margin-bottom: 16px;
						margin-top: 16px;

						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}

	&.active {
		.background {
			background: ${({ theme }) => theme.colors.background};
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		.menu-wrapper {
			visibility: visible;
			.nav-wrapper {
				transform: translateX(0px);
			}
		}
	}
`
