import styled from 'styled-components';
import { toRem } from 'shared/styles/functions'

export const ButtonWrapper = styled.div`
	text-align: center;
	margin-top: ${toRem(30)};
`

export const ModalContainer = styled.div`
	p {
		text-align: center;
		font-size: 14px;
		margin-bottom: 10px;
	}
`
