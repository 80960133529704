import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const LastBoardWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 4px;
	padding: 24px;
	${fontType(0)}
	display:flex;
	flex-direction: column;
`

export const SectionLabel = styled.h3`
	font-weight: 500;
`

export const ContainerListCards = styled.div`
	display: flex;
	justify-content: space-between;
`
