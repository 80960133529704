import { AxiosResponse } from 'axios'
import { BannerStatus, IBanner } from 'shared/interfaces/banner'
import { IBooklet } from 'shared/interfaces/booklet'
import axiosInstance from './axiosInstance'

export async function fetchBanners(retail?: string): Promise<IBanner[]> {
	const response: AxiosResponse = await axiosInstance.get(
		`/banner/get-page?retail=${retail}`,
	)

	return response.data.content
}

export async function deleteBanner(bannerId: string): Promise<IBanner[]> {
	const response: AxiosResponse = await axiosInstance.patch(
		`/banner/${BannerStatus.DELETED}/${bannerId}`,
	)

	return response.data.content
}

export async function deleteBooklet(booklet: string): Promise<IBooklet[]> {
	const response: AxiosResponse = await axiosInstance.patch(
		`/booklet/${BannerStatus.DELETED}/${booklet}`,
	)

	return response.data.content
}

export async function changeBannerStatus({
	bannerId,
	status,
}: {
	bannerId: string
	status: BannerStatus
}): Promise<IBanner[]> {
	const response: AxiosResponse = await axiosInstance.patch(
		`/banner/${status}/${bannerId}`,
	)

	return response.data.content
}

export async function updateBanner(banner: Partial<IBanner>): Promise<IBanner> {
	const response: AxiosResponse = await axiosInstance.put(
		`/banner/${banner._id}`,
		banner,
	)

	return response.data.content
}

export async function createBanner(banner: Partial<IBanner>): Promise<IBanner> {
	const response: AxiosResponse = await axiosInstance.post('/banner/', banner)

	return response.data.content
}
