import styled, { css, keyframes } from 'styled-components'

import { fontType, toRem, transition } from '../../../styles/functions'

interface IProps {
	submenuIsOpen: boolean
}

const showMenu = keyframes`
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`

const rotateArrow = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

export const SubmenuWrapper = styled.ul<IProps>`
	margin: 0 8px 8px;
	border-radius: 4px;

	&:nth-child(3) > li > svg > g > rect.a{
		display: none;
	}

	${transition()}

	.submenu {
		background: ${({ theme }) => theme.colors.white};
		padding: 20px 16px;
		display: flex;
		align-items: center;
		cursor: pointer;
		text-decoration: none;
		box-shadow: 0px 2px 3px #00000014;

		${transition()}

		svg {
			margin-right: 16px;
			color: ${({ theme }) => theme.colors.blue};
			${transition()}
			width: 20px;
			height: 20px;

			/* g > rect.a {
				display: none;
			} */
		}

		.submenu-text {
			width: 100%;
			font-size: ${toRem(12)};
			color: ${({ theme }) => theme.colors.blue};
			${fontType(1)};
			${transition()}
		}

		.arrow-icon {
			img.submenuIsOpen {
				transform: rotate(180deg);
				transition: 0.3s;
			}
			img.submenuIsClose {
				animation: 0.3s ${rotateArrow} ease-out;
			}
		}
	}

	${(props) =>
		props.submenuIsOpen
			? css`
					.submenu {
						border-radius: 4px 4px 0 0;
					}
			  `
			: css`
					.submenu {
						border-radius: 4px;
					}
			  `}
`

export const SubmenuRouteItem = styled.li<IProps>`
	list-style: none;
	background: ${({ theme }) => theme.colors.white};

	/* animation: .3s ${showMenu} ease-out; */

	.nav-link {
		display: flex;
		align-items: center;

		border-radius: 4px;
		padding: 20px 16px;

		background: ${({ theme }) => theme.colors.white};
		cursor: pointer;
		text-decoration: none;
		box-shadow: 0px 2px 3px #00000014;

		.nav-text {
			font-size: ${toRem(12)};
			color: ${({ theme }) => theme.colors.blue};
			${fontType(1)};
			${transition()}
		}

		&.active {
			.nav-text {
				font-weight: bold;
				margin-left: 8px;
			}
		}
	}

	& + li {
		border-radius: 0 0 4px 4px;
	}
`
