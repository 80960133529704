export const labelChannel = [
	{ value: 'STORE', label: 'Loja' },
	{ value: 'WHATSAPP', label: 'WhatsApp' },
	{ value: 'ECOMMERCE', label: 'E-commerce' },
	{ value: 'DESK', label: 'Mesa' },
	{ value: 'EMAIL', label: 'E-mail' },
	{ value: 'TELEPHONE', label: 'Telefone' },
]

export const CustomerProfile = [
	{ value: 'B2B_RETAIL', label: 'B2B Revenda' },
	{ value: 'B2B_CONSUMPTION', label: 'B2B Consumo' },
	{ value: 'OTHERS', label: 'Outros (descreva)' },
]

export const SetVision = [
	{ value: 'TICKET', label: 'Ticket' },
	{ value: 'ORDER', label: 'Pedido' },
	{ value: 'REVENUE', label: 'Faturamento' },
]

export const SeparationStages = [
	{ value: 'SEPARATION_PENDING', label: 'Pendente' },
	{ value: 'SEPARATION_ON_SEPARATION', label: 'Em Separação' },
	{ value: 'SEPARATION_SEPARATED', label: 'Separado' },
	{ value: 'SEPARATION_ON_CHECKING', label: 'Em Conferência' },
	{ value: 'SEPARATION_CHECKED', label: 'Conferido' },
]

export const COMMERCIAL_START_HOUR = 6
export const COMMERCIAL_END_HOUR = 22

export const DEFAULT_VALUE_OF_AMOUNT_SCHEDULE_DATE_OPTIONS = 14

export const WITHDRAWAL_MESSAGE = {
	PARAMS_NOT_FOUND:
		'Os parâmetros de separação desta loja ainda não foram configurados!',
	PLUGIN_PENDING_APPROVAL: 'Encaixe pendente de aprovação',
	PLUGIN_WAS_NOT_POSSIBLE: 'O encaixe não foi possível',
	PLUGIN_WAS_APPROVED: 'A solicitação de encaixe foi aprovada',
	DATE_HAS_BEEN_CHANGED_SUCCESSFULLY:
		'A data de retirada foi alterada com sucesso',
}

export const shifts = [
	{ value: 'morning', label: 'Manhã' },
	{ value: 'afternoon', label: 'Tarde' },
]
