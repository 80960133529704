import { AxiosResponse } from 'axios'
import {
	ICardsList,
	ITicket,
	ITicketPayload,
	IRequest,
	IDigitalDashboardData,
	ICardListPayload,
	ISeparationDashboardRequest,
	IDigitalSeparationDashboardData,
	VisionTypeEnum,
	ChannelTypeEnum,
	TicketSeparationStatusEnum,
} from 'shared/interfaces/ticket'
import { format } from 'date-fns'
import axiosInstance from './axiosInstance'
import fileDownload from 'js-file-download'
import { IOrder } from 'shared/interfaces/order'

export function createNewOrder(): IOrder {
	return {
		code: '',
		quantity: 0,
		totalSkus: 0,
		totalValue: 0,
		separators: [],
		checkers: [],
	}
}

export function initializeTicketFields() {
	const ticket: ITicket = {
		_id: '',
		ticket: 0,
		channel: 'WHATSAPP',
		codeOrName: '',
		createdAt: new Date(),
		updatedAt: new Date(),
		currentStatus: 'ATTENDANCE',
		customerProfile: 'B2B_CONSUMPTION',
		orders: [],
		statusHistory: [],
		statusSeparationHistory: [],
		numberOfOrders: 0,
		retail: ''
	}
	return ticket
}

export async function fetchCardsList(
	status: string,
	payload: ICardListPayload,
): Promise<ICardsList> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/ticket/list-by-column/${status}`,
			payload,
		)

		return response.data.content
	} catch (error) {
		return []
	}
}

export async function fetchTicket(ticketId: string): Promise<ITicket> {
	const response: AxiosResponse = await axiosInstance.get(
		`/ticket/${ticketId}`,
	)

	return response.data.content
}

export async function exportTicketService(payload: {
	startDate: string
	endDate: string
	professionals: string[]
	clients: string[]
	segments: string[]
	channels: ChannelTypeEnum[]
	retails: string[]
	vision: VisionTypeEnum
	dashboardData: 'attendance' | 'separation'
	separationStages?: TicketSeparationStatusEnum[]
}) {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/ticket/export-orders`,
			payload,
			{
				responseType: 'blob',
			},
		)
		const fileName = `pedidos_${format(
			new Date(),
			"yyyy-MM-dd'T'HH:mm:ss",
		)}.xlsx`
		fileDownload(response.data, fileName)
	} catch (error) {}
}

export async function fetchUpdateTicket(
	ticketId: string,
	ticketData: ITicketPayload,
	userId: string,
) {
	try {
		const response: AxiosResponse = await axiosInstance.patch(
			`/ticket/${ticketId}`,
			{
				userId,
				ticket: ticketData.ticket,
				channel: ticketData.channel,
				codeOrName: ticketData.codeOrName,
				customerProfile: ticketData.customerProfile,
				orders: ticketData.orders,
				withdrawalLocale: ticketData.withdrawalLocale,
				withdrawalAssistants: ticketData.withdrawalAssistants,
				actionStatus: {
					currentStatus: ticketData.currentStatus,
					newStatus: ticketData.actionStatus,
				},
				separationActionStatus: {
					currentStatus: ticketData.currentSeparationStatus,
					newStatus: ticketData.separationActionStatus,
				},
			},
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function fetchNewTicket(
	ticketData: ITicketPayload,
	userId: string,
) {
	try {
		const response: AxiosResponse = await axiosInstance.post(`/ticket`, {
			userId,
			channel: ticketData.channel,
			codeOrName: ticketData.codeOrName,
			customerProfile: ticketData.customerProfile,
			currentStatus: 'ATTENDANCE',
			orders: ticketData.orders,
			retail: ticketData.retail
		})

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function fetchDeleteTicket(ticketId: string) {
	try {
		const response: AxiosResponse = await axiosInstance.delete(
			`/ticket/${ticketId}`,
		)

		return response.data.content
	} catch (error) {}
}

export async function fetchAllCustomers(retails: string[]) {
	const response: AxiosResponse = await axiosInstance.post(
		`/ticket/list/customers`,
		{ retails }
	)

	return response.data.content
}

export async function fetchAllSegments(retails: string[]) {
	const response: AxiosResponse = await axiosInstance.post(
		`/ticket/list/segments`,
		{ retails }
	)

	return response.data.content
}

export async function fetchChannelsDashboard(
	payload: IRequest,
): Promise<IDigitalDashboardData> {
	const response: AxiosResponse = await axiosInstance.post(
		`/ticket/digital-channels-dashboard`,
		payload,
	)

	return response.data.content
}

export async function fetchChannelsDashboardMocked(
	payload: IRequest,
): Promise<IDigitalDashboardData> {
	const response: AxiosResponse = await axiosInstance.post(
		`/ticket/mock-digital-channels-dashboard`,
		payload,
	)

	return response.data.content
}

export async function fetchChannelsSeparationDashboard(
	payload: ISeparationDashboardRequest,
): Promise<IDigitalSeparationDashboardData> {
	const response: AxiosResponse = await axiosInstance.post(
		`/ticket/digital-channels-separation-dashboard`,
		payload,
	)

	return response.data.content
}

export async function fetchWithdrawal({
	searchString,
	retails
}: {
	searchString: string
	retails: string[]
}): Promise<ICardsList> {
	const response: AxiosResponse = await axiosInstance.post(
		`/ticket/list/withdrawal?searchString=${searchString}`, {
		retails
	},
	)

	return response.data.content
}
