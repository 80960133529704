import styled from 'styled-components'

import { fontType, toRem } from '../../styles/functions'

export const DefaultPagePlaceholderWrapper = styled.h1`
	margin-top: 128px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.placeholder-image {
		margin-bottom: 16px;
	}

	.placeholder-text {
		color: ${({ theme }) => theme.colors.blue};
		font-size: ${toRem(14)};
		${fontType(0)};
		max-width: 320px;
		text-align: center;
	}
`
