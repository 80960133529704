import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .inputs-group {
        margin: 40px 0;
        width: 100%;

        .input-container + .input-container {
            margin-top: 16px;
        }
    }
    
    .select-retail {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 40px;

        & > div {
            min-width: 50%;
        }
    }
    
    .submit-button {
        align-self: center;
    }
`
