import React from 'react'
import Card from 'modules/CardTicket'
import { Container } from './styles'
import { translateStatus } from 'shared/util/translate'
import { IPropsListTicket } from './types'
import { ITicket } from 'shared/interfaces/ticket'
import CardFit from 'shared/components/CardFit'

export default function ListTicket({
	title,
	cards,
	activateModalTicket,
	isWithdrawal = false,
}: IPropsListTicket) {
	return (
		<Container>
			<header>
				<p>{translateStatus(title)}</p>
				{isWithdrawal && (
					<div>
						<span>{cards.length}</span>
					</div>
				)}
			</header>

			{!isWithdrawal ? (
				<ul>
					{cards.map((card: ITicket) => (
						<Card
							key={card._id}
							data={card}
							handleClick={() => activateModalTicket('EDIT', card)}
						/>
					))}
				</ul>
			) : (
				<ul>
					{cards.map((card: ITicket) => (
						<CardFit
							key={card._id}
							ticket={card}
							isLast={false}
							showLocated={false}
							backgroundShadow={true}
							currentStatus={card.currentSeparationStatus || card.currentStatus}
							handleClick={() => activateModalTicket('EDIT', card)}
							fetchParams={() => { }}
						/>
					))}
				</ul>
			)}
		</Container>
	)
}
