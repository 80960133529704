import { Button, Modal, Select } from '@buildbox/components'
import maximizeIcon from 'assets/images/maximize-2.svg'
import ClientsGraph from 'modules/ClientsGraph'
import DashboardBarGraph from 'modules/DashboardBarGraph'
import React from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import DatePicker from 'shared/components/DatePicker'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import Loading from 'shared/components/Loading'
import PageTitle from 'shared/components/PageTitle'
import {
	IOpenedApprovedAverageDash,
	ITotalRevenueAverageDash,
} from 'shared/interfaces/ticket'
import { Row } from 'shared/styles'
import {
	primaryDashboardButtonStyles,
	primaryInputSeparationTicketStyles,
	primaryInputStyles,
	primarySelectStyles,
	reactSelectStyles,
} from 'shared/styles/theme'
import { SetVision, labelChannel } from 'shared/util/Consts'
import { options } from 'shared/util/SelectOption'
import { columnSize, graphTitle } from 'shared/util/dashboardUtil'
import { getDropdownButtonLabelOverride } from 'shared/util/multiSelectCheckboxUtils'
import GeneralTimeDash from '../../shared/components/GeneralTime'
import PerformanceDash from '../Performance'
import {
	Cards,
	SearchInputColumn as Column,
	ContainerRow,
	DashboardPageWrapper,
	ExportDataButtonWrapper,
	GraphCard,
	GraphExpandButton,
	Label,
	SecondContainerRow,
	TeamGraphWrapper,
	TitleRow,
	VisionColumn,
	VisionData,
} from './styles'
import { IViewProps } from './types'

import RevenueCard from '../../shared/components/RevenueCard'

import TotalCard from '../../shared/components/TotalCard'

import RenderAveragesCard from '../../shared/components/RenderAveragesCard'

function DashboardView(props: IViewProps): JSX.Element {
	const {
		handleSetDates,
		selectedDates,
		handleChannelSelect,
		handleCustomerSelect,
		handleSegmentSelect,
		handleProfessionalSelect,
		handleViewSelected,
		handleChangeRetails,
		setOpenModalChannelGraph,
		setOpenModalTeamGraph,
		setOpenModalPerformanceGraph,
		openModalChannelGraph,
		openModalTeamGraph,
		openModalPerformanceGraph,
		selectedChannel,
		selectedProfessional,
		selectedVision,
		professionalsOptions,
		selectedCustomer,
		customerOptions,
		selectedSegment,
		segmentsOptions,
		selectedRetails,
		retailOptions,
		vision,
		metrics,
		isLoading,
		isEmpty,
		canFilterProfessionals,
		exportTicket,
	} = props

	const {
		totalRevenueAverageDash,
		channelDash,
		teamDash,
		performanceDash,
		clientsProfileDash,
		timesDash,
	} = metrics

	const {
		channelDashTitle,
		teamDashTitle,
		performanceDashTitle,
		clientsProfileDashTitle,
	} = graphTitle(vision)

	if (isLoading) return <Loading />
	else
		return (
			<>
				<DashboardPageWrapper>
					<div className='page-header'>
						<Row>
							<TitleRow>
								<PageTitle>Dashboard de Atendimento</PageTitle>

								{retailOptions.length > 1 && (
									<Select
										round
										placeholder='Definir Separadores'
										{...primaryInputSeparationTicketStyles}
										id='retails'
										options={retailOptions}
										value={selectedRetails}
										onChange={handleChangeRetails}
										isMulti
										disabled={true}
									/>
								)}
							</TitleRow>
						</Row>
						<Row>
							<VisionColumn
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Período</Label>
								<DatePicker
									dateSetter={handleSetDates}
									value={selectedDates}
									datePickerTitle={'Seleção de Período'}
									{...primaryInputStyles}
								/>
							</VisionColumn>

							{canFilterProfessionals && (
								<Column
									sm={columnSize.smWidth}
									md={columnSize.mdWidth}
									lg={columnSize.lgWidth}
								>
									<Label>Profissional</Label>
									<ReactMultiSelectCheckboxes
										onChange={handleProfessionalSelect}
										options={options(professionalsOptions)}
										value={selectedProfessional}
										getDropdownButtonLabel={getDropdownButtonLabelOverride}
										placeholderButtonLabel='Profissional'
										placeholder='Buscar Profissional'
										classNamePrefix='gm'
										{...primaryInputStyles}
										styles={reactSelectStyles}
									/>
								</Column>
							)}

							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Clientes</Label>
								<ReactMultiSelectCheckboxes
									onChange={handleCustomerSelect}
									options={options(customerOptions)}
									value={selectedCustomer}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									isMulti
									placeholderButtonLabel='Clientes'
									placeholder='Buscar Cliente'
									noOptionsMessage={() => 'Nenhum cliente para mostrar.'}
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</Column>

							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Segmentos</Label>
								<ReactMultiSelectCheckboxes
									onChange={handleSegmentSelect}
									options={options(segmentsOptions)}
									value={selectedSegment}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									isMulti
									placeholderButtonLabel='Segmentos'
									placeholder='Buscar Segmento'
									noOptionsMessage={() => 'Nenhum segmento para mostrar.'}
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</Column>

							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Canal</Label>

								<ReactMultiSelectCheckboxes
									onChange={handleChannelSelect}
									options={options(labelChannel)}
									value={selectedChannel}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									placeholderButtonLabel='Canal'
									placeholder='Buscar Canal'
									noOptionsMessage={() => 'Nenhum canal para mostrar.'}
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</Column>

							<VisionColumn
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Visão</Label>

								<Select
									round
									placeholder={vision.toLowerCase()}
									id='select'
									className='select-vision'
									options={SetVision}
									value={selectedVision}
									onChange={handleViewSelected}
									{...primarySelectStyles}
								/>
							</VisionColumn>
							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<ExportDataButtonWrapper>
									<Button
										{...primaryDashboardButtonStyles}
										onClick={exportTicket}
									>
										Exportar Dados
									</Button>
								</ExportDataButtonWrapper>
							</Column>
						</Row>
					</div>
					{totalRevenueAverageDash &&
						channelDash &&
						teamDash &&
						performanceDash &&
						!isEmpty && (
							<VisionData>
								<ContainerRow>
									<Cards className='cards'>
										<TotalCard
											vision={vision}
											data={totalRevenueAverageDash as ITotalRevenueAverageDash}
											isSeparationCard={false}
										/>
										{vision === 'REVENUE' ? (
											<RevenueCard
												vision={vision}
												data={
													totalRevenueAverageDash as IOpenedApprovedAverageDash
												}
												dashboard='attendance'
											/>
										) : (
											<RevenueCard
												vision={vision}
												data={
													totalRevenueAverageDash as ITotalRevenueAverageDash
												}
												dashboard='attendance'
											/>
										)}

										<RenderAveragesCard
											vision={vision}
											data={totalRevenueAverageDash as ITotalRevenueAverageDash}
										/>
									</Cards>
									<GraphCard className='channel'>
										<div className='card-title'>
											<h3>{channelDashTitle}</h3>
											<GraphExpandButton
												onClick={() => setOpenModalChannelGraph(true)}
											>
												EXPANDIR
												<img src={maximizeIcon} alt='Maximizar gráfico' />
											</GraphExpandButton>
										</div>
										<div className='graph-wrapper'>
											<DashboardBarGraph
												graph='CHANNEL'
												dataGraph={channelDash}
												vision={vision}
											/>
										</div>
									</GraphCard>
								</ContainerRow>
								<SecondContainerRow>
									<div>
										<TeamGraphWrapper items={teamDash.length} className='team'>
											<div className='card-title'>
												<h3>{teamDashTitle}</h3>
												<span>(TOTAL: {teamDash.length} PESSOAS)</span>
												<GraphExpandButton
													onClick={() => setOpenModalTeamGraph(true)}
												>
													EXPANDIR
													<img src={maximizeIcon} alt='Maximizar gráfico' />
												</GraphExpandButton>
											</div>
											<div className='graph-wrapper-team'>
												<DashboardBarGraph
													graph='TEAM'
													dataGraph={teamDash}
													vision={vision}
												/>
											</div>
										</TeamGraphWrapper>

										{teamDash.length < 15 && (
											<GraphCard className='clients-profile'>
												<div className='card-title'>
													<h3>{clientsProfileDashTitle}</h3>
												</div>
												<ClientsGraph
													dataGrafic={clientsProfileDash}
													selectedDates={selectedDates}
													vision={vision}
												/>
											</GraphCard>
										)}
									</div>
									<div>
										<GraphCard className='performance'>
											<div className='card-title'>
												<h3>{performanceDashTitle}</h3>
												<GraphExpandButton
													onClick={() => setOpenModalPerformanceGraph(true)}
												>
													EXPANDIR
													<img src={maximizeIcon} alt='Maximizar gráfico' />
												</GraphExpandButton>
											</div>
											<div className='graph-wrapper-performance'>
												<PerformanceDash
													selectedDates={selectedDates}
													dataGrafic={performanceDash}
													isModal={false}
												/>
											</div>
										</GraphCard>

										<GraphCard className='general-time'>
											<GeneralTimeDash data={timesDash} />
										</GraphCard>
										{teamDash.length >= 15 && (
											<GraphCard className='clients-profile'>
												<div className='card-title'>
													<h3>{clientsProfileDashTitle}</h3>
												</div>
												<ClientsGraph
													dataGrafic={clientsProfileDash}
													selectedDates={selectedDates}
													vision={vision}
												/>
											</GraphCard>
										)}
									</div>
								</SecondContainerRow>
							</VisionData>
						)}

					{isEmpty && <DefaultPagePlaceholder />}
					{openModalChannelGraph && (
						<Modal
							isActive={openModalChannelGraph}
							onClose={() => setOpenModalChannelGraph(!openModalChannelGraph)}
							title={channelDashTitle}
							size={1024}
						>
							<DashboardBarGraph
								graph='CHANNEL'
								dataGraph={channelDash}
								vision={vision}
							/>
						</Modal>
					)}
					{openModalTeamGraph && (
						<Modal
							isActive={openModalTeamGraph}
							onClose={() => setOpenModalTeamGraph(!openModalTeamGraph)}
							title={`${teamDashTitle} (Total: ${teamDash.length} pessoas)`}
							size={1024}
							className='modal-full-screen'
						>
							<DashboardBarGraph
								graph='TEAM'
								dataGraph={teamDash}
								vision={vision}
							/>
						</Modal>
					)}
					{openModalPerformanceGraph && (
						<Modal
							isActive={openModalPerformanceGraph}
							onClose={() =>
								setOpenModalPerformanceGraph(!openModalPerformanceGraph)
							}
							title={performanceDashTitle}
							size={1024}
						>
							<PerformanceDash
								selectedDates={selectedDates}
								dataGrafic={performanceDash}
								isModal={true}
							/>
						</Modal>
					)}
				</DashboardPageWrapper>
			</>
		)
}

export default DashboardView
