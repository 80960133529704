const routesEnum = {
	LOGIN: '/login',
	SHOPFLOW: '/store-flow',
	USER: '/users',
	RETAIL: '/retails',
	ATTENDANCE: '/attendance',
	DASHBOARD_ATTENDANCE: '/attendance-dashboard',
	SEPARATION: '/separation',
	PROFILE: '/profile',
	DASHBOARD_SEPARATION: '/separation-dashboard',
	WITHDRAWAL_BOARD: '/withdrawal',
	APP_MANAGEMENT: '/app-management',
	PUSH_NOTIFICATIONS: '/push-notifications',
	QRCODE_PRINTING: '/qrcode-printing',
	STORE_AISLES: '/store-aisles',
	SERVICES_REVIEWS: '/services-reviews',
	BADGE_PRINTING: '/badge-printing',
}

export default routesEnum
