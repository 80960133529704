import React from 'react'
import { Container } from './styles'
import { IProps } from './types'
import eye from '../../assets/images/eye-view.svg'

function GeneralTime({ setView, data, sectionTitle }: IProps): JSX.Element {
	return (
		<div>
			<Container>
				<div>
					<h4>{sectionTitle}</h4>
					<div>
						<h5>{data ? data.toFixed(1) : 0} min</h5>
						<span onClick={() => setView(true)}>
							<img src={eye} alt='eye' />
						</span>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default GeneralTime
