import React from 'react'
import { ISwitchProps } from './types'

import { SwitchWrapper, FirstOption, SecondOption } from './styles'
// handleTeamGraphOptions(option: string): void

function SwitchPrimary(props: ISwitchProps) {
	const { handleOption, value } = props

	return (
		<SwitchWrapper currentValue={value}>
			<FirstOption
				onClick={() => {
					if (value === 'AVERAGE_TIME') {
						handleOption('VOLUME')
					}
				}}
				currentValue={value}
			>
				<p>Volume</p>
			</FirstOption>
			<SecondOption
				onClick={() => {
					if (value === 'VOLUME') {
						handleOption('AVERAGE_TIME')
					}
				}}
				currentValue={value}
			>
				<p>Tempo Médio</p>
			</SecondOption>
		</SwitchWrapper>
	)
}

export default SwitchPrimary
