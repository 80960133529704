import styled from 'styled-components'
import { theme } from '../../shared/styles/theme'
import { fontType, toRem } from '../../shared/styles/functions'

export const CountersHeader = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`

export const Title = styled.div`
	color: ${theme.colors.darkGrey};
	font-size: ${toRem(16)};
	padding-left: 8px;
	padding-right: 8px;
	margin-top: 16px;
	margin-bottom: 8px;

	${fontType(0)}
`

export const CountersContainer = styled.div`
	margin: 0 -8px 128px -8px;
`
export const CountersWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
`
