import styled from 'styled-components'

export const Container = styled.div`
	padding: 16px;
	div div {
		display: flex;
		align-items: center;
		cursor: pointer;

		p {
			text-align: left;
			font-size: 12px;
			letter-spacing: 0.48px;
			color: #354d9e;
			margin-left: 5px;
		}

		span {
			display: flex;
			align-items: center;
			width: 15px;
		}
	}

	h4 {
		text-align: center;
		font-size: 14px;
		color: #354d9e;
		font-weight: 600;
	}
	table {
		margin-top: 16px;
		font-family: arial, sans-serif;
		border-collapse: collapse;
		width: 100%;

		th {
			background-color: #f9f9f9;
			color: #354d9e;
			width: 111px;
			padding: 8px;
			font-size: 12px;
			text-align: left;
			padding: 8px 20px;
		}

		td {
			font-size: 12px;
			padding: 8px 20px;
		}
	}
`
