import axios, { AxiosInstance } from 'axios'
import { IUser } from 'shared/interfaces/user'
import { store } from 'shared/store'
import handleError from 'shared/util/handleError'

const API_URL = 'https://apihiga.buildbox.com.br/api'

const axiosInstance: AxiosInstance = axios.create({
	baseURL: API_URL,
	timeout: 300000,
})

axiosInstance.interceptors.request.use(
	(request) => {
		const accessToken: string = store.getState().token.accessToken
		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`
		}
		//const accessToken: string = 'bx_bypass_access_token' //for dev purposes. pls don't remove
		const loggedUser: IUser = store.getState().user
		if (loggedUser && loggedUser._id) {
			request.headers.secret = loggedUser._id
		}
		return request
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('REQUEST', { err })
		}
		handleError(err?.request?.data)
		throw err
	},
)

axiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('RESPONSE', { err })
		}

		handleError(err?.response?.data)

		throw err
	},
)

export default axiosInstance
