import styled from 'styled-components'

type ITableContent = {
	index: number
}

type ICircle = {
	color: string
	backgroundColor: string
}

type IPage = {
	active: boolean
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding-bottom: 120px;
`

export const Table = styled.table`
	width: 100%;
	border-spacing: 0;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 4px;
	padding: 32px 0 16px;
`
export const TableHeader = styled.thead`
	color: ${({ theme }) => theme.colors.blue};

	th {
		text-align: start;
		font-size: 0.75rem;
		border-left: 24px solid transparent;
		border-bottom: 16px solid transparent;
	}
`

export const TableContent = styled.tr<ITableContent>`
	td {
		height: 79px;
		font-size: 0.75rem;
		border-left: 24px solid transparent;
		background-color: ${({ theme, index }) =>
			index % 2 === 0 ? theme.colors.lightWhite : theme.colors.white};
		padding: 8px 0px;
		position: relative;

		input {
			width: 17px;
			height: 17px;
			accent-color: ${({ theme }) => theme.colors.blue};
		}

		img {
			width: 17px;
			height: 17px;
			cursor: pointer;

			:active {
				opacity: 0.7;
			}
		}
	}
`

export const RetailContainer = styled.td`
	flex-wrap: wrap;
	align-items: center;
	display: flex;
`

export const Circle = styled.div<ICircle>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	border-radius: 0.8125rem;
	opacity: 1;
	color: ${({ color }) => color};
	font-weight: bold;
	max-width: 8.125rem;
	padding: 0.4375rem;
	font-size: 0.625rem;
	margin: 0.125rem;
	width: fit-content;
`
export const IConsContainer = styled.td`
	width: 100px;

	div {
		gap: 20px;
		align-items: center;
		height: 100%;
		justify-content: center;
		display: flex;
	}
`
export const TableFooter = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const Results = styled.span`
	margin-top: 12px;

	font-size: 12px;
`

export const PaginationContainer = styled.div`
	margin-top: 12px;
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
`

export const Pagination = styled.div`
	min-height: 10px;
	align-items: center;
	justify-content: center;
	gap: 12px;
`

export const PaginationController = styled.button`
	border: none;
	background: none;
	cursor: pointer;
	padding: 16px;
	color: rgb(53, 77, 158);
	transition: all 0.3s ease 0s;
	font-size: 13px;
	opacity: ${({ disabled }) => (disabled ? 0 : 1)};

	:disabled {
		cursor: default;
	}
`

export const Page = styled.button<IPage>`
	transition: all 0.2s ease-in-out;
	width: 24px;
	height: 31px;
	font-size: 12px;
	border-radius: 4px;
	border: none;
	background-color: ${({ theme, active }) =>
		active ? theme.colors.blue : 'transparent'};
	color: ${({ theme, active }) => (active ? 'white' : theme.colors.blue)};
	cursor: pointer;

	:hover {
		background-color: ${({ theme, active }) =>
			!active ? theme.colors.lightGrey : theme.colors.darkBlue};
	}
`
