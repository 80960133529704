/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import { SubmenuWrapper, SubmenuRouteItem } from './styles'
import { IProps, submenuOptionEnum } from './types'

import chevronIcon from '../../../../assets/images/chevron.svg'

const Submenu: React.FC<IProps> = ({
	submenuKey,
	icon,
	title,
	routes,
	setOpenedSubnav,
	openedSubnav,
	handleSetIsOpen,
}) => {
	const showSubnav = useCallback(
		(submenuOption: submenuOptionEnum, submenuIsOpen: submenuOptionEnum) => {
			if (submenuIsOpen === submenuKey) {
				setOpenedSubnav('')
			} else {
				setOpenedSubnav(submenuOption)
			}
		},
		[setOpenedSubnav, submenuKey],
	)

	return (
		<SubmenuWrapper
			onClick={() => showSubnav(submenuKey, openedSubnav)}
			submenuIsOpen={openedSubnav === submenuKey}
		>
			<li className='submenu'>
				{icon}
				<p className='submenu-text'>{title}</p>
				<div className='arrow-icon'>
					<img
						src={chevronIcon}
						className={`${
							openedSubnav === submenuKey ? 'submenuIsOpen' : 'submenuIsClose'
						}`}
					/>
				</div>
			</li>
			{openedSubnav === submenuKey &&
				routes.map((route) => (
					<SubmenuRouteItem
						key={route.key}
						onClick={handleSetIsOpen}
						submenuIsOpen={openedSubnav === submenuKey}
						style={{
							borderRadius: `${routes.length === 1 && '0 0 4px 4px'}`,
						}}
					>
						<NavLink
							className='nav-link'
							activeClassName='active'
							to={route.path}
						>
							<p className='nav-text'>{route.name}</p>
						</NavLink>
					</SubmenuRouteItem>
				))}
		</SubmenuWrapper>
	)
}

export default Submenu
