import { DefaultStyles } from '@buildbox/components'
import { DefaultTheme } from 'styled-components'
import { StylesConfig } from 'react-select'

const theme: DefaultTheme = {
	colors: {
		background: '#0000008F',
		blue: '#354D9E',
		darkBlue: '#37456C',
		secondaryLightBlue: '#5064A7',
		darkGrey: '#444444',
		grey: '#999999',
		lightBlue: '#BECDFF',
		lightGrey: '#EEEEEE',
		red: '#E11F39',
		white: '#FFFFFF',
		lightWhite: '#f9f9f9',
		lightWhite2: '#F3F6FF',
		lightRed: '#e11f3914',
		lightGrey2: '#E9EAEE',
		graphSecondaryColor: '#C7D2F7',
		secondaryLightGrey: '#D1D1D1',
		secondaryDarkGrey: '#A0A0A0',
		tertiaryDarkGrey: '#8D9099',
	},
}

export { theme }

export const tableStyle: DefaultStyles = {
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.darkGrey,
	backgroundColor: theme.colors.lightWhite,
	fontSize: 12,
	fontFamily: 'Montserrat Regular',
}
export const primaryButtonStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 14,
	backgroundColor: theme.colors.blue,
	round: true,
	padding: [20, 55, 20, 55],
}

export const primaryDashboardButtonStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 12,
	backgroundColor: theme.colors.blue,
	round: true,
	padding: [20, 40, 20, 40],
}

export const primaryButtonDelete: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	padding: [10, 24, 10, 24],
	backgroundColor: theme.colors.white,
	round: true,
	fontSize: 12,
	primaryColor: theme.colors.red,
	secondaryColor: theme.colors.red,
}
export const PrimaryDashboardTable: DefaultStyles = {
	primaryColor: theme.colors.darkGrey,
	secondaryColor: theme.colors.white,
	backgroundColor: theme.colors.lightWhite,
	fontSize: 12,
	fontFamily: 'Montserrat Medium',
}

export const secondaryButtonStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 14,
	backgroundColor: theme.colors.red,
	round: true,
	padding: [20, 55, 20, 55],
}

export const whiteModalButtonStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 14,
	backgroundColor: theme.colors.white,
	primaryColor: theme.colors.blue,
	round: true,
	padding: [20, 55, 20, 55],
}

export const whiteModalDisabledButtonStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 14,
	backgroundColor: theme.colors.lightGrey,
	primaryColor: theme.colors.grey,
	round: true,
	padding: [20, 55, 20, 55],
}

export const primaryInputStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 16,
	padding: [26, 16, 10, 16],
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.grey,
}

export const ProfileInputStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 14,
	padding: [26, 16, 10, 16],
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.grey,
}

export const primarySelectStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 16,
	padding: [6, 6, 6, 6],
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.secondaryLightGrey,
}
export const primaryUserSelectStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 16,
	padding: [6, 6, 6, 6],
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.secondaryLightGrey,
	tertiaryColor: theme.colors.darkGrey,
}
export const primaryModalSeparationSelectStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 16,
	padding: [6, 6, 6, 6],
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.white,
	tertiaryColor: theme.colors.blue,
}
export const primaryModalButtonStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 10,
	backgroundColor: theme.colors.blue,
	round: true,
	padding: [10, 30, 10, 30],
	primaryColor: theme.colors.white,
}

export const primaryInputTicketStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 12,
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.secondaryDarkGrey,
}

export const secondarySelectTicketStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 12,
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.secondaryDarkGrey,
	tertiaryColor: theme.colors.blue,
}

export const primaryDisableInputTicketStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 12,
	primaryColor: theme.colors.tertiaryDarkGrey,
	secondaryColor: theme.colors.tertiaryDarkGrey,
}

export const primaryDisableSelectTicketStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 12,
	primaryColor: theme.colors.tertiaryDarkGrey,
	secondaryColor: theme.colors.tertiaryDarkGrey,
	tertiaryColor: theme.colors.tertiaryDarkGrey,
}
export const primaryInputSeparationTicketStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 12,
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.white,
}
export const secondaryDisabledInputSeparationTicketStyles: DefaultStyles = {
	backgroundColor: theme.colors.lightGrey,
	fontFamily: 'Montserrat Regular',
	fontSize: 14,
	primaryColor: theme.colors.lightGrey,
	secondaryColor: theme.colors.tertiaryDarkGrey,
}
export const primaryInputWithdrawalStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 14,
	primaryColor: theme.colors.darkGrey,
	secondaryColor: theme.colors.secondaryDarkGrey,
	padding: [8, 6, 4, 6],
}
export const primaryInputOrderStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 12,
	primaryColor: theme.colors.blue,
	secondaryColor: theme.colors.darkGrey,
}
export const primaryButtonOrderStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 14,
	backgroundColor: theme.colors.blue,
	round: true,
	padding: [10, 15, 10, 15],
}
export const secondInputTicketStyles: DefaultStyles = {
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 14,
	primaryColor: theme.colors.lightGrey,
	secondaryColor: theme.colors.secondaryDarkGrey,
}

export const SeeUserCardButtonDisabledStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 14,
	backgroundColor: theme.colors.lightGrey,
	primaryColor: theme.colors.white,
	round: true,
	padding: [20, 55, 20, 55],
}

export const SeeUserCardButtonActiveStyles: DefaultStyles = {
	fontFamily: 'Montserrat Medium',
	fontSize: 14,
	backgroundColor: theme.colors.blue,
	primaryColor: theme.colors.white,

	round: true,
	padding: [20, 55, 20, 55],
}

export const colorArray: string[] = [
	'#FF6633',
	'#FF33FF',
	'#00B3E6',
	'#E6B333',
	'#3366E6',
	'#999966',
	'#99FF99',
	'#B34D4D',
	'#80B300',
	'#809900',
	'#E6B3B3',
	'#6680B3',
	'#66991A',
	'#FF99E6',
	'#CCFF1A',
	'#FF1A66',
	'#E6331A',
	'#33FFCC',
	'#66994D',
	'#B366CC',
	'#4D8000',
	'#B33300',
	'#CC80CC',
	'#66664D',
	'#991AFF',
	'#E666FF',
	'#4DB3FF',
	'#1AB399',
	'#E666B3',
	'#33991A',
	'#CC9999',
	'#B3B31A',
	'#00E680',
	'#4D8066',
	'#809980',
	'#E6FF80',
	'#1AFF33',
	'#999933',
	'#FF3380',
	'#CCCC00',
	'#66E64D',
	'#4D80CC',
	'#9900B3',
	'#E64D66',
	'#4DB380',
	'#FF4D4D',
	'#99E6E6',
	'#6666FF',
]

export const reactSelectStyles: StylesConfig & {
	dropdownButton?: any
} = {
	container: (styles, state) => ({
		...styles,
		width: state.selectProps.width,
		boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.08)',
	}),
	menu: (styles, state) => ({
		...styles,
	}),
	option: (styles, state) => ({
		...styles,
		background: state.isSelected ? theme.colors.lightGrey : 'white',
		color: state.isSelected ? theme.colors.blue : '#999999',
		cursor: 'pointer',
		transition: 'all 0.2s',
		':hover': {
			background: '#EEEEEE',
			color: '#858585',
		},
	}),
	groupHeading: (styles: any, state: any) => ({
		...styles,
		textTransform: 'capitalize',
	}),
	dropdownButton: (styles: any, state: any) => ({
		...styles,
		width: '100%',
		height: 57,
		padding: 16,
		border: `1px solid ${theme.colors.blue}`,
		borderRadius: '29px',
		background: '#FFFFFF00',
	}),
	control: (styles: any) => ({
		...styles,
		margin: '0',
		width: '100%',
		minWidth: '0',
	}),
	input: (styles) => ({
		...styles,
		width: '100%',
	}),
}

export const selectStyles: StylesConfig = {
	menu: (styles) => ({
		...styles,
		width: 371,
		right: 0,
	}),
	option: (styles, state) => ({
		...styles,
		background: state.isSelected ? theme.colors.lightGrey : 'white',
		color: state.isSelected ? theme.colors.blue : theme.colors.grey,
		cursor: 'pointer',
		transition: 'all 0.2s',
		':hover': {
			background: theme.colors.lightGrey,
			color: '#858585',
		},
	}),
	input: () => ({}),
}
