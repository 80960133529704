import React from 'react'

import { IViewProps } from './types'
import DefaultPagePlaceholder from '../../shared/components/DefaultPagePlaceholder'

import {
	ComponentContainer,
	Title,
	Label,
	AbsoluteButton,
	PercentageButton,
	EntryVolumeButton,
	RadioButtons,
	GraphFilters,
	ContainerCardBox,
	HeaderWrapper,
} from './styles'

import LineGraph from '../../shared/components/LineGraph'
import Loading from 'shared/components/Loading'
import CardBox from 'shared/components/CardBox/view'

function RetailVisitsGraphView(props: IViewProps): JSX.Element {
	const {
		viewMode,
		setViewMode,
		graphData,
		graphParams,
		isLoading,
		calculateAverageHoursInPeriod,
		userPerPeriod,
	} = props

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<>
					{!!graphData.length && (
						<ComponentContainer>
							<HeaderWrapper>
								<Title>Visitas na Loja</Title>

								<ContainerCardBox>
									<CardBox
										value={calculateAverageHoursInPeriod()}
										label='Média da Entrada de Clientes por Hora:'
									/>
									<CardBox
										value={userPerPeriod}
										label='Entrada de Clientes no Periodo:'
									/>
								</ContainerCardBox>

								<GraphFilters>
									<Label>
										<RadioButtons>
											<AbsoluteButton
												value={viewMode}
												onClick={() => setViewMode('absolute')}
											>
												Lotação Absoluta
											</AbsoluteButton>
											<PercentageButton
												value={viewMode}
												onClick={() => setViewMode('percentage')}
											>
												% de Lotação
											</PercentageButton>
											<EntryVolumeButton
												value={viewMode}
												onClick={() => setViewMode('entry-volume')}
											>
												Volume de Entrada
											</EntryVolumeButton>
										</RadioButtons>
									</Label>
								</GraphFilters>
							</HeaderWrapper>

							<LineGraph
								data={graphData}
								graphParams={graphParams}
								legends={[]}
								isModal={false}
							/>
						</ComponentContainer>
					)}
					{!graphData.length && (
						<DefaultPagePlaceholder text='Nenhum dado a exibir.' />
					)}
				</>
			)}
		</>
	)
}

export default RetailVisitsGraphView
