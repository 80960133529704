import { Button, Modal, Select } from '@buildbox/components'
import cogoToast from 'cogo-toast'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import ImageUpload from 'shared/components/ImageUpload'
import { IImageUpload } from 'shared/components/ImageUpload/types'
import { uploadToS3 } from 'shared/services/aws.service'
import { saveNewBooklet, updateBooklet } from 'shared/services/booklet.service'
import {
	primaryDashboardButtonStyles,
	primaryInputTicketStyles,
} from 'shared/styles/theme'
import cogoDefaultOptions from 'shared/util/toaster'

import { isAfter, isFuture, parseISO } from 'date-fns'
import DateInpput from 'shared/components/DateInput'
import InputPrimary from 'shared/components/InputPrimary'
import { Container } from './styles'
import { IProps } from './types'

function ModalBooklet(props: IProps) {
	const {
		isActive,
		closeModal,
		selectedRetail,
		selectedBooklet,
		retailOptions,
		handleChangeRetail,
	} = props

	const [coverImage, setCoverImage] = useState<IImageUpload>({
		file: null,
		url: '',
	})
	const [frontImage, setFrontImage] = useState<IImageUpload>({
		file: null,
		url: '',
	})
	const [backImage, setBackImage] = useState<IImageUpload>({
		file: null,
		url: '',
	})

	const [isSubmiting, setIsSubmiting] = useState(false)
	const [isFormValid, setIsFormValid] = useState(false)

	const [title, setTitle] = useState('')
	const [vigor, setVigor] = useState('')
	const [validity, setValidity] = useState('')

	function loadBannerState() {
		if (!isActive) return

		if (selectedBooklet) {
			setTitle(selectedBooklet.title)
			setVigor(selectedBooklet.vigor)
			setValidity(selectedBooklet.validity)

			setCoverImage({ file: null, url: selectedBooklet.coverBookletImage })
			setFrontImage({ file: null, url: selectedBooklet.frontBookletImage })
			setBackImage({ file: null, url: selectedBooklet.backBookletImage })
		}
	}

	function validateDates(vigor: string, validity: string): boolean {
		const vigorDate = parseISO(vigor)
		const validityDate = parseISO(validity)

		return isFuture(validityDate) && isAfter(validityDate, vigorDate)
	}

	function validateForm() {
		const isValid =
			!!selectedRetail &&
			(!!coverImage.file || !!coverImage.url) &&
			(!!frontImage.file || !!frontImage.url) &&
			(!!backImage.file || !!backImage.url) &&
			!!title.length &&
			validateDates(vigor, validity)

		setIsFormValid(isValid)
	}

	function clearFiels() {
		setCoverImage({ file: null, url: '' })
		setFrontImage({ file: null, url: '' })
		setBackImage({ file: null, url: '' })
	}

	const onClose = useCallback(
		(refetchData: boolean = false) => {
			clearFiels()
			closeModal({ refetchData, dataToFetch: ['booklet'] })
		},
		[closeModal],
	)

	const handleChangeImage = useCallback(
		(
			value: IImageUpload,
			inputId: 'cover-image' | 'front-image' | 'back-image',
		) => {
			switch (inputId) {
				case 'cover-image':
					setCoverImage(value)
					break
				case 'front-image':
					setFrontImage(value)
					break
				case 'back-image':
					setBackImage(value)
					break
			}
		},
		[],
	)

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const str = event.target.value

		setTitle(str)
	}

	function handleVigorChange(event: ChangeEvent<HTMLInputElement>) {
		const str = event.target.value

		setVigor(str)
	}

	function handleValidityChange(event: ChangeEvent<HTMLInputElement>) {
		const str = event.target.value

		setValidity(str)
	}

	const handleSubmit = useCallback(() => {
		;(async () => {
			if (!isFormValid) return

			try {
				setIsSubmiting(true)

				let coverImageUrl, frontImageUrl, backImageUrl

				if (coverImage.file) {
					const { Location } = await uploadToS3(coverImage.file)
					coverImageUrl = Location
				}

				if (frontImage.file) {
					const { Location } = await uploadToS3(frontImage.file)
					frontImageUrl = Location
				}

				if (backImage.file) {
					const { Location } = await uploadToS3(backImage.file)
					backImageUrl = Location
				}

				const bookletDetails: any = {
					...selectedBooklet,
					retail: selectedRetail?.value,
					title: title,
					vigor: vigor,
					validity: validity,
				}

				if (coverImageUrl) bookletDetails.coverBookletImage = coverImageUrl
				if (frontImageUrl) bookletDetails.frontBookletImage = frontImageUrl
				if (backImageUrl) bookletDetails.backBookletImage = backImageUrl

				if (!!selectedBooklet) {
					await updateBooklet(bookletDetails, selectedBooklet._id)
				} else {
					await saveNewBooklet(bookletDetails)
					cogoToast.success('Encarte salvo com sucesso!', cogoDefaultOptions)
				}
			} finally {
				setIsSubmiting(false)
				onClose(true)
			}
		})()
	}, [
		isFormValid,
		coverImage.file,
		frontImage.file,
		backImage.file,
		selectedBooklet,
		selectedRetail,
		title,
		vigor,
		validity,
		onClose,
	])

	useEffect(validateForm, [
		frontImage,
		backImage,
		selectedRetail,
		title,
		validity,
		vigor,
	])
	useEffect(loadBannerState, [selectedBooklet])

	return (
		<Modal
			isActive={isActive}
			title='Encarte'
			onClose={() => onClose()}
			className='modal-booklet'
			size={624}
		>
			<Container>
				<div className='input-container select-retail'>
					<Select
						round
						placeholder='Loja'
						{...primaryInputTicketStyles}
						id='select-retail'
						options={retailOptions}
						value={selectedRetail}
						onChange={handleChangeRetail}
					/>
				</div>
				<div className='inputs-group'>
					<div className='input-container' style={{ width: 343 }}>
						<ImageUpload
							returnImage={(value) => handleChangeImage(value, 'cover-image')}
							imageUrl={coverImage.url}
							width='312'
							height='135'
							id='cover-image'
							secondLabel='TAMANHO 343X200'
						/>
						<span>CAPA</span>
					</div>
					<div className='input-container-wrapper'>
						<div className='input-container'>
							<ImageUpload
								returnImage={(value) => handleChangeImage(value, 'front-image')}
								imageUrl={frontImage.url}
								width='198'
								height='211'
								id='front-image'
							/>
							<span>FRENTE</span>
						</div>
						<div className='input-container'>
							<ImageUpload
								returnImage={(value) => handleChangeImage(value, 'back-image')}
								imageUrl={backImage.url}
								width='198'
								height='211'
								id='back-image'
							/>
							<span>VERSO</span>
						</div>
					</div>
				</div>

				<div className='form-wrapper'>
					<InputPrimary
						value={title}
						onChange={handleChange}
						label='Título do Encarte'
					/>

					<DateInpput
						value={vigor}
						label='Vigência'
						aria-label='Date'
						type='date'
						onChange={handleVigorChange}
					/>

					<DateInpput
						value={validity}
						label='Validade'
						aria-label='Date'
						type='date'
						onChange={handleValidityChange}
					/>
				</div>

				<Button
					{...primaryDashboardButtonStyles}
					className='submit-button'
					onClick={handleSubmit}
					disabled={isSubmiting || !isFormValid}
				>
					Cadastrar
				</Button>
			</Container>
		</Modal>
	)
}

export default ModalBooklet
