import { AxiosResponse } from 'axios'
import { BookletStatus, IBooklet } from 'shared/interfaces/booklet'
import axiosInstance from './axiosInstance'

export async function fetchCurrentBooklet(
	retailID: string,
): Promise<IBooklet[]> {
	const response: AxiosResponse = await axiosInstance.get(
		`/booklet/${retailID}`,
	)

	return response.data.content
}

export async function saveNewBooklet(
	booklet: Partial<IBooklet>,
): Promise<IBooklet> {
	const response: AxiosResponse = await axiosInstance.post(`/booklet/`, booklet)

	return response.data.content
}

export async function updateBooklet(
	booklet: Partial<IBooklet>,
	bookletId: string,
): Promise<IBooklet> {
	const response: AxiosResponse = await axiosInstance.put(
		`/booklet/${bookletId}`,
		booklet,
	)

	return response.data.content
}

export async function changeBookletStatus({
	booklet,
	status,
}: {
	booklet: string
	status: BookletStatus
}): Promise<IBooklet[]> {
	const response: AxiosResponse = await axiosInstance.patch(
		`/booklet/${status}/${booklet}`,
	)

	return response.data.content
}
