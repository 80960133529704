import styled from 'styled-components'
import { theme } from '../../../shared/styles/theme'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .inputs-group {
        margin: 40px 0;
        width: 100%;

        .input-container + .input-container {
            margin-top: 16px;
        }

        .select-retail {
            display: flex;
            justify-content: flex-end;

            & > div {
                min-width: 50%;
            }
        }
    }

    .view-qr-code {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100px;
        border-radius: 8px;
        background-color: #E4E7F5;
        text-decoration: none;

        span {
            color: ${theme.colors.blue};
            font-size: 14px;
            margin-left: 8px;
        }
    }
    
    .submit-button {
        align-self: center;
    }
`
