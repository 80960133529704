import { IError } from '../interfaces/error'
import cogoDefaultOptions from '../../shared/util/toaster'
import cogoToast from 'cogo-toast'
import { logout } from './session'

function handleError(error: IError): void {
	if (!error?.message) {
		//if (!error) {
		cogoToast.error('Servidor indisponível...', cogoDefaultOptions)
		return
	}
	if (error.code === 401) {
		// TODO: associate error codes to meanings in a separate file
		logout()
		return
	}

	cogoToast.error(error.message, cogoDefaultOptions)
}

export default handleError
