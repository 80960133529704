import { AxiosResponse } from 'axios'
import { IProfile } from '../../modules/Profile/types'
import axiosInstance from './axiosInstance'

export async function listProfiles() {
	try {
		const response: AxiosResponse = await axiosInstance.get(`/profile/`)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function permissionList() {
	try {
		const response: AxiosResponse = await axiosInstance.get(`/permission/`)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function createProfile(name: string, permissions: string[]) {
	try {
		const response: AxiosResponse = await axiosInstance.post(`/profile/`, {
			name,
			permissions,
		})

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function updateProfileDoc(currentProfile: IProfile) {
	try {
		const response: AxiosResponse = await axiosInstance.put(
			`/profile/` + currentProfile._id,
			{
				name: currentProfile.name,
				permissions: currentProfile.permissions,
			},
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function deleteProfile(profileId: string | undefined) {
	try {
		const response: AxiosResponse = await axiosInstance.delete(
			`/profile/` + profileId,
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function fetchProfilePage() {
	try {
		const response: AxiosResponse = await axiosInstance.get('/profile/get-page')

		return response.data.content
	} catch (error) {
		throw error
	}
}
