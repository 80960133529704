import { AxiosResponse } from 'axios'

import axiosInstance from '../../shared/services/axiosInstance'
import { IRetailTotalsResponse } from 'shared/interfaces/retail.responses'
import { IRetail } from 'shared/interfaces/retail'

export async function fetchRetailTotals(
	userId: string,
): Promise<IRetailTotalsResponse[]> {
	try {
		const response: AxiosResponse = await axiosInstance.get(`/retail/totals`)

		return response.data.content
	} catch (error) {
		return []
	}
}

export async function fetchAllRetail(): Promise<IRetail[]> {
	try {
		const response: AxiosResponse = await axiosInstance.get(`/retail`)

		return response.data.content
	} catch (error) {
		return []
	}
}

export async function createRetail(retail: IRetail) {
	const response: AxiosResponse = await axiosInstance.post(`/retail/`, {
		name: retail.name,
		occupancyCounter: {
			count: retail.occupancyCounter.count,
			maxOccupancy: retail.occupancyCounter.maxOccupancy,
		},
		status: 'ACTIVE',
	})

	return response.data.content
}

export async function editRetail(retail: IRetail) {
	const response: AxiosResponse = await axiosInstance.patch(
		`/retail/${retail._id}`,
		{
			name: retail.name,
			occupancyCounter: {
				maxOccupancy: retail.occupancyCounter.maxOccupancy,
			},
		},
	)

	return response.data.content
}

export async function deleteRetail(retailId: string) {
	const response: AxiosResponse = await axiosInstance.delete(
		`/retail/` + retailId,
	)

	return response.data
}
