export interface IPermission {
	_id?: string
	description: string
	name: string
	platform: string
	menu: string
	screenName: string
	screenUrl: string
}

// Need to be aligned with permission.name values
export const permissionNameValues = {
	LOGIN: 'login',
	EDIT: 'edit',
	FILTER_PROFESSIONALS: 'filter-professionals',
	PLUGIN_REQUEST: 'plugin-request',
	SEPARATE_ORDERS: 'separate-orders',
	CHECK_ORDERS: 'check-orders',
}
