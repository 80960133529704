import React from 'react'
import { Modal, Button, Select } from '@buildbox/components'
import {
	primaryButtonStyles,
	primaryInputSeparationTicketStyles,
	primaryInputTicketStyles
} from 'shared/styles/theme'
import {
	ModalWrapper,
	InputWrapper,
	Input,
	InputLabel,
	ButtonWrapper,
	ModalContainer,
	InputSeparatorsWrapper
} from './styles'
import { IViewProps } from './types'

function SettingsModal(props: IViewProps): JSX.Element {
	const {
		isActive,
		handlers,
		handleClose,
		inputMorningValue,
		inputEveningValue,
		isValidatedField,
		hasChanges,
		handleSubmit,
		activeDefaultSeparators,
		defaultSeparators,
		separatorsOptions,
		selectedRetailParams,
		retailOptions,
		selectedRetailOption
	} = props
	return (
		<ModalContainer>
			<Modal
				onClose={handleClose}
				title='Configurações'
				isActive={isActive}
				size={500}
			>
				<ModalWrapper>
					<form
						onSubmit={(event) => {
							event.preventDefault()
							handleSubmit()
						}}
					>
						<span className='select-retail-label'>Selecione a loja que deseja configurar</span>
								<Select
									round
									placeholder='LOJA'
									{...primaryInputTicketStyles}
									id='select-retail'
									options={retailOptions}
									value={selectedRetailOption}
									onChange={handlers.changeRetailParams}
								/>
						{!selectedRetailParams._id && (
							<span className='no-params-alert'>Os parâmetros de separação desta loja ainda não foram configurados.</span>
						)}
						<InputWrapper>
							<InputLabel>Limite de Pedidos (Manhã)</InputLabel>
							<Input
								type='number'
								value={inputMorningValue}
								onChange={handlers.changeMorningInput}
								validatedField={isValidatedField}
							/>
						</InputWrapper>
						<InputWrapper className='evening-input'>
							<InputLabel>Limite de Pedidos (Tarde)</InputLabel>
							<Input
								type='number'
								value={inputEveningValue}
								onChange={handlers.changeEveningInput}
								validatedField={isValidatedField}
							/>
						</InputWrapper>
						<InputSeparatorsWrapper>
							<div className="label">
								<InputLabel>Definir Separadores Padrão</InputLabel>
								<input
									checked={activeDefaultSeparators}
									onChange={handlers.changeActiveDefaultSeparators}
									type='checkbox'
									id='default-separators-checkbox'
								/>
							</div>
							{activeDefaultSeparators ? (
								<Select
									round
									placeholder='Definir Separadores'
									{...primaryInputSeparationTicketStyles}
									id='default-separators'
									options={separatorsOptions}
									value={defaultSeparators}
									onChange={handlers.changeDefaultSeparators}
									isMulti
									disabled={true}
								/>
							) : null}
						</InputSeparatorsWrapper>
						<ButtonWrapper>
							<Button
								disabled={!isValidatedField || !hasChanges}
								type='submit'
								{...primaryButtonStyles}
							>
								Aplicar
							</Button>
						</ButtonWrapper>
					</form>
				</ModalWrapper>
			</Modal>
		</ModalContainer>
	)
}

export default SettingsModal
