import React from 'react'
import { LogHistoryWrapper, Title } from './styles'
import { IViewProps } from './types'
import DefaultPagePlaceholder from '../../shared/components/DefaultPagePlaceholder'
import { Table } from '@buildbox/components'
import { tableStyle } from 'shared/styles/theme'
import { SearchTableInput } from 'shared/components/SearchTable'

function LogHistoryView(props: IViewProps): JSX.Element {
	const {
		tableData,
		columns,
		navProps,
		pageSize,
		handleChange,
		searchTable,
		setPageIndex,
	} = props

	return (
		<LogHistoryWrapper>
			<Title>Histórico de Operações</Title>
			{tableData.length ? (
				<Table
					columns={columns as any}
					data={tableData}
					navProps={navProps}
					pageSize={pageSize}
					{...{ ...tableStyle }}
				/>
			) : (
				<DefaultPagePlaceholder text='Não existem eventos no período selecionado.' />
			)}
			<SearchTableInput
				label='Ir para página'
				onClick={setPageIndex}
				onChange={handleChange}
				value={searchTable}
			/>
		</LogHistoryWrapper>
	)
}

export default LogHistoryView
