import 'react-activity/dist/react-activity.css'

import React from 'react'
import { Digital } from 'react-activity'
import { LoadingWrapper } from './styles'

function Loading({ color = '#354D9E' }) {
	return (
		<LoadingWrapper>
			<Digital size={24} color={color} speed={1} animation={true} />
		</LoadingWrapper>
	)
}

export default Loading
