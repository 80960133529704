import { ITicket, ticketSeparationStatus } from 'shared/interfaces/ticket'

export function ticketCount(ticket: ITicket) {
	return (
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.SEPARATION_PLUGIN_REQUEST &&
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.SEPARATION_READY_TO_DELIVERY &&
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_IN &&
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_OUT &&
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.WITHDRAWAL_SHELVED
	)
}

export function drawerFilter(ticket: ITicket) {
	return (
		ticket.currentSeparationStatus !==
		ticketSeparationStatus.SEPARATION_READY_TO_DELIVERY &&
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_IN &&
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_OUT &&
		ticket.currentSeparationStatus !==
			ticketSeparationStatus.WITHDRAWAL_SHELVED
	)
}
