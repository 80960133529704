import styled from 'styled-components'
import { fontType, toRem } from '../../shared/styles/functions'

export const Container = styled.div`
	& * {
		${fontType(0)};
	}
	.remove-user-content-modal {
		text-align: center;
		.button-container {
			display: flex;
			margin-top: 25px;
			justify-content: space-between;
			align-items: center;
		}
	}
`
export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`
export const TableContent = styled.div`
	margin-top: ${toRem(40)};
	justify-content: center;
	display: flex;

	.table-container {
		width: 100%;
	}
	.table-role-content-operator {
		background-color: ${({ theme }) => theme.colors.lightBlue};
		border-radius: ${toRem(13)};
		opacity: 1;
		color: ${({ theme }) => theme.colors.blue};
		max-width: ${toRem(130)};
		padding: ${toRem(7)};
		font-size: ${toRem(10)};
		margin: ${toRem(2)};
	}

	.trash-icon,
	.settings-icon {
		img {
			cursor: pointer;
		}
	}
`
export const TableRetailContent = styled.span`
	background-color: ${({ theme }) => theme.colors.grey};
	border-radius: ${toRem(13)};
	opacity: 1;
	color: ${({ theme }) => theme.colors.lightWhite};
	max-width: ${toRem(130)};
	padding: ${toRem(7)};
	font-size: ${toRem(10)};
	margin: ${toRem(2)};
`
export const ModalContent = styled.div`
	${fontType(0)};

	.button-container {
		display: flex;
		justify-content: center;
		margin-top: ${toRem(15)};
	}

	.input-container-wrapper {
		flex-direction: column;
		margin-top: ${toRem(30)};
	}
	.select-container,
	.input-container,
	.select-retail {
		margin-top: ${toRem(10)};

		input[type='number']::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
		input[type='number'] {
			-moz-appearance: textfield;
			appearance: textfield;
		}
	}
`
