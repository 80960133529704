import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const ModalWrapper = styled.div`
	${fontType(0)}
`
export const InputWrapper = styled.div`
	margin-top: 57px;

	#profile-name-input {
		max-width: 304px;
	}
`
export const ModalTitleSection = styled.h1`
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: 16px;
	margin-top: 40px;
`

export const ModalButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
