import { Button, Table } from '@buildbox/components'
import React from 'react'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import Loading from 'shared/components/Loading'
import PageTitle from 'shared/components/PageTitle'
import { primaryButtonStyles, tableStyle, theme } from 'shared/styles/theme'
import ProfileModalDelete from './ProfileModalDelete'
import ProfileModal from './Modal'
import { ModalWrapper, PageHeader, TableWrapper } from './styles'
import { IViewProps } from './types'

function Profile(props: IViewProps): JSX.Element {
	const {
		handleToggleModalState,
		modalIsActive,
		tableColumns,
		isLoading,
		tableRows,
		modalDeleteIsActive,
		handleDeleteModal,
		handleRemoveProfile,
		handleChangeNewProfileName,
		profileToCreate,
		handleCreateProfile,
		handleChangePermissions,
		isEditing,
		updateProfile,
		currentProfile,
		permissions,
	} = props

	return (
		<>
			<PageHeader>
				<PageTitle>Perfis de Acesso</PageTitle>
				<Button {...primaryButtonStyles} onClick={handleToggleModalState}>
					Novo
				</Button>
			</PageHeader>
			<TableWrapper>
				{isLoading ? (
					<Loading color={theme.colors.blue} />
				) : (
					<>
						{tableRows.length ? (
							<Table {...tableStyle} columns={tableColumns} data={tableRows} />
						) : (
							<DefaultPagePlaceholder />
						)}
					</>
				)}
			</TableWrapper>

			<ProfileModal
				handleChangeNewProfileName={handleChangeNewProfileName}
				handleChangePermissions={handleChangePermissions}
				currentProfile={profileToCreate}
				handleCreateProfile={handleCreateProfile}
				handleToggleModalState={handleToggleModalState}
				modalIsActive={modalIsActive}
				isEditing={isEditing}
				updateProfile={updateProfile}
				permissionsList={permissions}
			/>

			<ModalWrapper>
				<ProfileModalDelete
					isActive={modalDeleteIsActive}
					onClose={handleDeleteModal}
					title='Excluir Perfil'
					message='Tem certeza que deseja excluir esse perfil de acesso?'
					onConfirm={handleRemoveProfile}
					textButton='Excluir Perfil'
					currentProfile={currentProfile}
				/>
			</ModalWrapper>
		</>
	)
}

export default Profile
