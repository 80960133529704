import { fontType } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'
import styled from 'styled-components'

export const ImageUploadWrapper = styled.div<{ containerWidth: string; containerHeight: string }>`
	position: relative;

	.preview-image {
		position: relative;

		img {
		max-width: ${props => props.containerWidth}px;
		max-height: ${props => props.containerHeight}px;
		}

		div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: black;
			-webkit-mask-image: linear-gradient(to bottom, transparent 15%, black 100%);
		}
	}

	.label {
		&:hover {
			cursor: pointer;
		}

		.placeholder {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;

			width: ${props => props.containerWidth}px;
			height: ${props => props.containerHeight}px;
			border: 2px dashed ${theme.colors.lightBlue};
			border-radius: 8px;
			background-color: ${theme.colors.lightWhite2};

			img {
				width: 23px;
				height: 23px;
			}

			span {
				color: ${theme.colors.blue};
				font-size: 14px;
				text-align: center;
				${fontType(1)}
				margin-top: 14px;
			}
			span + span {
				font-size: 10px;
				font-family: 'Montserrat SemiBold', sans-serif;
				margin-top: 24px;
			}

			input {
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0;
			}

			.second-label {
				margin-top: 8px;
			}
		}
	}

	.trash-icon {
		position: absolute;
		bottom: 16px;
		right: 16px;

		border: none;
		background: none;

		&:hover {
			cursor: pointer;
		}
	}
`
