import { Button, Loading, Select, Table } from '@buildbox/components'
import React from 'react'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import ModalDelete from 'shared/components/ModalDelete'
import PageTitle from 'shared/components/PageTitle'
import {
	primaryDashboardButtonStyles,
	primaryInputTicketStyles,
	tableStyle,
	theme,
} from 'shared/styles/theme'

import ModalBanner from './ModalBanner'
import ModalBooklet from './ModalBooklet'
import {
	BannerSession,
	BookletSession,
	BookletTableHeader,
	Container,
	PageTitleRow,
} from './styles'
import { IViewProps } from './types'

function AppManagementView(props: IViewProps) {
	const {
		retailOptions,
		selectedRetail,
		handleChangeRetail,
		changeModalVisibility,
		isFetchingBanners,
		isFetchingBooklet,
		tableRows,
		tableColumns,
		modalVisibility,
		selectedBanner,
		closeModal,
		handleDeleteBanner,
		bookletTableRows,
		bookletColumns,
		changeBookletModalVisibility,
		selectedBooklet,
		handleDeleteBooklet,
	} = props

	return (
		<Container>
			<BookletSession>
				<PageTitleRow>
					<PageTitle>Encartes</PageTitle>

					<BookletTableHeader>
						<Select
							round
							placeholder='Loja'
							{...primaryInputTicketStyles}
							id='select-retail'
							options={retailOptions}
							value={selectedRetail}
							onChange={handleChangeRetail}
						/>

						<Button
							{...primaryDashboardButtonStyles}
							onClick={() =>
								changeBookletModalVisibility({
									modalOption: 'BOOKLET_CREATE',
								})
							}
						>
							Novo Encarte
						</Button>
					</BookletTableHeader>
				</PageTitleRow>
				<div className='booklet-container'>
					{isFetchingBooklet ? (
						<div className='container-loading-and-placeholder'>
							<Loading type='Digital' primaryColor={theme.colors.blue} />
						</div>
					) : !!bookletTableRows.length ? (
						<Table
							{...tableStyle}
							columns={bookletColumns}
							data={bookletTableRows}
						/>
					) : (
						<DefaultPagePlaceholder />
					)}
				</div>
			</BookletSession>

			<BannerSession>
				<div className='title-row'>
					<PageTitle>Banners</PageTitle>
					<Button
						{...primaryDashboardButtonStyles}
						onClick={() =>
							changeModalVisibility({ modalOption: 'BANNER_CREATE/UPDATE' })
						}
					>
						Novo Banner
					</Button>
				</div>

				<div className='table-container'>
					{isFetchingBanners ? (
						<Loading type='Digital' primaryColor={theme.colors.blue} />
					) : !!tableRows.length ? (
						<Table {...tableStyle} columns={tableColumns} data={tableRows} />
					) : (
						<DefaultPagePlaceholder />
					)}
				</div>
			</BannerSession>

			<ModalBooklet
				isActive={modalVisibility === 'BOOKLET_CHANGE'}
				closeModal={closeModal}
				retailOptions={retailOptions}
				selectedBooklet={selectedBooklet}
				selectedRetail={selectedRetail}
				handleChangeRetail={handleChangeRetail}
			/>

			<ModalBooklet
				isActive={modalVisibility === 'BOOKLET_CREATE'}
				closeModal={closeModal}
				retailOptions={retailOptions}
				selectedRetail={selectedRetail}
				handleChangeRetail={handleChangeRetail}
			/>

			<ModalBanner
				isActive={modalVisibility === 'BANNER_CREATE/UPDATE'}
				selectedBanner={selectedBanner}
				closeModal={closeModal}
				retailOptions={retailOptions}
				selectedRetail={selectedRetail}
				handleChangeRetail={handleChangeRetail}
			/>

			{!!selectedBooklet && (
				<ModalDelete
					isActive={modalVisibility === 'BOOKLET_DELETE'}
					onClose={() => closeModal({})}
					title='Excluir Encarte'
					message={`Você optou por excluir o encarte ${selectedBooklet.title}, esta ação não poderá ser desfeita!`}
					onConfirm={() => handleDeleteBooklet(selectedBooklet._id)}
					textButton='Excluir Encarte'
				/>
			)}

			{!!selectedBanner && (
				<ModalDelete
					isActive={modalVisibility === 'BANNER_DELETE'}
					onClose={() => closeModal({})}
					title='Excluir Banner'
					message={`Você optou por excluir o banner ${selectedBanner.title}, esta ação não poderá ser desfeita!`}
					onConfirm={() => handleDeleteBanner(selectedBanner._id)}
					textButton='Excluir Banner'
				/>
			)}
		</Container>
	)
}

export default AppManagementView
