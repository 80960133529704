import React from 'react'

import higaLogo from '../../assets/images/logo-higa-login.png'
import ButtonPrimary from '../../shared/components/ButtonPrimary'
import InputPrimary from '../../shared/components/InputPrimary'
import { formatCPF } from '../../shared/util/mask'
import { LoginWrapper } from './styles'
import { IViewProps } from './types'
import Loading from '../../shared/components/Loading'

function Login(props: IViewProps): JSX.Element {
	const { handleLogin, handleChange, userLogin, isFormValid, isLoading } = props

	const buttonProps = {
		onClick: handleLogin,
	}

	return (
		<>
			<LoginWrapper>
				<div className='logo-wrapper'>
					<img src={higaLogo} alt='Logo do Higa Atacados' className='logo' />
				</div>
				<div className='inputs-wrapper'>
					<div className='header'>
						<h2 className='sub-title'>Painel Administrativo</h2>
						<h1 className='title'>Controle de Entrada</h1>
					</div>
					<div className='fields'>
						<form action=''>
							<InputPrimary
								label='Matrícula'
								id='enrollment'
								className='input'
								onChange={handleChange}
								value={userLogin.enrollment}
								maxLength={5}
							/>
							<InputPrimary
								label='CPF'
								id='documentNumber'
								className='input'
								onChange={handleChange}
								value={formatCPF(userLogin.documentNumber)}
								maxLength={14}
							/>

							{isLoading ? (
								<Loading />
							) : (
								<ButtonPrimary
									disabled={!isFormValid}
									className='button'
									{...buttonProps}
								>
									{'Entrar'}
								</ButtonPrimary>
							)}
						</form>
					</div>
				</div>
			</LoginWrapper>
		</>
	)
}

export default Login
