import styled from 'styled-components'

export const PageWrapper = styled.div`
	background: #f9f9f9;
	min-height: 100vh;
	.container-wrapper {
		margin-top: 80px;
		padding-top: 64px;
	}
`
