import styled from 'styled-components'
import { toRem } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'

export const Label = styled.div`
	display: flex;
	flex-direction: column;

	padding: 6px 12px;
	border-radius: 16px;

	.label {
		color: ${theme.colors.grey};
		font-size: ${toRem(12)};
		max-width: 130px;
	}

	.value {
		color: ${theme.colors.darkGrey};
		font-size: ${toRem(32)};
		max-width: 130px;
	}
`
