import React from 'react'

import { primaryButtonDelete } from 'shared/styles/theme'
import { Modal, Button } from '@buildbox/components'
import { ButtonDelete, ModalDeleteContainer } from './styles'
import { IProps } from './types'

const ProfileModalDelete = (props: IProps) => {
	const {
		title,
		isActive,
		onClose,
		message,
		textButton,
		onConfirm,
		currentProfile,
	} = props
	const isEnableToDelete = currentProfile.usersCount >= 1

	return (
		<Modal
			isActive={isActive}
			onClose={() => onClose()}
			title={title}
			size={308}
		>
			<ModalDeleteContainer>
				<p>
					{!isEnableToDelete
						? message
						: `Não é possivel excluir esse perfil há ${currentProfile.usersCount} usuarios utilizando nesse momento!`}
				</p>

				<ButtonDelete>
					<Button
						className='btn-delete'
						onClick={!isEnableToDelete ? onConfirm : onClose}
						{...primaryButtonDelete}
					>
						{!isEnableToDelete ? textButton : 'Fechar Modal!'}
					</Button>
				</ButtonDelete>
			</ModalDeleteContainer>
		</Modal>
	)
}

export default ProfileModalDelete
