export function formatCPF(cpf: string) {
	cpf = cpf.replace(/[^\d]/g, '')

	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function numberFixed(value: number) {
	return value.toFixed(2)
}

export const formatter = new Intl.NumberFormat('pt-br', {
	style: 'currency',
	currency: 'BRL',
	minimumFractionDigits: 2,
})
