import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Creators as UserActions } from '../../shared/store/ducks/user'
import { Creators as TokenActions } from '../../shared/store/ducks/token'
import { formatCPF } from '../../shared/util/mask'
import routesEnum from '../Routes/enum'
import { IViewProps } from './types'
import Login from './view'
import { login } from 'shared/services/login.service'
import {
	IPermission,
	permissionNameValues,
} from 'shared/interfaces/permissions'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'

function LoginContainer(): JSX.Element {
	const dispatch = useDispatch()
	const history = useHistory()

	const [userLogin, setUserLogin] = useState({
		enrollment: '',
		documentNumber: '',
	})
	const [isFormValid, setIsFormValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	async function handleLogin() {
		try {
			setIsLoading(true)
			const response = await login({
				enrollment: userLogin.enrollment,
				documentNumber: formatCPF(userLogin.documentNumber),
			})
			dispatch(TokenActions.setToken({ accessToken: response.accessToken }))
			dispatch(
				UserActions.setUser({
					...response.user,
				}),
			)

			const userPermissions = response.user.profile.permissions as IPermission[]
			const routes = Object.values(routesEnum).filter(
				(route) => route !== '/login',
			)
			const allowedRoutes = routes.filter((route) => {
				return userPermissions.some((permission) => {
					return (
						`/${permission.screenUrl}` === route &&
						permission.name === permissionNameValues.EDIT
					)
				})
			})

			if (!!allowedRoutes.length) {
				history.push(allowedRoutes[0])
			} else {
				history.push(routesEnum.LOGIN)
				cogoToast.error(
					'Você não tem acesso ao painel administravo!',
					cogoDefaultOptions,
				)
			}
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target

		setUserLogin({
			...userLogin,
			[id]: value,
		})
	}

	useEffect(() => {
		const { enrollment, documentNumber } = userLogin

		const isEnrollmentValid = enrollment.length >= 1

		const isDocValid =
			documentNumber.length === 11 || documentNumber.length === 14

		const result = isEnrollmentValid && isDocValid
		setIsFormValid(result)
	}, [userLogin])

	const viewProps: IViewProps = {
		handleLogin,
		handleChange,
		userLogin,
		isFormValid,
		isLoading,
	}

	return createElement(Login, viewProps)
}

export default LoginContainer
