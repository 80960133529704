import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { handleGraphSize } from 'shared/util/calculation'
import styled, { css } from 'styled-components'
import { TeamGraphWrapperProps } from './types'

export const DashboardPageWrapper = styled.div`
	padding: 0 5vw 5vh;
	min-height: 100%;
	& * {
		${fontType(0)};
	}
	.page-header > div {
		align-items: center;

		h1 {
			@media screen and (min-width: 1500px) {
				font-size: ${toRem(35)};
			}

			${mediaQuery('lg')(`
				font-size: ${toRem(24)};
			`)}
		}

		> div:nth-child(2) > div {
			width: 100%;
		}
	}

	.DayPickerInput > input {
		border: 1px solid ${({ theme }) => theme.colors.blue} !important;
	}

	.select-vision > div:nth-child(1) {
		border: 1px solid ${({ theme }) => theme.colors.blue};

		div > div {
			color: ${({ theme }) => theme.colors.darkGrey};
			text-transform: capitalize;
		}
	}
`
export const Label = styled.label`
	font-size: ${toRem(8)};
	${fontType(2)};
	position: absolute;
	margin-left: ${toRem(20)};
	margin-top: 5px;
	color: ${({ theme }) => theme.colors.blue};
	text-transform: uppercase;
	z-index: 1;
`

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`

export const VisionData = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
`

export const ContainerRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: 100%;
	max-width: 100vw;
	/* height: 184px; */
	margin: 10px 0;

	& > div {
		width: 100%;
	}

	& > div:nth-child(1) {
		margin-right: 10px;
	}
	& > div:nth-child(2) {
		margin-left: 10px;
	}

	/* @media (max-width: 900px) { */
	@media (max-width: 1100px) {
		flex-direction: column;

		& > div:nth-child(1) {
			margin-bottom: 10px;
		}
		& > div:nth-child(2) {
			margin-top: 10px;
		}
	}
`

export const Cards = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* width: 50%; */
	/* padding: 0 20px; */

	& > div {
		border-radius: 2px;
		background: #fff;
	}

	& > div:nth-child(2) {
		margin: 0 16px;
	}

	.total-card,
	.revenues-card {
		flex: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		padding: 20px 20px 18px;
		min-width: 150px;

		h3 {
			font-size: 12px;
		}

		.value {
			font-size: 20px;
		}

		.percent {
			font-size: 9px;
		}
	}
	.total-card {
		border-bottom: 2px solid #354d9e;
		.value {
			color: #354d9e;
		}
	}
	.revenues-card {
		border-bottom: 2px solid #359e81;
		.value {
			color: #359e81;
		}
		div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
`

export const GraphExpandButton = styled.button`
	position: absolute;
	top: 0px;
	left: calc(100% - 90px);

	display: flex;
	align-items: center;
	justify-content: center;

	/* width: 56px; */
	padding: 4px 6px;
	background: #c7d2f7;
	border-radius: 9px;
	border: 0;

	color: #354d9e;
	font-size: 8px;

	> img {
		margin-left: 5px;
	}

	&:hover {
		cursor: pointer;
	}
`

export const TimeCardWrapper = styled.div`
	display: flex;
	margin-top: 20px;
`
export const TimeCardContainer = styled.div`
	margin-left: 91px;
`

export const GraphCard = styled.div`
	flex: 1;
	background: #fff;
	/* width: 50%; */
	max-width: 50%;
	border-radius: 2px;
	padding: 20px 0;

	${(props) =>
		props.className === 'performance'
			? css`
				max-height: 370px;
				max-width: 100%;
		  `
			: css``}

	${(props) =>
		props.className === 'general-time' &&
		css`
		margin-top: 20px;
		max-width: 100%;
		max-height: 290px;
	`}

	${(props) =>
		props.className === 'clients-profile' &&
		css`
		margin-top: 20px;
		max-width: 100%;
	`}

	.card-title {
		position: relative;
	}
	.card-title h3 {
		font-size: 14px;
		color: #354d9e;
		margin-left: 20px;
	}

	${({ className }) =>
		className === 'team' &&
		css`
			.card-title {
				display: flex;
				flex-direction: row;
				align-items: baseline;
			}
		`}

	.card-title span {
		font-size: 8px;
		color: #354d9e;
		margin-left: 8px;

		display: flex;
		align-items: center;
	}
	.card-title span div {
		margin-left: 16px;
	}
	.graph-wrapper > div {
		max-height: 185px;
		${fontType(0)}
	}

	.graph-wrapper-team > div,
	.graph-wrapper-performance > div {
	}
`

export const SecondContainerRow = styled.div`
	display: flex;

	width: 100%;
	max-width: 100vw;
	margin: 10px 0;

	& > div {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	& > div:nth-child(1) {
		margin-right: 10px;
	}
	& > div:nth-child(2) {
		margin-left: 10px;
	}

	@media (max-width: 1100px) {
		flex-direction: column;

		& > div:nth-child(1) {
			margin-bottom: 10px;
		}
		& > div:nth-child(2) {
			margin-top: 10px;
		}
	}
`

export const TeamGraphWrapper = styled.div<TeamGraphWrapperProps>`
	flex: 1;
	background: #fff;
	max-width: 100%;
	border-radius: 2px;
	padding: 20px 0;

	.card-title {
		position: relative;
	}
	.card-title h3 {
		font-size: 14px;
		color: #354d9e;
		margin-left: 20px;
	}

	&.team {
		max-height: ${({ items }) => items > 0 ? (handleGraphSize(items) + 60) + 'px' : '200px'};

		.card-title {
			display: flex;
			flex-direction: row;
			align-items: baseline;
		}
	}

	.card-title span {
		font-size: 8px;
		color: #354d9e;
		margin-left: 8px;

		display: flex;
		align-items: center;
	}
	.card-title span div {
		margin-left: 16px;
	}

	.graph-wrapper > div {
		max-height: 185px;
		${fontType(0)}
	}

	.graph-wrapper-team > div,
	.graph-wrapper-performance > div {
		max-height: ${(props) => handleGraphSize(props.items) + 'px'};
	}

	.team-graph-placeholder {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 100%;

		span {
			text-align: center;
			color: #ccc;
			margin-top: 8px;
			max-width: 250px;
		}
	}
`

export const TitleRow = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px 0;
	width: 100%;
`
