import React from 'react'
import { theme } from '../../styles/theme'
import { fontType, toRem } from '../../../shared/styles/functions'
import { GraphContainer } from './styles'
import { IProps } from './types'
import { ResponsiveLine } from '@nivo/line'

function LineGraph(props: IProps) {
	const { data, graphParams, legends, isModal } = props

	const GraphMarginBottom = isModal ? 50 : 150

	const containerTooltip = {
		background: theme.colors.darkGrey,
		color: 'white',
		borderRadius: 4,
		width: '320px',
		height: 'auto',
		fontType: fontType(0),
		fontSize: toRem(12),
		padding: '8px 16px 8px 8px',
	}

	return (
		<GraphContainer>
			<ResponsiveLine
				data={data}
				margin={{ top: 20, right: 30, bottom: GraphMarginBottom, left: 50 }}
				xScale={{ type: 'point' }}
				yScale={{
					type: 'linear',
					min: 0,
					stacked: false,
					reverse: false,
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					orient: 'bottom',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: graphParams.xTickRotation,
					legend: graphParams.xAxisLegend,
					legendOffset: 50,
					legendPosition: 'middle',
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '',
					legendOffset: -40,
					legendPosition: 'middle',
					format: (e) => (Math.floor(Number(e)) === e ? e : ''),
				}}
				colors={data.map((d) => d.color)}
				pointSize={8}
				pointBorderWidth={4}
				pointLabelYOffset={-12}
				useMesh={true}
				enableSlices='x'
				theme={{
					fontFamily: 'Montserrat Regular',
					tooltip: { container: containerTooltip },
					labels: {
						text: {
							fontFamily: 'Montserrat Regular',
						},
					},
				}}
				legends={
					legends && [
						{
							anchor: 'bottom-left',
							direction: 'row',
							justify: false,
							translateX: -5,
							translateY: 120,
							itemsSpacing: 0,
							itemDirection: 'left-to-right',
							itemWidth: 130,
							itemHeight: 20,
							itemOpacity: 0.75,
							symbolSize: 12,
							symbolShape: 'circle',
							symbolBorderColor: 'rgba(0, 0, 0, .5)',
							effects: [
								{
									on: 'hover',
									style: {
										itemBackground: 'rgba(0, 0, 0, .03)',
										itemOpacity: 1,
									},
								},
							],
						},
					]
				}
			/>
		</GraphContainer>
	)
}

export default LineGraph
