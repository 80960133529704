import React from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { ResponsiveBar } from '@nivo/bar'

import { options } from 'shared/util/SelectOption'

import PageTitle from 'shared/components/PageTitle'
import {
	Card,
	CardsWrapper,
	Container,
	FiltersWrapper,
	HeaderServicesReviews,
	IconReview,
	IconsWrapper,
	Label,
	SelectWrapper,
	LabelQuantityEvaluations,
	LabelAndIconWrapper,
	GraphCard,
	GraphPlaceholderContainer,
	LoadButtonText,
} from './styles'
import {
	primaryDashboardButtonStyles,
	primaryInputStyles,
	reactSelectStyles,
} from 'shared/styles/theme'
import { IViewProps } from './types'

import DatePicker from 'shared/components/DatePicker'
import Loading from 'shared/components/Loading'
import { Button } from '@buildbox/components'

function DashboardServiceReviewsView({
	icons,
	loading,
	dataGraph,
	ratingsData,
	selectFilters,
	selectedDates,
	handleSelectDates,
	fecthDataFromDashboard,
}: IViewProps) {
	return (
		<>
			<Container>
				<HeaderServicesReviews>
					<PageTitle>Avaliações</PageTitle>
					<FiltersWrapper>
						<SelectWrapper>
							<Label>Período por data</Label>
							<DatePicker
								dateSetter={handleSelectDates}
								value={selectedDates}
								datePickerTitle='Seleção de Período'
								{...primaryInputStyles}
							/>
						</SelectWrapper>
						{selectFilters?.map((filter, index) => {
							const key = `${index}-${filter.label}`
							return (
								<SelectWrapper key={key}>
									<Label>{filter.label}</Label>
									<ReactMultiSelectCheckboxes
										isMulti
										{...primaryInputStyles}
										styles={reactSelectStyles}
										value={filter.selectOptionState}
										onChange={filter.handleChangeSelect}
										placeholder={filter.placeholder}
										options={options(filter.options)}
										noOptionsMessage={filter.noOptionsMessage}
										getDropdownButtonLabel={filter.getDropdownButtonLabel}
										placeholderButtonLabel={filter.placeholderButtonLabel}
									/>
								</SelectWrapper>
							)
						})}

						<Button
							onClick={fecthDataFromDashboard}
							disabled={loading}
							{...primaryDashboardButtonStyles}
							padding={[13, 20, 13, 20]}
						>
							<LoadButtonText>Buscar</LoadButtonText>
						</Button>
					</FiltersWrapper>
				</HeaderServicesReviews>
				{loading ? (
					<Loading />
				) : (
					<>
						<CardsWrapper>
							<Card>
								<PageTitle>Avaliações de Atendimento</PageTitle>
								<IconsWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.bad} />
										<LabelQuantityEvaluations>
											{ratingsData?.aisle.bad}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.good} />
										<LabelQuantityEvaluations>
											{ratingsData?.aisle.good}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.veryGood} />
										<LabelQuantityEvaluations>
											{ratingsData?.aisle.veryGood}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
								</IconsWrapper>
							</Card>
							<Card>
								<PageTitle>Avaliações gerais no Caixa</PageTitle>
								<IconsWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.bad} />
										<LabelQuantityEvaluations>
											{ratingsData?.cashier.bad}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.good} />
										<LabelQuantityEvaluations>
											{ratingsData?.cashier.good}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.veryGood} />
										<LabelQuantityEvaluations>
											{ratingsData?.cashier.veryGood}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
								</IconsWrapper>
							</Card>
						</CardsWrapper>
						<GraphCard>
							<PageTitle>Quantidade de Atendimento</PageTitle>
							{dataGraph.length > 0 ? (
								<ResponsiveBar
									data={dataGraph}
									keys={['ruim', 'bom', 'muito bom']}
									indexBy='corredor'
									margin={{ top: 20, right: 10, bottom: 50, left: 135 }}
									padding={0.62}
									layout='horizontal'
									valueScale={{ type: 'linear' }}
									colors={['#F67F8F', '#A2B3EB', '#354D9E']}
									theme={{
										fontFamily: 'Montserrat Regular',
										labels: {
											text: {
												fontFamily: 'Montserrat Regular',
											},
										},
									}}
									borderColor={{
										from: 'color',
										modifiers: [['darker', 1.6]],
									}}
									enableLabel={false}
									enableGridX={true}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legendPosition: 'middle',
										legendOffset: 32,
									}}
									axisLeft={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legendPosition: 'middle',
										legendOffset: -40,
									}}
									animate={true}
								/>
							) : (
								<GraphPlaceholderContainer>
									<span>sem dados disponíveis</span>
								</GraphPlaceholderContainer>
							)}
						</GraphCard>
					</>
				)}
			</Container>
		</>
	)
}

export default DashboardServiceReviewsView
