import React from 'react'
import { CardFitWrapper, Container } from './styles'
import { IPropsListTicket } from './types'
import { ITicket } from 'shared/interfaces/ticket'
import CardFit from 'shared/components/CardFit'
import { translateSeparationStatus } from 'shared/util/translate'

function ListSeparationTickets({
	isLate = false,
	title,
	cards,
	activateModalTicket,
	fetchParams,
}: IPropsListTicket) {
	return (
		<Container>
			<header>
				<p>{isLate ? title : translateSeparationStatus(title)}</p>
			</header>

			<ul>
				{cards.map((ticket: ITicket) => {
					const cardProps = {
						ticket,
						currentStatus: ticket.currentSeparationStatus || ticket.currentStatus,
						handleClick: () => activateModalTicket('EDIT', ticket._id as string),
						fetchParams: fetchParams
					}

					return isLate ? (
						<CardFit key={ticket._id} isLast={true} {...cardProps} />
					) : (
						<CardFitWrapper key={ticket._id}>
							<CardFit isLast={false} {...cardProps} />
						</CardFitWrapper>
					)
				})}
			</ul>
		</Container>
	)
}

export default ListSeparationTickets
