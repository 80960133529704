import { Padding } from '@buildbox/components/lib/styles/types'

export const colors = {
    lightGrey: '#F5F5F5',
    grey: '#D1D1D1',
    darkGrey: '#444444',
    white: '#FFFFFF',
    modalBackground: '#0000008F',
    pageBackground: '#F9F9F9',
    picpayGreen: '#21C25E',
    facebook: '#4c69ba'
}


export function paddingToString(padding: Padding) {
    if (typeof padding === 'string') return padding

    return `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`
}
