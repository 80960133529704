import { createGlobalStyle } from 'styled-components'

import MontserratBold from '../../assets/fonts/Montserrat-Bold.woff'
import MontserratBold2 from '../../assets/fonts/Montserrat-Bold.woff2'
import MontserratMedium from '../../assets/fonts/Montserrat-Medium.woff'
import MontserratMedium2 from '../../assets/fonts/Montserrat-Medium.woff2'
import MontserratRegular from '../../assets/fonts/Montserrat-Regular.woff'
import MontserratRegular2 from '../../assets/fonts/Montserrat-Regular.woff2'
import { fontType, mediaQuery } from './functions'

export const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Montserrat Regular';
		src: url(${MontserratRegular}) format('woff'),
		url(${MontserratRegular2}) format('woff2');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Montserrat Medium';
		src: url(${MontserratMedium}) format('woff'),
		url(${MontserratMedium2}) format('woff2');
		font-weight: 500;
		font-style: normal;
	}

	@font-face {
		font-family: 'Montserrat Bold';
		src: url(${MontserratBold}) format('woff'),
		url(${MontserratBold2}) format('woff2');
		font-weight: 700;
		font-style: normal;
	}

	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	html {
		font-size: 16px;

		${mediaQuery('xs')(`
			font-size: 14px;
		`)}
	}

	img {
		vertical-align: middle;
		border-style: none;
		max-width: 100%;
		object-fit: cover;
		object-position: center;
	}

	#ct-container {
		${fontType(1)}
	}
	
	.modal-wrapper{
		overflow-y: auto;
	}
`
