/* eslint-disable react-hooks/rules-of-hooks */
import { SelectOption } from '@buildbox/components'
import cogoToast from 'cogo-toast'
import { ModalActionsEnum } from 'modules/ModalTicket/types'
import { createElement, FormEvent, useCallback, useEffect, useState } from 'react'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { ICardsList, ITicket } from 'shared/interfaces/ticket'
import { fetchWithdrawal } from 'shared/services/ticket.service'
import cogoDefaultOptions from 'shared/util/toaster'
import { useDebounce } from 'use-debounce/lib'

import { IViewProps } from './types'
import WithdrawalBoard from './view'

function withdrawalBoardContainer(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const [cardListData, setCardListData] = useState<ICardsList[]>([])
	const [searchString, setSearchString] = useState('')
	const [loading, setLoading] = useState(true)
	const [showModalDetails, setShowModalDetails] = useState(false)
	const [selectedTicket, setSelectedTicket] = useState<ITicket>()
	const [otherTickets, setOtherTickets] = useState<ICardsList>([])
	const [retailOptions, setRetailOptions] = useState<SelectOption[]>([])
	const [selectedRetails, setSelectedRetails] = useState<SelectOption[]>([])
	const [hasTicketsToShow, setHasTicketsToShow] = useState(false)

	const [retailsDebounce] = useDebounce(selectedRetails, 1000)

	function getSetRetails() {
		const userRetailOptions = user.retails.map(userRetail => ({
			value: String(userRetail._id),
			label: userRetail.name
		}))

		setRetailOptions(userRetailOptions)
		setSelectedRetails(userRetailOptions)
	}

	function fetchData(refresh = false): void {
		; (async () => {
			try {
				setLoading(true)

				const retails = retailsDebounce.map((x) => x.value)

				const filterOptions = {
					searchString: refresh ? '' : searchString,
					retails
				}

				const ticketDoc = await fetchWithdrawal(filterOptions)

				const formatedColumn = formatTicketToColumn(ticketDoc)

				setHasTicketsToShow(!!ticketDoc.length)
				setCardListData(formatedColumn)
			} catch (err) {
				cogoToast.error(err?.message, cogoDefaultOptions)
			} finally {
				setLoading(false)
			}
		})()
	}

	function formatTicketToColumn(tickets: ICardsList) {
		const readyToDeliveryColumn: ICardsList = []
		const checkInColumn: ICardsList = []
		const checkOutColumn: ICardsList = []
		const otherItemsColumns: ICardsList = []

		tickets.forEach((ticket: ITicket) => {

			if (ticket.currentSeparationStatus === 'SEPARATION_READY_TO_DELIVERY') {
				readyToDeliveryColumn.push(ticket)
			} else if (
				ticket.currentSeparationStatus === 'WITHDRAWAL_FULFILLED_CHECK_IN'
			) {
				checkInColumn.push(ticket)
			} else if (
				ticket.currentSeparationStatus === 'WITHDRAWAL_FULFILLED_CHECK_OUT'
			) {
				checkOutColumn.push(ticket)
			} else {
				otherItemsColumns.push(ticket)
			}
		})

		setOtherTickets(otherItemsColumns)

		return [
			readyToDeliveryColumn,
			checkInColumn,
			checkOutColumn,
			otherItemsColumns,
		]
	}

	function handleInputClick() {
		setSearchString('')
		fetchData(true)
	}

	function activateModalTicket(_: ModalActionsEnum, ticket: ITicket) {
		setSelectedTicket(ticket)
		setShowModalDetails(true)
	}

	function closeModal() {
		fetchData()
		setShowModalDetails(false)
	}

	function handleSearch(event: FormEvent<HTMLButtonElement>) {
		event.preventDefault()

		fetchData()
	}

	function handleChange(event: FormEvent<HTMLInputElement>) {
		setSearchString(event.currentTarget.value)
	}

	const handleRetailsSelect = useCallback((value: SelectOption[]) => {
		setSelectedRetails(value)
	}, [])

	const isOtherTicketsEmpty = !!otherTickets.length

	useEffect(fetchData, [retailsDebounce])
	useEffect(getSetRetails, [user])
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(fetchData, [])

	const viewProps: IViewProps = {
		cardListData,
		searchString,
		handleChange,
		handleSearch,
		loading,
		otherTickets,
		isOtherTicketsEmpty,
		activateModalTicket,
		selectedTicket,
		showModalDetails,
		closeModal,
		hasTicketsToShow,
		handleInputClick,
		retailOptions,
		handleRetailsSelect,
		selectedRetails
	}

	return createElement(WithdrawalBoard, viewProps)
}

export default withdrawalBoardContainer
