import React from 'react'

import { DateInputWrapper } from './styles'
import { IDateInputProps } from './types'

const DateInpput = ({ className, label, id, ...rest }: IDateInputProps) => {
	return (
		<DateInputWrapper className={className}>
			<input {...rest} />
			<label htmlFor={id}>{label}</label>
		</DateInputWrapper>
	)
}

export default DateInpput
