import { AxiosResponse } from 'axios'
import axiosInstance from './axiosInstance'

interface payloadRatingsData {
	startDate: string
	endDate: string
	attendants: string[]
	retails: string[]
	periods: string[]
}

export async function feactRatingsDashboardData(payload: payloadRatingsData) {
	const response: AxiosResponse = await axiosInstance.post(
		`/app-attendance/ratings-dashboard`,
		{
			...payload,
		},
	)
	return response.data.content
}
