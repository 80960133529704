import React, { useRef } from 'react'

//styles
import * as S from './styles'
import { primaryButtonStyles, primaryInputStyles } from 'shared/styles/theme'

//components
import { UserBadge } from 'shared/components/UserBadge'
import { Button } from '@buildbox/components'

//routes
import { Link } from 'react-router-dom'
import routesEnum from 'modules/Routes/enum'

//types
import { IViewProps } from './types'

import ArrowIcon from '../../assets/images/arrow-left.svg'
import { useReactToPrint } from 'react-to-print'

function BagdePrintingView({ pages }: IViewProps) {
	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	})

	return (
		<S.Container>
			<S.LeftContentWrapper className='leftWrapper'>
				<Link to={routesEnum.USER}>
					<S.LinkContent>
						<img src={ArrowIcon} alt='Ìcone de seta' />
						<Button {...primaryInputStyles} className='buttonBackAreas'>
							Usuários
						</Button>
					</S.LinkContent>
				</Link>
				<S.TitleDetails>Crachás</S.TitleDetails>
			</S.LeftContentWrapper>
			<S.HeaderActions>
				<Button
					{...primaryButtonStyles}
					onClick={handlePrint}
					className='buttonPrintingQRCode'
				>
					Imprimir Crachás
				</Button>
			</S.HeaderActions>
			<div ref={componentRef}>
				{pages.map((page, index) => (
					<S.QRCodeWrapper>
						<strong>
							Página {index + 1} de {pages.length}
						</strong>
						{page.map((user) => (
							<UserBadge key={user._id} user={user} />
						))}
					</S.QRCodeWrapper>
				))}
			</div>
		</S.Container>
	)
}

export default BagdePrintingView
