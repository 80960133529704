import React from 'react'
import { Button } from '@buildbox/components'
import PageTitle from 'shared/components/PageTitle'
import FloatLabelTextarea from 'shared/components/FloatLabelTextarea'
import {
	primaryDashboardButtonStyles,
	primaryInputStyles
} from 'shared/styles/theme'

import { IViewProps } from './types'
import { Container } from './styles'

function PushNotificationsView(props: IViewProps) {
	const {
		handleChangeTitle,
		title,
		handleChangeBody,
		body,
		handleSubmit,
		isSubmiting,
		isFormValid,
	} = props

	return (
		<Container>
			<PageTitle>Push Notifications</PageTitle>

			<p className='form-label'>Escreva a mensagem</p>
			<div className='input-title'>
				<FloatLabelTextarea
					{...primaryInputStyles}
					fontSize={14}
					label='Título do Push'
					round
					id='push-title'
					maxLength={200}
					onChange={handleChangeTitle}
					value={title}
				/>
			</div>
			<div className='input-body'>
				<FloatLabelTextarea
					{...primaryInputStyles}
					style={{ height: '116px' }}
					fontSize={14}
					label='Mensagem do Push'
					round
					id='push-body'
					maxLength={1024}
					onChange={handleChangeBody}
					value={body}
				/>
			</div>

			<div className='submit-button'>
				<Button
					{...primaryDashboardButtonStyles}
					onClick={handleSubmit}
					disabled={isSubmiting || !isFormValid}
				>Enviar Mensagem</Button>
			</div>
		</Container>
	)
}

export default PushNotificationsView
