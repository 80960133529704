import React, { useEffect, useState } from 'react'
import * as S from './styles'

//types
import { IUserBadge } from './types'

//icons
import HigaLogo from '../../../assets/images/logo-higa-login.png'

import QRcode from 'qrcode'

function UserBadge({ user }: IUserBadge) {
	const [QRCode, setQRCode] = useState('')

	const QRCodeObject = {
		attendantId: user._id,
	}

	useEffect(() => {
		;(async () => {
			const qrCodeBase64 = await QRcode.toDataURL(JSON.stringify(QRCodeObject))
			setQRCode(qrCodeBase64)
		})()
	}, [QRCodeObject, user])

	return (
		<S.Container>
			<S.QRCodeContainer>
				<img src={QRCode} alt='' />
			</S.QRCodeContainer>

			<span>{user.name}</span>
			<S.HigaLogo src={HigaLogo} alt='Higa' />
		</S.Container>
	)
}

export { UserBadge }
