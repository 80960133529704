import styled from 'styled-components'

export const OrderWrapper = styled.div`
	* {
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}

	display: flex;
	background: #ffff;
	padding: 10px;
	align-items: center;
	justify-content: space-around;

	.button {
	}
	#current-status-input {
		max-width: 100px;
	}

	div + div {
		margin-left: 10px;
	}
`
