import {
	addHours,
	endOfToday,
	format,
	getUnixTime,
	isPast,
	isValid,
	parse,
	parseISO,
	startOfToday,
	subHours,
} from 'date-fns'
import BR from 'date-fns/locale/pt-BR'
import React from 'react'
import RDP from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import { monthNamesArray, weekNamesLong, weekNamesShort } from '../../util/date'
import { DayPickerWrapper } from './styles'
import { IProps } from './types'

// This function converts Date from and to into timestamps,
// and it always returns a valid { startTime, endTime } pair,
// even though from or to are undefined
export function toTimestamps(
	from: Date | undefined | null,
	to: Date | undefined | null,
) {
	if (from && to) {
		const startTime = getUnixTime(subHours(from, 12))
		const endTime = getUnixTime(addHours(to, 12))
		return { startTime, endTime }
	}
	if (from && !to) {
		const startTime = getUnixTime(subHours(from, 12))
		const endTime = getUnixTime(addHours(from, 12))
		return { startTime, endTime }
	}
	if (!from && to) {
		const startTime = getUnixTime(subHours(to, 12))
		const endTime = getUnixTime(addHours(to, 12))
		return { startTime, endTime }
	} else {
		// if (!from && !to)
		const startTime = getUnixTime(startOfToday())
		const endTime = getUnixTime(endOfToday())
		return { startTime, endTime }
	}
}

function DatePicker({ dateSetter, value, datePickerTitle }: IProps) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	let dayPicker: DayPickerInput

	const FORMAT = "dd 'de' LLLL yyyy"

	// const FORMAT = 'dd MM yyyy'

	const locale = { locale: BR }

	function parseToDisplay(date: Date) {
		if (!date) return 'DD/MM/YY'

		return format(date, 'dd/MM/yy', { locale: BR })
	}

	function handleDayChange(date: Date): void {
		const range = RDP.DateUtils.addDayToRange(date, value)
		dateSetter(range)

		// Hide Overlay When Selected Two
		// if (range.from && range.to) {
		// 	dayPicker.hideDayPicker()
		// }
	}

	function handleFormatDate(date: Date): string {
		if (!date || !isValid(date)) return ''

		return format(date, FORMAT, locale)
	}

	function handleParseDate(date: string): Date | void {
		if (!date || !isValid(parseISO(date))) return

		if (isPast(parseISO(date))) return

		return parse(date, FORMAT, new Date(), locale)
	}

	// const disabledDays = {
	// 	before: addDays(new Date(), 1),
	// 	after: addDays(new Date(), 20)
	// }

	const { from, to } = value
	const modifiers = { start: from, end: to }
	const dayPickerProps = {
		months: monthNamesArray,
		weekdaysLong: weekNamesLong,
		weekdaysShort: weekNamesShort,
		// disabledDays,
		renderDay: (date: Date) => <p>{date.getDate()}</p>,
		renderWeek: (weekNumber: number) => <p>{weekNamesLong[weekNumber]}</p>,
		modifiers: modifiers,
		selectedDays: [from, { from, to }],
	}

	return (
		<DayPickerWrapper>
			<div className='date-picker-primary'>
				<DayPickerInput
					ref={(ref) => ref && (dayPicker = ref)}
					onDayChange={handleDayChange}
					value={`De ${parseToDisplay(from)} até ${parseToDisplay(to)}`}
					formatDate={handleFormatDate}
					parseDate={handleParseDate}
					placeholder={'Escolha uma data'}
					onDayPickerHide={() => null}
					onDayPickerShow={() => null}
					inputProps={{ readOnly: true }}
					dayPickerProps={dayPickerProps}
					hideOnDayClick={false}
				/>
			</div>
		</DayPickerWrapper>
	)
}

export default DatePicker
