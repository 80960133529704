import styled from 'styled-components'

import { fontType, mediaQuery, toRem } from '../../../shared/styles/functions'

export const RetailCardArea = styled.div`
	width: 100%;
	max-width: 200px;
	margin: 8px;

	${mediaQuery('sm')(`
		max-width: none;
	`)};
`
export const RetailCardTopArea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	padding: 32px;
	box-shadow: 0px 2px 3px #00000014;
	background-color: ${({ theme }) => theme.colors.white};
	margin-bottom: 8px;
	min-height: 250px;
`
export const RetailCardBotArea = styled.div`
	padding: 12px 16px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.colors.lightGrey};
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const Subtitle = styled.div`
	${fontType(0)}
	font-size: ${toRem(12)};
	color: ${({ theme }) => theme.colors.grey};
	margin-bottom: 8px;
`

export const RetailName = styled.span`
	${fontType(0)}
	font-size: ${toRem(14)};
	color: ${({ theme }) => theme.colors.darkGrey};
	margin-bottom: 40px;
	text-align: center;
`

export const RetailCountData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 16px;
`

export const RetailPercent = styled.div`
	${fontType(0)}
	font-size: ${toRem(16)};
	color: ${(props) => props.color};
`

export const RetailLastHourLabel = styled.div`
	${fontType(0)}
	font-size: ${toRem(10)};
	color: ${({ theme }) => theme.colors.background};
`

export const RetailLastHour = styled.div`
	${fontType(2)}
	font-size: ${toRem(10)};
	color: ${(props) => props.color};
`

export const RetailLastHourArrow = styled.img`
	margin-left: 8px;
`

export const RetailCount = styled.p`
	${fontType(0)}
	font-size: ${toRem(44)};
	color: ${(props) => props.color};
`

export const RetailMaxOccupancy = styled.p`
	${fontType(0)}
	font-size: ${toRem(16)};
	color: ${({ theme }) => {
		return theme.colors.darkGrey
	}};
`

export const LastHourWrapper = styled.div`
	display: flex;
	align-items: center;
`
