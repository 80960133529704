import { AxiosResponse } from 'axios'
import { INotification } from 'shared/interfaces/notification'
import axiosInstance from './axiosInstance'

export async function sendPushNotification(notification: INotification) {
    const response: AxiosResponse = await axiosInstance.post('/notification', {
        ...notification
    })

    return response.data.content
}
