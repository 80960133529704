import React from 'react'

import { IInputSearchProps } from './types'
import { Search } from 'react-feather'
import { InputSearchWrapper } from './styles'
import { FloatLabelInput } from '@buildbox/components'
import { primaryInputStyles} from '../../styles/theme'


function InputSearch({
	id,
	label,
	value,
	onChange,
	...props
}: IInputSearchProps) {
	return (
		<InputSearchWrapper>
			<FloatLabelInput
				id={id}
				label={label}
				value={value}
				onChange={onChange}
				type='text'
				{...props}
				{...primaryInputStyles}
			/>

			<Search className='search-icon' />
		</InputSearchWrapper>
	)
}

export default InputSearch
