import styled from 'styled-components'
import { fontType, toRem } from 'shared/styles/functions'
import { colors } from '../CustomSelect/theme'

export const SwitchWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	.checkbox-label {
		color: ${colors.darkGrey};
		font-size: ${toRem(14)};
		${fontType(0)};
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 34px;
		margin-right: 16px;

		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			-webkit-transition: 0.4s;
			transition: 0.4s;

			&:before {
				position: absolute;
				content: '';
				height: 26px;
				width: 26px;
				left: 4px;
				bottom: 4px;
				background-color: white;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			&.round {
				border-radius: 34px;

				&:before {
					border-radius: 50%;
				}
			}
		}

		input {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked + .slider {
				background-color: ${({ theme }) => theme.colors.blue};
			}

			&:focus + .slider {
				box-shadow: 0 0 1px ${({ theme }) => theme.colors.blue};
			}

			&:checked + .slider:before {
				-webkit-transform: translateX(26px);
				-ms-transform: translateX(26px);
				transform: translateX(26px);
			}
		}
	}
`
