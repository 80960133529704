import { createElement, useState, useEffect } from 'react'
import { addHours, startOfToday } from 'date-fns'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { IViewProps } from './types'
import { IFromTo } from '../../shared/components/DatePicker/types'
import { toTimestamps } from '../../shared/components/DatePicker/index'
import { fetchLogHistory } from 'shared/services/counter.service'
//import { IRetailTotalsResponse } from 'shared/interfaces/retail.responses'
import { ICounterListPaginate } from 'shared/interfaces/counterLog'
import StoreFlowView from './view'
import { TableNavProps } from '@buildbox/components'
import { ISelect } from 'shared/interfaces/select'
import { fetchAllRetail } from 'shared/services/retail.service'
import { IRetail } from 'shared/interfaces/retail'

const COUNTERS_PER_PAGE = 10

function StoreFlow(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	// ---------------------- STATE -----------------------------------------
	const [isLoading, setLoading] = useState(false)
	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: addHours(startOfToday(), 12),
		to: addHours(startOfToday(), 12),
	})
	//	const [retails, setRetails] = useState<IRetailTotalsResponse[]>([])
	const [counterLogs, setCounterLogs] = useState<ICounterListPaginate>({
		pageIndex: 0,
		numberOfPages: 0,
		pageContent: [],
		totalDocs: 0,
	})
	const [selectedRetails, setSelectedRetails] = useState<ISelect[]>([])
	const [retailOptions, setRetailOptions] = useState<any[]>([])
	const [viewMode, setViewMode] = useState<
		'absolute' | 'percentage' | 'entry-volume'
	>('absolute')

	// ---------------------- METHODS ---------------------------------------
	useEffect(() => {
		;(async () => {
			const retails: IRetail[] = await fetchAllRetail()
			setSelectedRetails(
				retails.map((retail) => {
					return {
						value: String(retail._id),
						label: retail.name,
					}
				}),
			)
			setRetailOptions(
				retails.map((retail) => {
					return {
						value: String(retail._id),
						label: retail.name,
					}
				}),
			)
		})()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		;(async () => {
			setLoading(true)
			await requestCounterLogs(0)
			setLoading(false)
		})()
		// eslint-disable-next-line
	}, [selectedRetails, selectedDates])

	async function requestCounterLogs(pageIndex: number) {
		const { startTime, endTime } = toTimestamps(
			selectedDates.from,
			selectedDates.to,
		)

		if (selectedRetails) {
			const logs: ICounterListPaginate = await fetchLogHistory(
				selectedRetails.map((retail) => String(retail.value)),
				startTime,
				endTime,
				pageIndex + 1,
				COUNTERS_PER_PAGE,
			)

			setCounterLogs(logs)
		}
	}

	async function goToPage(pageIndex: number) {
		setLoading(true)
		const { startTime, endTime } = toTimestamps(
			selectedDates.from,
			selectedDates.to,
		)
		const logs: ICounterListPaginate = await fetchLogHistory(
			selectedRetails.map((r) => r.value),
			startTime,
			endTime,
			pageIndex + 1,
			COUNTERS_PER_PAGE,
		)
		setCounterLogs(logs)
		setLoading(false)
	}

	const navProps: TableNavProps = {
		nextPage: requestCounterLogs,
		previousPage: requestCounterLogs,
		gotoPage: goToPage,
		pageCount: counterLogs.numberOfPages,
		pageIndex: counterLogs.pageIndex - 1,
		totalDocs: counterLogs.totalDocs,
	}

	//	function handleSetRetails(retails: IRetailTotalsResponse[]) {
	//		setRetails(retails)
	//	}

	function handleSetDates(value: IFromTo) {
		setSelectedDates(value)
	}

	function handleChangeSelectRetails(options: ISelect[]) {
		setSelectedRetails(options)
	}

	const viewProps: IViewProps = {
		isLoading,
		user,
		counterLogs,
		selectedDates,
		handleSetDates,
		navProps,
		counterPerPage: COUNTERS_PER_PAGE,
		goToPage,
		selectedRetails,
		handleChangeSelectRetails,
		retailOptions,
		viewMode,
		setViewMode,
	}

	return createElement(StoreFlowView, viewProps)
}

export default StoreFlow
