import { createElement } from 'react'
import { useLocation } from 'react-router-dom'

import { IViewProps } from './types'
import QRCodePrintingView from './view'

function QRCodePrinting() {
	const { search } = useLocation()
	const imageQrcode = search.split('qrCode=')[1]
	const typeArea = search
		.split('?areaName=')
		.join('')
		.split('&qrCode=')[0]
		.split('%20')
		.join(' ')

	const viewProps: IViewProps = {
		srcQrcode: imageQrcode,
		typeQrcode: typeArea,
	}
	return createElement(QRCodePrintingView, viewProps)
}

export default QRCodePrinting
