import { createElement, useState, useEffect } from 'react'
import { differenceInDays, fromUnixTime, isEqual, isToday } from 'date-fns'
import { toTimestamps } from '../../shared/components/DatePicker/index'
import { IGraphParams } from '../../shared/components/LineGraph/types'
import { IProps, IViewProps, ILogsSummary } from './types'
import { fetchUserPerPeriod } from 'shared/services/counter.service'

import RetailVisitsGraphView from './view'
import { fetchVisitorsGraphData } from 'shared/services/counter.service'
import { colorArray } from 'shared/styles/theme'

const COMMERCIAL_START_HOUR = 6
const COMMERCIAL_END_HOUR = 23

function RetailVisitsGraph(props: IProps): JSX.Element {
	const {
		selectedRetails,
		selectedDates,
		handleSetDates,
		viewMode,
		setViewMode,
	} = props
	const [isLoading, setLoading] = useState(true)

	// ---------------------- STATE -----------------------------------------
	const [userPerPeriod, setUserPerPeriod] = useState<string | any>()

	const [graphData, setGraphData] = useState<ILogsSummary[]>([])

	const [graphParams, setGraphParams] = useState<IGraphParams>({
		xTickRotation: 0,
		xAxisLegend: 'Hora',
		ymax: 300,
	})

	useEffect(() => {
		;(async () => {
			setLoading(true)
			//			await requestCounterLogs(0)
			await fetchDataClient()
			await setupGraphData()
			setLoading(false)
		})()
		// eslint-disable-next-line
	}, [selectedRetails, selectedDates, viewMode])

	async function fetchDataClient() {
		try {
			if (!selectedRetails || !selectedRetails.length || !selectedDates) {
				setUserPerPeriod(0)

				return
			}

			const { startTime, endTime } = toTimestamps(
				selectedDates.from,
				selectedDates.to,
			)
			const clientsPerPage = await fetchUserPerPeriod(
				selectedRetails,
				startTime,
				endTime,
			)
			setUserPerPeriod(clientsPerPage)
		} catch (err) {}
	}

	function calculateAverageHoursInPeriod() {
		if (
			!userPerPeriod ||
			!selectedDates ||
			!selectedDates.to ||
			!selectedDates.from
		) {
			return '0'
		}

		const daysDiff = differenceInDays(selectedDates.to, selectedDates.from) + 1

		return (userPerPeriod / (15 * daysDiff)).toFixed(1)
	}

	async function setupGraphData() {
		if (!selectedRetails || !selectedRetails.length) return

		const { startTime, endTime } = toTimestamps(
			selectedDates.from,
			selectedDates.to,
		)

		const currentHour = new Date().getHours()
		const currentMinute = new Date().getMinutes()
		let CURRENT_TIME = Number(
			`${currentHour}.${currentMinute.toString().padStart(2, '0')}`,
		)
		if (currentMinute >= 30) {
			CURRENT_TIME = currentHour + 1
		}

		if (
			selectedDates.from &&
			selectedDates.to &&
			isEqual(selectedDates.from, selectedDates.to) &&
			isToday(selectedDates.from) &&
			currentHour < COMMERCIAL_END_HOUR
		) {
			await summarizeLogData(
				startTime,
				endTime,
				COMMERCIAL_START_HOUR,
				CURRENT_TIME,
				selectedRetails,
			)
		} else {
			await summarizeLogData(
				startTime,
				endTime,
				COMMERCIAL_START_HOUR,
				COMMERCIAL_END_HOUR,
				selectedRetails,
			)
		}
	}

	async function summarizeLogData(
		startTime: number,
		endTime: number,
		startHour: number,
		endHour: number,
		retails: string[],
	) {
		// 1- Initializes variables
		const startTimeDate = fromUnixTime(startTime)
		const timezoneOffset = startTimeDate.getTimezoneOffset() / 60
		const HALF_HOUR = 1800
		const ONE_HOUR = 3600
		const ONE_DAY = ONE_HOUR * 24
		const xTick = endTime - startTime > ONE_DAY ? ONE_DAY : HALF_HOUR

		// If the graph will show only 1 day, redefines endTime and startTime
		if (xTick === HALF_HOUR) {
			endTime = startTime + endHour * ONE_HOUR
			startTime += startHour * ONE_HOUR
		}

		// Set Graph Params
		// Please, dont remove the commented code below. It will be needed in case auto-rotation is needed
		//const xRange = endTime - startTime
		//const xTickNumber = Math.ceil(xRange / xTick)
		//const xTickRotation = xTickNumber <= 12 ? 0 : xTickNumber < 24 ? -45 : -90
		const xTickRotation = -45

		const xAxisLegend = xTick === HALF_HOUR ? 'Horário' : ''
		switch (viewMode) {
			case 'absolute':
				setGraphParams({ xTickRotation, xAxisLegend, ymax: 300 })
				break
			case 'percentage':
				setGraphParams({ xTickRotation, xAxisLegend, ymax: 100 })
				break
			default:
				setGraphParams({ xTickRotation, xAxisLegend, ymax: 'auto' })
				break
		}

		const logsSummaryArray = await fetchVisitorsGraphData(
			retails,
			startTime,
			endTime,
			viewMode,
		)

		if (logsSummaryArray.length) {
			// SET FIXED COLORS
			for (let i = 0; i < logsSummaryArray.length; i++) {
				logsSummaryArray[i].color = colorArray[i]
			}
			if (xTick === HALF_HOUR) {
				// SET FIXED X value
				for (let i = 0; i < logsSummaryArray.length; i++) {
					const logSummaryDataY = logsSummaryArray[i].data.map(
						(logSummaryDataItem) => logSummaryDataItem.y,
					)
					const shiftYData = shiftArray(logSummaryDataY, timezoneOffset * 2 - 1)
					const logData = logsSummaryArray[i].data

					for (let j = 0; j < logData.length; j++) {
						logData[j].y = shiftYData[j]
					}

					logsSummaryArray[i].data = logData.filter((logDataItem) => {
						const [hours, minutes] = logDataItem.x.toString().split(':')
						const itemHour = Number(hours) + Number(minutes) / 100
						return itemHour > startHour && itemHour < endHour
					})
				}
			}
		}

		setGraphData(logsSummaryArray)
	}

	// Shifts array left  offset positions, if offset is negative
	// Shifts array right offset positions, if offset is positive
	function shiftArray(logSummaryData: any[], offset: number): any[] {
		return logSummaryData.slice(offset).concat(logSummaryData.slice(0, offset))
	}

	//	useEffect(() => {
	//		;(async () => {
	//			setLoading(true)
	//			await setupGraphData()
	//			setLoading(false)
	//		})()
	//		// eslint-disable-next-line
	//	}, [selectedRetails, selectedDates, viewMode])

	const viewProps: IViewProps = {
		viewMode,
		setViewMode,
		selectedDates,
		handleSetDates,
		graphData,
		graphParams,
		isLoading,
		userPerPeriod,
		calculateAverageHoursInPeriod,
	}

	return createElement(RetailVisitsGraphView, viewProps)
}

export default RetailVisitsGraph
