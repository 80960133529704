import { createElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { IUser } from 'shared/interfaces/user'

import { IViewProps } from './types'
import BagdePrintingView from './view'

function BagdePrinting() {
	const { state } = useLocation<IUser[]>()

	const [pages, setPages] = useState<IUser[][]>([])

	const maxItemsPage = 6

	const history = useHistory()

	if (!state) {
		history.push('/users')
	}

	useEffect(() => {
		if (state) {
			const initialPages: IUser[][] = []

			for (let i = 0; i < state.length; i += maxItemsPage) {
				const page = state.slice(i, i + maxItemsPage)
				initialPages.push(page)
			}

			setPages(initialPages)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const viewProps: IViewProps = {
		pages,
	}

	return createElement(BagdePrintingView, viewProps)
}

export default BagdePrinting
