import { createElement, useCallback, useEffect, useState } from 'react'
import { SelectOption, TableNavProps } from '@buildbox/components'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import {
	fetchStoreAislePage,
	deleteStoreAisle,
} from 'shared/services/storeAisle.service'
import {
	IPaginatedList,
	ITableColumn,
	ITableRow,
} from 'shared/interfaces/utils'
import { IStoreAisle } from 'shared/interfaces/storeAisle'

import {
	COUNTERS_PER_PAGE,
	ICloseModalProps,
	IChangeModalVisibility,
	IStoreAisleRow,
	IViewProps,
	ModalType,
} from './types'
import { render } from './util'
import StoreAislesView from './view'

function StoreAisles() {
	const { user } = useTypedSelector(['user'])

	const [retailOptions, setRetailOptions] = useState<SelectOption[]>([])
	const [selectedRetail, setSelectedRetail] = useState<SelectOption | null>(
		null,
	)

	const [isFetchingStoreAisles, setIsFetchingStoreAisles] = useState(false)
	const [tableProps, setTableProps] = useState<IPaginatedList<IStoreAisle>>({
		pageIndex: 1,
		numberOfPages: 1,
		pageContent: [],
		totalDocs: 0,
	})
	const [tableRows, setTableRows] = useState<ITableRow<IStoreAisleRow>[]>([])

	const [modalVisibility, setModalVisibility] = useState<ModalType>('')
	const [selectedStoreAisle, setSelectedStoreAisle] = useState<IStoreAisle>()

	const handleChangeRetail = useCallback(
		(selectedOption: SelectOption | null) => {
			if (!selectedOption) return

			setSelectedRetail(selectedOption)
		},
		[],
	)

	function populateRetailOptions() {
		const userRetails = user.retails

		const options = userRetails.map((retail) => ({
			value: String(retail._id),
			label: retail.name,
		}))

		setRetailOptions(options)
		setSelectedRetail(options[0])
	}

	const changeTableRows = useCallback(
		(pageContent: IStoreAisle[]) => {
			const rows = pageContent.map((storeAisle) => {
				return {
					retail: render.retailName(user.retails, storeAisle.retail),
					areaName: storeAisle.name,
					areaDescription: storeAisle.description,
					settings: render.settingsIcon(() =>
						changeModalVisibility({
							modal: 'CREATE/UPDATE',
							storeAisle: storeAisle,
						}),
					),
					trash: render.trashIcon(() =>
						changeModalVisibility({
							modal: 'DELETE',
							storeAisle: storeAisle,
						}),
					),
				}
			})

			setTableRows(rows)
		},
		[user.retails],
	)

	const fetchPageData = useCallback(
		(pageIndex = tableProps.pageIndex) => {
			;(async () => {
				if (!selectedRetail) return

				try {
					setIsFetchingStoreAisles(true)

					const pagePropsResponse = await fetchStoreAislePage({
						maxItemsInPage: COUNTERS_PER_PAGE,
						pageIndex: pageIndex,
						retail: selectedRetail?.value,
					})

					changeTableRows(pagePropsResponse.pageContent)

					setTableProps(pagePropsResponse)
				} finally {
					setIsFetchingStoreAisles(false)
				}
			})()
		},
		[changeTableRows, selectedRetail, tableProps.pageIndex],
	)

	function changeModalVisibility({
		modal,
		storeAisle,
	}: IChangeModalVisibility) {
		setModalVisibility(modal)
		setSelectedStoreAisle(storeAisle)
	}

	const closeModal = useCallback(
		({ refetchData = false, retail: retailId }: ICloseModalProps) => {
			changeModalVisibility({ modal: '' })

			if (refetchData) {
				if (retailId && retailId !== selectedRetail?.value) {
					setSelectedRetail(
						retailOptions.find((retail) => retail.value === retailId) || null,
					)
				} else {
					fetchPageData(1)
				}
			}
		},
		[retailOptions, fetchPageData, selectedRetail],
	)

	function fetchPageOne() {
		fetchPageData(1)
	}

	const handleDeleteStoreAisle = useCallback(
		(storeAisleId: string) => {
			;(async () => {
				try {
					await deleteStoreAisle(storeAisleId)

					cogoToast.success('Área excluído com sucesso!', cogoDefaultOptions)
				} finally {
					closeModal({ refetchData: true })
				}
			})()
		},
		[closeModal],
	)

	useEffect(populateRetailOptions, [])
	useEffect(fetchPageOne, [selectedRetail])

	const tableColumns: ITableColumn[] = [
		{ Header: 'Loja', accessor: 'retail', sortType: 'basic' },
		{ Header: 'Nome da Área', accessor: 'areaName', sortType: 'basic' },
		{ Header: 'Descrição', accessor: 'areaDescription', sortType: 'basic' },
		{ Header: '', accessor: 'settings', disableSortBy: true },
		{ Header: '', accessor: 'trash', disableSortBy: true },
	]

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => fetchPageData(pageIndex + 1),
		previousPage: (pageIndex) => fetchPageData(pageIndex + 1),
		gotoPage: (pageIndex) => fetchPageData(pageIndex + 1),
		pageCount: tableProps.numberOfPages,
		pageIndex: tableProps.pageIndex - 1,
		totalDocs: tableProps.totalDocs,
	}

	const viewProps: IViewProps = {
		retailOptions,
		selectedRetail,
		handleChangeRetail,
		isFetchingStoreAisles,
		tableColumns,
		tableRows,
		navProps,
		selectedStoreAisle,
		modalVisibility,
		changeModalVisibility,
		closeModal,
		handleDeleteStoreAisle,
	}

	return createElement(StoreAislesView, viewProps)
}

export default StoreAisles
