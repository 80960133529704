import { AxiosResponse } from 'axios'
import { ISeparationParams } from 'shared/interfaces/SeparationParams'
import axiosInstance from './axiosInstance'

export async function getPageSeparationParams(retails: string[]): Promise<ISeparationParams[]> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
            `/separation-params/page-params`,
            {
                retails
            }
        )

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function getRetailSeparationParams(retailId: string): Promise<ISeparationParams> {
	try {
		const response: AxiosResponse = await axiosInstance.get(
            `/separation-params/page-params/${retailId}`
        )

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function saveRetailSeparationParams({
	retail,
	...params
}: Partial<ISeparationParams>): Promise<ISeparationParams> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-params/${retail}`,
            params
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}