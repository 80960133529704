import React from 'react'

import { ButtonPrimaryWrapper } from './styles'
import { IProps } from './types'

function ButtonPrimary({ children, className, disabled, ...rest }: IProps) {
	return (
		<ButtonPrimaryWrapper className={className} {...rest}>
			<button disabled={disabled}>{children}</button>
		</ButtonPrimaryWrapper>
	)
}

export default ButtonPrimary
