import React from 'react'
import {
	CardWrapper,
	BadgeTicket,
	CardWrapperContainer,
	OrderCounter,
	Content,
	TotalSkus,
	TotalItems,
	TotalValue,
	Header,
	Footer,
	TicketAndSeparationNameWrapper,
	CreatedByWrapper,
	SeparatorLabel,
	ButtonSection,
	ButtonSectionLabel,
	ButtonWrapper,
	ButtonReject,
	ButtonApprove,
	LocatedWrapper,
	LocatedLabel,
	LocatedLocale,
	BadgeRetail
} from './styles'
import packageSVG from '../../../assets/images/package.svg'
import { ICardTicket } from './types'
import userSVG from 'assets/images/user.svg'
import userSVGRed from 'assets/images/user-red.svg'
import { formatter } from 'shared/util/mask'
import { ticketSeparationStatus } from 'shared/interfaces/ticket'
import Approve from '../../../assets/images/approve.svg'
import Reject from '../../../assets/images/reject.svg'
import { declineOrApprovePluginRequest } from 'shared/services/separation.service'
import { translateAllStatus, verifyBoard } from 'shared/util/translate'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

const CardFit: React.FC<ICardTicket> = ({
	ticket,
	isLast,
	currentStatus,
	handleClick,
	fetchParams,
	showLocated = false,
	backgroundShadow = false,
}) => {
	const { user } = useTypedSelector(['user'])

	const ticketRetailName = user.retails.find(userRetail => userRetail._id === ticket.retail)

	async function approveTicket() {
		await declineOrApprovePluginRequest(ticket, 'approve')
		fetchParams()
	}

	async function refuseTicket() {
		await declineOrApprovePluginRequest(ticket, 'decline')
		fetchParams()
	}

	return (
		<CardWrapperContainer draggable={true}>
			{currentStatus === ticketSeparationStatus.SEPARATION_PLUGIN_REQUEST &&
			isLast === false ? (
				<ButtonSection>
					<ButtonSectionLabel>Solicitação de Encaixe</ButtonSectionLabel>
					<ButtonWrapper>
						<ButtonReject onClick={refuseTicket}>
							<img src={Reject} alt='' />
						</ButtonReject>
						<ButtonApprove onClick={approveTicket}>
							<img src={Approve} alt='' />
						</ButtonApprove>
					</ButtonWrapper>
				</ButtonSection>
			) : null}

			{showLocated && (
				<LocatedWrapper>
					<LocatedLabel>Localizado em</LocatedLabel>
					<LocatedLocale>{`${verifyBoard(
						currentStatus ? currentStatus : '',
					)} - ${translateAllStatus(
						currentStatus ? currentStatus : '',
					)}`}</LocatedLocale>
				</LocatedWrapper>
			)}

			<CardWrapper
				showLocated={showLocated || backgroundShadow}
				onClick={handleClick}
			>
				{!!ticketRetailName && <BadgeRetail isLast={isLast}>{ticketRetailName?.name}</BadgeRetail>}
				<Header>
					<TicketAndSeparationNameWrapper>
						<BadgeTicket isLast={isLast}>{ticket.ticket}</BadgeTicket>

						<CreatedByWrapper isLast={isLast}>
							<img src={isLast ? userSVGRed : userSVG} alt='User' />

							<SeparatorLabel isLast={isLast}>
								{ticket.codeOrName}
							</SeparatorLabel>
						</CreatedByWrapper>
					</TicketAndSeparationNameWrapper>

					<OrderCounter>
						<img src={packageSVG} alt='Package' />
						<span>{ticket.orders.length}</span>
					</OrderCounter>
				</Header>
				<Content>
					<TotalSkus>
						{ticket.orders.reduce((total, value) => total + value.totalSkus, 0)}{' '}
						SKU's
					</TotalSkus>
					<TotalItems>
						{ticket.orders.reduce((total, value) => total + value.quantity, 0)}{' '}
						itens
					</TotalItems>
					<TotalValue>
						{formatter.format(
							ticket.orders.reduce(
								(total, value) => total + value.totalValue,
								0,
							),
						)}
					</TotalValue>
				</Content>
			</CardWrapper>
			<Footer>{ticket.formatedCreatedAt}</Footer>
		</CardWrapperContainer>
	)
}

export default CardFit
