import React from 'react'
import { Button, Select, Loading, Table } from '@buildbox/components'
import PageTitle from 'shared/components/PageTitle'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import {
	primaryDashboardButtonStyles,
	primaryInputTicketStyles,
	tableStyle,
	theme,
} from 'shared/styles/theme'
import ModalDelete from 'shared/components/ModalDelete'

import { COUNTERS_PER_PAGE, IViewProps } from './types'
import { Container, Header, LoadingWrapper } from './styles'
import ModalStoreAisle from './ModalStoreAisle'

function StoreAislesView(props: IViewProps) {
	const {
		retailOptions,
		selectedRetail,
		handleChangeRetail,
		isFetchingStoreAisles,
		tableColumns,
		tableRows,
		navProps,
		selectedStoreAisle,
		modalVisibility,
		changeModalVisibility,
		closeModal,
		handleDeleteStoreAisle,
	} = props

	return (
		<Container>
			<Header>
				<div className='title-and-filter'>
					<PageTitle>Áreas de Atendimento</PageTitle>

					<Select
						round
						placeholder='Selecionar Loja'
						{...primaryInputTicketStyles}
						id='select-retail'
						options={retailOptions}
						value={selectedRetail}
						onChange={handleChangeRetail}
					/>
				</div>

				<div className='button-create'>
					<Button
						{...primaryDashboardButtonStyles}
						onClick={() => changeModalVisibility({ modal: 'CREATE/UPDATE' })}
					>
						Nova Área
					</Button>
				</div>
			</Header>

			{isFetchingStoreAisles ? (
				<LoadingWrapper>
					<Loading type='Digital' primaryColor={theme.colors.blue} />
				</LoadingWrapper>
			) : !!tableRows.length ? (
				<Table
					{...tableStyle}
					columns={tableColumns as any}
					data={tableRows}
					pageSize={COUNTERS_PER_PAGE}
					navProps={navProps}
				/>
			) : (
				<DefaultPagePlaceholder />
			)}

			<ModalStoreAisle
				closeModal={closeModal}
				selectedStoreAisle={selectedStoreAisle}
				retailOptions={retailOptions}
				isActive={modalVisibility === 'CREATE/UPDATE'}
			/>

			{!!selectedStoreAisle && (
				<ModalDelete
					isActive={modalVisibility === 'DELETE'}
					onClose={() => closeModal({})}
					title='Excluir Área de Atendimento'
					message={`Você optou por excluir a área "${selectedStoreAisle.name}", esta ação não poderá ser desfeita!`}
					onConfirm={() => handleDeleteStoreAisle(selectedStoreAisle._id)}
					textButton='Excluir Área'
				/>
			)}
		</Container>
	)
}

export default StoreAislesView
