import React from 'react'
import { toMoney } from 'shared/util/currency'
import { IRenderAveragesCard } from './types'
import { RenderAveragesCardWrapper } from './styles'

function RenderAveragesCard(props: IRenderAveragesCard) {
	const { vision, data: { averages } } = props
	
	function renderValue(value: number) {
		return vision === 'REVENUE' ? toMoney(value) : value.toFixed(1)
	}

	return (
		<RenderAveragesCardWrapper>
			<h3>Média</h3>
			<div className='channel'>
				<h4>Canal</h4>
				<span>{renderValue(averages.averageUnitsPerChannel)}</span>
			</div>
			<div className='items'>
				<h4>Itens</h4>
				<span>{renderValue(averages.averageItemsPerUnit)}</span>
			</div>
			<div className='person'>
				<h4>Pessoa</h4>
				<span>
					<span>{renderValue(averages.averageUnitsPerAttendant)}</span>
				</span>
			</div>
			<div className='skus'>
				<h4>SKUs</h4>
				<span>{renderValue(averages.averageSkusPerUnit)}</span>
			</div>
		</RenderAveragesCardWrapper>
	)
}
export default RenderAveragesCard
