import styled, { css } from 'styled-components'
import { ellipsis, fontType, toRem, transition } from '../../styles/functions'

export const FloatLabelTextareaWrapper = styled.div<any>`
	${({ styles }) => css`
		position: relative;

		label.flt-label {
			color: ${styles.secondaryColor};
			font-size: ${toRem(styles.fontSize)};
			left: 16px;
			position: absolute;
			top: 18px;

			cursor: text;

			${transition()}
			${fontType(styles.fontFamily)};
		}

		textarea.flt-textarea {
			background: ${styles.backgroundColor};
			border-radius: ${styles.round ? '30px' : '4px'};

			color: ${styles.secondaryColor};
			font-size: ${toRem(styles.fontSize)};
			padding: ${styles.padding};
			height: 58px;
			width: 100%;
			border: solid 1px ${styles.secondaryColor};

			${transition()}
			/* ${ellipsis()} */ /* It's affecting textarea word wrap */
			${fontType(styles.fontFamily)};

			&:focus,
			&:valid {
				border: solid 1px ${styles.primaryColor};

				+ label {
					color: ${styles.primaryColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 12px;
					text-transform: uppercase;
				}
			}

			&[disabled] {
				border: ${styles.dashedWhenDisabled ? 'dashed' : 'solid'} 1px
					${styles.secondaryColor};

				+ label {
					color: ${styles.secondaryColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 12px;
				}
			}
		}

		p.show-value-length {
			width: 100%;
			color: ${styles.isFilled || styles.focused
			? styles.primaryColor
			: styles.secondaryColor
		};
			text-align: end;
			font-size: 10px;
			font-family: ${fontType(2)};
		}
	`}
`
