import React, { useState } from 'react'

import { logout } from '../../util/session'
import Submenu from './Submenu'
import { submenuOptionEnum } from './Submenu/types'

import { SideNavWrapper } from './styles'
import { IProps } from './types'
import { submenuOptions } from './data'

export default function SideNav({
	isOpen,
	handleSetIsOpen,
	routes,
	user,
}: IProps): JSX.Element {
	const [openedSubnav, setOpenedSubnav] = useState<submenuOptionEnum>('')

	const { name } = user

	const firstName = name.split(' ')[0]

	return (
		<SideNavWrapper className={isOpen ? 'active' : ''}>
			<div className='background' onClick={handleSetIsOpen} />
			<div className='menu-wrapper'>
				<div className='spacer' />
				<div className='nav-wrapper'>
					<nav>
						<div className='upper-part'>
							<ul className='nav-top'>
								<div className='profile'>
									{/* TODO: fazer quando o app aceitar fotos */}
									{/* <div className='photo'>
										<img
											src={require('../../assets/images/logo-higa-header.png')}
											alt='profile'
										/>
									</div> */}

									<div className='info'>
										<p className='name'>Olá, {firstName}</p>
										<p className='profile-link'>Meu Perfil</p>
									</div>
								</div>
							</ul>
							<ul className='nav-mid'>
								{submenuOptions.map((submenuOption) => {
									const submenuRoutes = routes.filter(
										(route) => route.submenu === submenuOption.submenuKey,
									)
									return (
										!!submenuRoutes.length && (
											<Submenu
												key={submenuOption.submenuKey}
												submenuKey={submenuOption.submenuKey}
												title={submenuOption.title}
												icon={submenuOption.icon}
												routes={submenuRoutes}
												setOpenedSubnav={setOpenedSubnav}
												openedSubnav={openedSubnav}
												handleSetIsOpen={handleSetIsOpen}
											/>
										)
									)
								})}
							</ul>
						</div>

						<div className='bottom-part'>
							<ul className='nav-bottom'>
								<li onClick={logout}>Sair</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</SideNavWrapper>
	)
}
