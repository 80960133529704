import React, { Fragment } from 'react'
import { IOrderProps } from './types'
import { FloatLabelInput } from '@buildbox/components'
import { OrderWrapper } from './styles'
import { primaryDisableInputTicketStyles } from 'shared/styles/theme'
import { toMoney } from 'shared/util/currency'

function OrderDisable(props: IOrderProps): JSX.Element {
	const { order, index } = props

	function convertNumberToString(value: number): string {
		if (isNaN(value) || value === 0 || value === null) return ''
		return value.toString()
	}

	return (
		<OrderWrapper>
			<FloatLabelInput
				label='Pedido Nº'
				id={`order-code-${index}`}
				round
				value={order.code}
				{...primaryDisableInputTicketStyles}
				disabled={true}
				dashedWhenDisabled={false}
			/>
			<FloatLabelInput
				label='Quantidade'
				id={`order-quantity-${index}`}
				round
				value={convertNumberToString(order.quantity)}
				{...primaryDisableInputTicketStyles}
				disabled={true}
				dashedWhenDisabled={false}
			/>

			<FloatLabelInput
				label="Total SKU's"
				id={`order-totalsku-${index}`}
				round
				value={convertNumberToString(order.totalSkus)}
				{...primaryDisableInputTicketStyles}
				disabled={true}
				dashedWhenDisabled={false}
			/>
			<FloatLabelInput
				label='Valor'
				id={`order-totalvalue-${index}`}
				round
				value={toMoney(order.totalValue)}
				{...primaryDisableInputTicketStyles}
				disabled={true}
				dashedWhenDisabled={false}
			/>

			<Fragment />
		</OrderWrapper>
	)
}

export default OrderDisable
