import React, { useEffect, useState } from 'react'
import { numberFixed } from 'shared/util/mask'
import { translateChannelName, abbreviateLastName } from 'shared/util/translate'
import { IProps, IGraphData } from './types'
import ResponsiveBarGraphForSeparationDash from 'shared/components/ResponsiveBarGraphForSeparationDash'
import { VisionTypeOptions } from 'shared/interfaces/ticket'
import { toMoney } from 'shared/util/currency'

function SeparationDashboardResponsiveBarGraph(props: IProps): JSX.Element {
	const [data, setData] = useState<IGraphData[]>([])
	const { dataGraph, graph, vision, visionType } = props

	const tooltip = ({ data }: any) => {
		let total = data.total
		let percent = data.percent

		if (vision === VisionTypeOptions.REVENUE) {
			total = toMoney(total)
		}

		return (
			<>
				<h4 style={{ fontWeight: 'bold' }}>{data.name}:</h4>
				{graph === 'TEAM' ? (
					<span>
						Total: {visionType === 'VOLUME' ? total : `${data.averageTime} min.`}
					</span>
				) : (
					<ul style={{ listStyle: 'none' }}>
						<li>Total: {total}</li>
						<li>
							Percentual:{' '}
							{numberFixed(percent * 100)}%
						</li>
					</ul>
				)}
			</>
		)
	}

	useEffect(() => {
		if (dataGraph) {
			if (graph === 'CHANNEL') {
				const translatedChannelName = dataGraph.map((item) => {
					return {
						...item,
						name: translateChannelName(item.name),
					}
				})
				setData(translatedChannelName)
			} else if (graph === 'TEAM') {
				setData(
					dataGraph
						.filter((item) => item.total >= 1)
						.map((item) => ({
							...item,
							abbreviatedName: abbreviateLastName(item.name),
						})),
				)
			}
		}
	}, [dataGraph, graph])

	return (
		<ResponsiveBarGraphForSeparationDash
			data={data}
			tooltipData={tooltip}
			graph={graph}
			vision={vision}
			visionType={visionType}
		/>
	)
}

export default SeparationDashboardResponsiveBarGraph
