import React from 'react'

import { ISecondaryInput } from './types'
import {
	ButtonWrapper,
	InputSearchContainer,
	InputSearchWrapper,
} from './styles'
import FloatLabelInput from './Input'
import { primaryButtonStyles, primaryInputStyles } from '../../styles/theme'

import { Button } from './styles'

function SecondaryInput(props: ISecondaryInput) {
	const { handleInputClick, handleSearch, ...rest } = props

	return (
		<InputSearchContainer>
			<InputSearchWrapper>
				<FloatLabelInput
					handleClick={handleInputClick}
					type='text'
					{...rest}
					{...primaryInputStyles}
				/>
			</InputSearchWrapper>

			<ButtonWrapper>
				<Button
					id='request-button'
					{...primaryButtonStyles}
					onClick={handleSearch}
					disabled={false}
				>
					Buscar
				</Button>
			</ButtonWrapper>
		</InputSearchContainer>
	)
}

export default SecondaryInput
