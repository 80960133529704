import styled from 'styled-components'

export const InputSearchWrapper = styled.div`
	position: relative;
	width: 100%;

	.search-icon {
		position: absolute;
		right: 16px;
		top: 16px;
		color: #d1d1d1;
	}
`
