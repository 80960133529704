import styled from 'styled-components'

export const TimeCardWrapper = styled.div`
	display: flex;
	margin-top: 20px;

	.attendance-card {
	}
`
export const TimeCardContainer = styled.div`
	margin-left: 91px;
`

export const DashTitle = styled.h3`
	font-size: 14px;
	color: #354d9e;
	margin-left: 20px;
`
