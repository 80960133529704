import styled, { css } from 'styled-components'
import { fontType } from 'shared/styles/functions'
import { handleGraphSize } from 'shared/util/calculation'
import { TeamGraphWrapperProps } from 'modules/Dashboard/types'

export const GraphContainer = styled.div<TeamGraphWrapperProps>`
	display: flex;
	flex-direction: column;
	${(props) =>
		props.graph === 'TEAM'
			? css`
					height: ${handleGraphSize(props.items) + 'px'};
			  `
			: css`
					height: 250px;
			  `}

	${fontType(0)};
`

export const LabelsWrapper = styled.div``

export const LegendWrapper = styled.label``
