import { SelectOption } from "@buildbox/components"
import { format, parseISO } from 'date-fns'
import {
    ITicket,
    TicketSeparationStatusOptions,
    ticketSeparationStatus,
    ISeparationStatusChange,
    ITicketPayload
} from "shared/interfaces/ticket"
import { toMoney } from "shared/util/currency"
import {
    withdrawalStatusActionAllowed,
    formatWithdrawalAssistantOptions
} from '../util'

export default class Ticket {
    private ticket: ITicket
    private statusAction?: ticketSeparationStatus
    private withdrawalAssistants: SelectOption[]

    private get totalValue() {
		const total = this.ticket.orders.reduce(
            (total, value) => total + value.totalValue, 0
        )
        return toMoney(total)
    }

    private get withdrawalAssistantsToSend() {
		return this.withdrawalAssistants.map(assistant => assistant.value)
    }

    private statusAssignedAt(status: ticketSeparationStatus) {
        let checkInDate: Date | undefined = undefined

        if (this.statusAction === status) {
            checkInDate = new Date()
        } else {
            const checkInStatusHistory: ISeparationStatusChange | undefined =
                this.ticket.statusSeparationHistory.find(
                    (statusItem: ISeparationStatusChange) => statusItem.status === status
                )

            if (checkInStatusHistory) {
                checkInDate = parseISO(String(checkInStatusHistory.assignedAt))
            }
        }

        return checkInDate ? format(checkInDate, 'dd/MM/yy - HH:mm') : undefined
    }

    private checkIfArrayIncludesAllItems(arr: string[], items: string[]) {
        return items.every(item => arr.includes(item))
    }

    get getId() {
        return String(this.ticket._id)
    }

    get dataToShow() {
        return {
            withdrawalLocale: this.ticket.withdrawalLocale,
            ticketNumber: this.ticket.ticket,
            createdBy: this.ticket.statusHistory[0]?.assignedBy.name,
            codeOrName: this.ticket.codeOrName,
            channel: this.ticket.channel,
            customerProfile: this.ticket.customerProfile,
            orders: this.ticket.orders,
            retail: this.ticket.retail,
            totalValue: this.totalValue,
            statusAction: this.statusAction,
            withdrawalAssistants: this.withdrawalAssistants
        }
    }

    get hasAValidStatusAction() {
        const validActions = this.ticket.currentSeparationStatus
            ? withdrawalStatusActionAllowed[this.ticket.currentSeparationStatus]
            : []

        return this.statusAction ? validActions.includes(this.statusAction) : false
    }

    get hasChangesInAssistants() {
        const ticketAssistantValues =
            this.ticket.withdrawalAssistants?.map(assistant => assistant._id) || []

        return (
            !this.checkIfArrayIncludesAllItems(
                ticketAssistantValues,
                this.withdrawalAssistantsToSend
            ) ||
            !this.checkIfArrayIncludesAllItems(
                this.withdrawalAssistantsToSend,
                ticketAssistantValues
            )
        )
    }

    payload({ sendStatusAction }: { sendStatusAction: boolean }): ITicketPayload {
        const payload: ITicketPayload = {
            ticket: this.ticket.ticket,
            channel: this.ticket.channel,
            codeOrName: this.ticket.codeOrName,
            customerProfile: this.ticket.customerProfile,
            orders: this.ticket.orders,
            withdrawalLocale: this.ticket.withdrawalLocale,
            withdrawalAssistants: this.withdrawalAssistantsToSend,
        }

        if (sendStatusAction) {
            payload.currentSeparationStatus = this.ticket.currentSeparationStatus
            payload.separationActionStatus = this.statusAction
        }

        return payload
    }

    currentColumnIs(
        status: 'readyToWithdrawal' | 'checkIn' | 'checkOut' | 'shelved'
    ) {
        switch (status) {
            case 'readyToWithdrawal':
                return this.ticket.currentSeparationStatus === TicketSeparationStatusOptions.SEPARATION_READY_TO_DELIVERY
            case 'checkIn':
                return this.ticket.currentSeparationStatus === TicketSeparationStatusOptions.WITHDRAWAL_FULFILLED_CHECK_IN
            case 'checkOut':
                return this.ticket.currentSeparationStatus === TicketSeparationStatusOptions.WITHDRAWAL_FULFILLED_CHECK_OUT
            case 'shelved':
                return this.ticket.currentSeparationStatus === TicketSeparationStatusOptions.WITHDRAWAL_SHELVED
            default:
                return false
        }
    }

    get checkInDateInfo() {
        return this.statusAssignedAt(ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_IN)
    }

    get checkOutDateInfo() {
        return this.statusAssignedAt(ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_OUT)
    }

    setStatusAction(statusAction: ticketSeparationStatus) {
        return new Ticket({
            ticket: this.ticket,
            withdrawalAssistants: this.withdrawalAssistants,
            statusAction
        })
    }

    setWithdrawalAssistants(withdrawalAssistants: SelectOption[]) {
        return new Ticket({
            ticket: this.ticket,
            statusAction: this.statusAction,
            withdrawalAssistants
        })
    }

    constructor({
        ticket,
        statusAction,
        withdrawalAssistants
    }: {
        ticket: ITicket
        statusAction?: ticketSeparationStatus
        withdrawalAssistants?: SelectOption[]
    }) {
        this.ticket = ticket
        this.statusAction = statusAction
        this.withdrawalAssistants = withdrawalAssistants
            ? withdrawalAssistants
            : ticket.withdrawalAssistants
                ? formatWithdrawalAssistantOptions(ticket.withdrawalAssistants)
                : []
    }
}
