import React from 'react'
import { IOrderProps } from './types'
import { Button, FloatLabelInput } from '@buildbox/components'
import { OrderWrapper } from './styles'
import {
	primaryInputOrderStyles,
	primaryButtonOrderStyles,
} from 'shared/styles/theme'
import { IOrder } from 'shared/interfaces/order'
import { toMoney, toNumber } from 'shared/util/currency'

function Order(props: IOrderProps): JSX.Element {
	const { order, onUpdate, updateTotal, index, removeOrder } = props
	const updateHandler = (data: Partial<IOrder>) => {
		onUpdate(index, { ...order, ...data })
		updateTotal()
	}

	function changeProduct({ target }: React.ChangeEvent<HTMLInputElement>) {
		updateHandler({ code: target.value })
	}

	function changeQuantity({ target }: React.ChangeEvent<HTMLInputElement>) {
		let value = Number.parseInt(target.value)

		updateHandler({ quantity: value })
	}

	function changeTotalSkus({ target }: React.ChangeEvent<HTMLInputElement>) {
		let value = Number.parseFloat(target.value)

		updateHandler({ totalSkus: value })
	}

	function changeTotalValue({ target }: React.ChangeEvent<HTMLInputElement>) {
		let value = toNumber(target.value)

		if (isNaN(value)) return

		updateHandler({ totalValue: value })
	}

	function convertNumberToString(value: number): string {
		if (isNaN(value) || value === 0 || value === null) return ''
		return value.toString()
	}

	return (
		<OrderWrapper>
			<FloatLabelInput
				label='Pedido Nº'
				id={`order-code-${index}`}
				round
				value={order.code}
				onChange={(e) => changeProduct(e)}
				{...primaryInputOrderStyles}
			/>
			<FloatLabelInput
				label='Quantidade'
				id={`order-quantity-${index}`}
				round
				onChange={(e) => changeQuantity(e)}
				value={convertNumberToString(order.quantity)}
				{...primaryInputOrderStyles}
			/>

			<FloatLabelInput
				label="Total SKU's"
				id={`order-totalsku-${index}`}
				round
				onChange={(e) => {
					changeTotalSkus(e)
				}}
				value={convertNumberToString(order.totalSkus)}
				{...primaryInputOrderStyles}
			/>
			<FloatLabelInput
				label='Valor'
				id={`order-totalvalue-${index}`}
				round
				onChange={(e) => changeTotalValue(e)}
				value={toMoney(order.totalValue)}
				{...primaryInputOrderStyles}
			/>
			<Button
				{...primaryButtonOrderStyles}
				onClick={(e) => removeOrder(index, e)}
				className='button'
			>
				-
			</Button>
		</OrderWrapper>
	)
}

export default Order
