import React from 'react';

import { primaryButtonDelete } from 'shared/styles/theme'
import { Modal, Button } from '@buildbox/components'
import {
  ButtonDelete,
  ModalDeleteContainer
} from './styles'
import { IProps } from './types'

const ModalDelete = (props: IProps) => {
  const {
    title,
    isActive,
    onClose,
    message,
    textButton,
    onConfirm
  } = props

  function handleConfirm(e: any) {
    e.preventDefault()
    onConfirm()
  }

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      title={title}
      size={308}
    >
      <ModalDeleteContainer>
        <p>{message}</p>

        <ButtonDelete>
          <Button
            className='btn-delete'
            onClick={handleConfirm}
            {...primaryButtonDelete}
          >
            {textButton}
          </Button>
        </ButtonDelete>
      </ModalDeleteContainer>
    </Modal>
  )
}

export default ModalDelete;