import React, { Fragment, useState } from 'react'
import { IGeneralTimeProps } from './types'

import { TimeCardWrapper, TimeCardContainer, DashTitle } from './styles'
import GeneralTimeDetail from 'modules/GeneralTimeDetail'
import GeneralTime from 'modules/GeneralTime'

function GeneralTimeDash(props: IGeneralTimeProps) {
	const [showTimeGraphDetail, setShowTimeGraphDetail] = useState(false)
	const [showTimeSeparationGraphDetail, setShowTimeSeparationGraphDetail] =
		useState(false)

	const { data } = props

	return (
		<Fragment>
			{!showTimeGraphDetail && !showTimeSeparationGraphDetail ? (
				<Fragment>
					<DashTitle>Tempo Geral</DashTitle>
					<TimeCardWrapper>
						<GeneralTime
							setView={setShowTimeGraphDetail}
							data={data.timesAttendingDash.averageTotalAttendanceTime}
							sectionTitle={
								data.timesAttendingDash.sectionTitle === 'ATTENDANCE'
									? 'Atendimento'
									: data.timesAttendingDash.sectionTitle
							}
						/>
						<TimeCardContainer>
							<GeneralTime
								setView={setShowTimeSeparationGraphDetail}
								data={data.timesSeparationDash.averageTotalAttendanceTime}
								sectionTitle={
									data.timesSeparationDash.sectionTitle ===
									'SEPARATION_AND_CONFERENCE'
										? 'Separação e Conferência'
										: data.timesSeparationDash.sectionTitle
								}
							/>
						</TimeCardContainer>
					</TimeCardWrapper>
				</Fragment>
			) : (
				<>
					{showTimeGraphDetail ? (
						<GeneralTimeDetail
							setView={setShowTimeGraphDetail}
							data={data.timesAttendingDash}
						/>
					) : (
						<GeneralTimeDetail
							setView={setShowTimeSeparationGraphDetail}
							data={data.timesSeparationDash}
						/>
					)}
				</>
			)}
		</Fragment>
	)
}

export default GeneralTimeDash
