import React, { useEffect, useState } from 'react'
import { differenceInDays } from 'date-fns'
import { GraphContainer } from './styles'
import { IGraphParams } from 'shared/components/LineGraph/types'
import LineGraph from '../../shared/components/LineGraph'
import { IProps, ILogsSummary, ILogsSummaryData } from './types'
import { COMMERCIAL_END_HOUR, COMMERCIAL_START_HOUR } from 'shared/util/Consts'

function PerformanceDash(props: IProps): JSX.Element {
	const { dataGrafic, selectedDates, isModal } = props

	const [graphData, setGraphData] = useState<ILogsSummary[]>([])

	const [graphParams, setGraphParams] = useState<IGraphParams>({
		xTickRotation: 0,
		xAxisLegend: '',
		ymax: 'auto',
	})

	useEffect(() => {
		if (!!!dataGrafic?.totalApproved) return

		const timezoneOffset = -(new Date().getTimezoneOffset() / 60)

		const daysDiff =
			differenceInDays(selectedDates.to as Date, selectedDates.from as Date) + 1

		const opened =
			daysDiff === 1
				? makeHourlyChart(dataGrafic.totalOpened, timezoneOffset)
				: makeChart(dataGrafic.totalOpened)

		const approved =
			daysDiff === 1
				? makeHourlyChart(dataGrafic.totalApproved, timezoneOffset)
				: makeChart(dataGrafic.totalApproved)

		const mapValues = opened.map((e: any) => e.y)
		const max = Math.max(...mapValues)

		setGraphParams({
			xAxisLegend: '',
			xTickRotation: 0,
			ymax: max * 0.15 + max,
		})

		convertToLogsSummary(approved, opened)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDates, dataGrafic])

	function makeChart(data: number[]): ILogsSummaryData[] {
		let items: ILogsSummaryData[] = []

		for (let index = 0; index < dataGrafic.xTickLabel.length; index++) {
			const x = dataGrafic.xTickLabel[index]
			const y = data[index]

			items.push({
				x: x,
				y: y || 0,
			})
		}

		return items
	}

	function makeHourlyChart(
		data: number[],
		timezoneOffset: number,
	): ILogsSummaryData[] {
		let items: ILogsSummaryData[] = []

		const values = shiftArray(data, timezoneOffset)

		for (let index = 0; index < dataGrafic.xTickLabel.length; index++) {
			const x = dataGrafic.xTickLabel[index]
			const y = values[index]

			if (index >= COMMERCIAL_START_HOUR && index <= COMMERCIAL_END_HOUR) {
				items.push({
					x: x,
					y: y || 0,
				})
			}
		}

		return items
	}

	// Shifts array left  offset positions, if offset is negative
	// Shifts array right offset positions, if offset is positive
	function shiftArray(source: number[], offset: number): number[] {
		return offset < 0
			? source.concat(source.splice(0, -offset)) // shifts left
			: source.splice(-offset, offset).concat(source) // shifts right
	}

	function convertToLogsSummary(
		approved: ILogsSummaryData[],
		opened: ILogsSummaryData[],
	) {
		let approvedData = approved
		let openedData = opened

		if (selectedDates.from === selectedDates.to) {
			const currentHour = new Date().getHours()

			approvedData = approved.filter(approvedItem => {
				const hour = Number(approvedItem.x.toString().split(':')[0])
				return hour <= currentHour
			})
			openedData = opened.filter(openedItem => {
				const hour = Number(openedItem.x.toString().split(':')[0])
				return hour <= currentHour
			})
		}

		setGraphData([
			{
				id: 'Aprovados',
				color: '#354D9E',
				data: approvedData,
			},
			{
				id: 'Abertos',
				color: '#BECDFF',
				data: openedData,
			},
		])
	}

	return (
		<GraphContainer isModal={isModal}>
			<LineGraph data={graphData} graphParams={graphParams} isModal={isModal} />
		</GraphContainer>
	)
}

export default PerformanceDash
