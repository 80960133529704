import styled from 'styled-components'

import { fontType, mediaQuery, toRem, transition } from '../../styles/functions'

const lineStyle = `
	display: block;
	height: 1px;
	width: 20px;
	margin-bottom: 5px;
	position: relative;
	${transition()}

	&:last-of-type {
		margin-bottom: 0;
	}
`
export const HeaderWrapper = styled.div`
	position: fixed;
	top: 0;
	z-index: 20;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	box-shadow: 0px 2px 3px #00000014;
	border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
	background: ${({ theme }) => theme.colors.white};

	.menu-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		height: 80px;
		.menu {
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			cursor: pointer;
			${transition()}

			.line01 {
				${lineStyle}
				background: ${({ theme }) => theme.colors.blue};
			}
			.line02 {
				${lineStyle}
				background: ${({ theme }) => theme.colors.blue};
			}
			.line03 {
				${lineStyle}
				background: ${({ theme }) => theme.colors.blue};
			}
		}
	}

	.higa-logo {
		display: flex;
		justify-content: center;
		flex: 1;
		img {
			width: 85px;

			${mediaQuery('sm')(`
				width: 65px;
			`)}
		}
	}

	.profile {
		display: flex;
		align-items: center;
		display: flex;
		justify-content: flex-end;
		margin-right: 32px;

		${mediaQuery('sm')(`
			margin-right: 16px;
		`)}

		.info {
			.name {
				font-size: ${toRem(12)};
				color: ${({ theme }) => theme.colors.blue};
				${fontType(0)}
			}

			.profile-link {
				font-size: ${toRem(10)};
				color: ${({ theme }) => theme.colors.grey};
				border: none;
				background: none;
				cursor: pointer;
				${fontType(0)}
			}
		}

		.photo {
			margin-left: 16px;
			width: 48px;
			height: 48px;
			overflow: hidden;
			border-radius: 100%;
			background: ${({ theme }) => theme.colors.lightGrey};
			display: flex;
			justify-content: center;
			align-items: center;

			${mediaQuery('sm')(`
				display: none;
			`)}
		}
	}

	&.active {
		.menu-wrapper {
			.menu {
				transform: rotate(-180deg);
				.line01 {
					transform: rotate(-45deg);
					top: 6px;
				}
				.line02 {
					transform: rotate(45deg);
				}
				.line03 {
					transform: rotate(-60deg);
					opacity: 0;
				}
			}
		}
	}
`
