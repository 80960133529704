import styled, { css } from 'styled-components'
import { colors } from 'shared/components/CustomSelect/theme'
import {
	ellipsis,
	fontType,
	isDashed,
	toRem,
	transition,
} from 'shared/styles/functions'

// OmitStyledComponentsDefaultProps<'tertiaryColor'>
// { infoColor: string, errorColor: string }
export const FloatLabelInputWrapper = styled.div<any>`
	${({ styles }) => css`
		position: relative;

		label.fli-label {
			color: ${styles.secondaryColor};
			font-size: 12px;
			left: 16px;
			position: absolute;
			top: 12px;

			cursor: text;

			${transition()}
			${fontType(0)};
		}

		input.fli-input {
			background: ${styles.backgroundColor};
			border-radius: ${styles.round ? '30px' : '4px'};
			border: solid 1px ${styles.secondaryColor};
			color: #444444;
			font-size: $12px;
			padding: ${styles.padding};
			width: 100%;

			${transition()} ${ellipsis()} ${fontType(0)};

			&:focus,
			&:valid {
				border: solid 1px ${styles.primaryColor};

				+ label {
					color: ${styles.primaryColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 3px;
				}

				#search-icon {
					background: ${styles.primaryColor};
				}
			}

			&[disabled] {
				border: ${isDashed(styles.dashedWhenDisabled)} 1px
					${styles.secondaryColor};

				+ label {
					color: ${styles.secondaryColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 12px;
				}
			}
		}

		p.fli-info-message {
			margin-top: 4px;
			font-size: ${toRem(styles.fontSize - 4)};
			color: ${styles.infoColor ? styles.infoColor : styles.primaryColor};
			${fontType(styles.fontFamily)};
		}

		p.fli-error-message {
			margin-top: 4px;
			font-size: ${toRem(styles.fontSize - 4)};
			color: ${styles.errorColor ? styles.errorColor : colors.error};
			${fontType(styles.fontFamily)};
		}

		#search-icon {
			position: absolute;
			right: 6px;
			top: 7px;
		}

		#old-search-icon {
			position: absolute;
			right: 12px;
			top: 8.5px;
			color: #d1d1d1;

			width: 15px;
		}
	`}
`

export const DisableButton = styled.button`
	background-color: #cecece;
	border-radius: 50%;
	width: 27px;
	height: 27px;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	border: none;
`
