import { ModalActionsEnum } from 'modules/ModalTicket/types'
import { createElement, useCallback, useEffect, useState } from 'react'
import { IGetPendingFitResponse } from 'shared/interfaces/separation'
import { IScheduleDateOption, ITicket } from 'shared/interfaces/ticket'
import {
	getPendingFit,
	fetchTicketsWithLateSchedules,
	separationDateOptions,
	getOrderWithdrawalDate
} from 'shared/services/separation.service'
import {
	fetchTicket,
	initializeTicketFields,
} from 'shared/services/ticket.service'
import { IViewProps } from './types'
import SeparationView from './view'
import { ticketCount } from './util'
import { SelectOption } from '@buildbox/components'
import { useDebounce } from 'use-debounce/lib'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { getPageSeparationParams } from 'shared/services/separationParams.service'
import { ISeparationParams } from 'shared/interfaces/SeparationParams'

const headerColumnsSize = {
	searchInput: 4.5,
	retailsSelect: 7,
	settingsIcon: 0.5
}

function SeparationContainer(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const [modalActive, setModalActive] = useState(false)
	const [params, setParams] = useState<ISeparationParams[]>([])
	const [scheduleDaysList, setScheduleDaysList] = useState<IScheduleDateOption[][]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [modalAction, setModalAction] = useState<ModalActionsEnum>('EDIT')
	const [pendingForApprovalItems, setPendingForApprovalItems] =
		useState<IGetPendingFitResponse[]>([])
	const [showModalTicket, setShowModalTicket] = useState(false)
	const [currentTicket, setCurrentTicket] = useState<ITicket>(
		initializeTicketFields(),
	)
	const [currentTicketWithdrawalDate, setCurrentTicketWithdrawalDate] =
		useState<IGetPendingFitResponse>()
	const [lateTickets, setLateTickets] = useState<ITicket[]>([])
	const [searchString, setSearchString] = useState('')
	const [retailOptions, setRetailOptions] = useState<SelectOption[]>([])
	const [selectedRetails, setSelectedRetails] = useState<SelectOption[]>([])

	const [retailsDebounce] = useDebounce(selectedRetails, 1000)

	function handleToggle() {
		if (modalActive) {
			fetchParams()
		}
		setModalActive(!modalActive)
	}

	function getSetRetails() {
		const userRetailOptions = user.retails.map(userRetail => ({
			value: String(userRetail._id),
			label: userRetail.name
		}))

		setRetailOptions(userRetailOptions)
		setSelectedRetails(userRetailOptions)
	}

	function fetchParams() {
		(async () => {
			try {
				if (!retailsDebounce.length) return

				setIsLoading(true)

				const retails = retailsDebounce.map((x) => x.value)

				const params = await getPageSeparationParams(retails)

				const filterOptions = {
					searchString,
					retails: params.map(param => param.retail)
				}

				const dayList = await separationDateOptions(filterOptions)
				const ticketsWithLateSchedules = await fetchTicketsWithLateSchedules(filterOptions)
				const pendingFitList = await getPendingFit(filterOptions)

				setParams(params)
				setScheduleDaysList(dayList)
				setLateTickets(ticketsWithLateSchedules.filter(ticketCount))
				setPendingForApprovalItems(pendingFitList)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleModal() {
		setShowModalTicket(!showModalTicket)
		fetchParams()
	}

	async function activateModalTicket(
		action: ModalActionsEnum,
		ticketId: string,
	) {
		try {
			const ticketDoc = await fetchTicket(ticketId)
			const ticketWithdrawalDate = await getOrderWithdrawalDate(ticketDoc)

			setCurrentTicketWithdrawalDate(ticketWithdrawalDate)
			setCurrentTicket(ticketDoc)

			setModalAction(action)
			setShowModalTicket(true)
		} catch { }
	}

	function handleSearch() {
		fetchParams()
	}

	function changeSearchString(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchString(e.target.value)
	}

	function clearSearchInput() {
		setSearchString('')
	}

	const handleRetailsSelect = useCallback((value: SelectOption[]) => {
		setSelectedRetails(value)
	}, [])

	const handleChangeSearchString = useCallback(changeSearchString, [])
	const handleClearSearchInput = useCallback(clearSearchInput, [])

	const showPlaceholder = !params.length  || (!scheduleDaysList.length && !lateTickets.length)

	useEffect(fetchParams, [retailsDebounce])
	useEffect(getSetRetails, [user])
	useEffect(() => {
		fetchParams()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const viewProps: IViewProps = {
		handleToggle,
		modalActive,
		params,
		scheduleDaysList,
		isLoading,
		activateModalTicket,
		modalAction,
		showModalTicket,
		currentTicket,
		handleModal,
		lateTickets,
		pendingForApprovalItems,
		fetchParams,
		showPlaceholder,
		currentTicketWithdrawalDate,
		searchString,
		handleSearch,
		handleChangeSearchString,
		handleClearSearchInput,
		headerColumnsSize,
		retailOptions,
		handleRetailsSelect,
		selectedRetails
	}

	return createElement(SeparationView, viewProps)
}

export default SeparationContainer
