import React, { Fragment, useState } from 'react'
import {
	Label,
	ModalWrapper,
	PendingForApprovalContainer,
	PendingForApprovalWrapper,
	PendingForApprovalButton
} from './styles'
import { IPendingForApprovalProps } from './types'
import HelpCircle from '../../../assets/images/help-circle.svg'
import { Modal } from '@buildbox/components'
import { dateFormat } from 'shared/util/translate'

function PendingForApprovalLabel(props: IPendingForApprovalProps): JSX.Element {
	const { data } = props
	const [modalActive, setModalActive] = useState(false)

	function handleToggle() {
		setModalActive((value) => !value)
	}

	return (
		<PendingForApprovalWrapper>
			{data.length >= 1 ? (
				<PendingForApprovalContainer>
					<PendingForApprovalButton onClick={handleToggle}>
						<Label>{`${data.length} Encaixes pendentes de Aprovação`}</Label>
						<img src={HelpCircle} alt='' />
					</PendingForApprovalButton>
					<Modal
						onClose={handleToggle}
						title='Encaixes pendentes'
						isActive={modalActive}
						size={372}
					>
						<ModalWrapper>
							{data.map((item) => (
								<p>{dateFormat(new Date(item.date), item.period)}</p>
							))}
						</ModalWrapper>
					</Modal>
				</PendingForApprovalContainer>
			) : (
				<Fragment />
			)}
		</PendingForApprovalWrapper>
	)
}

export default PendingForApprovalLabel
