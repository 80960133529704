import React from 'react'
import PageTitle from 'shared/components/PageTitle'
import {
	Loading,
	Table,
	Button,
	Modal,
	FloatLabelInput,
} from '@buildbox/components'
import ModalDelete from 'shared/components/ModalDelete'
import { Container, TableContent, PageHeader, ModalContent } from './styles'
import { IViewProps } from './types'
import {
	primaryButtonStyles,
	primaryInputStyles,
	tableStyle,
} from 'shared/styles/theme'

function ListRetail(props: IViewProps): JSX.Element {
	const {
		tableColumns,
		tableRows,
		loading,
		showModal,
		changeModalState,
		handleChange,
		selectedRetail,
		create,
		edit,
		deleteModal,
		changeDeleteModalState,
		deleteRetails,
		isFormValid,
	} = props

	return (
		<>
			<Container>
				<PageHeader>
					<PageTitle>{'Lojas'}</PageTitle>
					<Button {...primaryButtonStyles} onClick={() => changeModalState()}>
						{'Nova'}
					</Button>
				</PageHeader>
				<TableContent>
					{loading ? (
						<Loading type='Digital' primaryColor='#354D9E' />
					) : (
							<div className='table-container'>
								<Table {...tableStyle} columns={tableColumns} data={tableRows} />
							</div>
						)}
				</TableContent>
				<Modal
					isActive={showModal}
					onClose={() => changeModalState()}
					title={selectedRetail._id ? 'Atualizar Loja' : 'Nova Loja'}
				>
					<ModalContent>
						<div className='input-container-wrapper'>
							<div className='input-container'>
								<FloatLabelInput
									{...primaryInputStyles}
									label='Nome'
									round
									id='name'
									onChange={handleChange}
									value={selectedRetail.name}
								/>
							</div>
							<div className='input-container'>
								<FloatLabelInput
									{...primaryInputStyles}
									label='Ocupação Máxima'
									round
									id='maxOccupancy'
									onChange={handleChange}
									value={selectedRetail.maxOccupancy}
									type='number'
								/>
							</div>
						</div>
						{selectedRetail._id ? (
							<div className='button-container'>
								<Button
									disabled={!isFormValid}
									{...primaryButtonStyles}
									onClick={edit}
								>
									Atualizar
								</Button>
							</div>
						) : (
								<div className='button-container'>
									<Button
										disabled={!isFormValid}
										{...primaryButtonStyles}
										onClick={create}
									>
										Cadastrar
								</Button>
								</div>
							)}
					</ModalContent>
				</Modal>
				<ModalDelete
					isActive={deleteModal}
					onClose={() => changeDeleteModalState()}
					title='Excluir Loja'
					message={`Você optou por Excluir a loja ${selectedRetail.name}, esta ação não poderá ser desfeita!`}
					onConfirm={deleteRetails}
					textButton='Excluir Loja'
				/>
			</Container>
		</>
	)
}

export default ListRetail
