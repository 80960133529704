import styled from 'styled-components'
import { Modal } from '@buildbox/components'
import { fontType } from 'shared/styles/functions'

export const CustomModal = styled(Modal)`
	${fontType(0)}
`

export const ModalContent = styled.form``

export const SectionLabel = styled.span`
	font-size: 16px;
`

export const Content = styled.div`
	margin-top: 10px;
	flex-direction: column;

	.input-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
		margin-top: 20px;
		justify-content: space-between;
		width: 100%;

		#select2 {
			margin-top: 10px;
		}

		#created-by, #code-or-name {
			margin-bottom: 10px;
		}
	}

	.ticket {
		background: #becdff;
		padding: 10px;
		border-radius: 30px;
		color: #354d9e;
		font-size: 14px;
	}

	.text-ticket {
		text-align: center;
		margin-top: 25px;
	}

	.action-dates {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		
		width: 100%;
		font-size: 14px;

		&, span + span {
			margin-top: 16px;
		}
	}
`

export const OrderInformation = styled.div`
	margin-top: 30px;
`
export const OrderValueWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
`
export const OrderLabel = styled.span`
	${fontType(0)}
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: 12px;
	margin-bottom: 4px;
`
export const OrderValue = styled.span`
	${fontType(0)}
	color: ${({ theme }) => theme.colors.blue};
	font-size: 18px;
`
export const OrderRequests = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`
export const OrderContainer = styled.div`
	margin-top: 10px;

	::-webkit-scrollbar-track {
		background-color: #f4f4f4;
	}
	::-webkit-scrollbar {
		width: 6px;
		background: #f4f4f4;
	}
	::-webkit-scrollbar-thumb {
		background: #dad7d7;
	}
`

export const OrderWithdrawalWrapper = styled.div`
	margin: 16px 0 30px;
`
