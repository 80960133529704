import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import {
	Button,
	FloatLabelInput,
	Modal,
	Select,
	SelectOption,
} from '@buildbox/components'
import {
	primaryDashboardButtonStyles,
	primaryInputStyles,
	primaryInputTicketStyles,
} from 'shared/styles/theme'
import {
	createStoreAisle,
	updateStoreAisle,
} from 'shared/services/storeAisle.service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { IStoreAisle } from 'shared/interfaces/storeAisle'

import QRCodeIcon from '../../../assets/images/qr-code-icon.svg'

import { IProps } from './types'
import { Container } from './styles'
import { Link } from 'react-router-dom'
import routesEnum from 'modules/Routes/enum'

export default function ModalStoreAisle(props: IProps) {
	const { isActive, selectedStoreAisle, retailOptions, closeModal } = props

	const [selectedRetail, setSelectedRetail] = useState<SelectOption | null>(
		null,
	)
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [qrCode, setQrCode] = useState('')
	const [isSubmiting, setIsSubmiting] = useState(false)
	const [isFormValid, setIsFormValid] = useState(false)

	function generateQRCode(storeAisle: IStoreAisle) {
		;(async () => {
			const qrCodeBase64 = await QRCode.toDataURL(
				JSON.stringify({
					storeAisleId: storeAisle._id,
					retailId: selectedRetail?.value,
				}),
			)

			setQrCode(qrCodeBase64)
		})()
	}

	function loadStoreAisleState() {
		if (!isActive) return

		if (selectedStoreAisle) {
			setSelectedRetail(
				retailOptions.find(
					(retail) => retail.value === selectedStoreAisle.retail,
				) || null,
			)
			setName(selectedStoreAisle.name)
			setDescription(selectedStoreAisle.description)

			generateQRCode(selectedStoreAisle)
		}
	}

	function validateForm() {
		const isValid = !!selectedRetail && !!name && !!description

		setIsFormValid(isValid)
	}

	function clearFiels() {
		setSelectedRetail(null)
		setName('')
		setDescription('')
	}

	const onClose = useCallback(
		(refetchData: boolean = false) => {
			clearFiels()
			closeModal({ refetchData, retail: selectedRetail?.value })
		},
		[closeModal, selectedRetail],
	)

	const handleChangeRetail = useCallback(
		(selectedOption: SelectOption | null) => {
			if (!selectedOption) return

			setSelectedRetail(selectedOption)
		},
		[],
	)

	const handleChangeTitle = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setName(event.target.value)
		},
		[],
	)

	const handleChangeRedirectLink = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setDescription(event.target.value)
		},
		[],
	)

	const handleSubmit = useCallback(() => {
		;(async () => {
			if (!isFormValid) return

			try {
				setIsSubmiting(true)

				const storeAisleToSave = {
					...selectedStoreAisle,
					retail: selectedRetail?.value || '',
					name: name,
					description: description,
				}

				if (!selectedStoreAisle) {
					await createStoreAisle(storeAisleToSave)
				} else {
					await updateStoreAisle(storeAisleToSave)
				}

				cogoToast.success(
					'Área de Atendimento salva com sucesso!',
					cogoDefaultOptions,
				)
			} finally {
				setIsSubmiting(false)
				onClose(true)
			}
		})()
	}, [
		isFormValid,
		description,
		selectedStoreAisle,
		selectedRetail,
		name,
		onClose,
	])

	useEffect(validateForm, [selectedRetail, name, description])
	useEffect(loadStoreAisleState, [selectedStoreAisle, selectedRetail])

	return (
		<Modal
			isActive={isActive}
			title={!selectedStoreAisle ? 'Nova Área' : 'Detalhes da Área'}
			onClose={() => onClose()}
			className='modal-store-aisle'
			size={600}
		>
			<Container>
				<div className='inputs-group'>
					<div className='input-container select-retail'>
						<Select
							round
							placeholder='Loja'
							{...primaryInputTicketStyles}
							id='select-retail'
							options={retailOptions}
							value={selectedRetail}
							onChange={handleChangeRetail as any}
						/>
					</div>
					<div className='input-container'>
						<FloatLabelInput
							{...primaryInputStyles}
							label='Nome da área'
							round
							id='title'
							onChange={handleChangeTitle}
							value={name}
							maxLength={50}
						/>
					</div>
					<div className='input-container'>
						<FloatLabelInput
							{...primaryInputStyles}
							label='Descrição'
							round
							id='email'
							onChange={handleChangeRedirectLink}
							value={description}
						/>
					</div>
					{selectedStoreAisle && (
						<div className='input-container'>
							<Link
								className='view-qr-code'
								to={`${routesEnum.QRCODE_PRINTING}?areaName=${selectedStoreAisle.name}&qrCode=${qrCode}`}
							>
								<img src={QRCodeIcon} alt='ìcone de QR Code' />
								<span>Visualizar QR Code</span>
							</Link>
						</div>
					)}
				</div>

				<Button
					{...primaryDashboardButtonStyles}
					className='submit-button'
					onClick={handleSubmit}
					disabled={isSubmiting || !isFormValid}
				>
					{!selectedStoreAisle ? 'Cadastrar' : 'Salvar'}
				</Button>
			</Container>
		</Modal>
	)
}
