import styled from 'styled-components'

export const Container = styled.div`
	width: 280px;
	height: 445px;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.grey};
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	span {
		color: ${({ theme }) => theme.colors.blue};
		text-align: center;
		max-width: 120px;
		font-family: 'Montserrat SemiBold', sans-serif;
		font-weight: bolder;
	}
`

export const QRCodeContainer = styled.div`
	width: 150px;
	height: 150px;
	margin-top: 50px;
	margin-bottom: 30px;

	img {
		width: 100%;
		height: 100%;
	}
`

export const HigaLogo = styled.img`
	width: 150px;
	height: 150px;
	position: absolute;
	bottom: 0px;
`
