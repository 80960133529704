import styled from 'styled-components';
import { paddingToString } from './functions'
import { colors } from './theme'
import { StylerType } from './types'

export const styler: StylerType = (props) => {
	const {
		fontFamily,
		primaryColor,
		backgroundColor,
		padding,
		round,
		fontSize,
		secondaryColor,
		tertiaryColor
	} = props.styles

	const paddingString = paddingToString(padding)
	const doublePaddingString = paddingToString([
		(padding[0] as number) * 2,
		(padding[1] as number) * 2,
		(padding[2] as number) * 2,
		(padding[3] as number) * 2
	])

	return {
		container: (styles, state) => ({
			...styles,
			fontFamily: fontFamily
		}),
		// menu: (styles, state) => ({
		// 	...styles
		// }),
		// option: (styles, state) => ({
		// 	color: state.isSelected ? secondaryColor : colors.darkGrey,
		// 	background: state.isSelected ? primaryColor : colors.white,
		// 	padding: doublePaddingString,
		// 	cursor: 'pointer',

		// 	':hover': {
		// 		background: primaryColor,
		// 		color: secondaryColor
		// 	}
		// }),

		singleValue: (styles, state) => ({
			color: primaryColor,
			fontSize: fontSize,
			padding: paddingString
		}),
		placeholder: (styles, state) => ({
			color: tertiaryColor,
			fontSize: fontSize,
			padding: paddingString
		}),
		noOptionsMessage: (styles, state) => ({
			padding: doublePaddingString,
			// opacity: state.isDisabled ? 0.2 : 0.4,
			color: colors.darkGrey
		}),
		multiValue: (styles, state) => ({
			display: 'flex',
			background: primaryColor,
			borderRadius: round ? 100 : 4,
			padding: paddingString,
			marginRight: 8
		}),
		multiValueLabel: (styles, state) => ({
			...styles,
			color: secondaryColor,
			fontSize: fontSize - 2,
			padding: 0
		}),
		multiValueRemove: (styles, state) => ({
			...styles,
			marginLeft: 8,
			color: secondaryColor,
			padding: 0,

			':hover': {
				background: primaryColor,
				color: secondaryColor
			}
		}),
		indicatorSeparator: () => ({}),
		dropdownIndicator: (styles, state) => ({
			color: state.isDisabled ? colors.grey : tertiaryColor
		}),
		control: (styles, state) => ({
			background: backgroundColor,
			border: `${state.isDisabled ? 'dashed' : 'solid'} 1px ${tertiaryColor}`,
			padding: doublePaddingString,
			borderRadius: round ? 300 : 4,
			cursor: 'pointer',
			display: 'flex'
		}),
		valueContainer: (styles, state) => ({
			display: 'inline-flex',
			alignItems: 'center',
			overflow: 'auto',
			flex: 1
		}),
		input: (styles, state) => ({
			...styles,
			color: colors.grey,
			fontSize: fontSize
		})
	}
}

export const OptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-self: flex-end;
`

export const OptionDate = styled.div`
	font-size: 14px;
`

export const OptionLimit = styled.div`
	font-size: 10px;
	border-radius: 10px;
	padding-top: 3px;
	padding-right: 10px;
	padding-bottom: 3px;
	padding-left: 10px;
	min-width: 50px;
	text-align: center;
`