import styled from 'styled-components'
import { theme } from '../../shared/styles/theme'
import { mediaQuery, fontType, toRem } from '../../shared/styles/functions'

export const Title = styled.h3`
	color: ${theme.colors.darkGrey};
	font-size: ${toRem(20)};
	margin-bottom: 16px;

	${fontType(0)}
`

export const LogHistoryWrapper = styled.div`
	padding: 32px;
	background: ${({ theme }) => theme.colors.white};
	margin-bottom: 32px;
	width: 100%;

	.pagination-button-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px;
		${fontType(0)}
		span {
			margin-right: 10px;
			font-size: 12px;
			${fontType(1)}
		}
		div {
			padding: 0px;
			height: ${toRem(41)};
			border-radius: 50px;
			width: ${toRem(100)};
			display: flex;
			border: 1px solid;
			border-color: ${({ theme }) => theme.colors.blue};
			font-size: 12px;
			justify-content: space-evenly;

			input {
				width: ${toRem(45)};
				${fontType(0)}
				outline:none;
				border: none;
				text-align: center;
				color:  ${({ theme }) => theme.colors.darkGrey};
			}
			a {
				img {
					cursor: pointer;
					${fontType(0)}
				}
			}
		}
	}
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;

		${mediaQuery('sm')(`
			flex-direction: column;
		`)}

		.page-title {
			${mediaQuery('sm')(`
				margin-bottom: 32px;
			`)}
		}
	}

	.search-section {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 32px;
	}
`
