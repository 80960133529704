import styled from 'styled-components'
import { fontType } from 'shared/styles/functions'

export const LogHistoryWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	color: ${({ theme }) => theme.colors.blue};

	${fontType(0)}
	span {
		margin-right: 10px;
		font-size: 12px;
		${fontType(1)}
	}
	div {
		padding: 15px;
		border-radius: 50px;
		width: 120px;
		display: flex;
		border: 1px solid;
		border-color: ${({ theme }) => theme.colors.blue};
		font-size: 12px;

		button {
			background: transparent;
			outline: none;
			border: none;
			cursor: pointer;
		}
		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		input {
			width: 65px;
			${fontType(0)}
			outline:none;
			border: none;
			background-color: transparent;
		}
		a {
			img {
				cursor: pointer;
				${fontType(0)}
			}
		}
	}
`
