import { fontType, toRem } from 'shared/styles/functions'
import styled from 'styled-components'
import { Row, Column } from 'shared/styles'

import { theme } from 'shared/styles/theme'

export const BoardWrapper = styled.div`
	padding: 0 5vw;

	* {
		::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme.colors.lightGrey};
		}
		::-webkit-scrollbar {
			width: 6px;
			background: ${({ theme }) => theme.colors.grey};
		}
		::-webkit-scrollbar-thumb {
			background: #dad7d7;
			border-radius: 50px;
		}
		${fontType(0)}
	}
`
export const HeaderWrapper = styled(Row)`
	display: flex;

	.search-input-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;

		svg {
			position: relative;
			right: calc(-100% + 21px + 13px);
			.a {
				display: none;
			}
			.b {
				stroke: ${theme.colors.grey};
			}
		}
	}

	.button-wrapper {
		display: flex;
		justify-content: flex-end;
	}
`

export const ContainerListCards = styled.div`
	/* display: flex; */
	padding: 30px 0;
	height: calc(100% - 80px);
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1rem;
`

export const Label = styled.label`
	font-size: ${toRem(8)};
	${fontType(2)};
	position: absolute;
	margin-left: ${toRem(20)};
	margin-top: 5px;
	color: ${({ theme }) => theme.colors.blue};
	text-transform: uppercase;
	z-index: 1;
`

export const SearchInputColumn = styled(Column)`
	padding: 20px 10px;

	div div {
		width: 100%;
		*.css-8atqhb {
			display: flex;
			flex-direction: row;
		}
		*.css-1hwfws3 {
			width: calc(90% - 24px);
			margin: 0;
			padding: 0;
		}
		*.css-151xaom-placeholder {
			font-size: 14px;
		}
		*.css-1wy0on6 {
			width: 24px;
		}
	}
`
