export function safeDivision(numerator: number, denominator: number): number {
	return denominator !== 0 ? numerator / denominator : 0
}

export function handleGraphSize(items: number) {
	switch (items) {
		case 1: return 120
		case 2: return 160
		case 3: return 200
		case 4: return 220
		default: return items * 50
	}
}
