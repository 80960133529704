import { toRem, transition, fontType } from 'shared/styles/functions'
import { TicketStatusEnum } from 'shared/interfaces/ticket'
import styled, { css, keyframes } from 'styled-components'

export const modalContainer = styled.div``

export const LabelBackground = styled.div``

interface IActionWrapper {
	currentStatus: TicketStatusEnum
}

interface ISelectWrapper {
	inputIsVisible?: boolean
	input: string
}

export const ActionWrapper = styled.div<IActionWrapper>`
	display: grid;
	${(props) =>
		props.currentStatus === 'APPROVED'
			? css`
					grid-template-areas:
						'currentStatus actionStatus'
						'orderWithdrawal orderWithdrawal';
					grid-template-columns: 1fr 1fr;
					& > div:first-child {
						grid-area: currentStatus;
					}
					& > div:nth-child(2) {
						grid-area: actionStatus;
					}
					& > div:last-child {
						grid-area: orderWithdrawal;
					}
			  `
			: css`
					grid-template-columns: 1fr 1fr;
			  `}

	justify-content: space-between;

	margin: 10px 0px 10px 0px;

	gap: 1rem;
	margin-bottom: 25px;
`

export const animeTop = keyframes`
  to{
    opacity: 1;
    transform: initial;
  }
`

export const InputOthers = styled.div`
	margin: 10px 0px 10px 0px;
	${transition(0.5)}
	opacity: 0;
	transform: translateY(-20px);
	animation: ${animeTop} 0.4s forwards;
`

export const ModalContent = styled.form`
	/* #select {
		width: 201px;
	} */
`
export const SectionLabel = styled.span`
	font-size: 16px;
`

export const SectionLabelBackground = styled.div`
	padding: 10px;

	span {
		cursor: pointer;
	}
`
export const Content = styled.div`
	/* display: flex; */
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	flex-direction: column;

	.input-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
		margin-top: 20px;
		justify-content: space-between;
		width: 100%;

		#select2 {
			margin-top: 10px;
		}

		#created-by, #code-or-name {
			margin-bottom: 10px;
		}
	}

	.ticket {
		background: #becdff;
		padding: 10px;
		border-radius: 30px;
		color: #354d9e;
		font-size: 14px;
	}

	.text-ticket {
		text-align: center;
		margin-top: 25px;
	}

	.action-dates {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		
		width: 100%;
		font-size: 14px;

		&, span + span {
			margin-top: 16px;
		}
	}
`

export const OrderInformation = styled.div`
	margin-top: 30px;
`
export const ModalFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	margin-top: 40px;
`
export const OrderValueWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
`

export const OrderLabel = styled.span`
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: 12px;
	margin-bottom: 4px;
`
export const OrderValue = styled.span`
	color: ${({ theme }) => theme.colors.blue};
	font-size: 18px;
`
export const OrderRequests = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`
export const OrderContainer = styled.div`
	margin-top: 10px;

	::-webkit-scrollbar-track {
		background-color: #f4f4f4;
	}
	::-webkit-scrollbar {
		width: 6px;
		background: #f4f4f4;
	}
	::-webkit-scrollbar-thumb {
		background: #dad7d7;
	}
`
export const ButtonDeleteTicket = styled.div`
	text-align: center;
	margin-top: ${toRem(30)};

	.btn-delete button {
		border: 1px solid ${({ theme }) => theme.colors.red};
	}
`

export const Label = styled.label`
	font-size: ${toRem(8)};
	${fontType(2)};
	position: absolute;
	margin-left: ${toRem(20)};
	margin-top: 10px;
	color: ${({ theme }) => theme.colors.blue};
	text-transform: uppercase;
	z-index: 1;
`

export const OrderWithdrawalWrapper = styled.div``

export const SelectSeparators = styled.div`
	margin-top: 20px;

	span {
		${fontType(0)}
	}
`
export const SeparatorCheckboxInput = styled.div``

export const SeparatorSelectWrapper = styled.div<ISelectWrapper>`
	${(props) =>
		props.input === 'separators'
			? css`
					margin-top: 16px;
			  `
			: css``}

	${(props) =>
		props.inputIsVisible
			? css``
			: css`
					width: 400px;
			  `}
`
export const SelectAndCheckBoxWrapper = styled.div<ISelectWrapper>`
	${(props) =>
		props.inputIsVisible
			? css`
					span {
						display: none;
					}
					#separator-checkbox {
						display: none;
					}
					#checker-checkbox {
						display: none;
					}
			  `
			: css`
					display: flex;
					align-items: center;
			  `}

	${(props) =>
		props.input === 'separators'
			? css``
			: css`
					margin-top: 5px;
			  `}
	* {
		::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme.colors.lightGrey};
		}
		::-webkit-scrollbar {
			width: 3px;
			background: ${({ theme }) => theme.colors.grey};
			height: 5px;
		}
		::-webkit-scrollbar-thumb {
			background: #dad7d7;
			border-radius: 50px;
		}
		${fontType(0)}
	}
`

export const CheckBoxWrapper = styled.div<ISelectWrapper>`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	text-align: center;

	input {
		margin-right: 8px;
	}
	span {
		font-size: 14px;
		color: ${({ theme }) => theme.colors.darkGrey};
	}

	#separators {
		width: 400px;
	}
	#checkers {
		width: 400px;
	}
`

export const CheckAllOrdersWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	width: 100%;
	margin-top: 10px;

	input {
		margin: 10px;
	}
`
