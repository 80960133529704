import styled, { css } from 'styled-components'
import { theme } from '../../shared/styles/theme'

const wrapperWidth = css`
	width: 100%;
	margin: 0 auto;
	max-width: 990px;
`

const mediaWrapper = css`
	@media screen and (max-width: 912px) {
		width: 85%;
	}
`

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	padding-bottom: 5rem;
	background-color: ${theme.colors.lightWhite};
`
export const HeaderActions = styled.div`
	${wrapperWidth}
	${mediaWrapper}
	margin-bottom: 2rem;

	display: flex;
	justify-content: flex-end;

	.buttonBackAreas > button {
		padding: 24px 0;
		font-size: 14px;
		color: ${theme.colors.blue};
		background-color: transparent;
	}

	@media screen and (max-width: 280px) {
		> .buttonPrintingQRCode button {
			padding: 16px 24px;
		}
	}

	@media screen and (max-width: 760px) {
		justify-content: center;
	}
`

export const LeftContentWrapper = styled.div`
	${wrapperWidth}
	${mediaWrapper}
	margin-bottom: 18px;

	.buttonBackAreas > button {
		padding: 24px 0;

		@media screen and (max-width: 540px) {
			padding: 16px 0;
		}
	}
`

export const LinkContent = styled.div`
	display: flex;
	align-items: center;

	img {
		margin-right: 0.75rem;
	}
`

export const TitleDetails = styled.span`
	font-size: 28px;
	font-family: 'Montserrat Regular';
	padding: 12px 0;
	color: ${theme.colors.darkGrey};

	@media screen and (max-width: 540px) {
		font-size: 18px;
	}
`

export const QRCodeWrapper = styled.div`
	${wrapperWidth}
	${mediaWrapper}
	height: 1400px;
	background-color: ${theme.colors.white};
	padding: 4rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
	position: relative;

	strong {
		position: absolute;
		top: 65px;
		text-align: center;
		font-size: 12px;
		color: ${({ theme }) => theme.colors.darkGrey};
		font-family: 'Montserrat Regular';;
	}
`

export const LogoHiga = styled.img`
	width: 109.16px;
	height: 50.03px;
`
