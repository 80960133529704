import React, { ChangeEvent } from 'react'
import { createElement, useEffect, useState } from 'react'
import { IRetail } from 'shared/interfaces/retail'
import {
	createRetail,
	deleteRetail,
	editRetail,
	fetchAllRetail,
} from 'shared/services/retail.service'
import { IViewProps } from './types'
import ListRetail from './view'
import cogoDefaultOptions from '../../shared/util/toaster'
import cogoToast from 'cogo-toast'
import TrashIcon from '../../assets/images/trash.svg'
import SettingsIcon from '../../assets/images/settings.svg'
import { TableRetailContent } from 'modules/ListUser/styles'

function ListRetailContainer(): JSX.Element {
	const [tableRows, setTableRows] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [selectedRetail, setSelectedRetail] = useState({
		_id: '',
		name: '',
		maxOccupancy: 0,
	})
	const [isFormValid, setIsFormValid] = useState(false)

	useEffect(() => {
		const { name, maxOccupancy } = selectedRetail

		const isNameValid = name.length >= 1
		const isMaxOccupancyValid = maxOccupancy >= 1

		const result = isNameValid && isMaxOccupancyValid

		setIsFormValid(result)
	}, [selectedRetail])

	async function fetchAll() {
		try {
			setLoading(true)
			const retails: IRetail[] = await fetchAllRetail()
			setTableRows(
				retails.map((retail) => {
					return {
						name: handleRetailName(retail.name),
						maxOccupancy: retail.occupancyCounter.maxOccupancy,
						settings: handleSettingsIcon(retail),
						trash: handleTrashIcon(retail),
					}
				}),
			)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function changeModalState(selectedRetail?: IRetail): void {
		setShowModal(!showModal)

		if (selectedRetail) {
			setSelectedRetail({
				_id: String(selectedRetail._id),
				name: selectedRetail.name,
				maxOccupancy: selectedRetail.occupancyCounter.maxOccupancy,
			})
		} else {
			setSelectedRetail({
				_id: '',
				name: '',
				maxOccupancy: 0,
			})
		}
	}

	function changeDeleteModalState(selectedRetail?: IRetail): void {
		setDeleteModal(!deleteModal)
		if (selectedRetail) {
			setSelectedRetail({
				_id: String(selectedRetail._id),
				name: selectedRetail.name,
				maxOccupancy: selectedRetail.occupancyCounter.maxOccupancy,
			})
		} else {
			setSelectedRetail({
				_id: '',
				name: '',
				maxOccupancy: 0,
			})
		}
	}

	async function deleteRetails() {
		try {
			await deleteRetail(selectedRetail._id)
			changeDeleteModalState()
			cogoToast.success('Loja removida com sucesso!', cogoDefaultOptions)
		} catch (error) {
		} finally {
		}
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target

		setSelectedRetail({
			...selectedRetail,
			[id]: value,
		})
	}

	function handleTrashIcon(selectedRetail: IRetail): JSX.Element {
		return (
			<span
				onClick={() => {
					changeDeleteModalState(selectedRetail)
				}}
				className='trash-icon'
			>
				<img src={TrashIcon} alt='trash' />
			</span>
		)
	}

	function handleSettingsIcon(selectedRetail: IRetail): JSX.Element {
		return (
			<span
				onClick={() => {
					changeModalState(selectedRetail)
				}}
				className='settings-icon'
			>
				<img src={SettingsIcon} alt='settings' />
			</span>
		)
	}

	function handleRetailName(retailName: string): JSX.Element {
		return <TableRetailContent>{retailName}</TableRetailContent>
	}

	async function create() {
		try {
			await createRetail({
				name: selectedRetail.name,
				occupancyCounter: {
					count: 0,
					maxOccupancy: Number(selectedRetail.maxOccupancy),
				},
			})

			changeModalState()

			cogoToast.success('Loja cadastrada com sucesso!', cogoDefaultOptions)
		} catch (error) {
		} finally {
		}
	}

	async function edit() {
		try {
			await editRetail({
				_id: selectedRetail._id,
				name: selectedRetail.name,
				occupancyCounter: {
					count: 0,
					maxOccupancy: Number(selectedRetail.maxOccupancy),
				},
			})

			changeModalState()

			cogoToast.success('Loja atualizada com sucesso!', cogoDefaultOptions)
		} catch (error) {}
	}

	const tableColumns: Object[] = [
		{ Header: 'Name', accessor: 'name', sortType: 'basic' },
		{
			Header: 'Ocupação Máxima da Loja',
			accessor: 'maxOccupancy',
			sortType: 'basic',
		},
		{ Header: '', accessor: 'settings', disableSortBy: true },
		{ Header: '', accessor: 'trash', disableSortBy: true },
	]

	useEffect(() => {
		;(async () => {
			fetchAll()
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!showModal) {
			fetchAll()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal])

	useEffect(() => {
		if (!deleteModal) {
			fetchAll()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteModal])

	const viewProps: IViewProps = {
		tableColumns,
		tableRows,
		loading,
		showModal,
		changeModalState,
		handleChange,
		selectedRetail,
		create,
		edit,
		deleteModal,
		changeDeleteModalState,
		deleteRetails,
		isFormValid,
	}
	return createElement(ListRetail, viewProps)
}

export default ListRetailContainer
