import { createElement, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce/lib'
import { IViewProps } from './types'
import BoardView from './view'
import {
	ICardsList,
	ITicket,
	TicketStatusValues,
} from 'shared/interfaces/ticket'
import { IOrder } from 'shared/interfaces/order'

import {
	fetchCardsList,
	initializeTicketFields,
} from 'shared/services/ticket.service'
import { ModalActionsEnum } from 'modules/ModalTicket/types'
import { SelectOption } from '@buildbox/components'
import { labelChannel } from 'shared/util/Consts'
import { fetchAllProfessional } from 'shared/services/user.service'
import { ChannelTypeEnum } from 'shared/interfaces/ticket'
import { escapeString } from 'shared/util/ticket'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

function Board(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const [isLoading, setIsLoading] = useState(false)
	const [cardsListArray, setCardsListArray] = useState<ICardsList[]>([])
	const [filteredCardsListArray, setFilteredCardsListArray] = useState<
		ICardsList[]
	>([])
	const [currentTicket, setCurrentTicket] = useState<ITicket>(
		initializeTicketFields(),
	)
	const [modalAction, setModalAction] = useState<ModalActionsEnum>('CREATE')
	const [showModalTicket, setShowModalTicket] = useState(false)

	// filters
	const [professionalsOptions, setProfessionalsOptions] = useState<
		SelectOption[]
	>([])
	const [retailOptions, setRetailOptions] = useState<
		SelectOption[]
	>([])
	const [searchInput, setSearchInput] = useState('')
	const [selectedProfessional, setSelectedProfessional] = useState<
		SelectOption[]
	>([])
	const [selectedChannel, setSelectedChannel] =
		useState<SelectOption[]>(labelChannel)
	const [selectedRetails, setSelectedRetails] = useState<SelectOption[]>([])
	const [isFirstRequest, setIsFirstRequest] = useState(true)
	const [searchStringDebounce] = useDebounce(searchInput, 1000)
	const [professionalDebounce] = useDebounce(selectedProfessional, 1000)
	const [channelDebounce] = useDebounce(selectedChannel, 1000)
	const [retailsDebounce] = useDebounce(selectedRetails, 1000)

	const columnSize = {
		smInput: '3.6',
		smSelect: '2.1',
		smBtn: '2.1',
	}

	function requestCardsListArray() {
		; (async () => {
			setIsLoading(true)

			const professionals = professionalDebounce.map((x) => x.value)
			const channels = channelDebounce.map((x) => x.value as ChannelTypeEnum)
			const retails = retailsDebounce.map((x) => x.value)

			const filterOptions = {
				professionals,
				channels,
				retails
			}

			const ticketsListArrayPromises: Promise<ICardsList>[] = []
			getStatusValuesToShow(TicketStatusValues).forEach(function (status) {
				ticketsListArrayPromises.push(fetchCardsList(status, filterOptions))
			})
			const ticketsListArray = await Promise.all(ticketsListArrayPromises)

			setCardsListArray(ticketsListArray)
			setFilteredCardsListArray(filterTickets(ticketsListArray))
			setIsLoading(false)
		})()
	}

	function getStatusValuesToShow(statusValues: string[]): string[] {
		return statusValues.filter((value) => {
			return !['INVOICED', 'CANCELED'].includes(value)
		})
	}

	function getSetProfessional() {
		; (async () => {
			const retailsId = selectedRetails.map(retail => retail.value)
			const users = await fetchAllProfessional(retailsId)

			const optionFormat = (item: any) => ({
				label: item.name,
				value: item._id,
			})

			const professionalOptions = users.map((user: any) => optionFormat(user))

			setProfessionalsOptions(professionalOptions)
			if (isFirstRequest) {
				setSelectedProfessional(professionalOptions)
				setIsFirstRequest(false)
			}
		})()
	}

	function getSetRetails() {
		const userRetailOptions = user.retails.map(userRetail => ({
			value: String(userRetail._id),
			label: userRetail.name
		}))

		setRetailOptions(userRetailOptions)
		setSelectedRetails(userRetailOptions)
	}

	function handleCreateTicket() {
		activateModalTicket('CREATE', initializeTicketFields())
	}
	function activateModalTicket(action: ModalActionsEnum, ticket: ITicket) {
		setCurrentTicket(ticket)
		setModalAction(action)
		setShowModalTicket(true)
	}
	function handleModal() {
		setShowModalTicket(!showModalTicket)
		getSetProfessional()
		requestCardsListArray()
	}

	function filterTickets(ticketsListArray: ICardsList[]): ICardsList[] {
		const filteredTicketsListArray: ICardsList[] = []
		ticketsListArray.forEach((ticketsList) => {
			const filteredTicketsList: ITicket[] = []
			ticketsList.forEach((ticket) => {
				const updatedTicket = { ...ticket }

				if (
					matchesSearch(ticket.ticket.toString()) ||
					matchesSearch(ticket.codeOrName) ||
					(updatedTicket.orders = filterOrders(ticket.orders)).length > 0
				) {
					filteredTicketsList.push(updatedTicket)
				}
			})

			filteredTicketsListArray.push(filteredTicketsList)
		})

		return filteredTicketsListArray
	}

	function filterOrders(orders: IOrder[]): IOrder[] {
		return orders.filter((order) => matchesSearch(order.code))
	}

	function matchesSearch(field: string): boolean {
		const pattern = new RegExp(`${escapeString(searchStringDebounce)}`, 'i')

		return pattern.test(field)
	}

	function hasTicket() {
		return filteredCardsListArray.some((e) => e.length > 0)
	}

	function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
		const value = event.target.value
		setSearchInput(value)
	}

	function handleProfessionalSelect(value: SelectOption[]): void {
		setSelectedProfessional(value)
	}

	function handleChannelSelect(value: SelectOption[]): void {
		setSelectedChannel(value)
	}

	const handleRetailsSelect = useCallback((value: SelectOption[]) => {
		setSelectedRetails(value)
	}, [])

	useEffect(requestCardsListArray, [
		professionalDebounce,
		channelDebounce,
		retailsDebounce
	])

	useEffect(getSetProfessional, [selectedRetails])
	useEffect(getSetRetails, [user])
	useEffect(() => {
		setFilteredCardsListArray(filterTickets(cardsListArray))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchStringDebounce])

	const viewProps: IViewProps = {
		isLoading,
		handleChangeSearch,
		searchInput,
		ticketStatusValuesToShow: getStatusValuesToShow(TicketStatusValues),
		cardsListArray: filteredCardsListArray,
		hasTicket,
		handleModal,
		showModalTicket,
		handleCreateTicket,
		activateModalTicket,
		modalAction,
		currentTicket,
		handleProfessionalSelect,
		handleChannelSelect,
		selectedProfessional,
		professionalsOptions,
		selectedChannel,
		columnSize,
		retailOptions,
		handleRetailsSelect,
		selectedRetails
	}

	return createElement(BoardView, viewProps)
}

export default Board
