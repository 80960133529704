import styled from 'styled-components'
import { theme } from '../../shared/styles/theme'
import { fontType, toRem } from '../../shared/styles/functions'
import { ButtonGhostWrapper } from '../../shared/styles'

export const ComponentContainer = styled.div`
	background-color: white;
	height: 100px;
	width: 100%;
	min-height: 521px;
	padding: 32px;
	margin-bottom: 32px;
`
export const HeaderWrapper = styled.div`
	display: grid;

	h3 {
		grid-area: title;
	}

	div:nth-child(2) {
		grid-area: cardBox;
		justify-self: end;
	}

	div:nth-child(3) {
		grid-area: graphFilters;
	}

	grid-template-areas:
		'title cardBox'
		'graphFilters cardBox';
`

export const Title = styled.h3`
	color: ${theme.colors.darkGrey};
	font-size: ${toRem(20)};

	${fontType(0)}
`

export const Label = styled.label`
	color: ${theme.colors.darkGrey};
	display: flex;
	flex-direction: column;
	font-size: ${toRem(12)};
	${fontType(0)};
`

export const RadioButtons = styled.div`
	flex-direction: row;
	margin-top: 18px;
`

export const AbsoluteButton = styled(ButtonGhostWrapper)`
	margin-right: 8px;
	${(props) =>
		props.value === 'absolute'
			? `
				background-color: #BECDFF;
				border: 1px solid transparent;`
			: `background-color: transparent;`};
`

export const PercentageButton = styled(ButtonGhostWrapper)`
	${(props) =>
		props.value === 'percentage'
			? `
				background-color: #BECDFF;
				border: 1px solid transparent;`
			: `background-color: transparent;`};
`

export const EntryVolumeButton = styled(ButtonGhostWrapper)`
	margin-left: 8px;
	${(props) =>
		props.value === 'entry-volume'
			? `
				background-color: #BECDFF;
				border: 1px solid transparent;`
			: `background-color: transparent;`};
`

export const RetailSelect = styled.span`
	margin-top: 8px;
	padding: 14px 24px;
	border: 1px solid ${({ theme }) => theme.colors.blue};
	border-radius: 39px;
`

export const GraphFilters = styled.div`
	display: flex;
`

export const ContainerCardBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
`
