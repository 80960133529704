import styled from 'styled-components'
import { fontType, toRem } from 'shared/styles/functions'

export const Container = styled.div`
	header {
		display: flex;
		align-items: center;
		height: ${toRem(42)};

		p {
			${fontType(1)};
			color: ${({ theme }) => theme.colors.darkGrey};
			font-size: 14px;
		}
		div {
			background: #354d9e;
			margin-left: 8px;
			width: 20px;
			height: 21px;

			display: flex;
			justify-content: center;
			align-items: center;

			border-radius: 50%;

			span {
				color: #ffffff;
				font-size: ${toRem(10)};
				${fontType(0)};
			}
		}
	}

	ul {
		background: white;
		height: 100vh;
		padding: 5px;
		overflow-y: auto;
	}

	* {
		::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme.colors.lightGrey};
		}
		::-webkit-scrollbar {
			width: 6px;
			background: ${({ theme }) => theme.colors.grey};
		}
		::-webkit-scrollbar-thumb {
			background: #dad7d7;
			border-radius: 50px;
		}
		${fontType(0)}
	}
`
