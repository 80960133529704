import { SelectOption } from '@buildbox/components'
import { IRetail } from 'shared/interfaces/retail'
import { ticketSeparationStatus } from 'shared/interfaces/ticket'
import { IUser } from 'shared/interfaces/user'

export const withdrawalStatusActionAllowed: { [key: string]: ticketSeparationStatus[] } = {
	SEPARATION_READY_TO_DELIVERY: [
		ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_IN,
	],
	WITHDRAWAL_FULFILLED_CHECK_IN: [
		ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_OUT,
	],
	WITHDRAWAL_FULFILLED_CHECK_OUT: [ticketSeparationStatus.WITHDRAWAL_SHELVED],
	WITHDRAWAL_SHELVED: [],
}

export function formatWithdrawalAssistantOptions(
	withdrawalAssistants: IUser[]
): SelectOption[] {
	return withdrawalAssistants.map(assistant => ({
		value: assistant._id,
		label: assistant.name
	}))
}

export function getRetailName({ ticketRetailId, userRetails }: {
	ticketRetailId: string
	userRetails: IRetail[]
}) {
	return userRetails.find(
		userRetail => userRetail._id === ticketRetailId
	)?.name || ''
}
