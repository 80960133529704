import styled from 'styled-components'
import { fontType, transition, toRem } from '../../styles/functions'

export const DayPickerWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	.date-picker-primary {
		position: relative;

		width: 100%;

		.DayPickerInput {
			width: 100%;
			input {
				width: 100%;
				border: none;
				background: none;
				border: 1px solid #d1d1d1;
				border-radius: 39px;
				padding: 20px 32px;
				text-align: center;
				color: ${({ theme }) => theme.colors.darkGrey};
				font-size: ${toRem(12)};

				${transition(0)}
				${fontType(0)}

				letter-spacing: -0.3px;

				&::placeholder {
					color: ${({ theme }) => theme.colors.darkGrey};
				}
			}
		}
	}

	.DayPicker-Day--today {
		color: $green !important;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
		background-color: $green !important;
		color: white !important;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
		background-color: lighten($green, 30%) !important;
		color: white !important;
	}
	.DayPicker-Day {
		border-radius: 0 !important;
	}
	.DayPicker-Day--start {
		border-top-left-radius: 48% !important;
		border-bottom-left-radius: 48% !important;
	}
	.DayPicker-Day--end {
		border-top-right-radius: 48% !important;
		border-bottom-right-radius: 48% !important;
	}
`
