import { createElement } from 'react'
import { getSeparationBoardColumns } from 'shared/util/separation'
import { IViewProps, LastBoardProps } from './types'
import LateScheduleTicketsBoardView from './view'

function LateScheduleTicketsBoard(props: LastBoardProps): JSX.Element {
	const { data, activateModalTicket, fetchParams } = props

	const {
		pendingColumn,
		separationColumn,
		checkingColumn
	} = getSeparationBoardColumns(data)

	const viewProps: IViewProps = {
		pendingColumn,
		separationColumn,
		checkingColumn,
		activateModalTicket,
		fetchParams,
		isLate: true
	}

	return createElement(LateScheduleTicketsBoardView, viewProps)
}

export default LateScheduleTicketsBoard
