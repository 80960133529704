import { AxiosResponse } from 'axios'
import { IStoreAisle } from 'shared/interfaces/storeAisle'
import { IPaginatedList } from 'shared/interfaces/utils'
import axiosInstance from './axiosInstance'

interface IFetchStoreAisleProps {
	retail: string
	pageIndex: number
	maxItemsInPage: number
}

export async function fetchStoreAislePage({
    retail,
    pageIndex,
    maxItemsInPage
}: IFetchStoreAisleProps): Promise<IPaginatedList<IStoreAisle>> {
	const response: AxiosResponse = await axiosInstance.post(
		'/store-aisle/get-page',
		{
            retail,
            pageIndex,
            maxItemsInPage
		},
	)

	return response.data.content
}

export async function createStoreAisle(storeAisle: Partial<IStoreAisle>) {
	const response: AxiosResponse = await axiosInstance.post(
		'/store-aisle',
		{
			...storeAisle
		},
	)

	return response.data.content
}

export async function updateStoreAisle({ _id, ...storeAisle }: Partial<IStoreAisle>) {
	const response: AxiosResponse = await axiosInstance.patch(
		`/store-aisle/${_id}`,
		{
			...storeAisle
		},
	)

	return response.data.content
}

export async function deleteStoreAisle(_id: string) {
	const response: AxiosResponse = await axiosInstance.delete(
		`/store-aisle/${_id}`,
	)

	return response.data.content
}
