/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import { SelectOption } from '@buildbox/components'
import cogoToast from 'cogo-toast'
import { createElement, useEffect, useState } from 'react'
import { ISelect } from 'shared/interfaces/select'
import { ISeparationParams } from 'shared/interfaces/SeparationParams'
import { IUser } from 'shared/interfaces/user'
import { saveRetailSeparationParams } from 'shared/services/separationParams.service'
import { listSeparator } from 'shared/services/user.service'
import { haveChangesInTheSeparatorsOrder } from 'shared/util/separation'
import cogoDefaultOptions from 'shared/util/toaster'
import { ISettingsModal, IViewProps } from './types'
import SettingsModal from './view'

const INITIAL_RETAIL_PARAMS: ISeparationParams = {
	_id: '',
	defaultSeparators: [],
	periodLimitQuantity: {
		morning: 0,
		afternoon: 0
	},
	retail: ''
}

function SettingsModalContainer(props: ISettingsModal): JSX.Element {
	const { isActive, handleToggle, selectedRetailsInThePageFilter, params } = props

	const [selectedRetailOption, setSelectedRetailOption] = useState<SelectOption>(selectedRetailsInThePageFilter[0])
	const [selectedRetailParams, setSelectedRetailParams] = useState<ISeparationParams>(INITIAL_RETAIL_PARAMS)
	const [inputMorningValue, setInputMorningValue] = useState(0)
	const [inputEveningValue, setInputEveningValue] = useState(0)
	const [isValidatedField, setIsValidatedField] = useState(false)
	const [hasChanges, setHasChanges] = useState(false)
	const [activeDefaultSeparators, setActiveDefaultSeparators] = useState(false)
	const [defaultSeparators, setDefaultSeparators] = useState<ISelect[]>([])
	const [currentDefaultSeparators, setCurrentDefaultSeparators] = useState<string[]>([])
	const [separatorsOptions, setSeparatorsOptions] = useState<ISelect[]>([])

	function findRetailParams(retailId: string): ISeparationParams | undefined {
		return params.find(param => {
			return param.retail === retailId
		})
	}

	function handleClose() {
		handleToggle()
		setInputMorningValue(0)
		setInputEveningValue(0)
		setCurrentDefaultSeparators([])
		setActiveDefaultSeparators(false)
		setDefaultSeparators([])
	}

	const handlers = {
		changeEveningInput: (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = Number(e.target.value)
			setInputEveningValue(value)
		},
		changeMorningInput:(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = Number(e.target.value)
			setInputMorningValue(value)
		},
		changeActiveDefaultSeparators: (
			e: React.ChangeEvent<HTMLInputElement>
		) => {
			setActiveDefaultSeparators(e.target.checked)
			!e.target.checked && setDefaultSeparators([])
		},
		changeDefaultSeparators: (selectedOptions: any) => {
			!!selectedOptions
				? setDefaultSeparators(selectedOptions)
				: setDefaultSeparators([])
		},
		changeRetailParams: (selectedOption: SelectOption | undefined) => {
			if (!selectedOption) return

			const findedRetailParams =
				findRetailParams(selectedOption.value) || INITIAL_RETAIL_PARAMS


			setSelectedRetailOption(selectedOption)
			setSelectedRetailParams(findedRetailParams)
		}
	}

	function handleSubmit() {
		(async () => {
			try {

				const params = {
					retail: selectedRetailOption.value,
					periodLimitQuantity: {
						morning: Number(inputMorningValue),
						afternoon: Number(inputEveningValue)
					},
					defaultSeparators: defaultSeparators.map(item => item.value)
				}
				
				await saveRetailSeparationParams(params)

				cogoToast.success(
					'Configurações atualizadas com sucesso!',
					cogoDefaultOptions,
				)
			} catch (err) {
				console.error({ err })
			} finally {
				handleClose()
			}
		})()
	}

	function validateField() {
		let isValid = !!inputMorningValue && !!inputEveningValue

		if (activeDefaultSeparators) {
			isValid = isValid && !!defaultSeparators.length
		}

		setIsValidatedField(isValid)
	}

	function checkIfHasChanges() {
		let hasChanges = (
			!!currentDefaultSeparators.length !== activeDefaultSeparators ||
			selectedRetailParams.periodLimitQuantity.morning !== inputMorningValue ||
			selectedRetailParams.periodLimitQuantity.afternoon !== inputEveningValue
		)

		if (activeDefaultSeparators) {
			hasChanges = hasChanges || haveChangesInTheSeparatorsOrder(
				defaultSeparators.map(separator => ({
					_id: separator.value,
					name: separator.label
				} as IUser)),
				currentDefaultSeparators,
			)
		}
		
		setHasChanges(hasChanges)
	}

	function fetchSeparators(retailId: string) {
		(async () => {
			const separatorsList = await listSeparator([retailId])

			const formattedSeparatorsList = separatorsList.map(separator => ({
				value: separator._id,
				label: separator.name
			}))

			setSeparatorsOptions(formattedSeparatorsList)
			setDefaultSeparators(formattedSeparatorsList.filter(separator => {
				return selectedRetailParams.defaultSeparators.includes(separator.value)
			}))
		})()
	}

	useEffect(() => {
		validateField()
		checkIfHasChanges()
	}, [
		inputMorningValue,
		inputEveningValue,
		activeDefaultSeparators,
		defaultSeparators
	])
	useEffect(() => {
		setInputMorningValue(selectedRetailParams.periodLimitQuantity.morning)
		setInputEveningValue(selectedRetailParams.periodLimitQuantity.afternoon)
		setCurrentDefaultSeparators(selectedRetailParams.defaultSeparators)
		setActiveDefaultSeparators(!!selectedRetailParams.defaultSeparators.length)
		if (separatorsOptions) {
			setDefaultSeparators(separatorsOptions.filter(separator => {
				return selectedRetailParams.defaultSeparators.includes(separator.value)
			}))
		}
	}, [selectedRetailParams])
	useEffect(() => {
		fetchSeparators(selectedRetailOption.value)
		const findedRetailParams = findRetailParams(selectedRetailOption.value)
		findedRetailParams && setSelectedRetailParams(findedRetailParams)
	}, [params, selectedRetailOption])

	const viewProps: IViewProps = {
		isActive,
		inputMorningValue,
		inputEveningValue,
		isValidatedField,
		hasChanges,
		handleSubmit,
		handleClose,
		activeDefaultSeparators,
		defaultSeparators,
		separatorsOptions,
		handlers,
		selectedRetailParams,
		retailOptions: selectedRetailsInThePageFilter,
		selectedRetailOption,
	}

	return createElement(SettingsModal, viewProps)
}

export default SettingsModalContainer
