import React from 'react'

import { theme } from '../../../shared/styles/theme'
import {
	RetailCardArea,
	RetailCardTopArea,
	RetailCardBotArea,
	RetailName,
	RetailCountData,
	RetailCount,
	RetailMaxOccupancy,
	RetailPercent,
	RetailLastHourLabel,
	RetailLastHour,
	RetailLastHourArrow,
	LastHourWrapper,
} from './styles'
import { IViewProps } from './types'

function RetailCard(props: IViewProps): JSX.Element {
	const { name, count, maxOccupancy, percentage, lastHour } = props

	function calculateCountColor(count: number, maxOccupancy: number) {
		const percentage = count / maxOccupancy
		if (percentage > 0.89) return theme.colors.red
		if (percentage > 0.65) return '#FFA500' // Add to theme??
		return theme.colors.blue
	}

	const countColor = calculateCountColor(count, maxOccupancy)
	const lastHourColor = lastHour > 0 ? theme.colors.blue : theme.colors.darkGrey
	const arrowFile =
		lastHour > 0
			? require('assets/images/arrow-up.svg')
			: require('assets/images/arrow-down.svg')

	return (
		<RetailCardArea>
			<RetailCardTopArea>
				<RetailName>{name}</RetailName>
				<RetailCountData>
					<RetailCount color={countColor}>{count}</RetailCount>
					<RetailMaxOccupancy>{`/${maxOccupancy}`}</RetailMaxOccupancy>
				</RetailCountData>
				<RetailPercent color={countColor}>{percentage}</RetailPercent>
			</RetailCardTopArea>
			<RetailCardBotArea>
				<RetailLastHourLabel>Última hora</RetailLastHourLabel>

				<LastHourWrapper>
					<RetailLastHour color={lastHourColor}>{lastHour}</RetailLastHour>

					{typeof lastHour === 'number' && (
						<RetailLastHourArrow src={arrowFile} />
					)}
				</LastHourWrapper>
			</RetailCardBotArea>
		</RetailCardArea>
	)
}

export default RetailCard
