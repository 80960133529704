import React, { ChangeEvent, createElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Creators as UserActions } from '../../shared/store/ducks/user'
import {
	createProfile,
	deleteProfile,
	fetchProfilePage,
	updateProfileDoc,
	permissionList
} from 'shared/services/profile.service'
import handleError from 'shared/util/handleError'
import { IProfile, IViewProps } from './types'
import SettingsIcon from '../../assets/images/settings.svg'
import Profile from './view'
import TrashIcon from '../../assets/images/trash.svg'
import { ProfileLabel, RenderIconInTable } from './styles'
import { INITIAL_PROFILE_VALUE } from './data'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { IPermission } from 'shared/interfaces/permissions'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { fetchUserDetails } from 'shared/services/user.service'
import { IUser } from 'shared/interfaces/user'

function ProfileContainer(): JSX.Element {
	const { user } = useTypedSelector(['user'])
	const dispatch = useDispatch()

	const [modalIsActive, setModalIsActive] = useState(false)
	const [permissions, setPermissions] = useState<IPermission[]>([])
	const [tableRows, setTableRows] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [modalDeleteIsActive, setModalDeleteIsActive] = useState(false)
	const [profileToCreate, setProfileToCreate] = useState<IProfile>(
		INITIAL_PROFILE_VALUE,
	)
	const [currentProfile, setCurrentProfile] = useState<IProfile>(
		INITIAL_PROFILE_VALUE,
	)
	const [isEditing, setIsEditing] = useState(false)

	const tableColumns: Object[] = [
		{ Header: 'Perfil', accessor: 'name', sortType: 'basic' },
		{ Header: 'Permissões', accessor: 'permissions', sortType: 'basic' },
		{ Header: 'Usuários', accessor: 'users', sortType: 'basic' },
		{ Header: '', accessor: 'settings', disableSortBy: true },
		{ Header: '', accessor: 'trash', disableSortBy: true },
	]

	function fetchDataTable() {
		;(async () => {
			try {
				setIsLoading(true)
				const profileList: IProfile[] = await fetchProfilePage()
				const permissionsList: IPermission[] = await permissionList()

				setTableRows(
					profileList.map((profile) => {
						return {
							name: renderProfileLabel(profile.name),
							permissions: profile.permissions.length,
							users: profile.usersCount,
							settings: handleSettingsIcon(profile),
							trash: handleTrashIcon(profile),
						}
					}),
				)
				setPermissions(permissionsList)
			} catch (error) {
				handleError(error)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	async function handleDeleteModal(profile?: IProfile) {
		await toggleModalDeleteState()

		if (profile) {
			setCurrentProfile({
				_id: profile._id,
				name: profile.name,
				status: profile.status,
				permissions: profile.permissions,
				usersCount: profile.usersCount,
			})
		} else {
			setCurrentProfile(INITIAL_PROFILE_VALUE)
		}
	}

	async function handleRemoveProfile() {
		try {
			await deleteProfile(currentProfile._id)

			await toggleModalDeleteState()

			fetchDataTable()

			cogoToast.success('Perfil removido com sucesso!', cogoDefaultOptions)
		} catch (error) {
			console.log(error)
		}
	}

	async function toggleModalDeleteState() {
		setModalDeleteIsActive((oldValue) => !oldValue)
	}
	async function toggleModalState() {
		setModalIsActive((oldValue) => !oldValue)
	}

	function renderProfileLabel(profileName: string) {
		return <ProfileLabel>{profileName}</ProfileLabel>
	}

	function changeProfile(profile: IProfile) {
		handleToggleModalState()
		setProfileToCreate(profile)
		setIsEditing(true)

		if (profile) {
			setCurrentProfile({
				_id: profile._id,
				name: profile.name,
				status: profile.status,
				permissions: profile.permissions,
				usersCount: profile.usersCount,
			})
		} else {
			setCurrentProfile(INITIAL_PROFILE_VALUE)
		}
	}

	async function handleToggleModalState() {
		setProfileToCreate(INITIAL_PROFILE_VALUE)
		setCurrentProfile(INITIAL_PROFILE_VALUE)
		setIsEditing(false)

		await toggleModalState()
	}

	async function handleCreateProfile() {
		try {
			await createProfile(profileToCreate.name, profileToCreate.permissions)

			await toggleModalState()

			setProfileToCreate(INITIAL_PROFILE_VALUE)

			fetchDataTable()

			cogoToast.success('Perfil criado com sucesso!', cogoDefaultOptions)
		} catch (error) {
			console.log(error)
		}
	}

	async function updateProfile() {
		await updateProfileDoc(profileToCreate as IProfile)

		await toggleModalState()

		fetchDataTable()

		if (profileToCreate._id === user.profile._id) {
			const userData: IUser = await fetchUserDetails(user._id)

			dispatch(
				UserActions.setUser({
					...userData,
				}),
			)
		}

		cogoToast.success('Perfil atualizado com sucesso!', cogoDefaultOptions)
	}

	function handleChangeNewProfileName(event: ChangeEvent<HTMLInputElement>) {
		setProfileToCreate({ ...profileToCreate, name: event.target.value })
	}

	function handleChangePermissions(permissions: string[]) {
		setProfileToCreate({ ...profileToCreate, permissions: permissions })
	}

	function handleSettingsIcon(profile: IProfile): JSX.Element {
		return (
			<RenderIconInTable onClick={() => changeProfile(profile)}>
				<img src={SettingsIcon} alt='settings' />
			</RenderIconInTable>
		)
	}

	function handleTrashIcon(profile: IProfile): JSX.Element {
		return (
			<RenderIconInTable onClick={() => handleDeleteModal(profile)}>
				<img src={TrashIcon} alt='trash' />
			</RenderIconInTable>
		)
	}

	useEffect(fetchDataTable, [])

	const viewProps: IViewProps = {
		handleToggleModalState,
		modalIsActive,
		tableColumns,
		isLoading,
		tableRows,
		modalDeleteIsActive,
		handleDeleteModal,
		handleRemoveProfile,
		handleChangeNewProfileName,
		profileToCreate,
		handleCreateProfile,
		handleChangePermissions,
		isEditing,
		updateProfile,
		currentProfile,
		permissions,
	}

	return createElement(Profile, viewProps)
}

export default ProfileContainer
