import React from 'react'
import ReactSelect from 'react-select'

import { ISelectProps } from './types'
import { colors } from './theme'
import { styler, OptionWrapper, OptionDate, OptionLimit } from './styles'

export default function Select<SelectOption>({
	primaryColor = colors.darkGrey,
	secondaryColor = colors.grey,
	fontSize = 16,
	tertiaryColor = colors.grey,
	fontFamily = 'sans-serif',
	round = false,
	backgroundColor = 'transparent',
	padding = [6, 6, 6, 6],
	styles,
	...rest
}: ISelectProps<SelectOption>) {
	return (
		<>
			<ReactSelect
				styles={{
					...styles,
					...styler({
						styles: {
							primaryColor,
							secondaryColor,
							fontSize,
							fontFamily,
							round,
							backgroundColor,
							padding,
							tertiaryColor,
						},
					}),
				}}
				{...rest}
				placeholder={null}
				formatOptionLabel={(option: any) => {
					const { date, period, limit, schedules, selected } = JSON.parse(option.label)

					return (
						<OptionWrapper>
							<OptionDate
								style={{
									color: !selected
										? schedules < limit
											? '#354D9E'
											: '#999'
										: '#444',
								}}
							>
								{`${date} - ${period}`}
							</OptionDate>
							{!selected && (
								<OptionLimit
									style={{
										backgroundColor: schedules < limit ? '#BECDFF' : '#EEE',
										color: schedules < limit ? '#354D9E' : '#C9C9C9',
									}}
								>
									{`${schedules}/${limit}`}
								</OptionLimit>
							)}
						</OptionWrapper>
					)
				}}
			/>
		</>
	)
}
