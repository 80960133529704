import React from 'react'

import { CardPanelWrapper, CardWrapContent } from './styles'
import { ICardPanel } from './types'
import CardFit from '../CardFit'

function CardPanel(props: ICardPanel) {
	return (
		<CardPanelWrapper>
			<h1>{props.label}</h1>

			<CardWrapContent>
				{props.data.map((card) => (
					<CardFit
						key={card._id}
						ticket={card}
						isLast={false}
						showLocated={true}
						currentStatus={card.currentSeparationStatus || card.currentStatus}
						handleClick={() => {}}
						fetchParams={() => {}}
					/>
				))}
			</CardWrapContent>
		</CardPanelWrapper>
	)
}
export default CardPanel
