import React from 'react'
import { BoardWrapper, HeaderWrapper } from './styles'
import { Button } from '@buildbox/components'
import { IViewProps } from './types'
import { primaryButtonStyles, primaryInputStyles } from 'shared/styles/theme'
import {
	ContainerListCards,
	Label,
	SearchInputColumn as Column,
} from './styles'
import ListTicket from 'modules/ListTicket'
import Loading from 'shared/components/Loading'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { labelChannel } from 'shared/util/Consts'
import { getDropdownButtonLabelOverride } from 'shared/util/multiSelectCheckboxUtils'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import ModalCards from 'modules/ModalTicket'
import InputSearch from 'shared/components/InputSearch'
import { reactSelectStyles } from '../../shared/styles/theme'
import { options } from 'shared/util/SelectOption'

function BoardView(props: IViewProps): JSX.Element {
	const {
		isLoading,
		handleChangeSearch,
		searchInput,
		ticketStatusValuesToShow,
		cardsListArray,
		hasTicket,
		handleModal,
		showModalTicket,
		activateModalTicket,
		handleCreateTicket,
		modalAction,
		currentTicket,
		handleProfessionalSelect,
		handleChannelSelect,
		selectedProfessional,
		professionalsOptions,
		selectedChannel,
		columnSize,
		retailOptions,
		handleRetailsSelect,
		selectedRetails
	} = props

	return (
		<BoardWrapper>
			<HeaderWrapper>
				<Column
					sm={columnSize.smInput}
					md={columnSize.smInput}
					lg={columnSize.smInput}
				>
					<InputSearch
						label='Buscar por Cliente ou Nº do Ticket'
						round
						id='search-input'
						onChange={handleChangeSearch}
						value={searchInput}
					/>
				</Column>

				<Column
					sm={columnSize.smSelect}
					md={columnSize.smSelect}
					lg={columnSize.smSelect}
				>
					<Label>Profissional</Label>
					<ReactMultiSelectCheckboxes
						onChange={handleProfessionalSelect}
						options={options(professionalsOptions)}
						value={selectedProfessional}
						getDropdownButtonLabel={getDropdownButtonLabelOverride}
						placeholderButtonLabel='Profissional'
						placeholder='Buscar Profissional'
						classNamePrefix='gm'
						{...primaryInputStyles}
						styles={reactSelectStyles}
					/>
				</Column>

				<Column
					sm={columnSize.smSelect}
					md={columnSize.smSelect}
					lg={columnSize.smSelect}
				>
					<Label>Canal</Label>

					<ReactMultiSelectCheckboxes
						onChange={handleChannelSelect}
						options={options(labelChannel)}
						value={selectedChannel}
						getDropdownButtonLabel={getDropdownButtonLabelOverride}
						placeholderButtonLabel='Canal'
						placeholder='Buscar Canal'
						noOptionsMessage={() => 'Nenhum canal para mostrar.'}
						{...primaryInputStyles}
						styles={reactSelectStyles}
						multi={false}
					/>
				</Column>

				<Column
					sm={columnSize.smSelect}
					md={columnSize.smSelect}
					lg={columnSize.smSelect}
				>
					<Label>Loja</Label>

					<ReactMultiSelectCheckboxes
						onChange={handleRetailsSelect}
						options={options(retailOptions)}
						value={selectedRetails}
						getDropdownButtonLabel={getDropdownButtonLabelOverride}
						placeholderButtonLabel='Loja'
						placeholder='Buscar Loja'
						noOptionsMessage={() => 'Nenhuma loja para mostrar.'}
						{...primaryInputStyles}
						styles={reactSelectStyles}
						multi={false}
					/>
				</Column>

				<Column
					sm={columnSize.smBtn}
					md={columnSize.smBtn}
					lg={columnSize.smBtn}
				>
					<div className='button-wrapper'>
						<Button
							round
							{...primaryButtonStyles}
							onClick={() => handleCreateTicket()}
						>
							Novo Ticket
						</Button>
					</div>
				</Column>
			</HeaderWrapper>

			{isLoading ? (
				<Loading />
			) : (
				<ContainerListCards>
					{hasTicket() &&
						ticketStatusValuesToShow.map((status, index) => (
							<ListTicket
								key={index}
								title={status}
								cards={cardsListArray[index]}
								activateModalTicket={activateModalTicket}
							/>
						))}
				</ContainerListCards>
			)}

			{showModalTicket && (
				<ModalCards
					modalAction={modalAction}
					active={showModalTicket}
					className='modal'
					handleModal={handleModal}
					currentTicket={currentTicket}
				/>
			)}
			{!hasTicket() && !isLoading && (
				<DefaultPagePlaceholder text='Nenhum dado a exibir.' />
			)}
		</BoardWrapper>
	)
}

export default BoardView
