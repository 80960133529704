import React from 'react'
import { GraphContainer } from './styles'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { IResponsiveProps } from './types'
import { theme } from 'shared/styles/theme'
import { VisionTypeOptions } from 'shared/interfaces/ticket'

export default function ResponsiveScatterPlotGraph(props: IResponsiveProps) {
	const { data, tooltip, vision } = props

	const graphData = [
		{
			id: '1',
			data: data,
		},
	]

	function calculateNodeSize(currentNode: any) {
		const minSize = 5, maxSize = 30

		if (data.length === 1) return maxSize

		const minFat = 1
		const maxFat = data.reduce((max, { z }) => z > max ? z : max, 0)

		const m = (maxSize - minSize) / (maxFat - minFat)
		const n = maxSize - m * maxFat

		return m * currentNode.z + n
	}

	return (
		<GraphContainer>
			<ResponsiveScatterPlot
				data={graphData}
				xScale={{
					type: 'linear',
					min: 0,
					max: 'auto',
				}}
				yScale={{
					type: 'linear',
					min: 0,
					max: 'auto',
				}}
				margin={{ top: 20, right: 20, bottom: 50, left: 70 }}
				colors={['#354D9E']}
				tooltip={tooltip}
				nodeSize={calculateNodeSize}
				theme={{
					fontFamily: 'Montserrat Regular',
					axis: {
						ticks: {
							line: {
								stroke: theme.colors.graphSecondaryColor,
							},
						},
					},
					grid: {
						line: {
							stroke: theme.colors.graphSecondaryColor,
						},
					},
				}}
				axisBottom={{
					legendPosition: 'middle',
					legendOffset: 40,
					legend: 'Tempo total das etapas selecionadas (min)'
				}}
				axisLeft={{
					legendPosition: 'middle',
					legendOffset: -50,
					legend: vision !== VisionTypeOptions.REVENUE ? 'SKUs' : 'Faturamento'
				}}
			/>
		</GraphContainer>
	)
}
