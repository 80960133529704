import { createElement } from 'react'
import { ISubmenu } from './Submenu/types'

import { ReactComponent as AppSVG } from '../../../assets/images/app.svg'
import { ReactComponent as GridSVG } from '../../../assets/images/grid.svg'
import { ReactComponent as UserPlusSVG } from '../../../assets/images/users.svg'
import { ReactComponent as ColumnsSVG } from '../../../assets/images/columns.svg'

export const submenuOptions: ISubmenu[] = [
	{
		submenuKey: 'APP',
		title: 'App',
		icon: createElement(AppSVG),
	},
	{
		submenuKey: 'ADMIN',
		title: 'Admin',
		icon: createElement(UserPlusSVG),
	},
	{
		submenuKey: 'PHYSICAL_STORE',
		title: 'Loja Física',
		icon: createElement(GridSVG),
	},
	{
		submenuKey: 'DIGITAL_CHANNELS',
		title: 'Canais Digitais',
		icon: createElement(ColumnsSVG),
	},
]
