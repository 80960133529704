export const columnSize = {
	smWidth: '12',
	mdWidth: '4',
	lgWidth: '1.7',
	lgDatePickerWidth: '1.8',
}

export function roundAverageValues({
	averagePerChannel,
	averagePerAttendant,
	averagePerItem,
	averagePerSku,
}: {
	averagePerChannel: number
	averagePerAttendant: number
	averagePerItem: number
	averagePerSku: number
}) {
	return {
		averageUnitsPerChannel: !Number.isInteger(averagePerChannel)
			? Number(averagePerChannel.toFixed(2))
			: averagePerChannel,
		averageUnitsPerAttendant: !Number.isInteger(averagePerAttendant)
			? Number(averagePerAttendant.toFixed(2))
			: averagePerAttendant,
		averageItemsPerUnit: !Number.isInteger(averagePerItem)
			? Number(averagePerItem.toFixed(2))
			: averagePerItem,
		averageSkusPerUnit: !Number.isInteger(averagePerSku)
			? Number(averagePerSku.toFixed(2))
			: averagePerSku,
	}
}

export function graphTitle(vision: string) {
	const channelDashTitle =
		vision === 'REVENUE' ? 'Canal (valores em R$)' : 'Canal'
	const teamDashTitle = vision === 'REVENUE' ? 'Time (valores em R$)' : 'Time'
	const performanceDashTitle =
		vision === 'REVENUE' ? 'Performance (valores em R$)' : 'Performance'
	const clientsProfileDashTitle =
		vision === 'REVENUE'
			? 'Perfil de Clientes (valores em R$)'
			: 'Perfil de Clientes'

	return {
		channelDashTitle,
		teamDashTitle,
		performanceDashTitle,
		clientsProfileDashTitle,
	}
}
