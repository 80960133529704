import styled from 'styled-components'
import { fontType, toRem } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'

export const DashboardWrapper = styled.div`
	width: 82%;
	margin: auto;

	& * {
		${fontType(0)};
	}
`

export const DashboardHeader = styled.div`
	${fontType(0)}
	font-size: ${toRem(28)};
	color: ${({ theme }) => theme.colors.blue};
	margin-bottom: 32px;
	justify-content: space-between;
	display: flex;
`

export const PickerContainer = styled.div`
	justify-content: flex-end;
`
export const Title = styled.div`
	color: ${theme.colors.darkGrey};
	font-size: ${toRem(16)};
	margin-top: 56px;
	margin-bottom: 14px;
	${fontType(0)}
	display:flex;
	align-items: center;
	justify-content: space-between;
`

export const FilterWrapper = styled.div`
	display: flex;
	flex-direction: space-between;
`

export const Label = styled.label`
	color: ${({ theme }) => theme.colors.darkGrey};

	font-size: ${toRem(12)};
	${fontType(0)};
	margin-bottom: 8px;
	display: inline-block;
`

export const BottomPartWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* flex-wrap: wrap; */
	margin: 0 -16px;
`
export const GraphWrapper = styled.div`
	flex: 1;
	margin: 16px;
`

export const TableWrapper = styled.div`
	flex: 1;
	margin: 16px;
`

export const DatePickerWrapper = styled.div`
	margin-right: 16px;
`

export const SelectWrapper = styled.div`
	width: 50%;

	* {
		::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme.colors.lightGrey};
		}
		::-webkit-scrollbar {
			height: 6px;
			background: ${({ theme }) => theme.colors.grey};
		}
		::-webkit-scrollbar-thumb {
			background: #dad7d7;
			border-radius: 50px;
		}
	}
`
