import { AxiosResponse } from 'axios'
import { IListToSelectOption, IUser } from 'shared/interfaces/user'
import { formatCPF } from 'shared/util/mask'

import axiosInstance from '../../shared/services/axiosInstance'

export async function fetchUsers() {
	const response: AxiosResponse = await axiosInstance.get(`/user/`)

	return response.data.content
}

export async function createNewUser(
	selectedUser: IUser,
	selectedRetails: string[],
	selectedProfile: string,
) {
	const response: AxiosResponse = await axiosInstance.post(`/user/`, {
		enrollment: selectedUser.enrollment,
		name: selectedUser.name,
		documentNumber: formatCPF(selectedUser.documentNumber),
		profile: selectedProfile,
		email: selectedUser.email,
		retails: selectedRetails,
		photoUser: selectedUser.photoUser,
	})

	return response.data.content
}

export async function deleteUser(userId: string) {
	const response: AxiosResponse = await axiosInstance.delete(`/user/` + userId)

	return response.data.content
}

export async function listSeparator(
	retails: string[],
): Promise<IListToSelectOption[]> {
	const response: AxiosResponse = await axiosInstance.post(
		`/user/list/separators/`,
		{ retails },
	)

	return response.data.content
}

export async function listCheckers(
	retails: string[],
): Promise<IListToSelectOption[]> {
	const response: AxiosResponse = await axiosInstance.post(
		`/user/list/checkers/`,
		{ retails },
	)

	return response.data.content
}

export async function editUser(
	selectedUser: IUser,
	selectedValueRetail: string[],
	selectedProfile: string,
) {
	const response: AxiosResponse = await axiosInstance.patch(
		`/user/` + selectedUser._id,
		{
			enrollment: selectedUser.enrollment,
			documentNumber: formatCPF(selectedUser.documentNumber),
			name: selectedUser.name,
			email: selectedUser.email,
			profile: selectedProfile,
			retails: selectedValueRetail,
			photoUser: selectedUser.photoUser,
		},
	)

	return response.data
}

export async function fetchAllProfessional(retails: string[]) {
	const response: AxiosResponse = await axiosInstance.post(
		`/user/list/professionals`,
		{ retails },
	)

	return response.data.content
}

export async function fetchAttendants(retails: string[]): Promise<IUser[]> {
	const response: AxiosResponse = await axiosInstance.post(
		`/user/list/attendants`,
		{ retails },
	)

	return response.data.content
}

export async function fetchUserDetails(userId: string) {
	const response: AxiosResponse = await axiosInstance.get(`/user/${userId}`)

	return response.data.content
}
