import { format, isValid, isWithinInterval, parseISO } from 'date-fns'
import React from 'react'

import Switch from 'shared/components/Switch'
import { BannerStatus, IBanner } from 'shared/interfaces/banner'
import { BookletStatus, IBooklet } from 'shared/interfaces/booklet'
import SettingsIcon from '../../assets/images/settings.svg'
import TrashIcon from '../../assets/images/trash.svg'
import { BookletCoverImageContainer, BookletImageContainer } from './styles'

function renderBannerImage(banner: IBanner): JSX.Element {
	return (
		<div className='image-wrapper'>
			<img className='banner-image' src={banner.image} alt={banner.title} />
		</div>
	)
}

function renderBookletImage(booklet: IBooklet): JSX.Element {
	return (
		<BookletCoverImageContainer>
			<div className='image-wrapper'>
				<img
					className='banner-image'
					src={booklet.coverBookletImage}
					alt={booklet.title}
				/>
			</div>
		</BookletCoverImageContainer>
	)
}

function renderBookletImageBackAndFront(booklet: IBooklet): JSX.Element {
	return (
		<BookletImageContainer>
			<div className='image-wrapper'>
				<img
					className='banner-image'
					src={booklet.backBookletImage}
					alt={booklet.title}
				/>
			</div>
			<div className='image-wrapper'>
				<img
					className='banner-image'
					src={booklet.frontBookletImage}
					alt={booklet.title}
				/>
			</div>
		</BookletImageContainer>
	)
}

export function renderRegisterDate(date: string): string {
	const parsedDate = parseISO(date)

	if (!isValid(parsedDate)) return '-'

	return format(parsedDate, "dd/MM/yyyy 'às' HH:mm")
}

export function renderDate(date: Date): string {
	return format(date, 'dd/MM/yyyy')
}

export function renderCreatedBy(fullName: string) {
	const firstName = fullName.split(' ')[0]

	return firstName
}

function renderRedirectLink(link: string) {
	return <div className='link-wrapper'>{link}</div>
}

function renderStatusSwitchButton({
	status,
	isLoading,
	onClick,
}: {
	status: BannerStatus
	isLoading: boolean
	onClick: (newStatus: BannerStatus) => void
}) {
	const isChecked = status === BannerStatus.ACTIVE

	const onChange = (switchValue: boolean) => {
		onClick(switchValue ? BannerStatus.ACTIVE : BannerStatus.INACTIVE)
	}

	return (
		<Switch
			className='switch-status'
			label=''
			disabled={isLoading}
			isChecked={isChecked}
			onChange={onChange}
		/>
	)
}

function renderBookletStatusSwitchButton({
	booklet,
	status,
	isLoading,
	onClick,
}: {
	booklet: IBooklet
	status: BookletStatus
	isLoading: boolean
	onClick: (newStatus: BookletStatus) => void
}) {
	const isChecked = status === BookletStatus.ACTIVE

	const onChange = (switchValue: boolean) => {
		onClick(switchValue ? BookletStatus.ACTIVE : BookletStatus.INACTIVE)
	}

	const currentDate = new Date()

	const vigor = booklet.vigor

	const validity = booklet.validity

	const switchValue = isWithinInterval(currentDate, {
		start: parseISO(vigor),
		end: parseISO(validity),
	})

	return (
		<Switch
			className='switch-status'
			label=''
			disabled={!switchValue}
			isChecked={switchValue && isChecked}
			onChange={onChange}
		/>
	)
}

function renderSettingsIcon(onClick: () => void): JSX.Element {
	return (
		<div className='icon-wrapper'>
			<button onClick={onClick} className='settings-icon'>
				<img src={SettingsIcon} alt='settings' />
			</button>
		</div>
	)
}

function renderTrashIcon(onClick: () => void): JSX.Element {
	return (
		<div className='icon-wrapper'>
			<button onClick={onClick} className='trash-icon'>
				<img src={TrashIcon} alt='trash' />
			</button>
		</div>
	)
}

export const render = {
	bannerImage: renderBannerImage,
	registerDate: renderRegisterDate,
	createdBy: renderCreatedBy,
	redirectLink: renderRedirectLink,
	statusSwitchButton: renderStatusSwitchButton,
	editButton: renderSettingsIcon,
	deleteButton: renderTrashIcon,
	bookletCoverImage: renderBookletImage,
	bookletStatusSwitch: renderBookletStatusSwitchButton,
	renderBookletImageBackAndFront: renderBookletImageBackAndFront,
	renderDate: renderDate,
}
