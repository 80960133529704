import { createElement, useEffect, useState } from 'react'

import { IProps, IViewProps } from './types'

import CountersView from './view'

import { fetchRetailTotals } from '../../shared/services/retail.service'
import { IRetailTotalsResponse } from 'shared/interfaces/retail.responses'

const UPDATE_INTERVAL = 5000

function RetailCounter(props: IProps): JSX.Element {
	const { user } = props

	const [isLoading, setLoading] = useState(false)
	const [retailsTotals, setRetailsTotals] = useState<IRetailTotalsResponse[]>(
		[],
	)

	async function getRetailsTotals() {
		try {
			const userRetails = await fetchRetailTotals(user._id)
			if (!userRetails.length) return
			setRetailsTotals(userRetails)
		} catch (error) {}
	}

	useEffect(() => {
		if (!user)
			return // Don't remove! This is needed to prevent polling when no user is logged
		;(async () => {
			setLoading(true)
			await getRetailsTotals()
			setLoading(false)
		})()

		const timerId = setInterval(getRetailsTotals, UPDATE_INTERVAL)
		// CODE REVIEWERS: the code below "return () => { ... " cannot be put inside a function,
		// If it is, React does not ever call the arrow, and the Interval
		// is never cleaned up, causing a bug
		return () => {
			clearInterval(timerId)
		}
		// eslint-disable-next-line
	}, [])

	const viewProps: IViewProps = {
		isLoading,
		retailsTotals,
	}

	return createElement(CountersView, viewProps)
}

export default RetailCounter
