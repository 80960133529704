import { createElement, useCallback, useEffect, useState } from 'react'
import { SelectOption } from '@buildbox/components'
import { endOfToday, startOfWeek, formatISO } from 'date-fns'

import { shifts } from 'shared/util/Consts'
import { getDropdownButtonLabelOverride } from 'shared/util/multiSelectCheckboxUtils'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

import { fetchAttendants } from 'shared/services/user.service'
import { feactRatingsDashboardData } from 'shared/services/ratingsDashboardData.service'

import { IFromTo } from 'shared/components/DatePicker/types'
import { IViewProps, IRatingsDashboardData } from './types'

import DashboardServiceReviewsView from './view'
import { useDebounce } from 'use-debounce/lib'

import IconHappy from '../../assets/images/icon-happy.svg'
import IconNeutral from '../../assets/images/icon-neutral.svg'
import IconSad from '../../assets/images/icon-sad.svg'

export const icons = {
	bad: IconSad,
	good: IconNeutral,
	veryGood: IconHappy,
}

function DashboardServiceReviews() {
	const { user } = useTypedSelector(['user'])
	const [loading, setLoading] = useState<boolean>(false)
	const [numberOfRequests, setNumberOfRequests] = useState<number>(0)

	const [retailsOptions, setRetailsOptions] = useState<SelectOption[]>([])
	const [attendantOptions, setAttendantsOptions] = useState<SelectOption[]>([])
	const [selectPeriods, setSelectPeriods] = useState<SelectOption[]>(shifts)
	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfWeek(new Date(), { weekStartsOn: 1 }),
		to: endOfToday(),
	})

	const [isFirstLoad, setIsFirstLoad] = useState(true)

	const [selectedRetails, setSelectedRetails] = useState<SelectOption[]>([])
	const [selectedAttendants, setSelectedAttendants] = useState<SelectOption[]>(
		[],
	)
	const [ratingsData, setRatingsData] = useState<IRatingsDashboardData>()
	const [dataGraph, setDataGraph] = useState<Object[]>([])

	const [datesDebounce] = useDebounce(selectedDates, 1000)
	const [retailsDebounce] = useDebounce(selectedRetails, 1000)
	const [attendantsDebounce] = useDebounce(selectedAttendants, 1000)
	const [periodsDebounce] = useDebounce(selectPeriods, 1000)

	const handleSelectRetails = useCallback((value: SelectOption[]): void => {
		setSelectedRetails(value)
	}, [])

	const handleSelectAttendant = useCallback((value: SelectOption[]): void => {
		setSelectedAttendants(value)
	}, [])

	const handleSelectPeriods = useCallback((value: SelectOption[]): void => {
		setSelectPeriods(value)
	}, [])

	const handleSelectDates = useCallback((value: IFromTo): void => {
		setSelectedDates(value)
	}, [])

	function fetchAllAttendantsOfRetails() {
		;(async () => {
			setNumberOfRequests((s) => s + 1)

			const attendants = await fetchAttendants(
				selectedRetails.map((retail) => retail.value),
			)
			const allAtendants = attendants.map((attendant) => ({
				value: attendant._id,
				label: attendant.name,
			}))
			setAttendantsOptions(allAtendants)

			isFirstLoad && fecthDataFromDashboard()

			if (numberOfRequests <= 1) {
				setSelectedAttendants(allAtendants)
			} else {
				setSelectedAttendants(
					selectedAttendants.filter((selectedAttendant) =>
						allAtendants.find(
							(attendant) => attendant.value === selectedAttendant.value,
						),
					),
				)
			}
		})()
	}

	function insertingRetailInState() {
		if (user.retails) {
			const retailsUser = user.retails.map((retail) => ({
				value: retail._id || '',
				label: retail.name,
			}))
			setRetailsOptions(retailsUser)
			setSelectedRetails(retailsUser)
		}
	}

	function fecthDataFromDashboard() {
		;(async () => {
			try {
				const payload = {
					startDate: formatISO(datesDebounce.from as Date),
					endDate: formatISO(datesDebounce.to as Date),
					attendants: attendantsDebounce.map((attendant) => attendant.value),
					retails: retailsDebounce.map((retail) => retail.value),
					periods: periodsDebounce.map((turn) => turn.value),
				}
				setLoading(true)
				const data = await feactRatingsDashboardData({ ...payload })
				setRatingsData(data)
			} finally {
				setLoading(false)
				setIsFirstLoad(false)
			}
		})()
	}

	function insertDataGraphInTheState() {
		;(() => {
			if (ratingsData?.attendanceAmount) {
				const transformedChartData = ratingsData?.attendanceAmount.map(
					(data) => {
						return {
							corredor: data.aisleName,
							ruim: data.rating.bad,
							ruimColor: '#F67F8F',
							bom: data.rating.good,
							bomColor: '#8A9CDA',
							'muito bom': data.rating.veryGood,
							'muito bom color': '#354D9E',
						}
					},
				)
				setDataGraph(transformedChartData)
			}
		})()
	}

	const selectFilters = [
		{
			label: 'Loja',
			options: retailsOptions,
			isMult: true,
			placeholder: 'Lojas',
			selectOptionState: selectedRetails,
			placeholderButtonLabel: 'Lojas',
			handleChangeSelect: handleSelectRetails,
			noOptionsMessage: () => 'Nenhuma loja para mostrar.',
			getDropdownButtonLabel: getDropdownButtonLabelOverride,
		},
		{
			isMult: true,
			options: attendantOptions,
			label: 'Atendente',
			placeholder: 'atendente',
			selectOptionState: selectedAttendants,
			placeholderButtonLabel: 'atendente',
			handleChangeSelect: handleSelectAttendant,
			getDropdownButtonLabel: getDropdownButtonLabelOverride,
			noOptionsMessage: () => 'Nenhuma atendente para mostrar.',
		},
		{
			isMult: true,
			options: shifts,
			label: 'Período',
			placeholder: 'turno',
			selectOptionState: selectPeriods,
			placeholderButtonLabel: 'turno',
			handleChangeSelect: handleSelectPeriods,
			noOptionsMessage: () => 'Nenhuma turno para mostrar.',
			getDropdownButtonLabel: getDropdownButtonLabelOverride,
		},
	]

	useEffect(insertingRetailInState, [])
	useEffect(fetchAllAttendantsOfRetails, [selectedRetails])

	useEffect(insertDataGraphInTheState, [ratingsData])

	const viewProps: IViewProps = {
		icons,
		loading,
		dataGraph,
		ratingsData,
		selectFilters,
		selectedDates,
		handleSelectDates,
		fecthDataFromDashboard,
	}
	return createElement(DashboardServiceReviewsView, viewProps)
}

export default DashboardServiceReviews
