import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const Container = styled.div`
	/* display: grid; */
	/* grid-template-columns: 1fr 1fr 1fr; */
	display: flex;
	justify-content: center;
	padding: 0 20px;
	margin-top: 25px;

	* {
		${fontType(0)}
	}

	div {
		div {
			display: flex;
			align-items: center;
		}
		h4 {
			margin-bottom: 12px;
			color: #444444;
			font-size: 12px;
			letter-spacing: 0.19px;
		}
		h5 {
			font-size: 24px !important;
			color: #354d9e;
		}
		span {
			margin-left: 8px;
			background-color: ${({ theme }) => theme.colors.lightBlue};
			border-radius: 50%;
			padding: 3px;
			width: 19px;
			height: 19px;
			display: flex;
			cursor: pointer;

			img {
				/* height: 15px; */
			}
		}
	}
`
