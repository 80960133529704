import StoreFlow from 'modules/StoreFlow'
import Login from 'modules/Login'
import ListUser from 'modules/ListUser'
import ListReail from 'modules/ListRetail'
import Board from 'modules/Board'
import Dashboard from 'modules/Dashboard'
import Separation from 'modules/Separation'
import DashboardSeparation from 'modules/DashboardSeparation'
import Profile from 'modules/Profile'
import WithdrawalBoard from 'modules/WithdrawalBoard'
import AppManagement from 'modules/AppManagement'
import PushNotifications from 'modules/PushNotifications'
import QRCodePrinting from 'modules/QRCodePrinting'
import StoreAisles from 'modules/StoreAisles'
import DashboardServiceReviews from 'modules/DashboardServiceReviews'

import routesEnum from './enum'
import { IRouteData } from './types'
import BagdePrinting from 'modules/BadgePrinting'

export const routesData: IRouteData[] = [
	{
		component: Login,
		key: routesEnum.LOGIN,
		name: 'Login',
		path: routesEnum.LOGIN,
		requirePermission: false,
	},
	{
		component: StoreFlow,
		key: routesEnum.SHOPFLOW,
		name: 'Fluxo de Loja',
		path: routesEnum.SHOPFLOW,
		isFull: true,
		submenu: 'PHYSICAL_STORE',
		requirePermission: true,
	},
	{
		component: ListUser,
		key: routesEnum.USER,
		name: 'Usuários',
		path: routesEnum.USER,
		submenu: 'ADMIN',
		requirePermission: true,
	},
	{
		component: Profile,
		key: routesEnum.PROFILE,
		name: 'Perfis de Acesso',
		path: routesEnum.PROFILE,
		isFull: false,
		submenu: 'ADMIN',
		requirePermission: true,
	},
	{
		component: ListReail,
		key: routesEnum.RETAIL,
		name: 'Lojas',
		path: routesEnum.RETAIL,
		submenu: 'ADMIN',
		requirePermission: true,
	},
	{
		component: Board,
		key: routesEnum.ATTENDANCE,
		name: 'Painel de Atendimento',
		path: routesEnum.ATTENDANCE,
		isFull: true,
		submenu: 'DIGITAL_CHANNELS',
		requirePermission: true,
	},
	{
		component: Dashboard,
		key: routesEnum.DASHBOARD_ATTENDANCE,
		name: 'Dashboard de Atendimento',
		path: routesEnum.DASHBOARD_ATTENDANCE,
		isFull: true,
		submenu: 'DIGITAL_CHANNELS',
		requirePermission: true,
	},
	{
		component: Separation,
		key: routesEnum.SEPARATION,
		name: 'Painel de Separação',
		path: routesEnum.SEPARATION,
		submenu: 'DIGITAL_CHANNELS',
		requirePermission: true,
	},
	{
		component: DashboardSeparation,
		key: routesEnum.DASHBOARD_SEPARATION,
		name: 'Dashboard de Separação',
		path: routesEnum.DASHBOARD_SEPARATION,
		isFull: true,
		submenu: 'DIGITAL_CHANNELS',
		requirePermission: true,
	},
	{
		component: WithdrawalBoard,
		key: routesEnum.WITHDRAWAL_BOARD,
		name: 'Painel de Retirada',
		path: routesEnum.WITHDRAWAL_BOARD,
		isFull: true,
		submenu: 'DIGITAL_CHANNELS',
		requirePermission: true,
	},
	{
		component: DashboardServiceReviews,
		key: routesEnum.SERVICES_REVIEWS,
		name: 'Dashboard de Avaliações',
		path: routesEnum.SERVICES_REVIEWS,
		isFull: false,
		submenu: 'APP',
		requirePermission: true,
	},
	{
		component: AppManagement,
		key: routesEnum.APP_MANAGEMENT,
		name: 'Gestão',
		path: routesEnum.APP_MANAGEMENT,
		isFull: false,
		submenu: 'APP',
		requirePermission: true,
	},
	{
		component: StoreAisles,
		key: routesEnum.STORE_AISLES,
		name: 'Áreas de Atendimento',
		path: routesEnum.STORE_AISLES,
		isFull: false,
		submenu: 'APP',
		requirePermission: true,
	},
	{
		component: PushNotifications,
		key: routesEnum.PUSH_NOTIFICATIONS,
		name: 'Pushs',
		path: routesEnum.PUSH_NOTIFICATIONS,
		isFull: false,
		submenu: 'APP',
		requirePermission: true,
	},
	{
		component: BagdePrinting,
		key: routesEnum.BADGE_PRINTING,
		name: 'Impressão de crachás',
		path: routesEnum.BADGE_PRINTING,
		isFull: false,
		requirePermission: false,
	},
	{
		component: QRCodePrinting,
		key: routesEnum.QRCODE_PRINTING,
		name: 'Impressão do QRCode',
		path: routesEnum.QRCODE_PRINTING,
		isFull: false,
		requirePermission: true,
	},
]
