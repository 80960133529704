import { createElement, useMemo, useState, useEffect, ChangeEvent } from 'react'
import { fromUnixTime } from 'date-fns'
import { sortDate } from 'shared/util/date'
import { IProps, IViewProps, ITable } from './types'
import LogHistoryView from './view'
import { ICounterLog } from 'shared/interfaces/counterLog'

function LogHistory(props: IProps): JSX.Element {
	const { counterLogs, navProps, pageSize, goToPage } = props

	// ------------------------------ STATE ---------------------------------
	const [tableData, setTableData] = useState<ITable[]>([]) // Saves data to render
	const [searchTable, setSearchTable] = useState<string>('')

	const columns = useMemo(
		() => [
			{
				Header: 'Data Hora',
				accessor: 'dateAndTime',
				sortType: sortDate('dateAndTime'),
			},
			{ Header: 'Operador', accessor: 'operatorName', sortType: 'basic' },
			{ Header: 'Operação', accessor: 'operation', sortType: 'basic' },
			{ Header: 'Loja', accessor: 'retailName', sortType: 'basic' },
			{
				Header: 'Capacidade',
				accessor: 'capacity',
				sortType: 'basic',
			},
			{
				Header: 'Lotação (%)',
				accessor: 'percentage',
				sortType: 'basic',
			},
		],
		[],
	)

	// ---------------------------- FUNCTIONS -------------------------------
	// Fetches log history from backend
	function setupLogsTable() {
		const table: ITable[] = backendLogsToTable(counterLogs.pageContent)
		setTableData(table)
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target

		setSearchTable(value)
	}

	function unixToString(timestamp: number): string {
		const dataAndTime = fromUnixTime(timestamp)
		//return format(timestamp, 'dd/MM/yy hh:mm:ss', {
		//	locale: BR
		//})
		return dataAndTime.toLocaleString()
	}

	// Converts logs from backend format to table format
	function backendLogsToTable(dbData: ICounterLog[]): ITable[] {
		if (dbData) {
			const table: ITable[] = dbData.map((log) => {
				return {
					dateAndTime: unixToString(log.timestamp),
					operatorName: log.operatorName,
					operation: operationToPortuguese(log.operation),
					retailName: log.retailName,
					capacity: countToCapacity(
						log.occupancyRatio.count,
						log.occupancyRatio.maxOccupancy,
					),
					percentage: log.occupancyRatio.occupancyPercentage,
				}
			})

			return table
		}

		return []
	}

	function operationToPortuguese(operEnglish: string): string {
		if (operEnglish === 'ENTRY') return 'ENTRADA'
		if (operEnglish === 'EXIT') return 'SAÍDA'
		return '-'
	}

	function setPageIndex(): void {
		goToPage(Number(searchTable) - 1)
	}

	function countToCapacity(count: number, maxOccupancy: number): string {
		return ' ' + count + '/' + maxOccupancy
	}

	useEffect(() => {
		setupLogsTable()
		// eslint-disable-next-line
	}, [counterLogs])

	const viewProps: IViewProps = {
		tableData,
		columns,
		navProps,
		counterLogs,
		pageSize,
		handleChange,
		searchTable,
		setPageIndex,
	}

	return createElement(LogHistoryView, viewProps)
}

export default LogHistory
