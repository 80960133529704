import styled from 'styled-components'
import { fontType } from 'shared/styles/functions'

export const Container = styled.div`
    & * {
        ${fontType(0)};
    }
    
    .icon-wrapper {
        min-width: 20px;

        button {
            background: none;
            border: none;
        }

        button:hover {
            cursor: pointer;
        }
    }
`

export const Header = styled.div`
    .title-and-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & > div:nth-child(2) {
            min-width: 200px;
        }
    }

    .button-create {
        width: 100%;
        margin-top: 48px;

        display: flex;
        justify-content: flex-end;

        button {
            width: 200px;
        }
    }
`

export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
