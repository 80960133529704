import styled from 'styled-components'

export const RevenueCardWrapper = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #359e81;

	padding: 20px 20px 18px;
	min-width: 150px;

	h3 {
		font-size: 12px;
	}

	.value {
		font-size: 20px;
		color: #359e81;
	}

	.percent {
		font-size: 9px;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`
