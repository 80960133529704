import React from 'react'

import { HeaderWrapper } from './styles'
import { IProps } from './types'

export default function Header({
	isOpen,
	handleSetIsOpen,
	user,
}: IProps): JSX.Element {
	const { name } = user

	const firstName = name.split(' ')[0]

	return (
		<HeaderWrapper className={isOpen ? 'active' : ''}>
			<div className='menu-wrapper' onClick={handleSetIsOpen}>
				<div className='menu'>
					<span className='line01'></span>
					<span className='line02'></span>
					<span className='line03'></span>
				</div>
			</div>

			<div className='higa-logo'>
				<img
					src={require('../../../assets/images/logo-higa-header.png')}
					alt='Higa Logo'
				/>
			</div>

			<div className='profile'>
				<div className='info'>
					<p className='name'>Olá, {firstName}</p>
				</div>

				{/* TODO: Add User Image */}
				{/* <div className='photo'>
					<img alt='Profile' />
				</div> */}
			</div>
		</HeaderWrapper>
	)
}
