import React from 'react'
import Loading from 'shared/components/Loading'
import {
	DashboardWrapper,
	DashboardHeader,
	Label,
	FilterWrapper,
	BottomPartWrapper,
	GraphWrapper,
	TableWrapper,
	DatePickerWrapper,
	SelectWrapper,
} from './styles'
import { IViewProps } from './types'
import RetailVisitsGraph from '../RetailVisitsGraph'
import RetailCounter from '../RetailCounter'
import LogHistory from '../LogHistory'
import DatePicker from 'shared/components/DatePicker'
import { Select } from '@buildbox/components'
import { primarySelectStyles } from 'shared/styles/theme'

function StoreFlowView(props: IViewProps): JSX.Element {
	const {
		isLoading,
		user,
		counterLogs,
		selectedDates,
		handleSetDates,
		navProps,
		counterPerPage,
		goToPage,
		selectedRetails,
		handleChangeSelectRetails,
		retailOptions,
		viewMode,
		setViewMode,
	} = props

	return (
		<DashboardWrapper>
			<DashboardHeader>Em tempo real</DashboardHeader>
			<RetailCounter user={user} />
			<DashboardHeader>Dashboard</DashboardHeader>
			<FilterWrapper>
				<DatePickerWrapper>
					<Label>Período</Label>

					<DatePicker
						dateSetter={handleSetDates}
						value={selectedDates}
						datePickerTitle={'Seleção de Período'}
					/>
				</DatePickerWrapper>

				<SelectWrapper>
					<Label>Lojas</Label>

					<Select
						{...primarySelectStyles}
						placeholder='Selecione as lojas'
						options={retailOptions}
						round
						id='retails'
						onChange={handleChangeSelectRetails}
						value={selectedRetails}
						isMulti
						isLoading={isLoading}
						isDisabled={isLoading}
					/>
				</SelectWrapper>
			</FilterWrapper>

			<BottomPartWrapper>
				<GraphWrapper>
					{isLoading ? (
						<Loading />
					) : (
						<RetailVisitsGraph
							selectedRetails={
								selectedRetails ? selectedRetails.map((r) => r.value) : []
							}
							selectedDates={selectedDates}
							handleSetDates={handleSetDates}
							viewMode={viewMode}
							setViewMode={setViewMode}
						/>
					)}
				</GraphWrapper>

				<TableWrapper>
					{isLoading ? (
						<Loading />
					) : (
						<LogHistory
							pageSize={counterPerPage}
							navProps={navProps}
							counterLogs={counterLogs}
							goToPage={goToPage}
						/>
					)}
				</TableWrapper>
			</BottomPartWrapper>
		</DashboardWrapper>
	)
}

export default StoreFlowView
