import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { theme } from '../../styles/theme'
import { toRem } from '../../../shared/styles/functions'
import { GraphContainer } from './styles'
import { IResponsiveGraphProps } from './types'
import { VisionTypeOptions } from 'shared/interfaces/ticket'
import { toMoney } from 'shared/util/currency'

function ResponsiveBarGraphForSeparationDash(props: IResponsiveGraphProps) {
	const { data, tooltipData, graph, vision, visionType } = props

	const containerTooltip = {
		background: theme.colors.darkGrey,
		color: 'white',
		borderRadius: 4,
		fontSize: toRem(12),
		padding: '16px',
	}

	const themeConfig = {
		tooltip: { container: containerTooltip },
		fontFamily: 'Montserrat Regular',
		legends: {
			text: {
				fontFamily: 'Montserrat Regular',
			},
		},
		grid: {
			line: {
				stroke: theme.colors.graphSecondaryColor,
			},
		},
		axis: {
			ticks: {
				line: {
					stroke: theme.colors.graphSecondaryColor,
				},
			},
		},
	}

	function handleLabel(label: number) {
		if (graph === 'CHANNEL') {
			return Math.floor(Number(label)) === label ? label : ''
		}
		return label
	}

	function formatLabel(label: string | number) {
		return (
			visionType === 'VOLUME' &&
			graph === 'TEAM' &&
			vision === VisionTypeOptions.REVENUE
		) ? toMoney(label as number) : label
	}

	const currentGraph = graph === 'CHANNEL' && vision === 'REVENUE'

	return (
		<GraphContainer>
			<ResponsiveBar
				data={data}
				keys={
					visionType === 'AVERAGE_TIME' && graph === 'TEAM'
						? ['averageTime']
						: ['total']
				}
				indexBy={graph === 'TEAM' ? 'abbreviatedName' : 'name'}
				groupMode='grouped'
				colors={['#354D9E']}
				margin={{
					top: 20,
					right: 20,
					bottom: graph === 'TEAM' ? 50 : 30,
					left: graph === 'CHANNEL' ? 50 : 100,
				}}
				padding={graph === 'TEAM' ? 0.2 : 0.7}
				borderRadius={5}
				layout={graph === 'TEAM' ? 'horizontal' : 'vertical'}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				theme={themeConfig}
				tooltip={tooltipData}
				axisLeft={{
					tickSize: 0,
					tickPadding: 7,
					format: (e) => handleLabel(e as number),
				}}
				axisRight={null}
				axisBottom={graph === 'TEAM' ? {
					legendPosition: 'middle',
					legendOffset: 40,
					legend: visionType === 'AVERAGE_TIME' ? 'Min.' : 'Qnt.',
					format: (e) => Math.floor(Number(e)) === e ? e : '',
				} : {}}
				labelSkipWidth={graph === 'TEAM' ? 0 : 12}
				labelSkipHeight={12}
				labelTextColor='white'
				labelFormat={formatLabel}
				enableLabel={!currentGraph}
				animate={true}
				motionStiffness={90}
				motionDamping={15}
			/>
		</GraphContainer>
	)
}

export default ResponsiveBarGraphForSeparationDash
