import { SelectOption } from '@buildbox/components'
import { ISelectOption } from '@buildbox/components/lib/components/Select/types'
import {
	endOfDay,
	formatISO,
	startOfDay,
} from 'date-fns'
import { createElement, useCallback, useEffect, useState } from 'react'
import { IFromTo } from 'shared/components/DatePicker/types'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import {
	ChannelTypeEnum,
	IOpenedApprovedAverageDash,
	ITotalRevenueAverageDash,
	VisionTypeOptions,
	VisionTypeEnum,
	IDigitalSeparationDashboardData,
	TicketSeparationStatusOptions,
	TicketSeparationStatusEnum
} from 'shared/interfaces/ticket'
import {
	exportTicketService,
	fetchChannelsSeparationDashboard,
} from 'shared/services/ticket.service'
import { roundAverageValues } from 'shared/util/dashboardUtil'
import { useDebounce } from 'use-debounce/lib'
import {
	IDigitalTeamGraphVisionOptions,
	IDigitalVisionEnum,
	IFilterData,
	IFilterValue,
	IModalIsActiveProps,
	IViewProps,
} from './types'
import {
	initialFetch,
	MODAL_IS_ACTIVE,
	FILTER_DATA,
	FILTER_VALUE,
} from './util'
import DashboardSeparation from './view'

function DashboardSeparationContainer(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const [isLoading, setIsLoading] = useState(true)
	const [isEmpty, setIsEmpty] = useState(true)
	const [filtersValue, setFilterValue] =
		useState<IFilterValue>(FILTER_VALUE)
	const [dataDebounce] = useDebounce(filtersValue, 1000)
	const [filtersData, setFilterData] = useState<IFilterData>(FILTER_DATA)
	const [dashBoardMetrics, setDashboardMetrics] = useState(
		{} as IDigitalSeparationDashboardData,
	)
	const [modalIsActives, setModalIsActives] =
		useState<IModalIsActiveProps>(MODAL_IS_ACTIVE)

	const [teamGraphVision, setTeamGraphVision] = useState<IDigitalVisionEnum>(
		IDigitalTeamGraphVisionOptions.VOLUME,
	)

	function fetchData() {
		; (async () => {
			const retailOptions = user.retails.map(userRetail => ({
				value: String(userRetail._id),
				label: userRetail.name
			}))

			const fetchResult = await initialFetch(
				user.retails.map(retail => String(retail._id))
			)

			const professionals = [
				...fetchResult.professionalData.separators,
				...fetchResult.professionalData.checkers,
			]

			setFilterData((state) => ({
				...state,
				customerOptions: fetchResult.customerOptions,
				professionalData: {
					separators: fetchResult.professionalData.separators,
					checkers: fetchResult.professionalData.checkers,
				},
				segmentOptions: fetchResult.segmentOptions,
				channelOptions: fetchResult.channelOptions,
				visionOptions: fetchResult.visionOptions,
				stepsOptions: fetchResult.stepsOptions,
				professionalDataToUse: professionals,
				retailOptions: retailOptions,
			}))

			setFilterValue((state) => ({
				...state,
				customerValue: fetchResult.customerOptions,
				professionalValue: professionals,
				segmentValue: fetchResult.segmentOptions,
				channelValue: fetchResult.channelOptions,
				retailValue: retailOptions,
			}))

			getSetMetrics()
			setIsLoading(false)
		})()
	}

	function handleFilterOptions() {
		const stages = getSelectedStageValues(filtersValue.stepsValue)
		let professionals: ISelectOption[] = []

		if (
			!stages.includes(TicketSeparationStatusOptions.SEPARATION_ON_SEPARATION) &&
			!stages.includes(TicketSeparationStatusOptions.SEPARATION_ON_CHECKING)
		) {
			setFilterData((state) => ({
				...state,
				professionalDataToUse: [],
			}))
			setFilterValue((state) => ({
				...state,
				professionalValue: [],
			}))
			return
		}

		if (
			stages.includes(TicketSeparationStatusOptions.SEPARATION_ON_SEPARATION) ||
			stages.includes(TicketSeparationStatusOptions.SEPARATION_SEPARATED)
		) {
			professionals.push(
				...filtersData.professionalData.separators.filter(separator => {
					return !professionals.some(professional => professional.value === separator.value)
				})
			)
		}
		if (
			stages.includes(TicketSeparationStatusOptions.SEPARATION_ON_CHECKING) ||
			stages.includes(TicketSeparationStatusOptions.SEPARATION_CHECKED)
		) {
			professionals.push(
				...filtersData.professionalData.checkers.filter(checker => {
					return !professionals.some(professional => professional.value === checker.value)
				})
			)
		}

		setFilterData((state) => ({
			...state,
			professionalDataToUse: professionals,
		}))
		setFilterValue((state) => ({
			...state,
			professionalValue: professionals,
		}))
	}

	function verifyIfDashIsEmpty() {
		if (!!!dashBoardMetrics.channelDash) return

		const channelIsEmpty = dashBoardMetrics.channelDash.every(
			(e) => e.total === 0,
		)
		const clientslIsEmpty = dashBoardMetrics.clientsProfileDash.every(
			(e) => e.total === 0,
		)
		const performanceIsEmpty = true

		const metricsTotalRevenue =
			dashBoardMetrics.totalRevenueAverageDash as ITotalRevenueAverageDash

		const teamDashIsEmpty = dashBoardMetrics.teamDash.every(
			(e) => e.total === 0,
		)

		const timesDashIsEmpty =
			dashBoardMetrics.timesDash.timesAttendingDash
				.averageTotalAttendanceTime === 0

		const timesDashPerformanceIsEmpty =
			dashBoardMetrics.timesDash.timesSeparationDash
				.averageTotalAttendanceTime === 0

		const totalRevenueAverageDashIsEmpty = metricsTotalRevenue.totalUnits === 0
		const allEmpty =
			channelIsEmpty &&
			clientslIsEmpty &&
			performanceIsEmpty &&
			teamDashIsEmpty &&
			timesDashIsEmpty &&
			totalRevenueAverageDashIsEmpty &&
			timesDashIsEmpty &&
			timesDashPerformanceIsEmpty

		setIsEmpty(allEmpty)
	}

	function exportTicket() {
		const startDate = formatISO(startOfDay(filtersValue.dateRange.from as Date))
		const endDate = formatISO(endOfDay(filtersValue.dateRange.to as Date))
		const channels = filtersValue.channelValue.map((x) => x.value)
		const customers = filtersValue.customerValue.map((x) => x.value)
		const segments = filtersValue.segmentValue.map((x) => x.value)
		const professionals = filtersValue.professionalValue.map((x) => x.value)
		const retails = filtersValue.retailValue.map((x) => x.value)

		exportTicketService({
			startDate,
			endDate,
			professionals,
			clients: customers,
			segments: segments,
			channels: channels as ChannelTypeEnum[],
			vision: filtersValue.visionValue.value as VisionTypeEnum,
			dashboardData: 'separation',
			separationStages: getSelectedStageValues(filtersValue.stepsValue),
			retails: retails
		})
	}

	function getSetMetrics() {
		; (async () => {
			setIsLoading(true)
			const startDate = formatISO(
				startOfDay(filtersValue.dateRange.from as Date),
			)
			const endDate = formatISO(endOfDay(filtersValue.dateRange.to as Date))
			const channels = filtersValue.channelValue.map((x) => x.value)
			const customers = filtersValue.customerValue.map((x) => x.value)
			const segments = filtersValue.segmentValue.map((x) => x.value)
			const professionals = filtersValue.professionalValue.map((x) => x.value)
			const retails = filtersValue.retailValue.map((x) => x.value)

			const {
				totalRevenueAverageDash,
				channelDash,
				teamDash,
				performanceDash,
				clientsProfileDash,
				timesDash,
			} = await fetchChannelsSeparationDashboard({
				startDate,
				endDate,
				professionals,
				clients: customers,
				segments: segments,
				channels: channels as ChannelTypeEnum[],
				retails: retails,
				vision: filtersValue.visionValue?.value as VisionTypeEnum,
				separationStages: getSelectedStageValues(filtersValue.stepsValue),
				TeamGraphVision: filtersValue.teamGraphOption,
			})

			if (filtersValue.visionValue?.value === VisionTypeOptions.REVENUE) {
				const dash = totalRevenueAverageDash as IOpenedApprovedAverageDash
				totalRevenueAverageDash.averages = roundAverageValues({
					averagePerChannel: dash.averages.averageRevenuePerChannel,
					averagePerAttendant: dash.averages.averageRevenuePerAttendant,
					averagePerItem: dash.averages.averageRevenuePerItem,
					averagePerSku: dash.averages.averageRevenuePerSku,
				})
			} else {
				const dash = totalRevenueAverageDash as ITotalRevenueAverageDash
				totalRevenueAverageDash.averages = roundAverageValues({
					averagePerChannel: dash.averages.averageUnitsPerChannel,
					averagePerAttendant: dash.averages.averageUnitsPerAttendant,
					averagePerItem: dash.averages.averageItemsPerUnit,
					averagePerSku: dash.averages.averageSkusPerUnit,
				})
			}

			setDashboardMetrics({
				channelDash,
				clientsProfileDash,
				performanceDash,
				teamDash,
				timesDash,
				totalRevenueAverageDash,
			})

			setIsLoading(false)
		})()
	}

	function handleSetDates(value: IFromTo) {
		setFilterValue((state) => ({ ...state, dateRange: value }))
	}

	function handleProfessionalSelect(value: SelectOption[]): void {
		setFilterValue((state) => ({
			...state,
			professionalValue: value,
		}))
	}

	function handleSegment(value: SelectOption[]) {
		setFilterValue((state) => ({
			...state,
			segmentValue: value,
		}))
	}

	function handleCustomerClient(value: SelectOption[]) {
		setFilterValue((state) => ({
			...state,
			customerValue: value,
		}))
	}

	function handleChannel(value: SelectOption[]) {
		setFilterValue((state) => ({
			...state,
			channelValue: value,
		}))
	}

	function handleVisionOption(value: SelectOption) {
		setFilterValue((state) => ({
			...state,
			visionValue: value,
		}))
	}

	function handleSelectSteps(value: ISelectOption[]) {
		setFilterValue((state) => ({
			...state,
			stepsValue: value,
		}))
	}

	function handleChannelModalGraph() {
		setModalIsActives((state) => ({
			...state,
			channelGraph: !state.channelGraph,
		}))
	}

	function handleTeamModalGraph() {
		setModalIsActives((state) => ({
			...state,
			teamGraph: !state.teamGraph,
		}))
	}

	function handlePerformanceModal() {
		setModalIsActives((state) => ({
			...state,
			performanceGraph: !state.performanceGraph,
		}))
	}

	function handleClientProfileGraph() {
		setModalIsActives((state) => ({
			...state,
			clientProfileGraph: !state.clientProfileGraph,
		}))
	}

	function handleTeamGraphOptions(option: IDigitalVisionEnum) {
		setTeamGraphVision(option)
	}

	const handleRetailSelect = useCallback((value: SelectOption[]) => {
		setFilterValue((state) => ({
			...state,
			retailValue: value,
		}))
	}, [])

	function getSelectedStageValues(selectedStages: ISelectOption[]) {
		return selectedStages.map(stage => stage.value as TicketSeparationStatusEnum)
	}

	useEffect(handleFilterOptions, [
		filtersData.professionalData.checkers,
		filtersData.professionalData.separators,
		filtersValue.stepsValue,
	])

	useEffect(getSetMetrics, [dataDebounce])
	useEffect(verifyIfDashIsEmpty, [dashBoardMetrics])
	useEffect(fetchData, [])

	const viewProps: IViewProps = {
		filtersData,
		isLoading,
		dashBoardMetrics,
		modalIsActives,
		isEmpty,
		filtersValue,
		handleSetDates,
		handleProfessionalSelect,
		handleSegment,
		handleCustomerClient,
		handleChannel,
		handleVisionOption,
		handleSelectSteps,
		handleChannelModalGraph,
		handleTeamModalGraph,
		handlePerformanceModal,
		handleClientProfileGraph,
		exportTicket,
		handleTeamGraphOptions,
		teamGraphVision,
		handleRetailSelect,
	}

	return createElement(DashboardSeparation, viewProps)
}

export default DashboardSeparationContainer
