import React, { Fragment } from 'react'
import LateScheduleTicketsBoard from 'modules/LateScheduleTicketsBoard'
import {
	HeaderButton,
	HeaderRow,
	ContentWrapper,
	ModalWrapper,
	InputWrapper,
	HeaderColumnWrapper
} from './styles'
import SettingsIcon from '../../assets/images/blueSettings.svg'
import { IViewProps } from './types'
import SettingsModal from './SettingsModal'
import PendingForApprovalLabel from './PendingForApprovalLabel'
import SeparationDrawerBoard from 'modules/SeparationDrawerBoard'
import Loading from 'shared/components/Loading'
import ModalSeparationCards from 'modules/ModalTicketSeparation'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import SecondaryInput from 'shared/components/SecondaryInput'
import { primaryInputSeparationTicketStyles } from 'shared/styles/theme'
import { Column } from 'shared/styles'
import { Select } from '@buildbox/components'
import { ISeparationParams } from 'shared/interfaces/SeparationParams'

function SeparationView(props: IViewProps): JSX.Element {
	const {
		handleToggle,
		modalActive,
		params,
		scheduleDaysList,
		isLoading,
		activateModalTicket,
		modalAction,
		showModalTicket,
		currentTicket,
		handleModal,
		lateTickets,
		pendingForApprovalItems,
		fetchParams,
		showPlaceholder,
		currentTicketWithdrawalDate,
		searchString,
		handleSearch,
		handleChangeSearchString,
		handleClearSearchInput,
		headerColumnsSize,
		handleRetailsSelect,
		retailOptions,
		selectedRetails
	} = props

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<HeaderRow>
						<Column
							sm={headerColumnsSize.searchInput}
							md={headerColumnsSize.searchInput}
							lg={headerColumnsSize.searchInput}
						>
							<InputWrapper>
								<SecondaryInput
									label='Buscar por Pedidos'
									round
									id='search-input'
									onChange={handleChangeSearchString}
									value={searchString}
									handleInputClick={handleClearSearchInput}
									handleSearch={handleSearch}
								/>
							</InputWrapper>
						</Column>
						<Column
							sm={headerColumnsSize.retailsSelect}
							md={headerColumnsSize.retailsSelect}
							lg={headerColumnsSize.retailsSelect}
						>
							{retailOptions.length > 1 && (
								<HeaderColumnWrapper>
									<div style={{ width: '100%' }}>
										<Select
											round
											placeholder='Definir Lojas'
											{...primaryInputSeparationTicketStyles}
											id='retails'
											options={retailOptions}
											value={selectedRetails}
											onChange={handleRetailsSelect}
											isMulti
											disabled={true}
										/>
									</div>
								</HeaderColumnWrapper>
							)}
						</Column>
						<Column
							sm={headerColumnsSize.settingsIcon}
							md={headerColumnsSize.settingsIcon}
							lg={headerColumnsSize.settingsIcon}
						>
							<HeaderColumnWrapper>
								<HeaderButton onClick={handleToggle}>
									<img src={SettingsIcon} alt='settings-icon' />
								</HeaderButton>
							</HeaderColumnWrapper>
						</Column>
					</HeaderRow>
					{showPlaceholder ? (
						<DefaultPagePlaceholder text='Nenhum dado a exibir.' />
					) : (
						<Fragment>

							{!!lateTickets.length && (
								<LateScheduleTicketsBoard
									data={lateTickets}
									activateModalTicket={activateModalTicket}
									fetchParams={fetchParams}
								/>
							)}

							<PendingForApprovalLabel data={pendingForApprovalItems} />

							<ContentWrapper>
								{scheduleDaysList.map((item, index) => (
									<SeparationDrawerBoard
										key={Math.random() + index}
										scheduleDate={item}
										selectedRetailsInThePageFilter={selectedRetails}
										activateModalTicket={activateModalTicket}
										fetchParams={fetchParams}
									/>
								))}
							</ContentWrapper>
						</Fragment>
					)}
				</>
			)}

			{showModalTicket && (
				<ModalWrapper>
					<ModalSeparationCards
						modalAction={modalAction}
						active={showModalTicket}
						className='modal'
						handleModal={handleModal}
						currentTicket={currentTicket}
						withdrawalDate={currentTicketWithdrawalDate}
						retailSeparationParams={params.find(param => param.retail === currentTicket.retail) as ISeparationParams}
					/>
				</ModalWrapper>
			)}

			{!!selectedRetails.length && (
				<SettingsModal
					isActive={modalActive}
					handleToggle={handleToggle}
					selectedRetailsInThePageFilter={selectedRetails}
					params={params}
				/>
			)}
		</>
	)
}

export default SeparationView
