import ListTicket from 'modules/ListTicket'
import React, { Fragment } from 'react'
import Loading from 'shared/components/Loading'
import SecondaryInput from 'shared/components/SecondaryInput'
import ModalTicket from 'modules/ModalTicketWithdrawal'

import {
	WithdrawalBoardWrapper,
	Header,
	InputWrapper,
	ContainerListCards,
	CardPanelWrapper,
} from './styles'
import { IViewProps } from './types'

import { columnType } from './util'

import CardPanel from 'shared/components/CardPanel'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { Select } from '@buildbox/components'
import { primaryInputSeparationTicketStyles } from 'shared/styles/theme'

function WithdrawalBoard(props: IViewProps) {
	const {
		cardListData,
		searchString,
		handleChange,
		handleSearch,
		loading,
		otherTickets,
		isOtherTicketsEmpty,
		activateModalTicket,
		showModalDetails,
		selectedTicket,
		closeModal,
		hasTicketsToShow,
		handleInputClick,
		handleRetailsSelect,
		retailOptions,
		selectedRetails
	} = props

	return (
		<WithdrawalBoardWrapper>
			<Header>
				<InputWrapper>
					<SecondaryInput
						label='Buscar por Pedidos'
						round
						id='search-input'
						onChange={handleChange}
						value={searchString}
						handleInputClick={handleInputClick}
						handleSearch={handleSearch}
					/>
				</InputWrapper>
				{retailOptions.length > 1 && (
					<Select
						round
						placeholder='Definir Lojas'
						{...primaryInputSeparationTicketStyles}
						id='retails'
						options={retailOptions}
						value={selectedRetails}
						onChange={handleRetailsSelect}
						isMulti
						disabled={true}
					/>
				)}
			</Header>

			{!loading ? (
				<Fragment>
					{hasTicketsToShow ? (
						<Fragment>
							{isOtherTicketsEmpty && (
								<CardPanelWrapper>
									<CardPanel
										label='Tickets em outros Painéis'
										data={otherTickets}
									/>
								</CardPanelWrapper>
							)}
							<ContainerListCards>
								{columnType.map((status, index) => (
									<ListTicket
										key={index}
										title={status}
										cards={cardListData[index]}
										activateModalTicket={activateModalTicket}
										isWithdrawal={true}
									/>
								))}
							</ContainerListCards>
						</Fragment>
					) : (
						<DefaultPagePlaceholder text='Nenhum dado a exibir.' />
					)}
				</Fragment>
			) : (
				<Loading />
			)}
			{showModalDetails && selectedTicket && (
				<ModalTicket
					className='modal'
					closeModal={closeModal}
					active={showModalDetails}
					selectedTicket={selectedTicket}
				/>
			)}
		</WithdrawalBoardWrapper>
	)
}

export default WithdrawalBoard
