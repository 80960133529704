import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { theme } from '../../styles/theme'
import { toRem } from '../../../shared/styles/functions'
import { GraphContainer } from './styles'
import { IProps } from './types'
import { toMoney } from 'shared/util/currency'
import { VisionTypeOptions } from 'shared/interfaces/ticket'

function BarGraph(props: IProps) {
	const { data, tooltipData, graph, vision } = props

	const containerTooltip = {
		background: theme.colors.darkGrey,
		color: 'white',
		borderRadius: 4,

		fontSize: toRem(12),
		padding: '16px',
	}

	function handleMoneyValue(label: number) {
		return vision === VisionTypeOptions.REVENUE ? toMoney(label) : label
	}

	function handleLabel(str: number) {
		return graph === 'TEAM' ? str : Math.floor(Number(str)) === str ? str : ''
	}

	const attendances = graph === 'TEAM' ? data.length : 0

	const currentGraph = graph === 'CHANNEL' && vision === 'REVENUE'

	return (
		<GraphContainer items={attendances} graph={graph}>
			<ResponsiveBar
				data={data}
				keys={['totalApproved', 'totalOpened']}
				indexBy={graph === 'TEAM' ? 'abbreviatedName' : 'name'}
				groupMode='grouped'
				padding={0.2}
				colors={['#354D9E', '#A2B3EB']}
				margin={{
					top: 20,
					right: 40,
					bottom: 30,
					left: graph === 'TEAM' ? 90 : 68,
				}}
				borderRadius={5}
				layout={graph === 'TEAM' ? 'horizontal' : 'vertical'}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				theme={{
					fontFamily: 'Montserrat Regular',
					tooltip: { container: containerTooltip },
					labels: {
						text: {
							fontFamily: 'Montserrat Regular',
						},
					},
				}}
				tooltip={tooltipData && tooltipData}
				axisLeft={{
					tickSize: 0,
					tickPadding: 7,
					format: (e) => handleLabel(e as number),
				}}
				enableLabel={currentGraph ? false : true}
				labelSkipWidth={graph === 'TEAM' ? 0 : 30}
				labelSkipHeight={12}
				axisRight={null}
				labelFormat={(label) => handleMoneyValue(label as number)}
				axisBottom={{}}
				// enableLabel={false}
				labelTextColor='white'
				animate={true}
				motionStiffness={90}
				motionDamping={15}
			/>
		</GraphContainer>
	)
}

export default BarGraph
