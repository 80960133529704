import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const InputSearchWrapper = styled.div`
	position: relative;
	width: 100%;
	min-width: 283px;

	#search-input {
		height: 41px;
	}
`

export const InputSearchContainer = styled.div`
	display: flex;
	align-items: center;
`

export const ButtonWrapper = styled.div`
	margin-left: 20px;
`

export const Button = styled.button`
	display: flex;
	width: 107px;
	height: 41px;
	justify-content: center;
	align-items: center;

	border-radius: 29px;

	border: none;

	background-color: #354d9e;
	
	cursor: pointer;
	
	font-size: 12px;
	color: #ffffff;
	${fontType(0)};
	
	&:hover {
		opacity: 0.8;
	}
	
	:disabled {
		background-color: #233f9e;

	}
`
