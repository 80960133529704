import { AxiosResponse } from 'axios'
import { getUnixTime } from 'date-fns'
import axiosInstance from './axiosInstance'
import {
	ITicket,
	IDigitalDashboardData,
	IScheduleDateOption
} from 'shared/interfaces/ticket'
import { DEFAULT_VALUE_OF_AMOUNT_SCHEDULE_DATE_OPTIONS } from 'shared/util/Consts'
import { IGetPendingFitResponse } from 'shared/interfaces/separation'

export async function separationDateOptions({ searchString, retails }: {
	searchString: string
	retails: string[]
}): Promise<IScheduleDateOption[][]> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-schedules/get-page?searchString=${searchString}`,
			{
				retails
			}
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function fetchTicketsWithLateSchedules({ searchString, retails }: {
	searchString: string
	retails: string[]
}): Promise<ITicket[]> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-schedules/late-schedules?searchString=${searchString}`,
			{
				retails
			}
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function fetchRetailSeparationDateOptions(retailId: string) {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-schedules/list/scheduling-options`,
			{
				retailId: retailId,
				referenceDatetime: getUnixTime(new Date()),
				quantityItems: DEFAULT_VALUE_OF_AMOUNT_SCHEDULE_DATE_OPTIONS,
			},
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function getSeparationScheduleSuggestion(ticket: ITicket) {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-schedules/scheduling-suggestion/`,
			{
				ticketId: ticket._id,
				retailId: ticket.retail,
				referenceDatetime: getUnixTime(new Date()),
			},
		)

		return response.data.content
	} catch (error) {
		const err = error as any

		const retailParamsNotFound =
			err?.response?.data?.message?.includes('Parâmetros de separação da loja não encontrados')
		
		if (retailParamsNotFound) {
			return { retailParamsNotFound }
		}
	}
}

export async function getOrderWithdrawalDate(ticket: ITicket) {
	try {
		const response: AxiosResponse = await axiosInstance.get(
			`/separation-schedules/get-order-withdrawal-date/${ticket._id}/${ticket.retail}`,
		)

		return response.data.content
	} catch (error) {
		const err = error as any

		const retailParamsNotFound =
			err?.response?.data?.message?.includes('Parâmetros de separação da loja não encontrados')
		
		if (retailParamsNotFound) {
			return { retailParamsNotFound }
		}
	}
}

export async function tryRescheduleTicket({
	retailId,
	ticketId,
	date,
	period
}: {
	retailId: string
	ticketId: string
	date: number
	period: string
}) {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-schedules/try-reschedule-ticket/${ticketId}/${retailId}`,
			{
				date,
				period,
			},
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function getPendingFit({ searchString, retails }: {
	searchString: string
	retails: string[]
}): Promise<IGetPendingFitResponse[]> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-schedules/pending-fit?searchString=${searchString}`,
			{
				retails
			}
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}

export async function declineOrApprovePluginRequest(
	ticket: ITicket,
	action: 'approve' | 'decline',
): Promise<IDigitalDashboardData> {
	const response: AxiosResponse = await axiosInstance.post(
		`/separation-schedules/decline-or-approve-request-plugin/${ticket._id}/${action}`,
	)

	return response.data.content
}

export async function sendPluginRequest(
	ticketId: string,
	date: number,
	period: string,
) {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/separation-schedules/send-plugin-request/${ticketId}`,
			{
				date,
				period,
			},
		)

		return response.data.content
	} catch (error) {
		throw error
	}
}
