import React, { useEffect, useMemo, useState } from 'react'
import * as S from './styles'

//icons
import TrashIcon from '../../../assets/images/trash.svg'
import SettingsIcon from '../../../assets/images/settings.svg'

//types
import { IPages, IProps } from './types'

//hooks
import { useTheme } from 'styled-components'
import { store } from 'shared/store'

function UserList({
	data,
	setData,
	searchString,
	changeModalState,
	changeDeleteModalState,
	itemsPerPage = 10,
	setCheckedUsers,
}: IProps) {
	const [pages, setPages] = useState<IPages[]>([])

	const theme = useTheme()

	const pageNumber = pages.find((page) => page.active === true)?.index || 0

	const renderList = useMemo(() => {
		if (searchString) {
			// eslint-disable-next-line array-callback-return
			return data.filter((user) => {
				if (
					user.name.toLowerCase().includes(searchString.toLowerCase()) ||
					user.enrollment.toLowerCase().includes(searchString.toLowerCase()) ||
					user.retails.some((retail) =>
						retail.name.toLowerCase().includes(searchString.toLowerCase()),
					) ||
					user.documentNumber
						.toLowerCase()
						.includes(searchString.toLowerCase()) ||
					user.profile.name.toLowerCase().includes(searchString.toLowerCase())
				)
					return user
			})
		}

		return data
	}, [data, searchString])

	const splitedData = renderList.slice(
		pageNumber * itemsPerPage,
		pageNumber * itemsPerPage + itemsPerPage,
	)

	function handleCheckboxClick(id: string) {
		const newData = data.map((user) => ({
			...user,
			checked: user._id === id ? !user.checked : user.checked,
		}))
		setData(newData)
	}

	function handleMovePage(action: 'next' | 'prev') {
		let newPages = pages.map((page) => ({
			...page,
			active: false,
		}))

		if (action === 'next') {
			newPages[pageNumber + 1].active = true
		}
		if (action === 'prev') {
			newPages[pageNumber - 1].active = true
		}
		setPages(newPages)
	}

	function handlePageChange(page: IPages) {
		const newPages = pages.map((pag, index) => ({
			...pag,
			active: page.index === index ? true : false,
		}))

		setPages(newPages)
	}

	useEffect(() => {
		const totalPages = Array.from(
			{ length: renderList.length / itemsPerPage + 1 },
			(_, index) => ({
				index,
				page: index + 1,
				active: index === 0 ? true : false,
			}),
		)

		setPages(totalPages)
	}, [itemsPerPage, renderList])

	useEffect(() => {
		const checkedUsers = renderList.filter((user) => {
			return user.checked
		})

		setCheckedUsers(checkedUsers)
	}, [renderList, setCheckedUsers])

	return (
		<S.Container>
			<S.Table>
				<S.TableHeader>
					<tr>
						<th></th>
						<th>Perfil</th>
						<th>Mathícula</th>
						<th>Nome</th>
						<th>CPF</th>
						<th>Loja</th>
						<th></th>
					</tr>
				</S.TableHeader>
				<tbody>
					{splitedData.map((user, index) => {
						const retailsList = user.retails.map((retail) => retail.name)

						const isCurrentUser = user._id === store.getState().user._id

						return (
							<S.TableContent index={index} key={user._id}>
								<td>
									<input
										type='checkbox'
										name={user.name}
										id={user._id}
										checked={user.checked}
										onClick={() => handleCheckboxClick(user._id)}
										readOnly
									/>
								</td>

								<td>
									<S.Circle
										backgroundColor={theme.colors.blue}
										color={theme.colors.white}
									>
										{user.profile.name}
									</S.Circle>
								</td>
								<td>{user.enrollment}</td>
								<td>{user.name}</td>

								<td>{user.documentNumber}</td>

								<S.RetailContainer>
									{retailsList.map((retail) => (
										<S.Circle
											backgroundColor={theme.colors.grey}
											key={retail}
											color={theme.colors.white}
										>
											{retail}
										</S.Circle>
									))}
								</S.RetailContainer>

								<S.IConsContainer>
									<div>
										<img
											src={SettingsIcon}
											alt='SettingsIcon'
											onClick={() => changeModalState(user)}
										/>
										{!isCurrentUser && (
											<img
												src={TrashIcon}
												alt='TrashIcon'
												onClick={() => changeDeleteModalState(user)}
											/>
										)}
									</div>
								</S.IConsContainer>
							</S.TableContent>
						)
					})}
				</tbody>
			</S.Table>
			<S.TableFooter>
				<S.Results>
					Resultados, {splitedData.length} de {renderList.length}
				</S.Results>
				<S.PaginationContainer>
					<S.Pagination>
						<S.PaginationController
							disabled={pageNumber === 0}
							onClick={() => handleMovePage('prev')}
						>
							Anterior
						</S.PaginationController>
						{pages.map((page) => (
							<S.Page
								key={page.page}
								active={page.active}
								onClick={() => handlePageChange(page)}
							>
								{page.page}
							</S.Page>
						))}
						<S.PaginationController
							disabled={pageNumber === pages.length - 1}
							onClick={() => handleMovePage('next')}
						>
							Próxima
						</S.PaginationController>
					</S.Pagination>
				</S.PaginationContainer>
			</S.TableFooter>
		</S.Container>
	)
}

export default UserList
