import { format, getUnixTime } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { translateScheduleParams } from 'shared/util/translate'
import { SelectOption } from '@buildbox/components'
import {
    ISeparationDateOption
} from 'shared/interfaces/ticket'

export const formatSchedulingDateOption = (
    option: ISeparationDateOption,
    selected: boolean
) => ({
    value: JSON.stringify({
        value: getUnixTime(new Date(option.date)),
        period: option.period
    }),
    label: JSON.stringify({
        ...option,
        date: format(new Date(option.date), 'eeee, dd/MM/yy', { locale: ptBR }),
        period: translateScheduleParams(option.period),
        selected
    })
})

export function getFormattedSchedulingDateLabel(dateOption: SelectOption | undefined) {
    if (!dateOption) return ''

    const { label } = dateOption
    const { date, period } = JSON.parse(label)

    return `${date} - ${period}`
}