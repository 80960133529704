import React from 'react'
import { VisionColumn } from 'modules/Dashboard/styles'
import DatePicker from 'shared/components/DatePicker'
import PageTitle from 'shared/components/PageTitle'
import { Column, Row } from 'shared/styles'
import { columnSize, graphTitle } from 'shared/util/dashboardUtil'
import maximizeIcon from 'assets/images/maximize-2.svg'
import SeparationDashboardResponsiveBarGraph from '../SeparationDashboardResponsiveBarGraph'
import { Button, Modal, Select } from '@buildbox/components'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import {
	DashboardPageWrapper,
	Label,
	ButtonWrapper,
	VisionData,
	ContainerRow,
	Cards,
	GraphExpandButton,
	GraphCard,
	SecondContainerRow,
	TeamGraphWrapper,
	TitleRow
} from './styles'

import { IViewProps } from './types'
import {
	primaryDashboardButtonStyles,
	primaryInputSeparationTicketStyles,
	primaryInputStyles,
	primarySelectStyles,
	reactSelectStyles,
} from 'shared/styles/theme'
import { options } from 'shared/util/SelectOption'
import { getDropdownButtonLabelOverride } from 'shared/util/multiSelectCheckboxUtils'
import Loading from 'shared/components/Loading'
import TotalCard from 'shared/components/TotalCard'
import RevenueCard from 'shared/components/RevenueCard'
import RenderAveragesCard from 'shared/components/RenderAveragesCard'
import {
	IGraphTypeEnum,
	IOpenedApprovedAverageDash,
	ITotalRevenueAverageDash,
	VisionTypeEnum,
} from 'shared/interfaces/ticket'
import SeparationCustomerProfile from 'modules/SeparationCustomerProfile'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import SwitchPrimary from 'shared/components/SwitchPrimary'
import PerformanceSeparationGraph from '../PerformanceSeparationGraph'
import GeneralTimeDash from '../../shared/components/GeneralTime'
import TeamGraphPlaceholderSVG from '../../assets/images/team-graph-placeholder.svg'

function DashboardSeparation(props: IViewProps): JSX.Element {
	const {
		filtersValue,
		handleSetDates,
		handleProfessionalSelect,
		filtersData,
		isLoading,
		handleSegment,
		handleCustomerClient,
		handleChannel,
		handleVisionOption,
		handleSelectSteps,
		dashBoardMetrics,
		modalIsActives,
		handleChannelModalGraph,
		handleTeamModalGraph,
		isEmpty,
		handlePerformanceModal,
		handleClientProfileGraph,
		exportTicket,
		handleTeamGraphOptions,
		teamGraphVision,
		handleRetailSelect
	} = props

	const {
		totalRevenueAverageDash,
		channelDash,
		teamDash,
		performanceDash,
		clientsProfileDash,
		timesDash,
	} = dashBoardMetrics

	const {
		channelDashTitle,
		teamDashTitle,
		performanceDashTitle,
		clientsProfileDashTitle,
	} = graphTitle(filtersValue.visionValue.value)

	return (
		<DashboardPageWrapper>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<div className='page-header'>
						<Row>
							<TitleRow>
								<PageTitle>Dashboard de Separação</PageTitle>

								{filtersData.retailOptions.length > 1 && (
									<Select
										round
										placeholder='Definir Separadores'
										{...primaryInputSeparationTicketStyles}
										id='retails'
										options={filtersData.retailOptions}
										value={filtersValue.retailValue}
										onChange={handleRetailSelect}
										isMulti
										disabled={true}
									/>
								)}
							</TitleRow>
						</Row>
						<Row>
							<VisionColumn
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Período</Label>
								<DatePicker
									dateSetter={handleSetDates}
									value={filtersValue.dateRange}
									datePickerTitle='Seleção de Período'
									{...primaryInputStyles}
								/>
							</VisionColumn>

							<VisionColumn
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Etapa</Label>
								<ReactMultiSelectCheckboxes
									onChange={handleSelectSteps}
									value={filtersValue.stepsValue}
									options={options(filtersData.stepsOptions)}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									isMulti
									placeholderButtonLabel='Etapa'
									placeholder='Buscar Etapa'
									id='select'
									className='select-vision'
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</VisionColumn>

							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
								style={{ padding: '10px' }}
							>
								<Label>Profissional</Label>
								<ReactMultiSelectCheckboxes
									onChange={handleProfessionalSelect}
									options={options(filtersData.professionalDataToUse)}
									value={filtersValue.professionalValue}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									isMulti
									placeholderButtonLabel='Profissional'
									placeholder='Buscar Profissional'
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</Column>
							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
								style={{ padding: '10px' }}
							>
								<Label>Clientes</Label>
								<ReactMultiSelectCheckboxes
									onChange={handleCustomerClient}
									options={options(filtersData.customerOptions)}
									value={filtersValue.customerValue}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									isMulti
									placeholderButtonLabel='Clientes'
									placeholder='Buscar Cliente'
									noOptionsMessage={() => 'Nenhum cliente para mostrar.'}
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</Column>
							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
								style={{ padding: '10px' }}
							>
								<Label>Segmentos</Label>
								<ReactMultiSelectCheckboxes
									onChange={handleSegment}
									options={options(filtersData.segmentOptions)}
									value={filtersValue.segmentValue}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									isMulti
									placeholderButtonLabel='Segmentos'
									placeholder='Buscar Segmento'
									noOptionsMessage={() => 'Nenhum segmento para mostrar.'}
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</Column>
							<Column
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
								style={{ padding: '10px' }}
							>
								<Label>Canal</Label>
								<ReactMultiSelectCheckboxes
									onChange={handleChannel}
									options={options(filtersData.channelOptions)}
									value={filtersValue.channelValue}
									getDropdownButtonLabel={getDropdownButtonLabelOverride}
									isMulti
									placeholderButtonLabel='Canal'
									placeholder='Buscar Canal'
									noOptionsMessage={() => 'Nenhum canal para mostrar.'}
									{...primaryInputStyles}
									styles={reactSelectStyles}
								/>
							</Column>
							<VisionColumn
								sm={columnSize.smWidth}
								md={columnSize.mdWidth}
								lg={columnSize.lgWidth}
							>
								<Label>Visão</Label>

								<Select
									round
									placeholder='Ticket'
									id='select'
									className='select-vision'
									options={filtersData.visionOptions}
									value={filtersValue.visionValue}
									onChange={handleVisionOption}
									{...primarySelectStyles}
								/>
							</VisionColumn>
						</Row>
						<Row>
							<VisionColumn>
								<ButtonWrapper>
									<Button
										{...primaryDashboardButtonStyles}
										onClick={exportTicket}
									>
										Exportar Dados
									</Button>
								</ButtonWrapper>
							</VisionColumn>
						</Row>
					</div>
					{!isEmpty ? (
						<VisionData>
							<ContainerRow>
								<Cards className='cards'>
									<TotalCard
										vision={filtersValue.visionValue.value as VisionTypeEnum}
										data={totalRevenueAverageDash as ITotalRevenueAverageDash}
										isSeparationCard={true}
									/>
									{filtersValue.visionValue.value === 'REVENUE' ? (
										<RevenueCard
											vision={filtersValue.visionValue.value as VisionTypeEnum}
											data={
												totalRevenueAverageDash as IOpenedApprovedAverageDash
											}
											dashboard="separation"
										/>
									) : (
										<RevenueCard
											vision={filtersValue.visionValue.value as VisionTypeEnum}
											data={totalRevenueAverageDash as ITotalRevenueAverageDash}
											dashboard="separation"
										/>
									)}

									<RenderAveragesCard
										vision={filtersValue.visionValue.value as VisionTypeEnum}
										data={totalRevenueAverageDash as ITotalRevenueAverageDash}
									/>
								</Cards>
								<GraphCard className='channel'>
									<div className='card-title'>
										<h3>{channelDashTitle}</h3>
										<GraphExpandButton onClick={handleChannelModalGraph}>
											EXPANDIR
											<img src={maximizeIcon} alt='Maximizar gráfico' />
										</GraphExpandButton>
									</div>
									<div className='graph-wrapper'>
										<SeparationDashboardResponsiveBarGraph
											graph={IGraphTypeEnum.CHANNEL}
											dataGraph={channelDash}
											vision={filtersValue.visionValue.value}
											visionType={teamGraphVision}
										/>
									</div>
								</GraphCard>
							</ContainerRow>
							<SecondContainerRow>
								<div>
									<TeamGraphWrapper items={teamDash.length} className='team'>
										<div className='card-title'>
											<h3>{teamDashTitle}</h3>
											<span>
												{`(TOTAL: (${teamDash ? teamDash.length : 0}) PESSOAS)`}

												<div>
													<SwitchPrimary
														value={teamGraphVision}
														handleOption={handleTeamGraphOptions}
													/>
												</div>
											</span>
											<GraphExpandButton onClick={handleTeamModalGraph}>
												EXPANDIR
												<img src={maximizeIcon} alt='Maximizar gráfico' />
											</GraphExpandButton>
										</div>
										{!!teamDash.length ? (
											<div className='graph-wrapper-team'>
												<SeparationDashboardResponsiveBarGraph
													graph={IGraphTypeEnum.TEAM}
													dataGraph={teamDash}
													vision={filtersValue.visionValue.value}
													visionType={teamGraphVision}
												/>
											</div>
										) : (
											<div className='team-graph-placeholder'>
												<img src={TeamGraphPlaceholderSVG} alt="Team Graph Placeholder" />
												<span>Não há nenhuma informação para ser exibida</span>
											</div>
										)}
									</TeamGraphWrapper>

									{teamDash.length < 15 && (
										<GraphCard className='clients-profile'>
											<div className='card-title'>
												<h3>{clientsProfileDashTitle}</h3>
												<GraphExpandButton onClick={handleClientProfileGraph}>
													EXPANDIR
													<img src={maximizeIcon} alt='Maximizar gráfico' />
												</GraphExpandButton>
											</div>
											<SeparationCustomerProfile
												dataGrafic={clientsProfileDash}
												selectedDates={filtersValue.dateRange}
												vision={filtersValue.visionValue.value}
											/>
										</GraphCard>
									)}
								</div>
								<div>
									<GraphCard className='performance'>
										<div className='card-title'>
											<h3>{performanceDashTitle}</h3>
											<GraphExpandButton onClick={handlePerformanceModal}>
												EXPANDIR
												<img src={maximizeIcon} alt='Maximizar gráfico' />
											</GraphExpandButton>
										</div>
										<div className='graph-wrapper-performance'>
											<PerformanceSeparationGraph
												graphPerformanceData={performanceDash ? performanceDash.value : []}
												vision={filtersValue.visionValue.value as VisionTypeEnum}
											/>
										</div>
									</GraphCard>
									<GraphCard className='general-time'>
										<div className='card-title'>
											<GeneralTimeDash data={timesDash} />
										</div>
									</GraphCard>
									{teamDash.length >= 15 && (
										<GraphCard className='clients-profile'>
											<div className='card-title'>
												<h3>{clientsProfileDashTitle}</h3>
												<GraphExpandButton onClick={handleClientProfileGraph}>
													EXPANDIR
													<img src={maximizeIcon} alt='Maximizar gráfico' />
												</GraphExpandButton>
											</div>
											<SeparationCustomerProfile
												dataGrafic={clientsProfileDash}
												selectedDates={filtersValue.dateRange}
												vision={filtersValue.visionValue.value}
											/>
										</GraphCard>
									)}
								</div>
							</SecondContainerRow>
						</VisionData>
					) : (
						<DefaultPagePlaceholder />
					)}

					<Modal
						isActive={modalIsActives.channelGraph}
						onClose={handleChannelModalGraph}
						title={channelDashTitle}
						size={1024}
					>
						<SeparationDashboardResponsiveBarGraph
							graph={IGraphTypeEnum.CHANNEL}
							dataGraph={channelDash}
							vision={filtersValue.visionValue.value}
							visionType={teamGraphVision}
						/>
					</Modal>

					<Modal
						isActive={modalIsActives.teamGraph}
						onClose={handleTeamModalGraph}
						title={`${teamDashTitle} (Total: ${teamDash ? teamDash.length : 0
							} pessoas)`}
						size={1024}
						className='modal-full-screen'
					>
						<SeparationDashboardResponsiveBarGraph
							graph={IGraphTypeEnum.TEAM}
							dataGraph={teamDash}
							vision={filtersValue.visionValue.value}
							visionType={teamGraphVision}
						/>
					</Modal>

					<Modal
						isActive={modalIsActives.performanceGraph}
						onClose={handlePerformanceModal}
						title='Performance'
						size={1024}
						className='modal-full-screen'
					>
						<PerformanceSeparationGraph
							graphPerformanceData={performanceDash ? performanceDash.value : []}
							vision={filtersValue.visionValue.value as VisionTypeEnum}
						/>
					</Modal>

					<Modal
						isActive={modalIsActives.clientProfileGraph}
						onClose={handleClientProfileGraph}
						title='Perfil de Clientes'
						size={1024}
						className='modal-full-screen'
					>
						<SeparationCustomerProfile
							dataGrafic={clientsProfileDash}
							selectedDates={filtersValue.dateRange}
							vision={filtersValue.visionValue}
						/>
					</Modal>
				</>
			)}
		</DashboardPageWrapper>
	)
}

export default DashboardSeparation
