import { startOfWeek, endOfToday } from 'date-fns'
import { ticketSeparationStatus } from 'shared/interfaces/ticket'
import {
	fetchAllCustomers,
	fetchAllSegments,
} from 'shared/services/ticket.service'
import { listCheckers, listSeparator } from 'shared/services/user.service'
import { labelChannel, SetVision, SeparationStages } from 'shared/util/Consts'
import { translateSegment } from 'shared/util/translate'
import { IDigitalTeamGraphVisionOptions, IFilterData } from './types'

export const FILTER_VALUE = {
	professionalValue: [],
	dateRange: {
		from: startOfWeek(new Date(), { weekStartsOn: 1 }),
		to: endOfToday(),
	},
	segmentValue: [],
	customerValue: [],
	retailValue: [],
	channelValue: labelChannel,
	visionValue: {
		value: 'TICKET',
		label: 'Ticket',
	},
	stepsValue: SeparationStages,
	teamGraphOption: IDigitalTeamGraphVisionOptions.VOLUME,
}

export const MODAL_IS_ACTIVE = {
	channelGraph: false,
	teamGraph: false,
	performanceGraph: false,
	clientProfileGraph: false,
}

export const FILTER_DATA = {
	professionalData: {
		separators: [],
		checkers: [],
	},
	customerOptions: [],
	segmentOptions: [],
	channelOptions: [],
	visionOptions: [],
	retailOptions: [],
	stepsOptions: [],
	professionalDataToUse: [],
}

export async function initialFetch(retailsId: string[]): Promise<IFilterData> {
	const customers: string[] = await fetchAllCustomers(retailsId)

	const customerOptions = customers.map((item) => ({
		label: item,
		value: item,
	}))

	const segments: string[] = await fetchAllSegments(retailsId)

	const segmentsOptions = segments.map((item) => ({
		label: translateSegment(item),
		value: item,
	}))

	const separators = await listSeparator(retailsId)

	const separatorsOptions = separators.map((item: any) => ({
		label: item.name,
		value: item._id,
	}))

	const checker = await listCheckers(retailsId)

	const checkerOptions = checker.map((item: any) => ({
		label: item.name,
		value: item._id,
	}))

	return {
		customerOptions,
		professionalData: {
			separators: separatorsOptions,
			checkers: checkerOptions,
		},
		segmentOptions: segmentsOptions,
		channelOptions: labelChannel,
		visionOptions: SetVision,
		stepsOptions: SeparationStages,
		professionalDataToUse: [...separatorsOptions, ...checkerOptions],
		retailOptions: []
	}
}

export const separationStatusActionAllowed = {
	SEPARATION_PENDING: [ticketSeparationStatus.SEPARATION_ON_SEPARATION],
	SEPARATION_ON_SEPARATION: [ticketSeparationStatus.SEPARATION_ON_CHECKING],
	SEPARATION_SEPARATED: [ticketSeparationStatus.SEPARATION_ON_CHECKING],
	SEPARATION_ON_CHECKING: [ticketSeparationStatus.SEPARATION_READY_TO_DELIVERY],
	SEPARATION_CHECKED: [ticketSeparationStatus.SEPARATION_READY_TO_DELIVERY],
	SEPARATION_PLUGIN_REQUEST: [],
	SEPARATION_READY_TO_DELIVERY: [
		ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_IN,
	],
	SEPARATION_PLUGIN_REQUEST_APPROVED: [],
	SEPARATION_PLUGIN_REQUEST_REFUSED: [],
	WITHDRAWAL_FULFILLED_CHECK_IN: [
		ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_OUT,
	],
	WITHDRAWAL_FULFILLED_CHECK_OUT: [ticketSeparationStatus.WITHDRAWAL_SHELVED],
	WITHDRAWAL_SHELVED: [],
}
