import styled from 'styled-components'

export const RenderAveragesCardWrapper = styled.div`
	flex: 3;
	padding: 20px 20px 18px;

	font-size: 14px;

	h3,
	span {
		color: ${({ theme }) => theme.colors.blue};
	}

	div {
		margin: 20px 40px 0 0;
	}

	display: grid;
	grid-template-areas:
		'title title'
		'channel items'
		'person skus';

	h3 {
		grid-area: title;
	}
	.channel {
		grid-area: channel;
	}
	.items {
		grid-area: items;
	}
	.person {
		grid-area: person;
	}
	.skus {
		grid-area: skus;
	}
`
