import styled from 'styled-components';
import { toRem } from 'shared/styles/functions'

export const ButtonDelete = styled.div`
	text-align: center;
	margin-top: ${toRem(30)};

	.btn-delete button {
		border: 1px solid ${({ theme }) => theme.colors.red};
	}
`

export const ModalDeleteContainer = styled.div`
	p {
		text-align: center;
		font-size: 12px;
		margin-bottom: 10px;
	}
`
