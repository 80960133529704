import {
	IPermission,
	permissionNameValues,
} from 'shared/interfaces/permissions'
import { IProfile } from 'shared/interfaces/profile'
import routesEnum from '../../modules/Routes/enum'

export function redirectRoute(userProfile: IProfile, accessToken: string) {
	if (userProfile) {
		const allowedRoutes = getAllowedRoutes(userProfile)

		if (!!allowedRoutes.length && accessToken) {
			return allowedRoutes[0]
		}
	}

	return routesEnum.LOGIN
}

export function getAllowedRoutes(userProfile: IProfile) {
	const userPermissions = userProfile.permissions as IPermission[]
	const routes = Object.values(routesEnum).filter((route) => route !== '/login')

	const allowedRoutes = routes.filter((route) => {
		return userPermissions.some((permission) => {
			return (
				`/${permission.screenUrl}` === route &&
				permission.name === permissionNameValues.EDIT
			)
		})
	})

	return allowedRoutes
}
