import React from 'react'
import { toMoney } from 'shared/util/currency'
import { numberFixed } from 'shared/util/mask'
import { CardWrapper } from './styles'
import { ITotalCardProps } from './types'

function TotalCard(props: ITotalCardProps) {
	const { vision, data, isSeparationCard } = props

	return (
		<CardWrapper>
			<h3>{vision === 'REVENUE' ? 'Orçado' : 'Total'}</h3>
			<span className='value'>
				{vision === 'REVENUE' ? toMoney(data.totalRevenue) : data.totalUnits}
			</span>
			{!isSeparationCard ? (
				<>
					{vision !== 'REVENUE' ? (
						<span className='percent'>
							{numberFixed(data.percentUnitsApproved * 100)}% APROVADO
						</span>
					) : (
						<span style={{ display: 'hidden' }} />
					)}
				</>
			) : (
				<div className='label-container'>
					<span className='percent'>PERÍODO ANTERIOR</span>
					<span className='percent'>{`${data.lasPeriodUnit}   (${(
						data.averagePeriodUnit * 100
					).toFixed(0)}%)`}</span>
				</div>
			)}
		</CardWrapper>
	)
}
export default TotalCard
