import React from 'react'
import {
	IOpenedApprovedAverageDash,
	ITotalRevenueAverageDash,
} from 'shared/interfaces/ticket'
import { toMoney } from 'shared/util/currency'
import { IRevenueCard } from './types'

import { RevenueCardWrapper } from './styles'

function RevenueCard(props: IRevenueCard) {
	const { data, vision, dashboard } = props

	if (vision === 'REVENUE') {
		const dash = data as IOpenedApprovedAverageDash

		return (
			<RevenueCardWrapper>
				<h3>{dashboard === 'attendance' ? 'Fechado' : 'Pronto para retirada'}</h3>
				<span className='value'>{toMoney(dash.totalApprovedRevenue)}</span>
				<div>
					{!dash.percentRevenueApproved
						? 0
						: (dash.percentRevenueApproved * 100).toFixed(1)}{' '}
					%
				</div>
			</RevenueCardWrapper>
		)
	}
	const dash = data as ITotalRevenueAverageDash

	return (
		<RevenueCardWrapper>
			<h3>Faturamento</h3>
			<span className='value'>{toMoney(dash.totalRevenue)}</span>
			<div>
				<span className='percent'>VALOR MÉDIO</span>
				<span className='percent'>{toMoney(dash.averageRevenuePerUnit)}</span>
			</div>
		</RevenueCardWrapper>
	)
}

export default RevenueCard
