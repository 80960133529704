import styled from 'styled-components'
import { fontType } from 'shared/styles/functions'

export const Container = styled.div`
	width: 368px;

	* {
		::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme.colors.lightGrey};
		}
		::-webkit-scrollbar {
			width: 6px;
			background: ${({ theme }) => theme.colors.grey};
		}
		::-webkit-scrollbar-thumb {
			background: ${({ theme }) => theme.colors.grey};
			border-radius: 50px;
		}
		${fontType(0)}
	}
	display: flex;
	flex-direction: column;

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 24px;

		p {
			${fontType(1)};
			color: ${({ theme }) => theme.colors.darkGrey};
			font-size: 14px;
		}
	}

	ul {
		background: ${({ theme }) => theme.colors.lightWhite};
		border-radius: 4px;
		margin-top: 14px;
		display: flex;
		flex-direction: column;
		height: 100%;
		padding-bottom: 10px;
	}
`

export const CardFitWrapper = styled.div``
