import { IOrder } from "shared/interfaces/order";
import { IUser } from "shared/interfaces/user";
import {
    ITicket,
    ticketSeparationStatus,
    TicketSeparationStatusEnum
} from "shared/interfaces/ticket"

export function allOrdersHaveSeparators(orders: IOrder[]): boolean {
    return !(orders.some(
        order => !order.separators || order.separators.length === 0)
    )
}

export function allOrdersHaveCheckers(orders: IOrder[]): boolean {
    return !(orders.some(
        order => !order.checkers || order.checkers.length === 0)
    )
}

export function allOrdersAreSeparated(orders: IOrder[]): boolean {
    return !(orders.some(order => !order.separationIsCompleted))
}

export function allOrdersAreChecked(orders: IOrder[]): boolean {
    return !(orders.some(order => !order.checkingIsCompleted))
}

export function haveChangesInTheSeparatorsOrder(
    newOrderSeparators: IUser[],
    oldOrderSeparators: string[]
) {
    let haveOrderSeparatorsChanges: boolean = false

    if (newOrderSeparators.length >= oldOrderSeparators.length) {
        haveOrderSeparatorsChanges = newOrderSeparators.map(newOrderSeparatorItem => {
            return oldOrderSeparators.some(oldOrderSeparatorItem => {
                return oldOrderSeparatorItem === newOrderSeparatorItem._id
            })
        }).includes(false)
    } else {
        haveOrderSeparatorsChanges = oldOrderSeparators.map(oldOrderSeparatorItem => {
            return newOrderSeparators.some(newOrderSeparatorItem => {
                return newOrderSeparatorItem._id === oldOrderSeparatorItem
            })
        }).includes(false)
    }

    return haveOrderSeparatorsChanges
}

export function haveChangesInTheSeparators(
    newOrders: IOrder[],
    oldOrders: IOrder[]
): boolean {
    const haveChanges = newOrders.map((order, index) => {
        const newOrderSeparators = (order.separators || []) as IUser[]
        const oldOrderSeparators = (oldOrders[index].separators || []) as string[]

        return haveChangesInTheSeparatorsOrder(
            newOrderSeparators,
            oldOrderSeparators
        )
    }).includes(true)
    return haveChanges
}

export function haveChangesInTheCheckers(
    newOrders: IOrder[],
    oldOrders: IOrder[]
): boolean {
    const haveChanges = newOrders.map((order, index) => {
        const newOrderCheckers = (order.checkers || []) as IUser[]
        const oldOrderCheckers = (oldOrders[index].checkers || []) as string[]

        let haveOrderCheckersChanges: boolean = false

        if (newOrderCheckers.length >= oldOrderCheckers.length) {
            haveOrderCheckersChanges = newOrderCheckers.map(newOrderCheckerItem => {
                return oldOrderCheckers.some(oldOrderCheckerItem => {
                    return oldOrderCheckerItem === newOrderCheckerItem._id
                })
            }).includes(false)
        } else {
            haveOrderCheckersChanges = oldOrderCheckers.map(oldOrderCheckerItem => {
                return newOrderCheckers.some(newOrderCheckerItem => {
                    return newOrderCheckerItem._id === oldOrderCheckerItem
                })
            }).includes(false)
        }

        return haveOrderCheckersChanges
    }).includes(true)
    return haveChanges
}

export function haveChangesInTheCheckbox(
    field: 'separation' | 'checking',
    newOrders: IOrder[],
    oldOrders: IOrder[]
): boolean {
    const checkboxField = field === 'separation' ? 'separationIsCompleted' : 'checkingIsCompleted'

    const haveChanges = newOrders.map((order, index) => {
        const newOrderCheckboxValue = !!order[checkboxField]
        const oldOrderCheckboxValue = !!oldOrders[index][checkboxField]

        return newOrderCheckboxValue !== oldOrderCheckboxValue
    }).includes(true)
    return haveChanges
}

export function currentColumnIs(
    column: 'pending' | 'onSeparation' | 'onChecking',
    currentSeparationStatus?: TicketSeparationStatusEnum
) {
    switch (column) {
        case 'pending':
            return currentSeparationStatus === ticketSeparationStatus.SEPARATION_PENDING
        case 'onSeparation':
            return (currentSeparationStatus === ticketSeparationStatus.SEPARATION_ON_SEPARATION ||
                currentSeparationStatus === ticketSeparationStatus.SEPARATION_SEPARATED)
        case 'onChecking':
            return (currentSeparationStatus === ticketSeparationStatus.SEPARATION_ON_CHECKING ||
                currentSeparationStatus === ticketSeparationStatus.SEPARATION_CHECKED)
        default: return false
    }
}

export function completeTheSeparationOfAllOrders(orders: IOrder[]) {
    const ordersAreSeparated = allOrdersAreSeparated(orders)
    return orders.map(order => {
        if (ordersAreSeparated) {
            order.separationIsCompleted = undefined
        } else {
            order.separationIsCompleted = order.separationIsCompleted || new Date()
        }

        return order
    })
}

export function completeTheCheckingOfAllOrders(orders: IOrder[]) {
    const ordersAreChecked = allOrdersAreChecked(orders)
    return orders.map(order => {
        if (ordersAreChecked) {
            order.checkingIsCompleted = undefined
        } else {
            order.checkingIsCompleted = order.checkingIsCompleted || new Date()
        }

        return order
    })
}

function filterTicketsPerStatus(
    tickets: ITicket[],
    status: TicketSeparationStatusEnum[]
) {
    return tickets.filter(ticket => ticket.currentSeparationStatus
        ? status.includes(ticket.currentSeparationStatus)
        : false
    )
}

export function getSeparationBoardColumns(tickets: ITicket[]) {
    const pendingColumn = filterTicketsPerStatus(tickets, [
        ticketSeparationStatus.SEPARATION_PENDING,
        ticketSeparationStatus.SEPARATION_PLUGIN_REQUEST
    ])

    const separationColumn = filterTicketsPerStatus(tickets, [
        ticketSeparationStatus.SEPARATION_ON_SEPARATION,
        ticketSeparationStatus.SEPARATION_SEPARATED
    ])

    const checkingColumn = filterTicketsPerStatus(tickets, [
        ticketSeparationStatus.SEPARATION_ON_CHECKING,
        ticketSeparationStatus.SEPARATION_CHECKED
    ])

    return {
        pendingColumn,
        separationColumn,
        checkingColumn
    }
}
