import styled from 'styled-components'

import { fontType, mediaQuery, toRem } from '../../shared/styles/functions'

export const LoginWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	min-height: 100vh;
	background-color: #354d9e;
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: wrap;

	${mediaQuery('sm')(`
		padding: 0;
	`)};

	.logo-wrapper {
		.logo {
			height: auto;
			max-width: ${toRem(283)};
		}
	}

	.inputs-wrapper {
		flex-direction: column;
		margin: 0;
		background-color: #fff;
		display: flex;
		width: 384px;
		height: 474px;
		margin: 0;
		padding: 72px 37px 56px;
		align-content: space-between;
		min-width: 320px;

		.header {
			flex: 1;
			text-align: center;
			.title {
				${fontType(0)};
				font-size: ${toRem(24)};
				color: ${({ theme }) => theme.colors.red};
				line-height: 15px;
			}

			.sub-title {
				${fontType(1)};
				font-size: ${toRem(12)};
				color: ${({ theme }) => theme.colors.darkGrey};
				line-height: 29px;
				margin: 0 0 8px;
			}
		}

		.fields {
			flex: 1;
			text-align: center;
			.input {
				margin-bottom: 12px;
			}

			.button {
				margin-top: 40px;
			}
		}
	}
`
