import { format } from 'date-fns'

export function translateStatus(status: string) {
	const statusTicket = {
		ATTENDANCE: 'Em atendimento',
		QUOTE_SENT: 'Cotação enviada',
		NEGOTIATION: 'Em negociação',
		NEGOTIATION_SENT: 'Negociação enviada',
		APPROVED: 'Ticket Aprovado',
		INVOICED: 'Ticket Faturado',
		CANCELED: 'Ticket Cancelado',
		WITHDRAWAL_FULFILLED_CHECK_IN: 'Check-in Realizado',
		WITHDRAWAL_FULFILLED_CHECK_OUT: 'Check-out Realizado',
		SEPARATION_READY_TO_DELIVERY: 'Pronto para Retirada',
	} as { [id: string]: string }

	return statusTicket[status]
}

export function translateStatusSeparation(status: string) {
	const statusTicket = {
		SEPARATION_PENDING: 'Pendente',
		SEPARATION_ON_SEPARATION: 'Em Separação',
		SEPARATION_SEPARATED: 'Em Separação',
		SEPARATION_ON_CHECKING: 'Em Conferência',
		SEPARATION_CHECKED: 'Em Conferência',
		SEPARATION_READY_TO_DELIVERY: 'Pronto para Retirada',
	} as { [id: string]: string }

	return statusTicket[status]
}

export function translateAllStatus(status: string) {
	const statusTicket = {
		ATTENDANCE: 'Em atendimento',
		QUOTE_SENT: 'Cotação enviada',
		NEGOTIATION: 'Em negociação',
		NEGOTIATION_SENT: 'Negociação enviada',
		APPROVED: 'Ticket Aprovado',
		INVOICED: 'Ticket Faturado',
		CANCELED: 'Ticket Cancelado',
		WITHDRAWAL_FULFILLED_CHECK_IN: 'Check-in Realizado',
		WITHDRAWAL_FULFILLED_CHECK_OUT: 'Check-out Realizado',
		SEPARATION_READY_TO_DELIVERY: 'Pronto para Retirada',
		SEPARATION_PENDING: 'Pendente',
		SEPARATION_ON_SEPARATION: 'Em Separação',
		SEPARATION_SEPARATED: 'Separado',
		SEPARATION_ON_CHECKING: 'Em Conferência',
		SEPARATION_CHECKED: 'Conferido',
		WITHDRAWAL_SHELVED: 'Arquivado',
	} as { [id: string]: string }

	return statusTicket[status]
}

export function verifyBoard(status: string) {
	if (
		[
			'SEPARATION_PENDING',
			'SEPARATION_ON_SEPARATION',
			'SEPARATION_SEPARATED',
			'SEPARATION_ON_CHECKING',
			'SEPARATION_CHECKED',
			'SEPARATION_READY_TO_DELIVERY'
		].includes(status)
	) {
		return 'Separação'
	} else if (
		[
			'ATTENDANCE',
			'QUOTE_SENT',
			'NEGOTIATION',
			'APPROVED',
			'NEGOTIATION_SENT'
		].includes(status)
	) {
		return 'Atendimento'
	}
}

export function translateSegment(client: string) {
	switch (client) {
		case 'B2B_RETAIL':
			return 'B2B Revenda'
		case 'B2B_CONSUMPTION':
			return 'B2B Consumo'
		case 'OTHERS':
			return 'Outros'
		default:
			return client
	}
}

export function translateChannelName(channel: string) {
	switch (channel) {
		case 'STORE':
			return 'Loja'
		case 'WHATSAPP':
			return 'Whatsapp'
		case 'ECOMMERCE':
			return 'Ecommerce'
		case 'DESK':
			return 'Mesa'
		case 'EMAIL':
			return 'E-mail'
		case 'TELEPHONE':
			return 'Telefone'
		default:
			return channel
	}
}

export function abbreviateLastName(name: string): string {
	const splitName = name.split(' ')

	if (splitName.length > 1) {
		const firstName = splitName[0]
		const lastName = splitName[splitName.length - 1]

		return `${firstName} ${lastName[0]}.`
	}

	return name
}

export function dateFormat(date: Date, period: string): string {
	const day = format(new Date(date), 'eee')

	const formatDate =
		`${
			format(date, 'dd/MM/yy') === format(new Date(), 'dd/MM/yy')
				? 'Hoje'
				: translateDayOfWeek(day)
		}` +
		format(new Date(date), `, dd/MM/yy`) +
		` - ${translatePeriod(period)}`

	return formatDate
}

function translatePeriod(period: string) {
	if (period === 'morning') {
		return 'Manhã'
	} else {
		return 'Tarde'
	}
}

function translateDayOfWeek(day: string) {
	switch (day) {
		case 'Mon':
			return 'Segunda'
		case 'Tue':
			return 'Terça'
		case 'Wed':
			return 'Quarta'
		case 'Thu':
			return 'Quinta'
		case 'Fri':
			return 'Sexta'
		case 'Sat':
			return 'Sábado'
		case 'Sun':
			return 'Domingo'
		default:
			return day
	}
}

export function translateSeparationStatus(status: string) {
	switch (status) {
		case 'SEPARATION_PENDING':
			return 'Pendente'
		case 'SEPARATION_ON_SEPARATION':
			return 'Em separação'
		case 'SEPARATION_ON_CHECKING':
			return 'Em conferência'
		default:
			return status
	}
}

export function translateScheduleParams(param: 'morning' | 'afternoon') {
	switch (param) {
		case 'morning':
			return 'Manhã'
		case 'afternoon':
			return 'Tarde'
		default:
			return param
	}
}

export function translateSystemName(system: string) {
	switch (system) {
		case 'PANEL':
			return 'Painel'
		case 'APP':
			return 'App'
		default:
			return system
	}
}
