import React from 'react'
import { Label } from './styles'
import { ICardBox } from './types'

const CardBox: React.FC<ICardBox> = (props: ICardBox) => {
	return (
		<Label>
			<div className="label">
				{props.label}
			</div>
			<div className="value">
				{props.value}
			</div>
		</Label>
	)
}

export default CardBox
