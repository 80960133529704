import React, { useEffect, useState } from 'react'
import { FloatLabelTextareaWrapper } from './styles'
import { IFloatLabelTextareaProps } from './types'
import { colors, paddingToString } from './util'


function FloatLabelTextarea({
	label,
	id,
	className,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	infoMessage = '',
	errorMessage = '',
	hasError = false,
	infoColor = '',
	errorColor = '',
	dashedWhenDisabled = true,
	maxLength,
	value,
	...rest
}: IFloatLabelTextareaProps) {
	const [isFilled, setIsFilled] = useState(false)
	const [focused, setIsFocused] = useState(false)

	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString,
		dashedWhenDisabled,
		infoColor,
		errorColor,
		isFilled,
		focused
	}

	function handleFilled() {
		setIsFilled(!!value)
	}

	function handleFocus() {
		setIsFocused(state => !state)
	}

	useEffect(handleFilled, [value])

	return (
		<FloatLabelTextareaWrapper styles={mergedStyles} className={className}>
			<textarea
				className='flt-textarea'
				{...rest}
				value={value}
				id={id}
				required
				maxLength={maxLength}
				onFocus={handleFocus}
				onBlur={handleFocus}
			/>
			<label className='flt-label' htmlFor={id}>
				{label}
			</label>

			{maxLength && value !== undefined ? (
				<p className='show-value-length'>
					{`${String(value).length}/${maxLength}`}
				</p>
			) : null}
		</FloatLabelTextareaWrapper>
	)
}

export default FloatLabelTextarea
