import { AxiosResponse } from 'axios'
import axiosInstance from './axiosInstance'

import { ICounterListPaginate } from 'shared/interfaces/counterLog'
import { ILogsSummary } from 'modules/RetailVisitsGraph/types'

export async function fetchLogHistory(
	retails: string[],
	startTime: number,
	endTime: number,
	pageIndex: number,
	maxItemsInPage: number,
): Promise<ICounterListPaginate> {
	const response: AxiosResponse = await axiosInstance.post(
		'/counter/by-retail',
		{
			retails: retails,
			startTime: startTime,
			endTime: endTime,
			pageIndex: pageIndex,
			maxItemsInPage: maxItemsInPage,
		},
	)

	return response.data.content
}

export async function fetchVisitorsGraphData(
	retails: string[],
	startTime: number,
	endTime: number,
	viewMode: string,
): Promise<ILogsSummary[]> {
	const response: AxiosResponse = await axiosInstance.post(
		'/counter/visitors-graph-data',
		{
			retails,
			startTime,
			endTime,
			viewMode,
		},
	)

	return response.data.content
}
export async function fetchUserPerPeriod(
	retails: string[],
	startTime: number,
	endTime: number,
): Promise<ICounterListPaginate> {
	const response: AxiosResponse = await axiosInstance.post(
		'/counter/period-clients',
		{
			retails,
			startTime,
			endTime,
		},
	)

	return response.data.content
}
