import React from 'react'

import { DefaultPagePlaceholderWrapper } from './styles'
import { IProps } from './types'

function DefaultPagePlaceholder({
	className,
	text = 'Nada para mostrar aqui...',
}: IProps) {
	return (
		<DefaultPagePlaceholderWrapper className={className}>
			<img
				className='placeholder-image'
				src={require('../../../assets/images/grid.svg')}
				alt='Placeholder Higa'
			/>
			<p className='placeholder-text'>{text}</p>
		</DefaultPagePlaceholderWrapper>
	)
}

export default DefaultPagePlaceholder
