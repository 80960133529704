import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const CardPanelWrapper = styled.div`
	h1 {
		color: #444444;
		${fontType(1)}
		font-size: 14px;
	}
`

export const CardWrapContent = styled.div`
	background: #ffffff;
	margin-top: 14px;

	display: grid;
	grid-gap: 2px;
	grid-template-columns: repeat(3, 430px);

	justify-content: space-between;
	align-items: center;
`
