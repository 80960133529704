import { fontType } from 'shared/styles/functions'
import styled, { css } from 'styled-components'
import { IInputProps } from './types'

export const ModalWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	form {
		justify-content: center;
		display: flex;
		flex-direction: column;

		.select-retail-label {
			color: ${({ theme }) => theme.colors.darkGrey};
			margin-bottom: 16px;
		}

		.no-params-alert {
			color: ${({ theme }) => theme.colors.red};
			font-size: 12px;
			margin-top: 16px;
		}
	}
`
export const ModalContainer = styled.div`
	& * {
		${fontType(0)};
	}
`

export const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	${(props) =>
		props.className === 'evening-input'
			? css`
					margin-top: 20px;
			  `
			: css`
					margin-top: 48px;
			  `};
`

export const InputLabel = styled.span`
	${fontType(0)};
	color: ${({ theme }) => theme.colors.darkGrey};
`

export const Input = styled.input<IInputProps>`
	width: 151px;
	border-radius: 29px;
	padding: 20px;
	margin-left: 22px;

	${(props) =>
		props.validatedField
			? css`
					border: 1px solid ${({ theme }) => theme.colors.blue};
			  `
			: css`
					border: 1px solid ${({ theme }) => theme.colors.lightGrey};
			  `}
`

export const ButtonWrapper = styled.div`
	margin-top: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const InputSeparatorsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 48px;

	div.label {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;

		input#default-separators-checkbox {
			margin-left: 22px;
		}
	}
	#default-separators {
		width: 100%;
		margin-top: 20px;
	}
`

export const RetailInputWrapper = styled.div`
`
