import React, { useState } from 'react'

import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { routesData } from 'modules/Routes/data'
import Header from '../Header'
import SideNav from '../SideNav'
import { PageWrapper } from './styles'
import { Container } from 'shared/styles'
import { IPermission, permissionNameValues } from 'shared/interfaces/permissions'

export default function Page({ children, isFull = false }: any): JSX.Element {
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useTypedSelector(['user'])

	function handleSetIsOpen() {
		setIsOpen(!isOpen)
	}

	const userPermissions = user.profile.permissions as IPermission[]
	const routes = routesData.filter(route => {
		return userPermissions.some(permission => {
			return (
				`/${permission.screenUrl}` === route.path &&
				permission.name === permissionNameValues.EDIT
			)
		})
	});

	const headerProps = {
		isOpen,
		handleSetIsOpen,
		user,
	}

	const sideNavProps = {
		isOpen,
		handleSetIsOpen,
		routes,
		user,
	}

	return (
		<PageWrapper>
			<Header {...headerProps} />
			<SideNav {...sideNavProps} />

			<div className='container-wrapper'>
				{isFull ? <>{children}</> : <Container>{children}</Container>}
			</div>
		</PageWrapper>
	)
}
