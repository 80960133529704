import { toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const GraphContainer = styled.div<{ isModal: boolean }>`
	height: ${props => props.isModal ? toRem(420) : toRem(184)};

> div{
	height: ${props => props.isModal ? toRem(420) : toRem(321)};

	>div > div svg{
	height: ${props => props.isModal ? toRem(420) : toRem(200)};
		rect {height:${props => props.isModal ? toRem(420) : toRem(200)}}
	}
}
`