import styled from 'styled-components'
import { fontType, transition } from 'shared/styles/functions'
import { Row } from 'shared/styles'

export const HeaderRow = styled(Row)`
	display: flex;
	align-items: center;
	margin-bottom: 32px;
`

export const HeaderColumnWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`

export const HeaderButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	img {
		width: 24px;
		color: ${({ theme }) => theme.colors.blue};
		fill: red;
	}

	&:active {
		transform: translateY(1px);
		${transition(0)}
	}
`

export const ContentWrapper = styled.div`
	padding-bottom: 20px;
`
export const ModalWrapper = styled.div`
	* {
		${fontType(0)}
	}
`

export const InputWrapper = styled.div`
	#search-input {
		width: 283px;
	}
`
