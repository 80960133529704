import { CountersWrapper, CountersContainer } from './styles'
import { IViewProps } from './types'
import Loading from 'shared/components/Loading'
import React from 'react'
import RetailCard from 'shared/components/RetailCard'

function CountersView(props: IViewProps): JSX.Element {
	const { isLoading, retailsTotals } = props

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<CountersContainer>
					<CountersWrapper>
						{retailsTotals.length ? (
							<>
								{retailsTotals.map((retail) => (
									<RetailCard
										key={retail.id}
										id={retail.id}
										name={retail.retailName}
										count={retail.count}
										maxOccupancy={retail.maxOccupancy}
										percentage={retail.percentage}
										lastHour={retail.lastHour}
									/>
								))}
							</>
						) : (
							<>{/* TODO: Ask Miguel to Make a Placeholder */}</>
						)}
					</CountersWrapper>
				</CountersContainer>
			)}
		</>
	)
}

/*
{
	<CountersHeader>
		<Title>Visão atual</Title>
		<Title>{currentDateAndTime}</Title>
	</CountersHeader>
}
*/

export default CountersView
