import { fontType } from 'shared/styles/functions'
import styled, { css } from 'styled-components'

interface IStyleProps {
	currentValue: string
}

export const SwitchWrapper = styled.button<IStyleProps>`
	border: none;
	background-color: transparent;
	display: flex;

	p {
		text-transform: uppercase;
	}
`

export const FirstOption = styled.button<IStyleProps>`
	border: none;
	border-radius: 9px 0px 0px 9px;
	padding: 4px 20px;
	font-size: 8px;
	cursor: pointer;

	${(props) =>
		props.currentValue === 'VOLUME'
			? css`
					background-color: #354d9e;
					color: #c7d2f7;
			  `
			: css`
					background-color: #c7d2f7;
					color: #354d9e;
			  `};

	span {
		${fontType(0)}
	}
`

export const SecondOption = styled.button<IStyleProps>`
	background-color: blue;
	border: none;
	border-radius: 0px 9px 9px 0px;
	padding: 4px 20px;
	cursor: pointer;
	font-size: 8px;

	${(props) =>
		props.currentValue === 'AVERAGE_TIME'
			? css`
					background-color: #354d9e;
					color: #c7d2f7;
			  `
			: css`
					background-color: #c7d2f7;
					color: #354d9e;
			  `};

	span {
		${fontType(0)}
	}
`
