import React, { Fragment } from 'react'
import { Modal, FloatLabelInput, Select, Button } from '@buildbox/components'
import {
	primaryButtonStyles,
	secondInputTicketStyles,
	primaryInputSeparationTicketStyles,
	primaryInputStyles,
	primaryDisableInputTicketStyles,
	primaryDisableSelectTicketStyles,
} from 'shared/styles/theme'
import { IViewProps } from './types'
import {
	ModalContent,
	SectionLabel,
	ActionWrapper,
	OrderInformation,
	ModalFooter,
	OrderLabel,
	OrderValueWrapper,
	OrderValue,
	OrderRequests,
	Content,
	OrderContainer,
	SectionLabelBackground,
	OrderWithdrawalWrapper,
	SeparatorSelectWrapper,
	SelectAndCheckBoxWrapper,
	CheckBoxWrapper,
	CheckAllOrdersWrapper,
} from './styles'
import { translateStatusSeparation } from 'shared/util/translate'
import { toMoney } from 'shared/util/currency'
import { formatSeparatorOrCheckerOption } from 'shared/util/SelectOption'
import {
	ticketSeparationStatus,
	TicketSeparationStatusEnum,
} from 'shared/interfaces/ticket'
import OrderDisable from 'modules/OrderDisable'
import { IUser } from 'shared/interfaces/user'
import { dateFormat } from 'shared/util/translate'
import {
	currentColumnIs,
	allOrdersAreSeparated,
	allOrdersAreChecked
} from 'shared/util/separation'
import { format, parseISO } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'

const ModalTicket: React.FC<IViewProps> = (props: IViewProps) => {
	const {
		active,
		className,
		handleModal,
		modalAction,
		currentTicket,
		handleSubmitTicket,
		selectedChannel,
		selectedProfile,
		orders,
		codeOrName,
		newStatusSelection,
		filledData,
		newActionOptionSelected,
		handlerSelectNewAction,
		total,
		separatorListOptions,
		checkerListOptions,
		handleSeparators,
		handleCheckers,
		separationSelectIsVisible,
		checkerSelectIsVisible,
		withdrawalInput,
		handleInputChange,
		handleSeparatorCheckBoxChange,
		handleCheckerCheckBoxChange,
		separatorInputWrapper,
		checkerInputWrapper,
		moveToStyle,
		isLoading,
		withdrawalDate,
		handleCheckAllOrders,
		retail
	} = props

	return (
		<Modal
			title='Detalhe do Ticket'
			isActive={active}
			onClose={handleModal}
			className={className}
			size={600}
		>
			<ModalContent>
				{modalAction === 'EDIT' && (
					<>
						<SectionLabelBackground>
							<SectionLabel>{'Ações'}</SectionLabel>
						</SectionLabelBackground>
						<Content>
							<ActionWrapper currentStatus='APPROVED'>
								<FloatLabelInput
									label='Coluna atual'
									id='status-current'
									round
									disabled={true}
									placeholder={translateStatusSeparation(
										currentTicket.currentSeparationStatus as TicketSeparationStatusEnum,
									)}
									value={translateStatusSeparation(
										currentTicket.currentSeparationStatus as TicketSeparationStatusEnum,
									)}
									{...secondInputTicketStyles}
									dashedWhenDisabled={false}
								/>

								<Select
									round
									id='select-new-status'
									placeholder='Mover para'
									{...moveToStyle}
									value={newActionOptionSelected}
									onChange={handlerSelectNewAction}
									options={newStatusSelection.map((item) => ({
										value: item,
										label: translateStatusSeparation(item),
									}))}
								/>
								{newActionOptionSelected?.value ===
									ticketSeparationStatus.SEPARATION_READY_TO_DELIVERY ? (
									<OrderWithdrawalWrapper>
										<FloatLabelInput
											{...primaryInputStyles}
											label='Informe o local para retirada dos pedidos separados'
											round
											id='withdrawal-locale'
											onChange={handleInputChange}
											value={withdrawalInput}
										/>
									</OrderWithdrawalWrapper>
								) : (
									<div />
								)}
							</ActionWrapper>

							<SectionLabel>Informações sobre o Ticket</SectionLabel>
							<div className='text-ticket'>
								<span className='ticket'>Ticket: {currentTicket.ticket}</span>
								{withdrawalDate && (
									<div className="action-dates">
										{currentTicket.approvedAt && (
											<span className="approved-at">
												Aprovado em: {
													format(
														parseISO(
															currentTicket.approvedAt.toString()),
														'dd/MM/yy',
														{ locale: BR }
													)
												}
											</span>
										)}
										<span className="date-scheduling">
											Retirada esperada: {
												dateFormat(
													parseISO(withdrawalDate.date.toString()),
													withdrawalDate.period
												)
											}
										</span>
									</div>
								)}
							</div>
						</Content>
					</>
				)}

				<OrderInformation>
					<Content>
						<div className='input-wrapper'>
							<div>
								<FloatLabelInput
									label='CRIADO POR'
									id='created-by'
									round
									disabled={true}
									value={currentTicket.statusHistory[0]?.assignedBy.name}
									{...primaryDisableInputTicketStyles}
									dashedWhenDisabled={false}
								/>
								<FloatLabelInput
									label='CÓDIGO OU NOME DO CLIENTE'
									id='code-or-name'
									round
									value={codeOrName}
									{...primaryDisableInputTicketStyles}
									disabled={true}
									dashedWhenDisabled={false}
								/>
								<FloatLabelInput
									label='LOJA'
									id='retail'
									round
									value={retail}
									{...primaryDisableInputTicketStyles}
									disabled={true}
									dashedWhenDisabled={false}
								/>
							</div>
							<div>
								<Select
									round
									placeholder='CANAL'
									{...primaryDisableSelectTicketStyles}
									id='select'
									value={selectedChannel}
									isDisabled={true}
									dashedWhenDisabled={false}
								/>
								<Select
									round
									placeholder='PERFIL CLIENTE'
									{...primaryDisableSelectTicketStyles}
									id='select2'
									value={selectedProfile}
									isDisabled={true}
									dashedWhenDisabled={false}
								/>
							</div>
						</div>
					</Content>

					<OrderValueWrapper>
						<OrderRequests>
							<OrderLabel>N de pedidos</OrderLabel>
							<OrderValue>{orders.length}</OrderValue>
						</OrderRequests>
						<OrderRequests>
							<OrderLabel>Valor total</OrderLabel>
							<OrderValue>{toMoney(total)}</OrderValue>
						</OrderRequests>
					</OrderValueWrapper>

					{currentColumnIs('onSeparation', currentTicket.currentSeparationStatus) ? (
						<CheckAllOrdersWrapper>
							<span>Todos os pedidos foram separados</span>
							<input
								onChange={() => handleCheckAllOrders('separation')}
								checked={allOrdersAreSeparated(orders)}
								type='checkbox'
							/>
						</CheckAllOrdersWrapper>
					) : currentColumnIs('onChecking', currentTicket.currentSeparationStatus) ? (
						<CheckAllOrdersWrapper>
							<span>Todos os pedidos foram conferidos</span>
							<input
								onChange={() => handleCheckAllOrders('checking')}
								checked={allOrdersAreChecked(orders)}
								type='checkbox'
							/>
						</CheckAllOrdersWrapper>
					) : null}

					<OrderContainer>
						{orders.length > 0 &&
							orders.map((order, key) => (
								<Fragment key={key}>
									<OrderDisable
										key={key}
										order={order}
										index={key}
									/>

									{separationSelectIsVisible && order.separators && (
										<SelectAndCheckBoxWrapper
											input='separators'
											inputIsVisible={separatorInputWrapper}
										>
											<SeparatorSelectWrapper
												input='separators'
												inputIsVisible={separatorInputWrapper}
											>
												<Select
													round
													placeholder='Definir Separadores'
													{...primaryInputSeparationTicketStyles}
													id='separators'
													options={separatorListOptions}
													value={order.separators.map(separator => {
														return formatSeparatorOrCheckerOption(separator as IUser)
													})}
													onChange={option => handleSeparators(key, option)}
													isMulti
													disabled={true}
												/>
											</SeparatorSelectWrapper>
											<CheckBoxWrapper input='separators'>
												<input
													onChange={e => handleSeparatorCheckBoxChange(key, e)}
													checked={!!order.separationIsCompleted}
													type='checkbox'
													id='separator-checkbox'
												/>
												<span>
													Separação <br />
													Concluída
												</span>
											</CheckBoxWrapper>
										</SelectAndCheckBoxWrapper>
									)}

									{checkerSelectIsVisible && order.checkers && (
										<SelectAndCheckBoxWrapper
											input='checker'
											inputIsVisible={checkerInputWrapper}
										>
											<SeparatorSelectWrapper
												input='checker'
												inputIsVisible={checkerInputWrapper}
											>
												<Select
													round
													placeholder='Definir Conferentes'
													{...primaryInputSeparationTicketStyles}
													id='checkers'
													options={checkerListOptions}
													value={order.checkers.map(separator => {
														return formatSeparatorOrCheckerOption(separator as IUser)
													})}
													onChange={option => handleCheckers(key, option)}
													isMulti
													disabled={true}
												/>
											</SeparatorSelectWrapper>
											<CheckBoxWrapper input='checker'>
												<input
													checked={!!order.checkingIsCompleted}
													onChange={e => handleCheckerCheckBoxChange(key, e)}
													type='checkbox'
													id='checker-checkbox'
												/>
												<span>
													Conferência <br /> Concluída
												</span>
											</CheckBoxWrapper>
										</SelectAndCheckBoxWrapper>
									)}
								</Fragment>
							))}
					</OrderContainer>
				</OrderInformation>

				<ModalFooter>
					<Button
						{...primaryButtonStyles}
						onClick={handleSubmitTicket}
						disabled={!filledData || isLoading}
					>
						Salvar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalTicket
