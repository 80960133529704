import styled, { css } from 'styled-components'
import { fontType } from 'shared/styles/functions'
import { PageTitleWrapper as Title } from 'shared/components/PageTitle/styles'
import { theme } from 'shared/styles/theme'

const containerStyle = () => css`
	width: 100%;
	margin: 0 auto;
	max-width: 1150px;
`

export const Container = styled.div`
	* {
		${fontType(0)}
	}
	max-width: 100vw;
	min-height: 100vh;
	margin: 0 auto;

	background-color: ${theme.colors.lightWhite};
`

export const HeaderServicesReviews = styled.div`
	${containerStyle()}
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;

	> ${Title} {
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}

	@media screen and (max-width: 1200px) {
		flex-direction: column;
	}
`

export const FiltersWrapper = styled.div`
	gap: 1rem;
	display: flex;
	align-items: center;

	@media screen and (max-width: 970px) {
		align-items: center;
		flex-direction: column;
	}
`
export const SelectWrapper = styled.div`
	width: 100%;
	
	.date-picker-primary > .DayPickerInput > input {
		width: unset;
		padding: 15px 32px;
		border: 1px solid ${theme.colors.blue};
	}
	> div > button {
		height: 45px;
		width: 100%;
	}

	> div > button span:nth-child(1) {
		margin-top: 0.4rem;
	}

	&:nth-child(3) > div > button {
		min-width: 130px;
	}
`

export const Label = styled.label`
	font-size: 0.5rem;
	font-family: 'Montserrat Bold', sans-serif;
	font-weight: 700;
	position: absolute;
	margin-left: 1.25rem;
	margin-top: 5px;
	color: #354d9e;
	text-transform: uppercase;
	z-index: 1;
`

export const CardsWrapper = styled.div`
	${containerStyle()}
	display: flex;
	gap: 1rem;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`

export const Card = styled.div`
	width: 100%;
	height: 256px;
	padding: 8px 16px;
	margin-top: 28px;
	margin-bottom: 20px;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	background-color: #e4e7f5;
	border-radius: 8px;

	@media screen and (max-width: 768px) {
		margin-bottom: 1rem;
	}

	> ${Title} {
		max-width: 200px;
		text-align: center;
		margin-bottom: 2.5rem;

		font-size: 20px;
		font-family: 'Montserrat Medium';
	}
`

export const IconsWrapper = styled.div`
	gap: 2.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const IconReview = styled.img`
	margin-bottom: 0.8rem;
`

export const LabelAndIconWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`
export const LabelQuantityEvaluations = styled.span`
	width: 32px;
	height: 32px;

	line-height: 32px;
	border-radius: 50%;
	text-align: center;
	display: inline-block;

	font-weight: bold;
	color: ${theme.colors.blue};
	background-color: #bccaf9;
`

export const GraphCard = styled.div`
	${containerStyle()}
	height: 290px;
	padding: 32px 16px;

	border-radius: 8px;
	background-color: ${theme.colors.white};

	> ${Title} {
		font-size: 14px;
		margin-left: 1rem;
		font-family: 'Montserrat Medium';
	}
`

export const GraphPlaceholderContainer = styled.div`
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;

	span {
		color: ${theme.colors.blue};
	}
`

export const LoadButtonText = styled.span`
	color: ${theme.colors.white};
	font-size: 16px;
	padding: 25px;
`
