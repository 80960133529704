import { fontType, transition } from 'shared/styles/functions'
import styled, { css } from 'styled-components'

interface SeparationDrawerBoardWrapperProps {
	isActiveToClick: boolean
}

interface IHeaderProps {
	currentHeaderClass: boolean
}

export const SeparationDrawerBoardWrapper = styled.button<SeparationDrawerBoardWrapperProps>`
	* {
		${fontType(0)}
	}
	margin-top: 40px;
	background-color: ${({ theme }) => theme.colors.white};
	height: 70px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 24px;
	width: 100%;
	border: none;

	${(props) =>
		props.isActiveToClick &&
		css`
			cursor: pointer;
		`}
`

export const Header = styled.div<IHeaderProps>`
	display: flex;
	justify-content: space-between;
	width: 100%;

	${(props) =>
		props.currentHeaderClass
			? css`
					img {
						width: 24px;
						height: 24px;
						${transition()}
						transform: rotate(180deg);
					}
			  `
			: css`
					img {
						width: 24px;
						height: 24px;
						${transition()}
					}
			  `}
`

export const SectionLabel = styled.h3`
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	${fontType(1)}
`

export const ContainerListCards = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 24px;
	background-color: ${({ theme }) => theme.colors.white};
`

export const GoalContainer = styled.span`
	font-size: 12px;
	font-weight: bold;
	padding: 3px 10px 3px 10px;
	border-radius: 10px;
	margin-right: 16px;
	${(props) =>
		props.className === 'goal-inactive'
			? css`
					color: #c1c3c9;
					background-color: ${({ theme }) => theme.colors.lightGrey2};
			  `
			: css`
					color: ${({ theme }) => theme.colors.blue};
					background-color: ${({ theme }) => theme.colors.lightBlue};
			  `}
`
export const BoardContainer = styled.div`
	width: 100%;
	padding: 0;
`
