import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px 0 30px;

    .disabled-button-wrapper, .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .button-wrapper button {
        border: 1px solid ${props => props.theme.colors.blue};
    }
`

export const ButtonWithIcon = styled.div`
    img {
        width: 18px;
        height: 18px;
        margin-left: 8px;
    }
`

export const WithdrawalInfo = styled.div`
    margin: 20px 0;
    padding: 24px 16px;
    border-radius: 6px;
    background-color: #F0F3FF;

    .label {
        font-size: 10px;
        font-weight: bold;
        color: ${props => props.theme.colors.blue};
    }

    .check-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin-top: 8px;
        font-size: 14px;
        color: ${props => props.theme.colors.blue};
    }
`

export const ArchiveInfoMessage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 17px;

    img {
        width: 17px;
        height: 17px;
        margin-right: 6px;
    }

    span {
        font-size: 10px;
        color: ${props => props.theme.colors.grey}
    }
`
