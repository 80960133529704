import React from 'react'
import { Modal, FloatLabelInput, Select, Button } from '@buildbox/components'
import ModalDelete from 'shared/components/ModalDelete'
import {
	primaryButtonDelete,
	primaryButtonOrderStyles,
	primaryButtonStyles,
	primaryInputTicketStyles,
	secondInputTicketStyles,
	primaryInputWithdrawalStyles,
	selectStyles,
} from 'shared/styles/theme'
import CustomSelect from 'shared/components/CustomSelect'
import { IViewProps } from './types'
import {
	ModalContent,
	SectionLabel,
	ActionWrapper,
	OrderInformation,
	ModalFooter,
	OrderLabel,
	OrderValueWrapper,
	OrderValue,
	OrderRequests,
	Content,
	OrderContainer,
	SectionLabelBackground,
	InputOthers,
	ButtonDeleteTicket,
	ButtonAddOrderContainer,
	Label,
	OrderWithdrawalWrapper,
	PluginRequestMessage,
} from './styles'
import Order from '../Order'
import { translateStatus } from 'shared/util/translate'
import { toMoney } from 'shared/util/currency'
import InfoIcon from '../../assets/images/info.svg'
import RedInfoIcon from '../../assets/images/info-red.svg'
import { getFormattedSchedulingDateLabel } from 'shared/util/schedulingDateOptions'
import ModalConfirmPluginRequest from 'shared/components/ModalConfirmPluginRequest'

const ModalTicket: React.FC<IViewProps> = (props: IViewProps) => {
	const {
		active,
		className,
		handleModal,
		modalAction,
		currentTicket,
		handleSubmitTicket,
		channelOptions,
		handleEffectChannel,
		handleEffectProfile,
		profileOptions,
		selectedChannel,
		selectedProfile,
		orders,
		addOrder,
		setCodeOrName,
		codeOrName,
		removeOrder,
		newStatusSelection,
		others,
		filledData,
		newActionOptionSelected,
		handlerSelectNewAction,
		onChangeOrder,
		calculate,
		total,
		setInputOthers,
		inputOthers,
		changeDeleteModalState,
		remove,
		selectedTicket,
		orderWithdrawalOptionSelected,
		handlerSelectOrderWithdrawal,
		scheduleDateOptions,
		canPluginRequest,
		confirmPluginRequest,
		handleCancelPluginRequest,
		handleConfirmPluginRequest,
		withdrawalMessage,
		isLoading,
		retailOptions,
		handleEffectRetail,
		selectedRetail
	} = props

	return (
		<Modal
			title={modalAction === 'CREATE' ? 'Novo Ticket' : 'Detalhe do Ticket'}
			isActive={active}
			onClose={() => {
				handleModal()
			}}
			className={className}
			size={600}
		>
			<ModalContent>
				{modalAction === 'EDIT' && (
					<>
						<SectionLabelBackground>
							<SectionLabel>{'Ações'}</SectionLabel>
						</SectionLabelBackground>
						<Content>
							<ActionWrapper hasOrders={orders.length > 0}>
								<FloatLabelInput
									label='Coluna atual'
									id='status-current'
									round
									disabled
									placeholder={translateStatus(currentTicket.currentStatus)}
									value={translateStatus(currentTicket.currentStatus)}
									{...secondInputTicketStyles}
								/>
								<Select
									round
									id='select-new-status'
									placeholder='Mover para'
									{...primaryInputTicketStyles}
									value={newActionOptionSelected}
									onChange={handlerSelectNewAction}
									options={newStatusSelection.map((item) => ({
										value: item,
										label: translateStatus(item),
									}))}
								/>

								{orders.length > 0 && (
									<OrderWithdrawalWrapper>
										<Label>
											{currentTicket.currentStatus === 'APPROVED'
												? 'RETIRADA EM'
												: 'RETIRADA MAIS PRÓXIMA'}
										</Label>
										<CustomSelect
											id='order-withdrawal'
											round
											value={orderWithdrawalOptionSelected}
											onChange={handlerSelectOrderWithdrawal}
											options={scheduleDateOptions}
											{...primaryInputWithdrawalStyles}
											styles={selectStyles}
											isDisabled={!canPluginRequest}
										/>

										{withdrawalMessage && (
											<PluginRequestMessage errored={withdrawalMessage.type === 'error'}>
												<img
													src={withdrawalMessage.type === 'error' ? RedInfoIcon : InfoIcon}
													alt='info'
												/>
												<span>{withdrawalMessage.message}</span>
											</PluginRequestMessage>
										)}
									</OrderWithdrawalWrapper>
								)}
							</ActionWrapper>

							<SectionLabel>Informações sobre o Ticket</SectionLabel>
							<div className='text-ticket'>
								<span className='ticket'>Ticket: {currentTicket.ticket}</span>
							</div>
						</Content>
					</>
				)}

				<OrderInformation>
					<Content>
						<div className='input-wrapper'>
							<div>
								<Select
									round
									placeholder='CANAL'
									{...primaryInputTicketStyles}
									id='select'
									options={channelOptions}
									value={selectedChannel}
									onChange={handleEffectChannel}
								/>
								<Select
									round
									placeholder='PERFIL CLIENTE'
									{...primaryInputTicketStyles}
									id='select2'
									options={profileOptions}
									value={selectedProfile}
									onChange={handleEffectProfile}
								/>
								{modalAction === 'EDIT' && (
									<FloatLabelInput
										label='CÓDIGO OU NOME DO CLIENTE'
										id='code-or-name'
										round
										onChange={(e) => setCodeOrName(e.target.value)}
										value={codeOrName}
										{...primaryInputTicketStyles}
									/>
								)}
							</div>
							<div>
								{modalAction === 'EDIT' && (
									<>
										<FloatLabelInput
											label='CRIADO POR'
											id='created-by'
											round
											disabled
											value={currentTicket.statusHistory[0].assignedBy.name}
											{...primaryInputTicketStyles}
										/>
										<Select
											round
											placeholder='LOJA'
											{...primaryInputTicketStyles}
											id='select-retail'
											value={selectedRetail}
											isDisabled
										/>
									</>
								)}
								{modalAction === 'CREATE' && (
									<>
										<FloatLabelInput
											label='CÓDIGO OU NOME DO CLIENTE'
											id='code-or-name'
											round
											onChange={(e) => setCodeOrName(e.target.value)}
											value={codeOrName}
											{...primaryInputTicketStyles}
										/>
										<Select
											round
											placeholder='LOJA'
											{...primaryInputTicketStyles}
											id='select-retail'
											options={retailOptions}
											value={selectedRetail}
											onChange={handleEffectRetail}
										/>
									</>
								)}
							</div>
						</div>
					</Content>

					{others && (
						<InputOthers>
							<FloatLabelInput
								label='Descreva sobre o Perfil do Cliente'
								id='perfil-others'
								round
								onChange={(e) => setInputOthers(e.target.value)}
								value={inputOthers}
								{...secondInputTicketStyles}
							/>
						</InputOthers>
					)}
					<OrderValueWrapper>
						<OrderRequests>
							<OrderLabel>N de pedidos</OrderLabel>
							<OrderValue>{orders.length}</OrderValue>
						</OrderRequests>
						<OrderRequests>
							<OrderLabel>Valor total</OrderLabel>
							<OrderValue>{toMoney(total)}</OrderValue>
						</OrderRequests>
					</OrderValueWrapper>

					<ButtonAddOrderContainer>
						<Button
							{...primaryButtonOrderStyles}
							type='button'
							onClick={addOrder}
							className='button'
						>
							+
						</Button>
					</ButtonAddOrderContainer>
					<OrderContainer>
						{orders.length > 0 &&
							orders.map((order, key) => (
								<Order
									key={key}
									order={order}
									onUpdate={onChangeOrder}
									updateTotal={calculate}
									removeOrder={removeOrder}
									index={key}
								/>
							))}
					</OrderContainer>
				</OrderInformation>

				{modalAction === 'EDIT' && (
					<ButtonDeleteTicket>
						<Button
							className='btn-delete'
							onClick={changeDeleteModalState}
							{...primaryButtonDelete}
						>
							{'Excluir Ticket'}
						</Button>
					</ButtonDeleteTicket>
				)}

				<ModalFooter>
					<Button
						{...primaryButtonStyles}
						onClick={handleSubmitTicket}
						disabled={!filledData || isLoading}
					>
						Salvar
					</Button>
				</ModalFooter>

				<ModalDelete
					isActive={selectedTicket}
					onClose={() => changeDeleteModalState()}
					title='Excluir Ticket'
					message={`Todos os pedidos associados a este ticket serão excluídos. Você
						deseja excluir definitivamente este Ticket?`}
					onConfirm={remove}
					textButton='Excluir Ticket'
				/>

				<ModalConfirmPluginRequest
					isActive={confirmPluginRequest}
					onClose={handleCancelPluginRequest}
					date={getFormattedSchedulingDateLabel(orderWithdrawalOptionSelected)}
					onConfirm={handleConfirmPluginRequest}
				/>
			</ModalContent>
		</Modal>
	)
}

export default ModalTicket
