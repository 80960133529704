import React from 'react'

import { InputPrimaryWrapper } from './styles'
import { IProps } from './types'

function InputPrimary({
	label,
	id,
	className,
	value,
	onChange,
	maxLength,
}: IProps) {
	return (
		<InputPrimaryWrapper className={className}>
			<input
				id={id}
				required
				onChange={onChange}
				value={value}
				maxLength={maxLength}
			/>
			<label htmlFor={id}>{label}</label>
		</InputPrimaryWrapper>
	)
}

export default InputPrimary
