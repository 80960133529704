import { SelectOption } from '@buildbox/components'
import { IFromTo } from 'shared/components/DatePicker/types'
import { IDigitalSeparationDashboardData } from 'shared/interfaces/ticket'

export enum IDigitalTeamGraphVisionOptions {
	AVERAGE_TIME = 'AVERAGE_TIME',
	VOLUME = 'VOLUME',
}

export interface IViewProps {
	handleSetDates(value: IFromTo): void
	filtersValue: IFilterValue
	handleProfessionalSelect(value: SelectOption[]): void
	filtersData: IFilterData
	isLoading: boolean
	handleSegment(value: SelectOption[]): void
	handleCustomerClient(value: SelectOption[]): void
	handleChannel(value: SelectOption[]): void
	handleVisionOption(value: any): void
	handleSelectSteps(value: any): void
	handleRetailSelect(value: any): void
	dashBoardMetrics: IDigitalSeparationDashboardData
	modalIsActives: IModalIsActiveProps
	handleChannelModalGraph(): void
	handleTeamModalGraph(): void
	isEmpty: boolean
	handlePerformanceModal(): void
	handleClientProfileGraph(): void
	exportTicket(): void
	handleTeamGraphOptions(option: string): void
	teamGraphVision: IDigitalVisionEnum
}

export type IDigitalVisionEnum = keyof typeof IDigitalTeamGraphVisionOptions

export interface IFilterValue {
	professionalValue: SelectOption[]
	dateRange: IFromTo
	segmentValue: SelectOption[]
	customerValue: SelectOption[]
	channelValue: SelectOption[]
	retailValue: SelectOption[]
	visionValue: SelectOption
	stepsValue: SelectOption[]
	teamGraphOption: IDigitalVisionEnum
}

export interface IFilterData {
	professionalData: {
		separators: SelectOption[]
		checkers: SelectOption[]
	}
	customerOptions: SelectOption[]
	segmentOptions: SelectOption[]
	channelOptions: SelectOption[]
	retailOptions: SelectOption[]
	visionOptions: SelectOption[]
	stepsOptions: SelectOption[]
	professionalDataToUse: SelectOption[]
}

export interface IModalIsActiveProps {
	channelGraph: boolean
	teamGraph: boolean
	performanceGraph: boolean
	clientProfileGraph: boolean
}

export interface TeamGraphWrapperProps {
	items: number
	graph?: string
}
