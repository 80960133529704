import styled from 'styled-components'

import { fontType, toRem } from '../../shared/styles/functions'

export const Container = styled.div`
	& * {
		${fontType(0)};
	}

	.remove-user-content-modal {
		flex-direction: column;
		align-items: center;
		text-align: center;

		.button-container {
			margin-top: ${toRem(25)};
			justify-content: space-between;
			display: flex;
		}
	}
`
export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;

	.input-filter-section {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		div {
			margin-left: 20px;
			width: 100%;

			#user-input-filter {
			}
		}
	}
`
export const TableContent = styled.div`
	margin-top: ${toRem(40)};
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.table-role-content-operator {
		background-color: ${({ theme }) => theme.colors.lightBlue};
		border-radius: ${toRem(13)};
		opacity: 1;
		color: ${({ theme }) => theme.colors.blue};
		max-width: ${toRem(130)};
		padding: ${toRem(7)};
		font-size: ${toRem(10)};
		margin: ${toRem(2)};
	}

	.table-role-content-attendant {
		background-color: ${({ theme }) => theme.colors.white};
		border-radius: ${toRem(13)};
		opacity: 1;
		color: ${({ theme }) => theme.colors.blue};
		border: 1px solid ${({ theme }) => theme.colors.blue};
		max-width: ${toRem(130)};
		padding: ${toRem(7)};
		font-size: ${toRem(10)};
		margin: ${toRem(2)};
	}

	.table-role-content-digital-manager {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		border-radius: ${toRem(13)};
		opacity: 1;
		color: ${({ theme }) => theme.colors.white};
		max-width: ${toRem(130)};
		padding: ${toRem(7)};
		font-size: ${toRem(10)};
		margin: ${toRem(2)};
	}

	.table-role-content-store-manager {
		background-color: ${({ theme }) => theme.colors.secondaryLightBlue};
		border-radius: ${toRem(13)};
		opacity: 1;
		color: ${({ theme }) => theme.colors.white};
		max-width: ${toRem(130)};
		padding: ${toRem(7)};
		font-size: ${toRem(10)};
		margin: ${toRem(2)};
	}

	.table-role-content-admin {
		background-color: ${({ theme }) => theme.colors.blue};
		border-radius: ${toRem(13)};
		opacity: 1;
		color: ${({ theme }) => theme.colors.lightGrey};
		max-width: ${toRem(130)};
		padding: ${toRem(7)};
		font-size: ${toRem(10)};
		margin: ${toRem(2)};
	}

	.trash-icon,
	.settings-icon {
		cursor: pointer;
	}
	.table-container {
		width: 100%;
		overflow-x: auto;
	}
`
export const TableRetailContentWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export const ProfileNameContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export const IconWrapper = styled.div`
	min-width: 20px;
`

export const TableRetailContent = styled.span`
	background-color: ${({ theme }) => theme.colors.grey};
	border-radius: ${toRem(13)};
	opacity: 1;
	color: ${({ theme }) => theme.colors.lightWhite};
	max-width: ${toRem(130)};
	padding: ${toRem(7)};
	font-size: ${toRem(10)};
	margin: ${toRem(2)};
`
export const ModalContent = styled.div`
	.create-user-button-container,
	.button-container {
		display: flex;
		justify-content: center;
		margin-top: ${toRem(25)};
	}

	.input-photo {
		display: flex;
		justify-content: center;
	}

	.input-container,
	.select-container,
	.select-retail {
		margin-top: ${toRem(10)};
	}
`

export const SelectionsContainer = styled.div`
	width: 100%;
	height: 105px;
	display: flex;
	background-color: ${({ theme }) => theme.colors.white};
	margin-bottom: 8px;
	border-radius: 4px;
	align-items: center;
	justify-content: space-between;
	padding: 0px 40px;

	div {
		display: flex;

		input {
			width: 17px;
			height: 17px;
			margin-right: 24px;
			accent-color: ${({ theme }) => theme.colors.blue}
		}

		p {
			color: ${({ theme }) => theme.colors.blue};
			text-align: left;
			letter-spacing: 0.19px;
		}
	}
`
