import { SelectOption, TableNavProps } from '@buildbox/components'
import { ITableColumn, ITableRow } from 'shared/interfaces/utils'
import { IStoreAisle } from 'shared/interfaces/storeAisle'

export const COUNTERS_PER_PAGE = 10

export type ModalType = 'CREATE/UPDATE' | 'DELETE' | ''
export interface IChangeModalVisibility {
	modal: ModalType
	storeAisle?: IStoreAisle
}

export interface ICloseModalProps {
    refetchData?: boolean
	retail?: string
}

export interface IStoreAisleRow {
    retail: string
    areaName: string
    areaDescription: string
}

export interface IViewProps {
	retailOptions: SelectOption[]
	selectedRetail: SelectOption | null
	handleChangeRetail: (selectedOption: any) => void
	isFetchingStoreAisles: boolean
	tableColumns: ITableColumn[]
	tableRows: ITableRow<IStoreAisleRow>[]
	navProps: TableNavProps
	selectedStoreAisle: IStoreAisle | undefined
	modalVisibility: ModalType
	changeModalVisibility: (props: IChangeModalVisibility) => void
	closeModal: (props: ICloseModalProps) => void
	handleDeleteStoreAisle: (storeAisleId: string) => void
}
