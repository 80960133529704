import styled from 'styled-components'
import { fontType } from 'shared/styles/functions'

export const GraphContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 420px;
	${fontType(0)}
`

export const LabelsWrapper = styled.div``

export const LegendWrapper = styled.label``
