import { toRem, transition, fontType } from 'shared/styles/functions'
import styled, { css, keyframes } from 'styled-components'
import { theme } from 'shared/styles/theme'

export const modalContainer = styled.div``

export const LabelBackground = styled.div``

interface IActionWrapper {
	hasOrders: boolean
}

export const ActionWrapper = styled.div<IActionWrapper>`
	display: grid;
	${props => props.hasOrders ? css`
		grid-template-areas:
			'currentStatus actionStatus'
			'orderWithdrawal orderWithdrawal'
		;
		grid-template-columns: 1fr 1fr;
		& > div:first-child {
			grid-area: currentStatus;
		}
		& > div:nth-child(2) {
			grid-area: actionStatus;
		}
		& > div:last-child {
			grid-area: orderWithdrawal;
		}
	` : css`
		grid-template-columns: 1fr 1fr;
	`}

	justify-content: space-between;

	margin: 10px 0px 10px 0px;

	gap: 1rem;
	margin-bottom: 25px;
`

export const animeTop = keyframes`
  to{
    opacity: 1;
    transform: initial;
  }
`

export const InputOthers = styled.div`
	margin: 10px 0px 10px 0px;
	${transition(0.5)}
	opacity: 0;
	transform: translateY(-20px);
	animation: ${animeTop} 0.4s forwards;
`

export const ModalContent = styled.form`
	/* #select {
		width: 201px;
	} */
`
export const SectionLabel = styled.span`
	font-size: 16px;
`

export const SectionLabelBackground = styled.div`
	padding: 10px;

	span {
		cursor: pointer;
	}
`
export const Content = styled.div`
	/* display: flex; */
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	flex-direction: column;

	.input-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
		margin-top: 20px;
		justify-content: space-between;
		width: 100%;

		#select2 {
			margin: 10px 0;
		}

		#created-by, #code-or-name {
			margin-bottom: 10px;
		}
	}

	.ticket {
		background: #becdff;
		padding: 10px;
		border-radius: 30px;
		color: #354d9e;
		font-size: 14px;
	}

	.text-ticket {
		text-align: center;
		margin-top: 25px;
	}
`

export const OrderInformation = styled.div`
	margin-top: 30px;
`
export const ModalFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
`
export const OrderValueWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
`

export const OrderLabel = styled.span`
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: 12px;
	margin-bottom: 4px;
`
export const OrderValue = styled.span`
	color: ${({ theme }) => theme.colors.blue};
	font-size: 18px;
`
export const OrderRequests = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`
export const OrderContainer = styled.div`
	margin-top: 10px;

	::-webkit-scrollbar-track {
		background-color: #f4f4f4;
	}
	::-webkit-scrollbar {
		width: 6px;
		background: #f4f4f4;
	}
	::-webkit-scrollbar-thumb {
		background: #dad7d7;
	}
`
export const ButtonAddOrderContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`
export const ButtonDeleteTicket = styled.div`
	text-align: center;
	margin-top: ${toRem(30)};

	.btn-delete button {
		border: 1px solid ${({ theme }) => theme.colors.red};
	}
`

export const Label = styled.label`
	font-size: ${toRem(8)};
	${fontType(2)};
	position: absolute;
	margin-left: ${toRem(20)};
	margin-top: 10px;
	color: ${({ theme }) => theme.colors.blue};
	text-transform: uppercase;
	z-index: 1;
`

export const OrderWithdrawalWrapper = styled.div``;

interface IPluginRequestMessageProps {
	errored?: boolean
}

export const PluginRequestMessage = styled.div<IPluginRequestMessageProps>`
	display: flex;
	align-items: center;

	margin: 8px 0 0 20px;

	img {
		margin-right: 5px;
		height: 16px;
		width: 16px;
	}

	span {
	color: ${props => props.errored ? theme.colors.red : theme.colors.blue};
	font-size: 14px;
	}
`;
