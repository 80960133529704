import { FloatLabelInputWrapper } from './styles'
import { IFloatLabelInputProps } from './types'

import React from 'react'
import { colors } from 'shared/components/CustomSelect/theme'
import { paddingToString } from 'shared/components/CustomSelect/functions'

function ShortInput({
	label,
	id,
	className,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	dashedWhenDisabled = true,
	onChange,
	value,
	capture,
	...rest
}: IFloatLabelInputProps) {
	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString,
		dashedWhenDisabled,
	}

	return (
		<FloatLabelInputWrapper styles={mergedStyles} className={className}>
			<input
				className='fli-input'
				onChange={onChange}
				value={value}
				{...rest}
				id={id}
				required
			/>

			<label className='fli-label' htmlFor={id}>
				{label}
			</label>
		</FloatLabelInputWrapper>
	)
}

export default ShortInput
