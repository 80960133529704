import React from 'react';

import { primaryButtonStyles } from 'shared/styles/theme'
import { Modal, Button } from '@buildbox/components'
import {
  ButtonWrapper,
  ModalContainer
} from './styles';

interface IProps {
  date: string
  isActive: boolean
  onClose: () => void
  onConfirm: () => void

}

const ModalDelete = (props: IProps) => {
  const {
    isActive,
    onClose,
    date,
    onConfirm
  } = props

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      title={'Confirmar Encaixe?'}
      size={308}
    >
      <ModalContainer>
        <p>{`
          Você está solicitando um encaixe para ${date}. Iremos analisar e retornaremos com a confirmação.
        `}</p>

        <ButtonWrapper>
          <Button
            type="button"
            className="confirm-button"
            onClick={onConfirm}
            {...primaryButtonStyles}
          >
            Confirmar
          </Button>
        </ButtonWrapper>
      </ModalContainer>
    </Modal>
  )
}

export default ModalDelete;