import { fontType, toRem, transition } from 'shared/styles/functions'
import styled from 'styled-components'

export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`

export const ModalWrapper = styled.div`
	${fontType(0)}
`

export const TableWrapper = styled.div`
	margin-top: 28px;
`
export const RenderIconInTable = styled.div`
	cursor: pointer;

	&:active {
		transform: translateY(1px);
		${transition(0)}
	}
`

export const ProfileLabel = styled.span`
	background-color: ${({ theme }) => theme.colors.lightBlue};
	border-radius: ${toRem(13)};
	opacity: 1;
	color: ${({ theme }) => theme.colors.blue};
	max-width: ${toRem(130)};
	padding: ${toRem(7)};
	font-size: ${toRem(10)};
	margin: ${toRem(2)};
	${fontType(1)}
`
