import { createElement, ChangeEvent, useCallback, useEffect, useState } from 'react'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { sendPushNotification } from 'shared/services/notification.service'

import { IViewProps } from './types'
import PushNotificationsView from './view'

function PushNotifications() {
	const [title, setTitle] = useState('')
	const [body, setBody] = useState('')
	const [isSubmiting, setIsSubmiting] = useState(false)
	const [isFormValid, setIsFormValid] = useState(false)

	function validateForm() {
		const isValid = !!title && !!body
		setIsFormValid(isValid)
	}

	function clearFields() {
		setTitle('')
		setBody('')
	}

	const handleChangeTitle = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
		setTitle(e.target.value)
	}, [])

	const handleChangeBody = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
		setBody(e.target.value)
	}, [])

	const handleSubmit = useCallback(() => {
		(async () => {
			if (!isFormValid) return

			try {
				setIsSubmiting(true)

				await sendPushNotification({ title, body })

				clearFields()

				cogoToast.success('Push Notification enviada com sucesso!', cogoDefaultOptions)
			} finally {
				setIsSubmiting(false)
			}
		})()
	}, [title, body, isFormValid])

	useEffect(validateForm, [title, body])

	const viewProps: IViewProps = {
		handleChangeTitle,
		title,
		handleChangeBody,
		body,
		handleSubmit,
		isSubmiting,
		isFormValid,
	}

	return createElement(PushNotificationsView, viewProps)
}

export default PushNotifications
