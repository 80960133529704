import { useState, useCallback, useEffect, createElement } from 'react'
import { SelectOption } from '@buildbox/components'
import { useDebounce } from 'use-debounce/lib'
import { ticketSeparationStatus } from 'shared/interfaces/ticket'
import { listCheckers } from 'shared/services/user.service'
import { fetchUpdateTicket } from 'shared/services/ticket.service'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

import { IProps, IViewProps } from './types'
import { formatWithdrawalAssistantOptions } from './util'
import ModalTicket from './view'
import Ticket from './models/Ticket'
import { IUser } from 'shared/interfaces/user'

function ModalWithDrawal(props: IProps): JSX.Element {
	const {
		active,
		className,
		closeModal,
		selectedTicket,
	} = props

	const { user } = useTypedSelector(['user'])

	const [ticket, setTicket] = useState(new Ticket({ ticket: selectedTicket }))
	const [
		withdrawalAssistantsOptions,
		setWithdrawalAssistantsOptions
	] = useState<SelectOption[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const [
		saveAssistantsDebounce
	] = useDebounce(ticket.dataToShow.withdrawalAssistants, 2000)

	function fetchWithdrawalAssistants() {
		(async () => {
			try {
				const withdrawalAssistantsList = await listCheckers([selectedTicket.retail])
	
				setWithdrawalAssistantsOptions(
					formatWithdrawalAssistantOptions(
						withdrawalAssistantsList as IUser[]
					)
				)
			} catch (error) {
				console.error({ error })
			}
		})()
	}

	function submitWithdrawalAssistants() {
		(async () => {
			if (ticket.hasChangesInAssistants) {
				try {
					const statusAction = ticket.dataToShow.statusAction

					const updatedTicketResponse = await fetchUpdateTicket(
						ticket.getId,
						ticket.payload({ sendStatusAction: false }),
						user._id
					)
					const updatedTicketModel = new Ticket({
						ticket: updatedTicketResponse,
						statusAction: statusAction
					})
					setTicket(
						updatedTicketModel.setStatusAction(statusAction as ticketSeparationStatus)
					)
				} catch (error) {
					console.error({ error })
				}
			}
		})()
	}

	function setAction(statusAction: ticketSeparationStatus) {
		setTicket(ticket.setStatusAction(statusAction))
	}

	function setWithdrawalAssistants(value: any) {
		const options = (value || []) as SelectOption[]

		setTicket(ticket.setWithdrawalAssistants(options))
	}

	function confirmAction() {
		if (isLoading) return

		;(async () => {
			try {
				setIsLoading(true)

				const updatedTicketResponse = await fetchUpdateTicket(
					ticket.getId,
					ticket.payload({ sendStatusAction: true }),
					user._id
				)
					setTicket(new Ticket({ ticket: updatedTicketResponse }))
			} catch (error) {
				console.error({ error })
			} finally {
				setIsLoading(false)
			}
		})()
	}

	const handleCloseModal = useCallback(closeModal, [])
	const handleSetAction = useCallback(setAction, [setTicket, ticket])
	const handleSetWithdrawalAssistants = useCallback(setWithdrawalAssistants, [
		setTicket,
		ticket
	])
	const handleConfirmAction = useCallback(confirmAction, [
		isLoading,
		setIsLoading,
		fetchUpdateTicket,
		ticket,
		user,
		setTicket
	])

	useEffect(fetchWithdrawalAssistants, [])
	useEffect(submitWithdrawalAssistants, [saveAssistantsDebounce])

	const viewProps: IViewProps = {
		active,
		className,
		ticket,
		withdrawalAssistantsOptions,
		user,
		handleCloseModal,
		handleSetAction,
		handleSetWithdrawalAssistants,
		handleConfirmAction
	}

	return createElement(ModalTicket, viewProps)
}

export default ModalWithDrawal
