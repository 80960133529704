import React from 'react'
import { LogHistoryWrapper } from './styles'
import { ISearchTable } from './types'

export function SearchTableInput(props: ISearchTable): JSX.Element {
	const { onChange, value, label, onClick } = props
	return (
		<form>
			<LogHistoryWrapper className='pagination-button-container'>
				<span>{label}</span>
				<div>
					<input type='number' onChange={onChange} value={value} />
					<button onClick={onClick}>
						<img
							src={require('../../../assets/images/search.svg')}
							alt='search table'
						/>
					</button>
				</div>
			</LogHistoryWrapper>
		</form>
	)
}
