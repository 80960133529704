import { fontType } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.select-retail {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-bottom: 40px;

		& > div {
			min-width: 50%;
		}
	}

	.form-wrapper {
		width: 100%;
		gap: 12px;
		display: flex;
		flex-direction: column;
	}

	.inputs-group {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.input-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			width: 187px;
			margin-bottom: 40px;

			& > span {
				margin-top: 16px;
				padding: 4px 8px;
				border-radius: 11px;
				background-color: ${theme.colors.lightBlue};
				color: ${theme.colors.blue};
				font-size: 10px;
				${fontType(1)}
			}
		}

		.input-container + .input-container {
			margin-left: 16px;
		}
	}

	.input-container-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.submit-button {
		align-self: center;
		margin-top: 12px;
	}
`
