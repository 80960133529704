/* eslint-disable no-mixed-operators */
import {
	ChangeEvent,
	createElement,
	FormEvent,
	useEffect,
	useState,
} from 'react'
import { IModalTicketSeparation, IProps, IViewProps } from './types'
import ModalTicket from './view'
import {
	ChannelTypeEnum,
	CustomerProfileEnum,
	ticketSeparationStatus,
	TicketSeparationStatusEnum,
} from 'shared/interfaces/ticket'
import { fetchUpdateTicket } from 'shared/services/ticket.service'
import { SelectOption } from '@buildbox/components'
import { IOrder } from 'shared/interfaces/order'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { CustomerProfile, labelChannel } from 'shared/util/Consts'
import {
	allOrdersHaveSeparators,
	allOrdersHaveCheckers,
	allOrdersAreSeparated,
	allOrdersAreChecked,
	haveChangesInTheSeparators,
	haveChangesInTheCheckers,
	haveChangesInTheCheckbox,
	completeTheSeparationOfAllOrders,
	completeTheCheckingOfAllOrders,
} from 'shared/util/separation'
import { deepCopy } from 'shared/util/object'
import { listCheckers, listSeparator } from 'shared/services/user.service'
import { IUser } from 'shared/interfaces/user'
import {
	primaryInputTicketStyles,
	secondarySelectTicketStyles,
} from 'shared/styles/theme'
import { IDefaultStyles } from '@buildbox/components/lib/styles/types'
import { separationStatusActionAllowed } from 'modules/DashboardSeparation/util'

function ModalSeparationCards(props: IProps): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const {
		active,
		className,
		handleModal,
		modalAction,
		currentTicket,
		withdrawalDate,
		retailSeparationParams
	} = props
	const [filledData, setFilledData] = useState(false)
	const [newStatusSelection, setNewStatusSelection] = useState<
		TicketSeparationStatusEnum[]
	>([])
	const [newActionOptionSelected, setNewActionOptionSelected] =
		useState<SelectOption | null>(null)
	const [orderWithdrawalOptionSelected, setOrderWithdrawalOptionSelected] =
		useState<SelectOption>()
	const [selectedChannel, setSelectedChannel] = useState<SelectOption | null>(
		null,
	)
	const [channel, setChannel] = useState<ChannelTypeEnum>('STORE')
	const [selectedProfile, setSelectedProfile] = useState<SelectOption | null>(
		null,
	)
	const [profile, setProfile] = useState<CustomerProfileEnum>('B2B_CONSUMPTION')
	const [selectedAction, setSelectedAction] =
		useState<TicketSeparationStatusEnum>()
	const [selectedOrderWithdrawal, setSelectedOrderWithdrawal] = useState('')
	const [ticketNumber, setTicketNumber] = useState(0)
	const [codeOrName, setCodeOrName] = useState('')
	const [selectOthers, setSelectOthers] = useState(false)
	const [orders, setOrders] = useState<IOrder[]>([])
	const [inputOthers, setInputOthers] = useState<string>('')
	const [total, setTotal] = useState<number>(0)
	const [ticketRetail] = useState(
		user.retails.find(userRetail => userRetail._id === currentTicket.retail)
	)
	const [separatorListOptions, setSeparatorListOptions] = useState<
		SelectOption[]
	>([])
	const [checkerListOptions, setCheckerListOptions] = useState<SelectOption[]>(
		[],
	)
	const [withdrawalInput, setWithdrawalInput] = useState('')
	const [separatorInputWrapper, setSeparatorInputWrapper] = useState(false)
	const [checkerInputWrapper, setCheckerInputWrapper] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [populateOrdersNow, setPopulateOrdersNow] = useState(false)

	function initializeTicketFields() {
		const currentTicketCopy = deepCopy(currentTicket)

		setTicketNumber(currentTicketCopy.ticket)
		setChannel(
			currentTicketCopy._id
				? (currentTicketCopy.channel as ChannelTypeEnum)
				: 'STORE',
		)
		setCodeOrName(currentTicketCopy.codeOrName)
		actionsStatusAllowed(
			currentTicketCopy.currentSeparationStatus as TicketSeparationStatusEnum,
		)
		setProfile(currentTicketCopy.customerProfile as CustomerProfileEnum)

		setOrders(currentTicketCopy.orders)
		setPopulateOrdersNow(true)

		const total = currentTicketCopy.orders.reduce(
			(total, value) => total + value.totalValue,
			0,
		)
		setTotal(total)
	}

	function checkEmpty(value: string) {
		return value === '' ? true : false
	}

	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		setWithdrawalInput(event.target.value)
	}

	function handleSeparators(orderIndex: number, selectedOptions: any): void {
		if (!selectedOptions) return

		const updatedOrderSeparators = orders.map((orderItem, index) => {
			if (index === orderIndex) {
				orderItem.separators = selectedOptions.map(
					(option: any) =>
						({
							_id: option.value,
							name: option.label,
						} as IUser),
				)
			}
			return orderItem
		})

		setOrders(updatedOrderSeparators)
	}

	function handleSeparatorCheckBoxChange(
		orderIndex: number,
		event: ChangeEvent<HTMLInputElement>,
	) {
		const updatedSeparationCompleted = orders.map((orderItem, index) => {
			if (index === orderIndex) {
				orderItem.separationIsCompleted = event.target.checked
					? new Date()
					: undefined
			}
			return orderItem
		})

		setOrders(updatedSeparationCompleted)
	}

	function handleCheckers(orderIndex: number, selectedOptions: any): void {
		if (!selectedOptions) return

		const updatedOrderCheckers = orders.map((orderItem, index) => {
			if (index === orderIndex) {
				orderItem.checkers = selectedOptions.map(
					(option: any) =>
						({
							_id: option.value,
							name: option.label,
						} as IUser),
				)
			}
			return orderItem
		})

		setOrders(updatedOrderCheckers)
	}

	function handleCheckerCheckBoxChange(
		orderIndex: number,
		event: ChangeEvent<HTMLInputElement>,
	) {
		const updatedCheckingCompleted = orders.map((orderItem, index) => {
			if (index === orderIndex) {
				orderItem.checkingIsCompleted = event.target.checked
					? new Date()
					: undefined
			}
			return orderItem
		})

		setOrders(updatedCheckingCompleted)
	}

	function handlerSelectNewAction(selectedOption: SelectOption | null): void {
		if (!selectedOption) return

		setNewActionOptionSelected(selectedOption)
		setSelectedAction(selectedOption.value as TicketSeparationStatusEnum)
	}

	function handlerSelectOrderWithdrawal(
		selectedOption: SelectOption | null,
	): void {
		if (!selectedOption) return

		const { value, label } = selectedOption
		const { date } = JSON.parse(label)
		const labelDate = JSON.stringify({ date })

		setOrderWithdrawalOptionSelected({ value: value, label: labelDate })
		setSelectedOrderWithdrawal(value)
	}

	function actionsStatusAllowed(
		currentSeparationStatus: TicketSeparationStatusEnum,
	) {
		setNewStatusSelection(
			separationStatusActionAllowed[currentSeparationStatus],
		)
	}

	function findProfileSelect(): SelectOption | null {
		const findedProfile = CustomerProfile.find((x) => {
			return x.value === String(profile)
		})

		if (findedProfile === undefined) return null

		return {
			label: findedProfile.label,
			value: findedProfile.value,
		}
	}

	function findChannelSelect(): SelectOption | null {
		const findedChannel = labelChannel.find((x) => {
			return x.value === String(channel)
		})

		if (findedChannel === undefined) return null

		return {
			label: findedChannel.label,
			value: findedChannel.value,
		}
	}

	async function handleSubmitTicket(e: FormEvent<HTMLButtonElement>) {
		e.preventDefault()

		const ticketEdit: IModalTicketSeparation = {
			ticket: ticketNumber,
			channel,
			codeOrName,
			currentSeparationStatus: currentTicket.currentSeparationStatus,
			customerProfile: selectOthers ? inputOthers : profile,
			orders: orders,
			separationActionStatus: selectedAction,
			withdrawalLocale: withdrawalInput,
		}

		try {
			if (!currentTicket._id) return

			setIsLoading((state) => !state)

			await fetchUpdateTicket(currentTicket._id, ticketEdit, user._id)

			cogoToast.success('Ticket atualizado com sucesso!', cogoDefaultOptions)

			handleModal()
		} finally {
			setIsLoading((state) => !state)
		}
	}

	async function fetchSeparationParams() {
		try {
			const separators = await listSeparator([currentTicket.retail])
			const checkers = await listCheckers([currentTicket.retail])

			const separatorsList = separators.map((user) => {
				return {
					value: user._id,
					label: user.name,
				}
			})

			const checkerList = checkers.map((user) => {
				return {
					value: user._id,
					label: user.name,
				}
			})

			setSeparatorListOptions(separatorsList)
			setCheckerListOptions(checkerList)
		} catch (error) {}
	}

	function populateSeparatorsAndCheckers() {
		if (
			!orders.length ||
			!separatorListOptions.length ||
			!checkerListOptions.length
		)
			return

		const ordersPopulate = orders.map((order) => {
			order.separators = order.separators
				? order.separators
						.map((separatorId) => {
							const separator = separatorListOptions.find(
								(item) => item.value === separatorId,
							)

							return separator
								? ({ _id: separator.value, name: separator.label } as IUser)
								: separatorId
						})
						.filter((separator: any) => separator._id && separator.name)
				: []
			order.checkers = order.checkers
				? order.checkers
						.map((checkerId) => {
							const checker = checkerListOptions.find(
								(item) => item.value === checkerId,
							)

							return checker
								? ({ _id: checker.value, name: checker.label } as IUser)
								: checkerId
						})
						.filter((separator: any) => separator._id && separator.name)
				: []

			return order
		})

		setOrders(ordersPopulate)
	}

	const separationSelectIsVisible =
		newActionOptionSelected?.value ===
			ticketSeparationStatus.SEPARATION_ON_SEPARATION ||
		[
			ticketSeparationStatus.SEPARATION_ON_SEPARATION,
			ticketSeparationStatus.SEPARATION_SEPARATED,
			ticketSeparationStatus.SEPARATION_ON_CHECKING,
			ticketSeparationStatus.SEPARATION_CHECKED,
		].includes(currentTicket.currentSeparationStatus as ticketSeparationStatus)

	const checkerSelectIsVisible =
		newActionOptionSelected?.value ===
			ticketSeparationStatus.SEPARATION_ON_CHECKING ||
		currentTicket.currentSeparationStatus ===
			ticketSeparationStatus.SEPARATION_ON_CHECKING ||
		currentTicket.currentSeparationStatus ===
			ticketSeparationStatus.SEPARATION_CHECKED

	function inputWrapper() {
		if (
			currentTicket.currentSeparationStatus ===
			ticketSeparationStatus.SEPARATION_PENDING
		) {
			setSeparatorInputWrapper(true)
		} else if (
			currentTicket.currentSeparationStatus ===
				ticketSeparationStatus.SEPARATION_ON_SEPARATION ||
			currentTicket.currentSeparationStatus ===
				ticketSeparationStatus.SEPARATION_SEPARATED
		) {
			setSeparatorInputWrapper(false)
			setCheckerInputWrapper(true)
		} else {
			setSeparatorInputWrapper(false)
			setCheckerInputWrapper(false)
		}
	}

	function fieldValidation(): void {
		if (
			currentTicket.currentSeparationStatus ===
			ticketSeparationStatus.SEPARATION_PENDING
		) {
			setFilledData(
				allOrdersHaveSeparators(orders) &&
					newActionOptionSelected?.value ===
						ticketSeparationStatus.SEPARATION_ON_SEPARATION,
			)
		} else if (
			currentTicket.currentSeparationStatus ===
				ticketSeparationStatus.SEPARATION_ON_SEPARATION ||
			currentTicket.currentSeparationStatus ===
				ticketSeparationStatus.SEPARATION_SEPARATED
		) {
			if (
				newActionOptionSelected?.value ===
				ticketSeparationStatus.SEPARATION_ON_CHECKING
			) {
				setFilledData(
					allOrdersHaveSeparators(orders) &&
						allOrdersAreSeparated(orders) &&
						allOrdersHaveCheckers(orders),
				)
			} else {
				setFilledData(
					(allOrdersHaveSeparators(orders) &&
						haveChangesInTheSeparators(orders, currentTicket.orders)) ||
						haveChangesInTheCheckbox(
							'separation',
							orders,
							currentTicket.orders,
						),
				)
			}
		} else if (
			currentTicket.currentSeparationStatus ===
				ticketSeparationStatus.SEPARATION_ON_CHECKING ||
			currentTicket.currentSeparationStatus ===
				ticketSeparationStatus.SEPARATION_CHECKED
		) {
			if (
				newActionOptionSelected?.value ===
				ticketSeparationStatus.SEPARATION_READY_TO_DELIVERY
			) {
				setFilledData(
					allOrdersHaveSeparators(orders) &&
						allOrdersAreSeparated(orders) &&
						allOrdersHaveCheckers(orders) &&
						allOrdersAreChecked(orders) &&
						!checkEmpty(withdrawalInput),
				)
			} else {
				setFilledData(
					(allOrdersHaveSeparators(orders) &&
						allOrdersAreSeparated(orders) &&
						allOrdersHaveCheckers(orders) &&
						haveChangesInTheSeparators(orders, currentTicket.orders)) ||
						haveChangesInTheCheckbox(
							'separation',
							orders,
							currentTicket.orders,
						) ||
						haveChangesInTheCheckers(orders, currentTicket.orders) ||
						haveChangesInTheCheckbox('checking', orders, currentTicket.orders),
				)
			}
		}
	}

	function moveToCurrentStyle() {
		let moveToStyle: Partial<IDefaultStyles>

		if (
			[
				ticketSeparationStatus.SEPARATION_ON_SEPARATION,
				ticketSeparationStatus.SEPARATION_SEPARATED,
			].includes(
				currentTicket.currentSeparationStatus as ticketSeparationStatus,
			)
		) {
			moveToStyle = allOrdersAreSeparated(orders)
				? secondarySelectTicketStyles
				: primaryInputTicketStyles
		} else if (
			currentTicket.currentSeparationStatus ===
			ticketSeparationStatus.SEPARATION_PENDING
		) {
			moveToStyle = secondarySelectTicketStyles
		} else {
			moveToStyle =
				allOrdersAreSeparated(orders) && allOrdersAreChecked(orders)
					? secondarySelectTicketStyles
					: primaryInputTicketStyles
		}
		return moveToStyle
	}

	function handleCheckAllOrders(action: 'separation' | 'checking') {
		if (action === 'separation') {
			setOrders(completeTheSeparationOfAllOrders(orders))
		} else if (action === 'checking') {
			setOrders(completeTheCheckingOfAllOrders(orders))
		}
	}

	function populateOrdersWithDefaultSeparators() {
		(async () => {
			if (populateOrdersNow && !!separatorListOptions.length) {
				const defaultSeparators = retailSeparationParams.defaultSeparators

				if (!!defaultSeparators && !!defaultSeparators.length) {
					const populatedDefaultSeparators = separatorListOptions.filter(
						separator => defaultSeparators.includes(separator.value)
					).map(separator => ({
						_id: separator.value,
						name: separator.label
					} as IUser))

					const populatedOrders = orders.map(order => {
						return !order.separators || !order.separators.length
							? ({
								...order,
								separators: populatedDefaultSeparators
							})
							: order
					})

					setOrders(populatedOrders)
					setPopulateOrdersNow(false)
				}
			}
		})()
	}

	useEffect(() => {
		setSelectedProfile(findProfileSelect())
		setSelectedChannel(findChannelSelect())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channel, profile])

	useEffect(() => {
		initializeTicketFields()
		fetchSeparationParams()
		inputWrapper()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		selectedProfile?.value === 'OTHERS'
			? setSelectOthers(true)
			: setSelectOthers(false)
	}, [selectedProfile])

	useEffect(() => {
		actionsStatusAllowed(
			currentTicket.currentSeparationStatus as TicketSeparationStatusEnum,
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orders])

	useEffect(() => {
		if (
			currentTicket.customerProfile !== 'B2B_CONSUMPTION' &&
			currentTicket.customerProfile !== 'B2B_RETAIL'
		) {
			setProfile('OTHERS')
			setInputOthers(currentTicket.customerProfile)
		}
	}, [currentTicket])

	useEffect(populateSeparatorsAndCheckers, [
		separatorListOptions,
		checkerListOptions,
	])

	useEffect(fieldValidation, [
		channel,
		profile,
		codeOrName,
		orders,
		selectedAction,
		inputOthers,
		selectedOrderWithdrawal,
		withdrawalInput,
	])

	useEffect(populateOrdersWithDefaultSeparators, [
		populateOrdersNow,
		separatorListOptions
	])

	const viewProps: IViewProps = {
		active,
		className,
		handleSubmitTicket,
		handleModal,
		modalAction,
		currentTicket,
		selectedChannel,
		selectedProfile,
		orders,
		codeOrName,
		newStatusSelection,
		others: selectOthers,
		filledData,
		handlerSelectNewAction,
		newActionOptionSelected,
		total,
		setInputOthers,
		inputOthers,
		orderWithdrawalOptionSelected,
		handlerSelectOrderWithdrawal,
		separatorListOptions,
		checkerListOptions,
		handleSeparators,
		handleCheckers,
		separationSelectIsVisible,
		checkerSelectIsVisible,
		withdrawalInput,
		handleInputChange,
		handleSeparatorCheckBoxChange,
		handleCheckerCheckBoxChange,
		separatorInputWrapper,
		checkerInputWrapper,
		moveToStyle: moveToCurrentStyle(),
		isLoading,
		withdrawalDate,
		handleCheckAllOrders,
		retail: ticketRetail?.name || ''
	}

	return createElement(ModalTicket, viewProps)
}

export default ModalSeparationCards
