import { ISelectOption } from "@buildbox/components/lib/components/Select/types";
import { IUser } from "shared/interfaces/user";

export const options = (option: ISelectOption[]) => [
    {
        label: 'Todos',
        options: option,
    },
]

export function formatSeparatorOrCheckerOption(user: IUser) {
    return {
        label: user.name,
        value: user._id
    }
}