import React, { createElement } from 'react'

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { routesData } from './data'
import { redirectRoute } from '../../shared/util/redirect'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import Page from 'shared/components/Page'
import {
	IPermission,
	permissionNameValues,
} from 'shared/interfaces/permissions'

function Routes(): JSX.Element {
	const { user, token } = useTypedSelector(['user', 'token'])

	return (
		<BrowserRouter>
			<Switch>
				{routesData.map(
					({ requirePermission, component, path, isFull, ...props }) => {
						if (!requirePermission) {
							return (
								<Route exact path={path} key={path}>
									{createElement(component, props)}
								</Route>
							)
						} else if (
							user.profile &&
							user.profile.permissions.some((permission: IPermission) => {
								return (
									`/${permission.screenUrl}` === path &&
									permission.name === permissionNameValues.EDIT
								)
							})
						) {
							return (
								<Route exact path={path} key={path}>
									<Page isFull={isFull}>{createElement(component, props)}</Page>
								</Route>
							)
						}

						return null
					},
				)}

				<Redirect
					from={'*'}
					to={redirectRoute(user.profile, token.accessToken)}
				/>
			</Switch>
		</BrowserRouter>
	)
}

export default Routes
