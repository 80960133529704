import React from 'react'
import {
	CardWrapper,
	BadgeTicket,
	CardWrapperContainer,
	OrderCounter,
	Header,
	Footer,
	Content,
	CreatedByWrapper,
	BadgeRetail
} from './styles'
import packageSVG from '../../assets/images/package.svg'
import userSVG from 'assets/images/user.svg'
import { IAttendanceCardTicket } from './types'
import { translateChannelName } from 'shared/util/translate'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

const CardTicket: React.FC<IAttendanceCardTicket> = ({ data, handleClick }) => {
	const { user } = useTypedSelector(['user'])

	const ticketRetailName = user.retails.find(userRetail => userRetail._id === data.retail)

	return (
		<CardWrapperContainer onClick={() => handleClick()}>
			<CardWrapper>
				{!!ticketRetailName && <BadgeRetail>{ticketRetailName?.name}</BadgeRetail>}
				<Header>
					<BadgeTicket>{data.ticket}</BadgeTicket>
					<OrderCounter>
						<img src={packageSVG} alt='Package' />
						<span>{data.orders.length}</span>
					</OrderCounter>
				</Header>
				<Content>
					<h4>{data.codeOrName}</h4>

					<span>{translateChannelName(data.channel)}</span>

					<CreatedByWrapper>
						<img src={userSVG} alt='User' />
						<span>{data.statusHistory[0].assignedBy.name}</span>
					</CreatedByWrapper>
				</Content>
			</CardWrapper>
			<Footer>{data.formatedCreatedAt}</Footer>
		</CardWrapperContainer>
	)
}

export default CardTicket
