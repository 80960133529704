import styled from 'styled-components'

export const CardWrapper = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
	padding: 20px 20px 18px;
	min-width: 150px;

	h3 {
		font-size: 12px;
	}

	.value {
		font-size: 20px;
		color: ${({ theme }) => theme.colors.blue};
	}

	.percent {
		font-size: 9px;
	}
	.label-container {
		flex-direction: column;
		display: flex;
		align-items: center;
	}
`
