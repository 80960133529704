import React, { useEffect, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { GraphContainer } from './styles'
import { theme } from 'shared/styles/theme'
import { fontType, toRem } from 'shared/styles/functions'
import { ILogsSummaryData, IProps } from './types'
import { translateSegment } from 'shared/util/translate'

function SeparationCustomerProfile(props: IProps): JSX.Element {
	const { dataGrafic, selectedDates } = props

	const [graphData, setGraphData] = useState<ILogsSummaryData[]>([])

	useEffect(() => {
		if (!dataGrafic) return
		const mapGrafic = dataGrafic.map((e) => {
			return {
				'Total': e.total ? e.total.toFixed(0) : 0,
				customerProfile: translateSegment(e.customerProfile),
			}
		})

		setGraphData(mapGrafic)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataGrafic, selectedDates])

	const containerTooltip = {
		background: theme.colors.darkGrey,
		color: 'white',
		borderRadius: 4,
		width: '320px',
		height: '60px',
		fontType: fontType(0),
		fontSize: toRem(12),
		padding: '8px 16px 8px 8px',
	}

	return (
		<GraphContainer style={{ height: '177px' }}>
			<ResponsiveBar
				data={graphData}
				keys={['Total']}
				indexBy={'customerProfile'}
				margin={{ top: 50, right: 130, bottom: 50, left: 130 }}
				padding={0.3}
				borderRadius={2}
				layout='horizontal'
				colors={['#354D9E', '#A2B3EB']}
				axisTop={null}
				axisBottom={{
					tickRotation: 0,
					tickSize: 0,
					legend: '',
					format: (e) => Math.floor(Number(e)) === e ? e : '',
				}}
				labelTextColor='#444444'
				theme={{
					tooltip: { container: containerTooltip },
					fontFamily: 'Montserrat Regular',
					grid: {
						line: {
							stroke: 'nivo',
						},
					},
					axis: {
						ticks: {
							text: { fontSize: 10, letterSpacing: '0.48px', opacity: '0.8' },
							line: {
								stroke: 'red',
							},
						},
					},
					markers: {
						lineColor: 'red',
						lineStrokeWidth: 1,
						text: {},
					},
				}}
				label={'Total'}
				enableLabel={false}
				axisLeft={{ tickPadding: 5, tickRotation: 0, tickSize: 0 }}
				axisRight={{
					renderTick: ({ textAnchor, textBaseline, value, y }: any) => {
						return (
							<g transform={`translate(${28},${y})`}>
								<text
									alignmentBaseline={textBaseline}
									style={{ fontSize: 10, fill: '#354D9E', padding: 150 }}
									textAnchor={textAnchor}
								>
									{dataGrafic.find(
										(x) => translateSegment(x.customerProfile) === value,
									)?.total || 0}
								</text>
							</g>
						)
					},
				}}
				animate={true}
			/>
		</GraphContainer>
	)
}

export default SeparationCustomerProfile
