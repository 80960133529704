import { IDigitalVisionEnum } from 'modules/DashboardSeparation/types'
import { IOrder } from './order'
import { IUser } from './user'

export interface ITicket {
	_id?: string
	ticket: number
	channel: ChannelTypeEnum
	codeOrName: string
	customerProfile: string
	orders: IOrder[]
	numberOfOrders: number
	currentSeparationStatus?: TicketSeparationStatusEnum
	currentStatus: TicketStatusEnum
	statusHistory: IStatusChange[]
	statusSeparationHistory: ISeparationStatusChange[] | any
	isLast?: boolean
	updatedAt: Date
	createdAt: Date
	formatedCreatedAt?: string
	approvedAt?: Date
	withdrawalLocale?: string
	withdrawalAssistants?: IUser[]
	retail: string
}

export interface ITicketPayload {
	ticket: number
	channel: ChannelTypeEnum
	codeOrName: string
	customerProfile: string
	orders: IOrder[]
	currentStatus?: TicketStatusEnum
	actionStatus?: TicketStatusEnum
	currentSeparationStatus?: TicketSeparationStatusEnum
	separationActionStatus?: TicketSeparationStatusEnum
	withdrawalAssistants?: string[]
	withdrawalLocale?: string
	retail?: string
}

export interface ICardListPayload {
	professionals: string[]
	channels: ChannelTypeEnum[]
	retails: string[]
}

export interface IStatusChange {
	status: TicketStatusEnum
	assignedAt: Date
	assignedBy: {
		_id: string
		name: string
	}
}

export interface ISeparationStatusChange {
	status: TicketSeparationStatusEnum
	assignedAt: Date
	assignedBy: {
		_id: string
		name: string
	}
}

enum CustomerProfile {
	B2B_RETAIL,
	B2B_CONSUMPTION,
	OTHERS,
}

// export interface IStatusChange {
// 	status: TicketStatusEnum
// 	assignedAt: Date
// }

enum ChannelType {
	STORE,
	WHATSAPP,
	ECOMMERCE,
	DESK,
	EMAIL,
	TELEPHONE,
}

enum TicketStatus {
	ATTENDANCE,
	QUOTE_SENT,
	NEGOTIATION,
	NEGOTIATION_SENT,
	APPROVED,
	INVOICED,
	CANCELED,
	PENDING,
	SEPARATION,
	CONFERENCE,
}

export enum ticketSeparationStatus {
	SEPARATION_PENDING = 'SEPARATION_PENDING',
	SEPARATION_PLUGIN_REQUEST = 'SEPARATION_PLUGIN_REQUEST',
	SEPARATION_ON_SEPARATION = 'SEPARATION_ON_SEPARATION',
	SEPARATION_SEPARATED = 'SEPARATION_SEPARATED',
	SEPARATION_ON_CHECKING = 'SEPARATION_ON_CHECKING',
	SEPARATION_CHECKED = 'SEPARATION_CHECKED',
	SEPARATION_READY_TO_DELIVERY = 'SEPARATION_READY_TO_DELIVERY',
	SEPARATION_PLUGIN_REQUEST_APPROVED = 'SEPARATION_PLUGIN_REQUEST_APPROVED',
	SEPARATION_PLUGIN_REQUEST_REFUSED = 'SEPARATION_PLUGIN_REQUEST_REFUSED',
	WITHDRAWAL_FULFILLED_CHECK_IN = 'WITHDRAWAL_FULFILLED_CHECK_IN',
	WITHDRAWAL_FULFILLED_CHECK_OUT = 'WITHDRAWAL_FULFILLED_CHECK_OUT',
	WITHDRAWAL_SHELVED = 'WITHDRAWAL_SHELVED',
}

export const TicketStatusValues = [
	'ATTENDANCE',
	'QUOTE_SENT',
	'NEGOTIATION',
	'NEGOTIATION_SENT',
	'APPROVED',
	'INVOICED',
	'CANCELED',
]

export const TicketStatusValueOptions = {
	ATTENDANCE: 'ATTENDANCE',
	QUOTE_SENT: 'QUOTE_SENT',
	NEGOTIATION: 'NEGOTIATION',
	NEGOTIATION_SENT: 'NEGOTIATION_SENT',
	APPROVED: 'APPROVED',
	INVOICED: 'INVOICED',
	CANCELED: 'CANCELED',
}

export enum TicketSeparationStatus {
	SEPARATION_PENDING,
	SEPARATION_PLUGIN_REQUEST,
	SEPARATION_PLUGIN_REQUEST_APPROVED,
	SEPARATION_PLUGIN_REQUEST_REFUSED,
	SEPARATION_ON_SEPARATION,
	SEPARATION_SEPARATED,
	SEPARATION_ON_CHECKING,
	SEPARATION_CHECKED,
	SEPARATION_READY_TO_DELIVERY,
	WITHDRAWAL_FULFILLED_CHECK_OUT,
	WITHDRAWAL_SHELVED,
	WITHDRAWAL_FULFILLED_CHECK_IN,
}

export const TicketSeparationStatusValues: TicketSeparationStatusEnum[] = [
	'SEPARATION_PENDING',
	'SEPARATION_PLUGIN_REQUEST',
	'SEPARATION_PLUGIN_REQUEST_APPROVED',
	'SEPARATION_PLUGIN_REQUEST_REFUSED',
	'SEPARATION_ON_SEPARATION',
	'SEPARATION_SEPARATED',
	'SEPARATION_ON_CHECKING',
	'SEPARATION_CHECKED',
	'SEPARATION_READY_TO_DELIVERY',
	'WITHDRAWAL_SHELVED',
	'WITHDRAWAL_FULFILLED_CHECK_OUT',
	'WITHDRAWAL_FULFILLED_CHECK_IN',
]

export const TicketSeparationStatusOptions: {
	[key: string]: TicketSeparationStatusEnum
} = {
	SEPARATION_PENDING: 'SEPARATION_PENDING',
	SEPARATION_PLUGIN_REQUEST: 'SEPARATION_PLUGIN_REQUEST',
	SEPARATION_PLUGIN_REQUEST_APPROVED: 'SEPARATION_PLUGIN_REQUEST_APPROVED',
	SEPARATION_PLUGIN_REQUEST_REFUSED: 'SEPARATION_PLUGIN_REQUEST_REFUSED',
	SEPARATION_ON_SEPARATION: 'SEPARATION_ON_SEPARATION',
	SEPARATION_SEPARATED: 'SEPARATION_SEPARATED',
	SEPARATION_ON_CHECKING: 'SEPARATION_ON_CHECKING',
	SEPARATION_CHECKED: 'SEPARATION_CHECKED',
	SEPARATION_READY_TO_DELIVERY: 'SEPARATION_READY_TO_DELIVERY',
	WITHDRAWAL_FULFILLED_CHECK_IN: 'WITHDRAWAL_FULFILLED_CHECK_IN',
	WITHDRAWAL_FULFILLED_CHECK_OUT: 'WITHDRAWAL_FULFILLED_CHECK_OUT',
	WITHDRAWAL_SHELVED: 'WITHDRAWAL_SHELVED',
}

export const ChannelTypeValues = [
	'STORE',
	'WHATSAPP',
	'ECOMMERCE',
	'DESK',
	'EMAIL',
	'TELEPHONE',
]

export enum VisionType {
	TICKET,
	ORDER,
	REVENUE,
}

export const VisionTypeOptions = {
	TICKET: 'TICKET',
	ORDER: 'ORDER',
	REVENUE: 'REVENUE',
}

export interface IRequest {
	startDate: string
	endDate: string
	professionals: string[]
	clients: string[]
	segments: string[]
	retails: string[]
	channels: ChannelTypeEnum[]
	vision: VisionTypeEnum
	currentSeparationStatus?: string | undefined | null
}

export interface ISeparationDashboardRequest {
	startDate: string
	endDate: string
	professionals: string[]
	clients: string[]
	segments: string[]
	channels: ChannelTypeEnum[]
	retails: string[]
	vision: VisionTypeEnum
	separationStages: TicketSeparationStatusEnum[]
	TeamGraphVision: IDigitalVisionEnum
}

export interface IDigitalDashboardData {
	totalRevenueAverageDash: ITotalRevenueAverageDash | IOpenedApprovedAverageDash
	channelDash: IChannelStats[]
	teamDash: ITeamStats[]
	performanceDash: IPerformanceDash
	clientsProfileDash: IClientProfileStats[]
	timesDash: {
		timesAttendingDash: ITimesDash
		timesSeparationDash: ITimesDash
	}
}

export interface IDigitalSeparationDashboardData {
	totalRevenueAverageDash:
	| ITotalRevenueSeparationAverageDash
	| IOpenedApprovedAverageDash
	channelDash: ISeparationChannelStats[]
	teamDash: ISeparationTeamStats[]
	performanceDash: ISeparationPerformanceDash
	clientsProfileDash: ISeparationCustomerProfileStats[]
	timesDash: {
		timesAttendingDash: ITimesDash
		timesSeparationDash: ITimesDash
	}
}

export interface ISeparationChannelStats {
	name: string
	total: number
	percent: number
}

export interface ISeparationTeamStats {
	name: string
	total: number
	percent: number
	averageTime: number
}

export interface ISeparationCustomerProfileStats {
	customerProfile: string
	total: number
}

export interface ISeparationPerformanceDash {
	timezoneOffset: number
	xTickLabel: string[]
	value: IPerformanceDashValue[]
}

export interface IPerformanceDashValue {
	ticket: number
	x: number
	y: number
	z: number
}

export interface ITotalRevenueSeparationAverageDash {
	totalUnits: number
	totalRevenue: number
	lasPeriodUnit: number
	averagePeriodUnit: number
	averageRevenuePerUnit: number
	averages: {
		averageUnitsPerChannel: number
		averageUnitsPerAttendant: number
		averageItemsPerUnit: number
		averageSkusPerUnit: number
	}
}

export interface ITotalRevenueAverageDash {
	totalUnits: number
	percentUnitsApproved: number
	lasPeriodUnit: number
	averagePeriodUnit: number
	totalRevenue: number
	averageRevenuePerUnit: number
	averages: {
		averageUnitsPerChannel: number
		averageUnitsPerAttendant: number
		averageItemsPerUnit: number
		averageSkusPerUnit: number
	}
}

export interface IOpenedApprovedAverageDash {
	totalRevenue: number
	totalApprovedRevenue: number
	percentRevenueApproved: number
	averages: {
		averageRevenuePerChannel: number
		averageRevenuePerAttendant: number
		averageRevenuePerItem: number
		averageRevenuePerSku: number
	}
}

export interface IPerformanceDash {
	timezoneOffset: number
	xTickLabel: string[]
	totalOpened: number[]
	totalApproved: number[]
}

export interface IClientProfileStats {
	customerProfile: string
	totalOpened: number
	totalApproved: number
}
export interface ITimesDash {
	sectionTitle?: string
	averageTotalAttendanceTime: number
	attendanceTimesPerStatus: IStatusAttendanceTime[]
}

export interface IStatusAttendanceTime {
	status: string
	averageTime: number
}

export interface ITeamStats {
	name: string
	totalOpened: number
	percentOpened: number
	totalApproved: number
	percentApproved: number
}

export interface IChannelStats {
	name: string
	totalOpened: number
	percentOpened: number
	totalApproved: number
	percentApproved: number
}
export interface IScheduleDateOption {
	_id: string
	date: string
	period: string
	tickets: ITicket[]
	totalOrders: number
	maxItems: number
	retail: string
}

export interface ISeparationDateOption {
	_id?: string
	date: Date
	period: 'morning' | 'afternoon'
	schedules: number
	limit: number
}

export enum IGraphTypeEnum {
	TEAM = 'TEAM',
	CHANNEL = 'CHANNEL',
}

export type CustomerProfileEnum = keyof typeof CustomerProfile
export type ChannelTypeEnum = keyof typeof ChannelType
export type VisionTypeEnum = keyof typeof VisionType
export type TicketStatusEnum = keyof typeof TicketStatus
export type TicketSeparationStatusEnum = keyof typeof ticketSeparationStatus
export type ICardsList = ITicket[]
