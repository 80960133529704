import React, { Fragment } from 'react'
import { Select, Button } from '@buildbox/components'
import { ticketSeparationStatus } from 'shared/interfaces/ticket'
import {
    primaryButtonStyles,
    primaryInputSeparationTicketStyles,
    whiteModalButtonStyles,
    whiteModalDisabledButtonStyles,
    secondaryDisabledInputSeparationTicketStyles
} from 'shared/styles/theme'
import ArchiveSVG from '../../../assets/images/archive-icon.svg'
import CircleInfoSVG from '../../../assets/images/archive-info-icon.svg'
import {
    Container,
    WithdrawalInfo,
    ButtonWithIcon,
    ArchiveInfoMessage
} from './styles'
import { IProps } from './types'

const StatusActionComponent: React.FC<IProps> = (props) => {
    const {
        ticket,
        handleSetAction,
        withdrawalAssistantsOptions,
        handleSetWithdrawalAssistants,
        handleConfirmAction
    } = props

	const {
		withdrawalAssistants,
	} = ticket.dataToShow

    const disableAssistantsSelect =
        ticket.currentColumnIs('checkOut') || ticket.currentColumnIs('shelved')

    const selectStyles = !disableAssistantsSelect
        ? primaryInputSeparationTicketStyles
        : secondaryDisabledInputSeparationTicketStyles

    return (
        <Container>
            {!ticket.currentColumnIs('readyToWithdrawal') || (ticket.currentColumnIs('readyToWithdrawal') && ticket.hasAValidStatusAction) ? (
                <Fragment>
                    <Select
                        round
                        placeholder='AUXILIARES DE ENTREGA'
                        {...selectStyles}
                        id='checkers'
                        options={withdrawalAssistantsOptions}
                        value={withdrawalAssistants}
                        onChange={handleSetWithdrawalAssistants}
                        isMulti
                        disabled={disableAssistantsSelect}
                        isDisabled={disableAssistantsSelect}
                        dashedWhenDisabled={false}
                    />
                    <WithdrawalInfo>
                        <span className="label">LOG DE RETIRADA</span>
                        {ticket.checkInDateInfo ? (
                            <div className="check-info">
                                <span>Check-In</span>
                                <span>{ticket.checkInDateInfo}</span>
                            </div>
                        ) : null}
                        {ticket.checkOutDateInfo ? (
                            <div className="check-info">
                                <span>Check-Out</span>
                                <span>{ticket.checkOutDateInfo}</span>
                            </div>
                        ) : null}
                    </WithdrawalInfo>
                </Fragment>
            ) : null}
            {ticket.currentColumnIs('readyToWithdrawal') ? (
                <div className="button-wrapper">
                    <Button
                        {...primaryButtonStyles}
                        type='button'
                        onClick={() => !ticket.hasAValidStatusAction
                            ? handleSetAction(ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_IN)
                            : handleConfirmAction()
                        }
                    >
                        {!ticket.hasAValidStatusAction ? 'Check-In' : 'Confirmar Check-In'}
                    </Button>
                </div>
            ) : ticket.currentColumnIs('checkIn') ? (
                <div className="button-wrapper">
                    <Button
                        {...whiteModalButtonStyles}
                        type='button'
                        onClick={() => !ticket.hasAValidStatusAction
                            ? handleSetAction(ticketSeparationStatus.WITHDRAWAL_FULFILLED_CHECK_OUT)
                            : handleConfirmAction()
                        }
                    >
                        {!ticket.hasAValidStatusAction ? 'Check-Out' : 'Confirmar Check-Out'}
                    </Button>
                </div>
            ) : ticket.currentColumnIs('checkOut') ? (
                <Fragment>
                    <div className="button-wrapper">
                        <Button
                            {...whiteModalButtonStyles}
                            type='button'
                            onClick={() => !ticket.hasAValidStatusAction
                                ? handleSetAction(ticketSeparationStatus.WITHDRAWAL_SHELVED)
                                : handleConfirmAction()
                            }
                        >
                            <ButtonWithIcon>
                                {!ticket.hasAValidStatusAction ? 'Arquivar' : 'Confirmar Arquivamento'}
                                <img src={ArchiveSVG} alt="" />
                            </ButtonWithIcon>
                        </Button>
                    </div>
                    <ArchiveInfoMessage>
                        <img src={CircleInfoSVG} alt="" />
                        <span>ARQUIVAR TICKET SOMENTE APÓS PAGAMENTO E RETIRADA</span>
                    </ArchiveInfoMessage>
                </Fragment>
            ) : (
                <div className="disabled-button-wrapper">
                    <Button
                        {...whiteModalDisabledButtonStyles}
                        disabled
                    >
                        Arquivado
                    </Button>
                </div>
            )}
        </Container>
    )
}

export default StatusActionComponent
