import { ISelectOption } from '@buildbox/components/lib/components/Select/types'

export interface IDropdownButtonLabelProps {
	placeholderButtonLabel: string
	value: ISelectOption[] | ISelectOption
}

export function getDropdownButtonLabelOverride({
	placeholderButtonLabel,
	value,
}: IDropdownButtonLabelProps) {
	if (!value) return placeholderButtonLabel

	if (!Array.isArray(value)) return value.label

	if (value.length === 0) {
		return placeholderButtonLabel
	}
	if (value.length === 1) {
		return value[0].label
	}
	return `${value.length} selecionados`
}
