import { createElement, useState, useEffect, useCallback } from 'react'
import {
	startOfWeek,
	endOfToday,
	formatISO,
	startOfDay,
	endOfDay,
} from 'date-fns'
import { IFromTo } from '../../shared/components/DatePicker/types'

import { IViewProps } from './types'
import DashboardView from './view'
import { SelectOption } from '@buildbox/components'
import { labelChannel, SetVision } from 'shared/util/Consts'
import { useDebounce } from 'use-debounce/lib'
import { fetchAllProfessional } from 'shared/services/user.service'
import {
	exportTicketService,
	fetchAllCustomers,
	fetchAllSegments,
	fetchChannelsDashboard,
} from 'shared/services/ticket.service'
import { translateSegment } from 'shared/util/translate'
import {
	ChannelTypeEnum,
	IDigitalDashboardData,
	IOpenedApprovedAverageDash,
	ITotalRevenueAverageDash,
	VisionTypeEnum,
} from 'shared/interfaces/ticket'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import {
	IPermission,
	permissionNameValues,
} from 'shared/interfaces/permissions'
import { roundAverageValues } from 'shared/util/dashboardUtil'

function Dashboard(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const [canFilterProfessionals] = useState(
		(user.profile.permissions as IPermission[]).some((permission) => {
			return permission.name === permissionNameValues.FILTER_PROFESSIONALS
		}),
	)
	const [isLoading, setLoading] = useState(false)
	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfWeek(new Date(), { weekStartsOn: 1 }),
		to: endOfToday(),
	})

	const [selectedProfessional, setSelectedProfessional] = useState<
		SelectOption[]
	>([])

	const [professionalsOptions, setProfessionalsOptions] = useState<
		SelectOption[]
	>([])

	const [selectedCustomer, setSelectedCustomer] = useState<SelectOption[]>([])
	const [clientsOptions, setClientsOptions] = useState<SelectOption[]>([])

	const [selectedSegment, setSelectedSegment] = useState<SelectOption[]>([])
	const [segmentsOptions, setSegmentsOptions] = useState<SelectOption[]>([])

	const [selectedChannel, setSelectedChannel] =
		useState<SelectOption[]>(labelChannel)

	const [visionOptions, setVisionOptions] = useState<SelectOption[]>([])

	const [selectedVision, setSelectedVision] = useState<SelectOption | null>(
		null,
	)

	const [retailOptions] = useState<SelectOption[]>(
		user.retails.map((userRetail) => ({
			value: String(userRetail._id),
			label: userRetail.name,
		})),
	)
	const [selectedRetails, setSelectedRetails] = useState<SelectOption[]>(
		user.retails.map((userRetail) => ({
			value: String(userRetail._id),
			label: userRetail.name,
		})),
	)

	const [vision, setVision] = useState<VisionTypeEnum>('TICKET')

	const [metrics, setMetrics] = useState<IDigitalDashboardData>(
		{} as IDigitalDashboardData,
	)

	const [openModalChannelGraph, setOpenModalChannelGraph] = useState(false)
	const [openModalTeamGraph, setOpenModalTeamGraph] = useState(false)
	const [openModalPerformanceGraph, setOpenModalPerformanceGraph] =
		useState(false)

	const [isFirstRequest, setIsFirtRequest] = useState<boolean>(true)
	const [isEmpty, setIsEmpty] = useState(true)

	const [datesDebounce] = useDebounce(selectedDates, 1000)
	const [visionDebounce] = useDebounce(vision, 1000)
	const [channelDebounce] = useDebounce(selectedChannel, 1000)
	const [customerDebounce] = useDebounce(selectedCustomer, 1000)
	const [segmentDebounce] = useDebounce(selectedSegment, 1000)
	const [professionalDebounce] = useDebounce(selectedProfessional, 1000)
	const [retailsDebounce] = useDebounce(selectedRetails, 1000)

	function verifyIfDashIsEmpty() {
		if (!!!metrics.channelDash) return

		const channelIsEmpty = metrics.channelDash.every((e) => e.totalOpened === 0)
		const clientslIsEmpty = metrics.clientsProfileDash.every(
			(e) => e.totalOpened === 0,
		)
		const performanceIsEmpty = metrics.performanceDash.totalApproved.every(
			(e) => e === 0,
		)

		const metricsTotalRevenue =
			metrics.totalRevenueAverageDash as ITotalRevenueAverageDash

		const teamDashIsEmpty = metrics.teamDash.every((e) => e.totalOpened === 0)

		const timesDashIsEmpty =
			metrics.timesDash.timesAttendingDash.averageTotalAttendanceTime === 0

		const timesDashPerformanceIsEmpty =
			metrics.timesDash.timesSeparationDash.averageTotalAttendanceTime === 0

		const totalRevenueAverageDashIsEmpty = metricsTotalRevenue.totalUnits === 0
		const allEmpty =
			channelIsEmpty &&
			clientslIsEmpty &&
			performanceIsEmpty &&
			teamDashIsEmpty &&
			timesDashIsEmpty &&
			totalRevenueAverageDashIsEmpty &&
			timesDashPerformanceIsEmpty

		setIsEmpty(allEmpty)
	}

	function fetchData() {
		;(async () => {
			try {
				setLoading(true)

				if (
					professionalDebounce.length === 0 ||
					customerDebounce.length === 0 ||
					segmentDebounce.length === 0 ||
					channelDebounce.length === 0 ||
					retailsDebounce.length === 0
				)
					return

				await getSetMetrics()
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	async function getSetProfessional(retailsId: string[]) {
		const professional = await fetchAllProfessional(retailsId)

		const professionalOptions = professional.map((item: any) => ({
			label: item.name,
			value: item._id,
		}))

		setProfessionalsOptions(professionalOptions)
		setSelectedProfessional(professionalOptions)
	}

	async function getSetCustomer(retailsId: string[]) {
		const customers: string[] = await fetchAllCustomers(retailsId)

		const customersOptions = customers.map((item) => ({
			label: item,
			value: item,
		}))
		setClientsOptions(customersOptions)
		setSelectedCustomer(customersOptions)
	}

	async function getSetSegment(retailsId: string[]) {
		const segments: string[] = await fetchAllSegments(retailsId)

		const segmentsOptions = segments.map((item) => ({
			label: translateSegment(item),
			value: item,
		}))
		setSegmentsOptions(segmentsOptions)
		setSelectedSegment(segmentsOptions)
	}

	function handleSetDates(value: IFromTo) {
		setSelectedDates(value)
	}
	function handleProfessionalSelect(value: SelectOption[]): void {
		setSelectedProfessional(value)
	}
	function handleCustomerSelect(value: SelectOption[]): void {
		setSelectedCustomer(value)
	}
	function handleSegmentSelect(value: SelectOption[]): void {
		setSelectedSegment(value)
	}
	function handleChannelSelect(value: SelectOption[]): void {
		setSelectedChannel(value)
	}

	function handleViewSelected(value: SelectOption | null) {
		if (!value) return

		setVisionOptions(SetVision)
		setSelectedVision(value)
		setVision(value.value as VisionTypeEnum)
	}

	async function getSetMetrics() {
		const startDate = formatISO(startOfDay(datesDebounce.from as Date))
		const endDate = formatISO(endOfDay(datesDebounce.to as Date))
		const channels = channelDebounce.map((x) => x.value)
		const customers = customerDebounce.map((x) => x.value)
		const segments = segmentDebounce.map((x) => x.value)
		const professionals = professionalDebounce.map((x) => x.value)
		const retails = retailsDebounce.map((x) => x.value)

		const {
			totalRevenueAverageDash,
			channelDash,
			teamDash,
			performanceDash,
			clientsProfileDash,
			timesDash,
		} = await fetchChannelsDashboard({
			startDate,
			endDate,
			professionals,
			clients: customers,
			segments: segments,
			channels: channels as ChannelTypeEnum[],
			vision,
			retails,
		})

		if (vision === 'REVENUE') {
			const dash = totalRevenueAverageDash as IOpenedApprovedAverageDash
			totalRevenueAverageDash.averages = roundAverageValues({
				averagePerChannel: dash.averages.averageRevenuePerChannel,
				averagePerAttendant: dash.averages.averageRevenuePerAttendant,
				averagePerItem: dash.averages.averageRevenuePerItem,
				averagePerSku: dash.averages.averageRevenuePerSku,
			})
		} else {
			const dash = totalRevenueAverageDash as ITotalRevenueAverageDash
			totalRevenueAverageDash.averages = roundAverageValues({
				averagePerChannel: dash.averages.averageUnitsPerChannel,
				averagePerAttendant: dash.averages.averageUnitsPerAttendant,
				averagePerItem: dash.averages.averageItemsPerUnit,
				averagePerSku: dash.averages.averageSkusPerUnit,
			})
		}
		setMetrics({
			channelDash,
			clientsProfileDash,
			performanceDash,
			teamDash,
			timesDash,
			totalRevenueAverageDash,
		})
	}

	function exportTicket() {
		const startDate = formatISO(startOfDay(datesDebounce.from as Date))
		const endDate = formatISO(endOfDay(datesDebounce.to as Date))
		const channels = channelDebounce.map((x) => x.value)
		const customers = customerDebounce.map((x) => x.value)
		const segments = segmentDebounce.map((x) => x.value)
		const professionals = professionalDebounce.map((x) => x.value)
		const retails = retailsDebounce.map((x) => x.value)

		exportTicketService({
			startDate,
			endDate,
			professionals,
			clients: customers,
			segments: segments,
			channels: channels as ChannelTypeEnum[],
			vision,
			dashboardData: 'attendance',
			retails,
		})
	}

	const handleChangeRetails = useCallback((selectedOptions: any) => {
		if (!selectedOptions) return

		setSelectedRetails(selectedOptions)
	}, [])

	useEffect(() => {
		;(async () => {
			if (!isFirstRequest) return

			const hasData = [
				customerDebounce,
				segmentDebounce,
				professionalDebounce,
				retailsDebounce,
			]
				.map((x) => x.length > 0)
				.every((x) => x === true)

			if (hasData) {
				fetchData()
				setIsFirtRequest(false)
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isFirstRequest,
		customerDebounce,
		segmentDebounce,
		professionalDebounce,
		retailsDebounce,
	])

	useEffect(() => {
		if (isFirstRequest) return

		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		customerDebounce,
		segmentDebounce,
		datesDebounce,
		visionDebounce,
		channelDebounce,
		professionalDebounce,
		retailsDebounce,
	])

	useEffect(() => {
		async function init() {
			setVision('TICKET')
			try {
				setLoading(true)

				const retailsId = selectedRetails.map((retail) => String(retail.value))

				await getSetCustomer(retailsId)
				await getSetSegment(retailsId)
				await getSetProfessional(retailsId)
			} catch (error) {
			} finally {
				setLoading(false)
			}
		}

		init()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRetails])

	useEffect(() => {
		verifyIfDashIsEmpty()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metrics])

	const viewProps: IViewProps = {
		isLoading,
		selectedDates,
		handleSetDates,
		handleProfessionalSelect,
		handleCustomerSelect,
		handleSegmentSelect,
		handleChannelSelect,
		handleViewSelected,
		handleChangeRetails,

		setOpenModalChannelGraph,
		setOpenModalTeamGraph,
		setOpenModalPerformanceGraph,
		openModalChannelGraph,
		openModalTeamGraph,
		openModalPerformanceGraph,

		visionOptions,
		selectedChannel,
		selectedProfessional,
		professionalsOptions,
		selectedCustomer,
		customerOptions: clientsOptions,
		selectedSegment,
		segmentsOptions,
		selectedVision,
		retailOptions,
		selectedRetails,
		vision,
		metrics,
		isEmpty,
		canFilterProfessionals,
		exportTicket,
	}

	return createElement(DashboardView, viewProps)
}

export default Dashboard
