import { DisableButton, FloatLabelInputWrapper } from './styles'
import { IFloatLabelInputProps } from './types'

import React, { useState } from 'react'
import { colors } from 'shared/components/CustomSelect/theme'
import { paddingToString } from 'shared/components/CustomSelect/functions'
import { X, Search } from 'react-feather'

function FloatLabelInput({
	label,
	id,
	className,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	dashedWhenDisabled = true,
	infoMessage = '',
	errorMessage = '',
	hasError = false,
	infoColor = '',
	errorColor = '',
	handleClick,
	onChange,
	value,
	capture,
	...rest
}: IFloatLabelInputProps) {
	const [isFocused, setFocused] = useState(false)
	const [isBluried, setBluried] = useState(false)

	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString,
		dashedWhenDisabled,
		infoColor,
		errorColor,
	}

	function handleOnBlur() {
		setFocused(false)
		setBluried(true)
	}

	function handleOnFocus() {
		setFocused(true)
		setBluried(false)
	}

	return (
		<FloatLabelInputWrapper styles={mergedStyles} className={className}>
			<input
				onBlur={handleOnBlur}
				onFocus={handleOnFocus}
				className='fli-input'
				onChange={onChange}
				value={value}
				{...rest}
				id={id}
				required
			/>

			<label className='fli-label' htmlFor={id}>
				{label}
			</label>

			{infoMessage && !isBluried && isFocused ? (
				<p className='fli-info-message'>{infoMessage}</p>
			) : null}

			{errorMessage && hasError && isBluried ? (
				<p className='fli-error-message'>{errorMessage}</p>
			) : null}

			{isFocused || value === '' ? (
				<Search id='old-search-icon' />
			) : (
				<DisableButton onClick={() => handleClick()} id='search-icon'>
					<X size={14} />
				</DisableButton>
			)}
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelInput
