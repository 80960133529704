import React, { useMemo } from 'react'
import PageTitle from 'shared/components/PageTitle'
import {
	Loading,
	Button,
	Modal,
	FloatLabelInput,
	Select,
} from '@buildbox/components'
import ModalDelete from 'shared/components/ModalDelete'
import {
	Container,
	TableContent,
	PageHeader,
	ModalContent,
	SelectionsContainer,
} from './styles'
import { IViewProps } from './types'
import {
	primaryButtonStyles,
	primaryInputStyles,
	primaryUserSelectStyles,
	SeeUserCardButtonDisabledStyles,
	SeeUserCardButtonActiveStyles,
} from 'shared/styles/theme'
import { formatCPF } from 'shared/util/mask'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { theme } from 'shared/styles/theme'
import ShortInput from 'shared/components/ShortInput'
import ImageUpload from 'shared/components/ImageUpload'
import UserList from './UserList'

function ListUser(props: IViewProps): JSX.Element {
	const {
		loading,
		profilesOptions,
		changeModalState,
		showModal,
		handleChange,
		selectedUser,
		handleChangeSelectRetails,
		selectedRetails,
		handleChangeSelectProfile,
		selectedProfile,
		create,
		deleteModal,
		changeDeleteModalState,
		retailOptions,
		remove,
		edit,
		isFormValid,
		searchString,
		handleSearchStringChange,
		handleUploadPhotoUser,
		photoUserUrl,
		usersData,
		setUsersData,
		allSelected,
		setAllSelected,
		moveToSeeBadges,
		checkedUsers,
		setCheckedUsers,
	} = props

	const disabled = checkedUsers.length === 0

	const setButtonColor = useMemo(() => {
		if (disabled) {
			return { ...SeeUserCardButtonDisabledStyles }
		} else {
			return {
				...SeeUserCardButtonActiveStyles,
			}
		}
	}, [disabled])

	return (
		<>
			<Container>
				<PageHeader>
					<div className='input-filter-section'>
						<PageTitle>{'Usuários'}</PageTitle>

						<div>
							<ShortInput
								{...primaryInputStyles}
								label='Procurar por Usuário'
								round
								id='user-input-filter'
								onChange={handleSearchStringChange}
								value={searchString}
							/>
						</div>
					</div>
					<Button {...primaryButtonStyles} onClick={() => changeModalState()}>
						{'Novo'}
					</Button>
				</PageHeader>
				<TableContent>
					{loading ? (
						<Loading type='Digital' primaryColor={theme.colors.blue} />
					) : (
						<>
							<SelectionsContainer>
								<div>
									<input
										type='checkbox'
										name='all selected'
										checked={allSelected}
										onClick={() => setAllSelected(!allSelected)}
										readOnly
									/>
									<p>Selecionados ({checkedUsers.length})</p>
								</div>
								<div>
									<Button
										{...setButtonColor}
										onClick={moveToSeeBadges}
										disabled={disabled}
									>
										Ver Crachá{checkedUsers.length > 1 && 's'}
									</Button>
								</div>
							</SelectionsContainer>
							<div className='table-container'>
								{usersData.length ? (
									<UserList
										data={usersData}
										setData={setUsersData}
										searchString={searchString}
										changeModalState={changeModalState}
										changeDeleteModalState={changeDeleteModalState}
										setCheckedUsers={setCheckedUsers}
									/>
								) : (
									<DefaultPagePlaceholder />
								)}
							</div>
						</>
					)}
				</TableContent>

				<Modal
					isActive={showModal}
					onClose={() => changeModalState()}
					title={selectedUser._id ? 'Atualizar Usuário' : 'Novo Usuário'}
				>
					<ModalContent>
						<div className='select-container input-photo'>
							<ImageUpload
								width='211'
								imageUrl={photoUserUrl}
								returnImage={handleUploadPhotoUser}
							/>
						</div>
						<div className='select-container'>
							<Select
								{...primaryUserSelectStyles}
								options={profilesOptions}
								placeholder='Selecione o perfil'
								round
								id='roles'
								onChange={handleChangeSelectProfile}
								value={selectedProfile}
							/>
						</div>
						<div className='select-retail'>
							<Select
								{...primaryUserSelectStyles}
								placeholder='Selecione as lojas'
								options={retailOptions}
								round
								id='retails'
								onChange={handleChangeSelectRetails}
								value={selectedRetails}
								isMulti
							/>
						</div>
						<div className='input-container-wrapper'>
							<div className='input-container'>
								<FloatLabelInput
									{...primaryInputStyles}
									label='Nome'
									round
									id='name'
									onChange={handleChange}
									value={selectedUser.name}
								/>
							</div>
							<div className='input-container'>
								<FloatLabelInput
									{...primaryInputStyles}
									label='E-mail'
									round
									id='email'
									onChange={handleChange}
									value={selectedUser.email}
								/>
							</div>
							<div className='input-container'>
								<FloatLabelInput
									{...primaryInputStyles}
									label='CPF'
									round
									id='documentNumber'
									onChange={handleChange}
									value={formatCPF(selectedUser.documentNumber)}
									maxLength={14}
								/>
							</div>
							<div className='input-container'>
								<FloatLabelInput
									{...primaryInputStyles}
									label='Matrícula'
									round
									id='enrollment'
									onChange={handleChange}
									value={selectedUser.enrollment}
									maxLength={5}
								/>
							</div>
						</div>
						{selectedUser._id ? (
							<div className='button-container'>
								<Button
									disabled={!isFormValid}
									{...primaryButtonStyles}
									onClick={edit}
								>
									Atualizar
								</Button>
							</div>
						) : (
							<div className='button-container'>
								<Button
									disabled={!isFormValid}
									{...primaryButtonStyles}
									onClick={create}
								>
									Cadastrar
								</Button>
							</div>
						)}
					</ModalContent>
				</Modal>
				<ModalDelete
					isActive={deleteModal}
					onClose={() => changeDeleteModalState()}
					title='Excluir Usuário'
					message={`Você optou por Excluir o usuário ${selectedUser.name}, esta ação não poderá ser desfeita!`}
					onConfirm={remove}
					textButton='Excluir Usuário'
				/>
			</Container>
		</>
	)
}

export default ListUser
