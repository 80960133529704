import styled from 'styled-components'

import { ellipsis, fontType, toRem, transition } from '../../styles/functions'

export const DateInputWrapper = styled.div`
	position: relative;
	width: 100%;

	input[type='date']::-webkit-inner-spin-button,
	input[type='date']::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}

	input {
		padding: 26px 16px 10px 16px;
		border: solid 1px ${({ theme }) => theme.colors.lightGrey};
		border-radius: 30px;
		color: ${({ theme }) => theme.colors.grey};
		background: ${({ theme }) => theme.colors.white};
		width: 100%;
		font-size: ${toRem(14)};
		${transition()}
		${ellipsis()}

		&:focus,
 		&:valid {
			border: solid 1px ${({ theme }) => theme.colors.blue};

			+ label {
				${fontType(2)}
				top: 12px;
				left: 16px;
				font-size: ${toRem(10)};
				text-transform: uppercase;
				color: ${({ theme }) => theme.colors.blue};
			}
		}
	}

	label {
		position: absolute;
		top: 20px;
		left: 16px;
		color: ${({ theme }) => theme.colors.grey};
		font-size: ${toRem(14)};

		cursor: text;

		${fontType(0)}
		${transition()}
	}
`
