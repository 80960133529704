import React from 'react';
import ResponsiveScatterPlotGraph from 'shared/components/ResponsiveScatterPlotGraph'
import { IPerformanceDashValue } from 'shared/interfaces/ticket';
import { toRem } from 'shared/styles/functions';
import { theme } from 'shared/styles/theme';
import { toMoney } from 'shared/util/currency';
import { IProps } from './types';

const PerformanceSeparationGraph: React.FC<IProps> = (props) => {
	const { graphPerformanceData, vision } = props

	const tooltip = ({ node }: any) => {
		const data = node.data as IPerformanceDashValue
		const totalMinutes = data.x || 0
		const totalSkus = data.y || 0
		const totalRevenue = data.z || 0

		return (
			<div
				style={{
					background: theme.colors.darkGrey,
					color: 'white',
					borderRadius: 4,
					fontSize: toRem(12),
					padding: '16px',
				}}
			>
				<h4 style={{ fontWeight: 'bold' }}>Ticket {data.ticket}:</h4>
				<ul style={{ listStyle: 'none' }}>
					{vision !== 'REVENUE' && <li>Total SKU's: {totalSkus}</li>}
					<li>Tempo total: {totalMinutes.toFixed(1)}</li>
					<li>Faturamento: {toMoney(totalRevenue)}</li>
				</ul>
			</div>
		)
	}

	return (
		<ResponsiveScatterPlotGraph
			data={graphPerformanceData}
			vision={vision}
			tooltip={tooltip}
		/>
	);
}

export default PerformanceSeparationGraph;