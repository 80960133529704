import { Action, combineReducers, Reducer, ReducersMapObject } from 'redux'

import { IActionsCreators } from '../../interfaces/action'
import { IStore } from '../../interfaces/store'
import user from './user'
import token from './token'

const reducers: ReducersMapObject<IStore, Action<IActionsCreators>> = {
	user,
	token,
}

export const Reducers: Reducer = combineReducers(reducers)
