import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const WithdrawalBoardWrapper = styled.div`
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: #fafafa;
	padding: 0 87px;
`

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const InputWrapper = styled.div`
	#search-input {
		width: 283px;
	}
`
export const ContainerListCards = styled.div`
	/* display: flex; */
	padding: 30px 0;
	height: calc(100% - 80px);
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
`

export const CardPanelWrapper = styled.div`
	margin-top: 32px;
`

export const ModalWrapper = styled.div`
	* {
		${fontType(0)}
	}
`
