import { fontType, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const CardWrapperContainer = styled.div`
	* {
		${fontType(1)}
	}
	cursor: pointer;

	/* width: ${toRem(250)}; */
	height: auto;
	padding: ${toRem(10)};

	&:active {
		position: relative;
		top: 2px;
	}
`
export const Content = styled.div`
	padding: 15px 0px 15px 0px;

	span {
		color: ${({ theme }) => theme.colors.grey};
		font: normal normal medium 10px/13px Montserrat;
		letter-spacing: ${toRem(0.48)};
		color: ${({ theme }) => theme.colors.grey};
		text-transform: uppercase;
		opacity: 1;
		font-size: ${toRem(11)};
		margin-top: 20px;
	}
`

export const CardWrapper = styled.div`
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.lightWhite} 0% 0% no-repeat
		padding-box;
	border-radius: ${toRem(7)};
	padding: ${toRem(15)};

	p {
		font-size: 14px;
		color: ${({ theme }) => theme.colors.darkGrey};
		font-family: 'Montserrat Regular', sans-serif;
	}
`

export const BadgeTicket = styled.span`
	color: ${({ theme }) => theme.colors.blue};
	font-size: ${toRem(11)};
	background: ${({ theme }) => theme.colors.lightBlue};
	padding: ${toRem(6)};
	border-radius: ${toRem(50)};
	height: auto;
	min-width: 52px;
	text-align: center;
	font-weight: medium;
	margin-right: 12px;
`
export const OrderCounter = styled.span`
	span {
		color: ${({ theme }) => theme.colors.grey};
		margin-left: ${toRem(4)};
		letter-spacing: ${toRem(0.48)};
		font-size: ${toRem(12)};
	}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`
export const Footer = styled.div`
	color: ${({ theme }) => theme.colors.grey};
	font-size: ${toRem(13)};
	display: flex;
	justify-content: flex-end;
	padding: ${toRem(10)};
`
export const CreatedByWrapper = styled.div`
	margin-top: 8px;

	> span {
		color: #354d9e;
		font-size: 10px;
		margin-left: 4px;
	}
`
export const BadgeRetail = styled(BadgeTicket)`
	display: block;
	margin: 0 0 15px;
`
