import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Button } from '@buildbox/components'

import routesEnum from 'modules/Routes/enum'
import { primaryButtonStyles, primaryInputStyles } from 'shared/styles/theme'

import Logo from '../../assets/images/logo-higa-login.png'
import ArrowIcon from '../../assets/images/arrow-left.svg'
import {
	Container,
	LogoHiga,
	QRCodeWrapper,
	QRCodeType,
	QRCode,
	HeaderActions,
	TitleDetails,
	LinkContent,
	LeftContentWrapper,
	GuidanceText,
	BoldText,
} from './styles'
import { IViewProps } from './types'

const QRCodePrintingView = ({ srcQrcode, typeQrcode }: IViewProps) => {
	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	})

	return (
		<Container>
			<LeftContentWrapper className='leftWrapper'>
				<Link to={routesEnum.STORE_AISLES}>
					<LinkContent>
						<img src={ArrowIcon} alt='Ìcone de seta' />
						<Button {...primaryInputStyles} className='buttonBackAreas'>
							Todas as Áreas
						</Button>
					</LinkContent>
				</Link>
				<TitleDetails>Detalhes da Área</TitleDetails>
			</LeftContentWrapper>
			<HeaderActions>
				<Button
					{...primaryButtonStyles}
					onClick={handlePrint}
					className='buttonPrintingQRCode'
				>
					Imprimir QR Code
				</Button>
			</HeaderActions>
			<QRCodeWrapper ref={componentRef}>
				<QRCode src={srcQrcode} alt='QR code' />
				<QRCodeType>{typeQrcode}</QRCodeType>
				<GuidanceText>
					Abra o aplicativo do Higa e toque em{' '}
					<BoldText>Chamar Atendente</BoldText> para ler o QR Code acima e ser
					atendido.
				</GuidanceText>
				<LogoHiga src={Logo} alt='Logo Higa Atacado' />
			</QRCodeWrapper>
		</Container>
	)
}

export default QRCodePrintingView
