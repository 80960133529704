import ListSeparationTickets from 'modules/ListSeparationTickets'
import React from 'react'
import { ContainerListCards, LastBoardWrapper, SectionLabel } from './styles'
import { IViewProps } from './types'

function LateScheduleTicketsBoardView(props: IViewProps): JSX.Element {
	const {
		pendingColumn,
		separationColumn,
		checkingColumn,
	} = props

	return (
		<LastBoardWrapper>
			<SectionLabel>Atrasados</SectionLabel>
			<ContainerListCards>
				<ListSeparationTickets
					title='Pendente'
					cards={pendingColumn}
					{...props}
				/>
				<ListSeparationTickets
					title='Em separação'
					cards={separationColumn}
					{...props}
				/>
				<ListSeparationTickets
					title='Em Conferência'
					cards={checkingColumn}
					{...props}
				/>
			</ContainerListCards>
		</LastBoardWrapper>
	)
}

export default LateScheduleTicketsBoardView
