import styled from 'styled-components'
import { fontType } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'

export const Container = styled.div`
    & * {
        ${fontType(0)};
    }

    p.form-label {
        margin-top: 48px;
        color: ${theme.colors.darkGrey};
        font-size: 14px;
        ${fontType(1)};
    }

    div.input-title {
        margin-top: 16px;
        max-width: 586px;
    }

    div.input-body {
        margin-top: 32px;
    }

    div.submit-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;
    }
`
