// TODO: Criar um Bucket para a XP

import * as AWS from 'aws-sdk'
import { UtilGenerators } from '@buildbox/utils'

const region = 'us-east-1'
const bucketName = 'dev-buildbox-s3'
const accessKeyId = 'AKIAIKWWJZO4UODKWBPA'
const secretAccessKey = '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'

function config(): void {
    try {
        AWS.config.update({ region, accessKeyId, secretAccessKey })
    } catch (err) {
        console.error({ err })
    }
}

export function uploadToS3(file: any) {
    config()

    const S3: AWS.S3 = new AWS.S3({
        params: { Bucket: bucketName }
    })

    const [, ...rest] = file.name.split('.')

    const extension: string = rest[rest.length - 1] || 'jpg'

    const fileKey = `${UtilGenerators.generateRandomSerial(16)}.${extension}`

    const uploadPromise: Promise<AWS.S3.ManagedUpload.SendData> = S3.upload({
        Key: fileKey,
        Bucket: bucketName,
        Body: file
    }).promise()

    return uploadPromise
}
