import axiosInstance from './axiosInstance'
import { IUser } from 'shared/interfaces/user'

interface ILogin {
	enrollment: string
	documentNumber: string
}
interface ILoginResponse {
	user: IUser
	accessToken: string
}

export async function login(data: ILogin): Promise<ILoginResponse> {
	const response = await axiosInstance.post('auth/login', data)

	return response.data.content
}
