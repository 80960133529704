import React, { useState, useEffect } from 'react'
import handleError from 'shared/util/handleError'
import { IPermission } from 'shared/interfaces/permissions'
import Switch from 'shared/components/Switch'
import { translateSystemName } from 'shared/util/translate'

import { Modal, Table, FloatLabelInput, Button } from '@buildbox/components'
import {
	primaryButtonStyles,
	ProfileInputStyles,
	tableStyle,
} from 'shared/styles/theme'

import {
	ModalWrapper,
	InputWrapper,
	ModalTitleSection,
	ModalButtonWrapper,
} from './styles'
import { IProfileModalProps } from './types'

function ProfileModal(props: IProfileModalProps): JSX.Element {
	const {
		handleChangeNewProfileName,
		handleChangePermissions,
		currentProfile,
		handleCreateProfile,
		handleToggleModalState,
		modalIsActive,
		isEditing,
		updateProfile,
		permissionsList,
	} = props

	const [permissionTableRows, setPermissionTableRows] = useState<any[]>([])

	const permissionTableColumns: any[] = [
		{ Header: '', accessor: 'isEnable', sortType: 'basic' },
		{ Header: 'Sistema', accessor: 'system', sortType: 'basic' },
		{ Header: 'Menu', accessor: 'menu', sortType: 'basic' },
		{ Header: 'Tela', accessor: 'screen', sortType: 'basic' },
		{ Header: 'Permissão', accessor: 'permission', disableSortBy: true },
	]

	function fetchDataTable() {
		; (async () => {
			try {
				setPermissionTableRows(
					permissionsList.map((permission) => {
						return {
							isEnable: renderSwitchButton(permission),
							system: renderDataItem(
								permission.platform,
								true,
								translateSystemName,
							),
							menu: renderDataItem(permission.menu),
							permission: renderDataItem(permission.description),
							screen: renderDataItem(permission.screenName),
						}
					}),
				)
			} catch (error) {
				handleError(error)
			}
		})()
	}

	function renderDataItem(
		str: string,
		translate = false,
		translateFunction?: any,
	) {
		if (translate) {
			return str ? translateFunction(str) : '-'
		}

		return str ? str : '-'
	}

	function changePermission(permission: string, value: boolean) {
		let newPermissionsArray = currentProfile.permissions

		if (value) {
			newPermissionsArray.push(permission)
		} else {
			newPermissionsArray = newPermissionsArray.filter(
				(item) => item !== permission,
			)
		}

		handleChangePermissions(newPermissionsArray)
	}

	function renderSwitchButton(permission: IPermission): JSX.Element {
		const permissionIsActived = currentProfile.permissions.some(
			(item) => item === permission._id,
		)

		return (
			<Switch
				label=''
				isChecked={permissionIsActived}
				onChange={(value) =>
					permission._id && changePermission(permission._id, value)
				}
			/>
		)
	}

	useEffect(fetchDataTable, [currentProfile])

	return (
		<ModalWrapper>
			<Modal
				title={isEditing ? 'Atualizar Perfil' : 'Novo Perfil'}
				onClose={handleToggleModalState}
				isActive={modalIsActive}
				size={800}
				className='profile-modal'
			>
				<>
					<InputWrapper>
						<FloatLabelInput
							label='Nome do Perfil'
							id='profile-name-input'
							{...ProfileInputStyles}
							round={true}
							onChange={handleChangeNewProfileName}
							value={currentProfile.name}
						/>
					</InputWrapper>

					<ModalTitleSection>Permissões</ModalTitleSection>

					<Table
						{...tableStyle}
						columns={permissionTableColumns}
						data={permissionTableRows}
						pageSize={permissionTableRows.length}
					/>

					<ModalButtonWrapper>
						<Button
							{...primaryButtonStyles}
							onClick={isEditing ? updateProfile : handleCreateProfile}
						>
							{isEditing ? 'Atualizar' : 'Cadastrar'}
						</Button>
					</ModalButtonWrapper>
				</>
			</Modal>
		</ModalWrapper>
	)
}

export default ProfileModal
