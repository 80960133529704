import React, { useEffect, useState } from 'react'
import BarGraph from 'shared/components/BarGraph'

import { numberFixed } from 'shared/util/mask'
import { translateChannelName, abbreviateLastName } from 'shared/util/translate'
import { IProps } from './types'
import { IGraphData } from 'shared/components/BarGraph/types'
import { toMoney } from 'shared/util/currency'
import { VisionTypeOptions } from 'shared/interfaces/ticket'

function DashboardBarGraph(props: IProps): JSX.Element {
	const { dataGraph, graph, vision } = props

	const tooltipDataStyle = {
		listStyle: 'none',
	}

	const tooltip = ({ data }: any) => (
		<>
			<h4 style={{ fontWeight: 'bold' }}>{data.name}:</h4>
			<ul style={tooltipDataStyle}>
				<li>
					{`Total Aprovado: ${
						vision === VisionTypeOptions.REVENUE
							? toMoney(!data.totalApproved ? 0 : data.totalApproved)
							: !data.totalApproved
							? 0
							: data.totalApproved
					}`}
				</li>
				<li>
					{`Total Aberto: ${
						vision === VisionTypeOptions.REVENUE
							? toMoney(!data.totalOpened ? 0 : data.totalOpened)
							: !data.totalOpened
							? 0
							: data.totalOpened
					}`}
				</li>
				<li>
					Percentual Aprovado:
					{!data.percentApproved ? 0 : numberFixed(data.percentApproved * 100)}%
				</li>
				<li>
					Percentual Aberto:
					{' '}
					{!data.percentOpened ? 0 : numberFixed(data.percentOpened * 100)}%
				</li>
			</ul>
		</>
	)

	const [data, setData] = useState<IGraphData[]>([])

	useEffect(() => {
		if (dataGraph) {
			if (graph === 'CHANNEL') {
				const translatedChannelName = dataGraph.map((item) => {
					return {
						...item,
						name: translateChannelName(item.name),
					}
				})
				setData(translatedChannelName)
			} else if (graph === 'TEAM') {
				setData(
					dataGraph
						.filter((item) => item.totalOpened >= 1 || item.totalApproved >= 1)
						.map((item) => ({
							...item,
							abbreviatedName: abbreviateLastName(item.name),
						})),
				)
			}
		}
	}, [dataGraph, graph])

	return (
		<BarGraph data={data} vision={vision} tooltipData={tooltip} graph={graph} />
	)
}

export default DashboardBarGraph
